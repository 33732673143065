import { IconButton } from '@mui/material'
import { TbChevronLeft, TbInfoCircle, TbPlus, TbX } from 'react-icons/tb'
import './SidebarAccessoires.css'
import { AccessoireItems } from '../../../../../../data/dummy/TrailerTypes'
import { useState } from 'react'
import useUpdateTrailer from '../../hooks/useUpdateTrailer'
import isEqual from 'lodash.isequal'

export default function SidebarAccessoires({
  setSidebarContent,
  trailerInfo,
  setTrailerInfo,
}) {
  const [currentAccessoires, setCurrentAccessoires] = useState([
    ...trailerInfo.accesoires,
  ])
  const [customAccessoire, setCustomAccessoire] = useState('')

  const updateTrailerData = useUpdateTrailer(trailerInfo)

  const addAccessoire = () => {
    if (!customAccessoire) {
      return
    }
    if (!currentAccessoires.includes(customAccessoire)) {
      setCurrentAccessoires([...currentAccessoires, customAccessoire])
      setCustomAccessoire('')
    }
  }

  const addRegularAccessoire = (item: string) => {
    if (!currentAccessoires.includes(item)) {
      setCurrentAccessoires([...currentAccessoires, item])
    }
  }

  const removeAccessoire = (accessoire: string) => {
    const updatedAccessoires = currentAccessoires.filter(
      (item: string) => item !== accessoire
    )
    setCurrentAccessoires(updatedAccessoires)
  }

  const handleUpdateField = async (field, value) => {
    const updatedData = await updateTrailerData(field, value)
    setTrailerInfo(updatedData)
    setSidebarContent('')
    window.location.reload()
  }

  return (
    <div className="sideBarSettingsWrapper">
      <div className="sidebarSettingsHeader">
        <IconButton className="goBack" onClick={() => setSidebarContent('')}>
          <TbChevronLeft />
        </IconButton>
        <p>Accessoires</p>
        <p>
          Vermeld welke accessoires de huurder kan gebruiken voor deze
          aanhanger.
        </p>
      </div>
      <div className="sidebarSettingsContentContainer accessoires">
        <p>Accessoires toevoegen</p>
        <div className="searchbox accessoires">
          <input
            placeholder="Accessoire toevoegen..."
            value={customAccessoire}
            onChange={(e) => setCustomAccessoire(e.target.value)}
          />
          <button className="addAccessoire" onClick={() => addAccessoire()}>
            <TbPlus />
          </button>
        </div>
        {currentAccessoires.length > 0 && (
          <>
            <p>Bijgevoegde accessoires</p>
            {currentAccessoires.map((item, index) => (
              <div
                className="trailerTypeSelectContainer accessoires flexbox"
                key={index}
                onClick={() => removeAccessoire(item)}
              >
                <p>{item}</p>
                <div className="checkbox remove">
                  <TbX />
                </div>
              </div>
            ))}
          </>
        )}
      </div>
      <div
        className={`sidebarSettingsContentContainer ${
          currentAccessoires.length > 0 ? 'popular' : 'noResults'
        }`}
      >
        <p>Populaire accessoires</p>
        <div className="sidebarTrailerTypeGrid">
          {AccessoireItems.map((item) => {
            const isIncluded = currentAccessoires.some(
              (accessoire) => accessoire === item.accessoire
            )

            return (
              <div
                className={`trailerTypeSelectContainer accessoires ${
                  isIncluded && 'active'
                }`}
                key={item.id}
                onClick={() => {
                  !isIncluded
                    ? addRegularAccessoire(item.accessoire)
                    : removeAccessoire(item.accessoire)
                }}
              >
                <div>{item.icon}</div>
                <p>{item.accessoire}</p>
                <TbInfoCircle className="infoIcon" />
              </div>
            )
          })}
        </div>
      </div>
      {!isEqual(currentAccessoires, trailerInfo.accesoires) && (
        <div className="sidebarSettingsFooterAction updated">
          <button
            className="sidebarActionButton"
            onClick={() => handleUpdateField('accessoire', currentAccessoires)}
          >
            Wijzigingen opslaan
          </button>
        </div>
      )}
    </div>
  )
}
