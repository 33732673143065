import './SidebarLocation.css'
import { TbChevronLeft } from 'react-icons/tb'
import { IconButton } from '@mui/material'
import AutoComplete from '../../../../../addTrailerForm/hook/AutoComplete'
import { useEffect, useReducer, useCallback, useMemo, useState } from 'react'
import { Address } from '../../../../../addTrailerForm/TrailerForm'
import useUpdateTrailer from '../../hooks/useUpdateTrailer'

export interface LocationItem {
  long_name: string
  types: string[]
}

export interface GeoCode {
  lat: number
  lng: number
}

const addressReducer = (state, action) => {
  switch (action.type) {
    case 'update':
      return { ...state, [action.field]: action.value }
    default:
      return state
  }
}

const labelMap = {
  streetName: 'Straatnaam',
  city: 'Stad',
  postalCode: 'Postcode',
  houseNumber: 'Huisnummer',
}

export default function SidebarLocation({
  trailerInfo,
  setTrailerInfo,
  setSidebarContent,
}) {
  const [location, setLocation] = useState<LocationItem[]>([])
  const [geoCode, setGeoCode] = useState<GeoCode | null>(null)
  const [address, dispatch] = useReducer(addressReducer, {
    streetName: '',
    city: '',
    postalCode: '',
    houseNumber: '',
  })
  const updateTrailerData = useUpdateTrailer(trailerInfo)

  const updateAddress = useCallback(
    (fieldName: keyof Address, fieldValue: string) => {
      dispatch({ type: 'update', field: fieldName, value: fieldValue })
    },
    []
  )

  useEffect(() => {
    const componentMapping = {
      route: 'streetName',
      locality: 'city',
      postal_code: 'postalCode',
      street_number: 'houseNumber',
    }
    Object.entries(componentMapping).forEach(([component, addressField]) => {
      const componentValue = getAddressComponent(component)
      if (componentValue) {
        updateAddress(addressField as keyof Address, componentValue)
      } else {
        updateAddress(addressField as keyof Address, '')
      }
    })
  }, [location, updateAddress])

  useEffect(() => {
    if (trailerInfo.address) {
      Object.keys(trailerInfo.address).forEach((key) => {
        updateAddress(key as keyof Address, trailerInfo.address[key])
      })
    }
  }, [trailerInfo, updateAddress])

  function getAddressComponent(type: string): string {
    const result = location.find((item) => item.types.includes(type))
    return result ? result.long_name : ''
  }

  const handleUpdateField = useCallback(
    async (field, value) => {
      try {
        const updatedData = await updateTrailerData(field, value)
        setTrailerInfo(updatedData)
        setSidebarContent('')
      } catch (error) {
        console.error('Error updating trailer field:', error)
      }
    },
    [updateTrailerData, setTrailerInfo, setSidebarContent]
  )

  const addressObj = useMemo(
    () => ({
      city: address.city,
      streetName: address.streetName,
      postalCode: address.postalCode,
      houseNumber: address.houseNumber,
    }),
    [address]
  )

  return (
    <div className="sideBarSettingsWrapper">
      <div className="sidebarSettingsHeader">
        <IconButton className="goBack" onClick={() => setSidebarContent('')}>
          <TbChevronLeft />
        </IconButton>
        <p>Locatie aanpassen</p>
        <p>
          Wijzig de ophaal- & terug breng locatie van geselecteerde aanhanger.
        </p>
      </div>
      <div className="sidebarSettingsContentContainer location">
        <div className="locationRadiusInfoContainer">
          <div className="locationRadiusImage">
            <img alt="radius" src={'/images/radius_info.svg'} />
          </div>
          <div className="radiusInfoContent">
            <p>Locatie radius</p>
            <p>
              De exacte locatie van jouw aanhanger wordt alleen vrijgegeven bij
              verhuur.
            </p>
          </div>
        </div>
        <p>Locatie</p>
        <div className="searchbox price location">
          <AutoComplete
            setLocation={setLocation}
            customStyle={'sidebar'}
            setGeoCode={setGeoCode}
          />
        </div>
        <div className="divider fullwidth" />
        <div className="exactLocationSidebarGrid">
          {Object.keys(address).map((key) => (
            <div className="locationSideBarGridItem" key={key}>
              <span>{labelMap[key]}</span>
              <input
                placeholder=""
                type="text"
                value={address[key]}
                required
                onChange={(e) =>
                  updateAddress(key as keyof Address, e.target.value)
                }
                id={key}
                name={key}
              />
            </div>
          ))}
        </div>
      </div>
      {Object.keys(addressObj).some(
        (key) => addressObj[key] !== trailerInfo.address[key]
      ) && (
        <div className="sidebarSettingsFooterAction updated">
          <button
            className="sidebarActionButton"
            onClick={() => {
              handleUpdateField('address', address)
              window.location.reload()
            }}
          >
            Wijzigingen opslaan
          </button>
        </div>
      )}
    </div>
  )
}
