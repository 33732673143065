import { useEffect, useRef } from 'react'

export default function useDebouncedEffect(effect, deps, delay) {
  const timeoutRef = useRef(null)

  useEffect(() => {
    const handler = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
      timeoutRef.current = setTimeout(() => {
        effect()
      }, delay)
    }

    handler()

    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, deps)
}
