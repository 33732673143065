import { cva, VariantProps } from 'class-variance-authority'
import './tag.css'

const tagVariants = cva('tag', {
  variants: {
    variant: {
      primary: 'tag__primary',
      secondary: 'tag__secondary',
      outline: 'tag__outline',
      primaryOutline: 'tag__outline-primary',
    },
    size: {
      sm: 'tag__small',
      md: 'tag__medium',
      lg: 'tag__large',
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
})

export interface TagProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof tagVariants> {}

const Tag = ({
  variant,
  size,
  children,
  ...props
}: TagProps): React.ReactElement => {
  return <div className={`${tagVariants({ variant, size })}`}>{children}</div>
}

export { Tag }
