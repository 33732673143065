import { useState, useRef, useCallback, useEffect } from 'react'
import { GoogleMap, OverlayViewF, OverlayView } from '@react-google-maps/api'
import CustomMarker from './CustomMarker'
import { TbMinus, TbPlus } from 'react-icons/tb'
import './Map.css'
import mapStyles from '../../../data/mapStyles.js'

interface MapProps {
  filteredTrailers: any[]
  setCenterCoordinates: (coordinates: { lat: number; lng: number }) => void
  centerCoordinates: { lat: number; lng: number }
}

const Map: React.FC<MapProps> = ({
  filteredTrailers,
  setCenterCoordinates,
  centerCoordinates,
}) => {
  const mapRef = useRef(null)
  const [activeTrailer, setActiveTrailer] = useState<any>({})
  const [zoomLevel, setZoomLevel] = useState(12)

  const mapContainerStyle = { width: '100%', height: '100%', borderRadius: 10 }
  const options = {
    styles: mapStyles,
    disableDefaultUI: true,
    clickableIcons: false,
  }

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.panTo(centerCoordinates)
    }
  }, [centerCoordinates])

  function handleLoad(map) {
    mapRef.current = map
  }

  const handleZoomChanged = useCallback(() => {
    const newZoom = mapRef.current?.getZoom()
    if (newZoom !== undefined) {
      setZoomLevel(newZoom)
    }
  }, [])

  const handleCenterChanged = useCallback(() => {
    const newPos = mapRef.current?.getCenter().toJSON()
    if (newPos) {
      setCenterCoordinates(newPos)
    }
  }, [setCenterCoordinates])

  function panTo(coords) {
    if (mapRef.current) {
      mapRef.current.panTo(coords)
    }
  }

  function zoomIn() {
    if (mapRef.current) {
      const currentZoom = mapRef.current.getZoom()
      mapRef.current.setZoom(currentZoom + 1)
    }
  }

  function zoomOut() {
    if (mapRef.current) {
      const currentZoom = mapRef.current.getZoom()
      mapRef.current.setZoom(currentZoom - 1)
    }
  }

  // Adjust marker size based on zoom level
  const markerSize = Math.max(10, 30 - zoomLevel)

  return (
    <div className="map">
      <GoogleMap
        onLoad={handleLoad}
        zoom={zoomLevel}
        center={centerCoordinates}
        options={options}
        mapContainerStyle={mapContainerStyle}
        onDragEnd={handleCenterChanged}
        onZoomChanged={handleZoomChanged}
      >
        <div className="zoomButtonContainer">
          <button onClick={zoomIn}>
            <TbPlus />
          </button>
          <button onClick={zoomOut}>
            <TbMinus />
          </button>
        </div>
        {filteredTrailers?.slice(0, zoomLevel * 2).map((trailer) => (
          <OverlayViewF
            key={trailer.id}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            position={{
              lat: Number(trailer.nearbyLatitude),
              lng: Number(trailer.nearbyLongitude),
            }}
          >
            <div
              onClick={() => setActiveTrailer(trailer)}
              style={{ width: markerSize, height: markerSize }}
            >
              <CustomMarker
                trailer={trailer}
                setCenterCoordinates={(coords) => {
                  panTo(coords)
                  setCenterCoordinates(coords)
                }}
                activeTrailer={activeTrailer}
                setActiveTrailer={setActiveTrailer}
                mapRef={mapRef}
              />
            </div>
          </OverlayViewF>
        ))}
      </GoogleMap>
    </div>
  )
}

export default Map
