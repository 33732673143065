import useClickOutside from 'hooks/onClickOutside'
import { useEffect, useRef, useState } from 'react'
import { TbChevronDown } from 'react-icons/tb'
import './TimeSelect.css'
import {
  getTimeString,
  getTimeNumber,
} from '../../view/utility/CalendarUtilities'

interface TimeSelectProps {
  timePeriods?: { startTime: string; endTime: string }[]
  timeBoxNumber: number
  addNewTimePeriod: () => void
  deleteTimePeriod: () => void
  period: { startTime: string; endTime: string }
  handleTimeChange: (
    timeBoxNumber: number,
    startTime: number,
    endTime: number
  ) => void
  activeRanges?: number[]
}

const TimeSelect: React.FC<TimeSelectProps> = ({
  timePeriods = [],
  timeBoxNumber,
  addNewTimePeriod,
  period,
  deleteTimePeriod,
  handleTimeChange,
  activeRanges,
}) => {
  const timeSelectRef = useRef(null)
  const [showTimeSelectOptions, setShowTimeSelectOptions] = useState(false)
  const [optionsArray, setOptionsArray] = useState<number[][][]>([])

  const convertToNumberArray = (timePeriods) => {
    return timePeriods.flatMap(({ startTime, endTime }) => [
      getTimeNumber(startTime),
      getTimeNumber(endTime),
    ])
  }

  useEffect(() => {
    const times: number[] = []
    for (let i = 5; i <= 25; i += 0.5) {
      times.push(i)
    }

    const timePeriodsInNumbers =
      activeRanges?.length > 0
        ? activeRanges
        : convertToNumberArray(timePeriods)

    // Create pairs of time periods, where each pair is a separate period
    const periodPairs = []
    for (let i = 0; i < timePeriodsInNumbers?.length; i += 2) {
      periodPairs.push([timePeriodsInNumbers[i], timePeriodsInNumbers[i + 1]])
    }

    const startTimes = periodPairs.map((pair, index) => {
      const previousPairEndTime = index > 0 ? periodPairs[index - 1][1] : null
      if (previousPairEndTime !== null) {
        return times.filter(
          (t) => t >= previousPairEndTime + 1 && t < pair[1] - 1
        )
      } else {
        return times.filter((t) => t < pair[1] - 1)
      }
    })

    const endTimes = periodPairs.map((pair, index) => {
      const nextPairStartTime =
        index < periodPairs?.length - 1 ? periodPairs[index + 1][0] : null
      if (nextPairStartTime !== null) {
        return times.filter(
          (t) => t >= pair[0] + 1 && t <= nextPairStartTime - 1
        )
      } else {
        return times.filter((t) => t >= pair[0] + 1)
      }
    })

    setOptionsArray([startTimes, endTimes])
  }, [timePeriods])

  useClickOutside(timeSelectRef, () => {
    setShowTimeSelectOptions(false)
  })

  const handleStartTimeSelect = (time) => {
    const tempEndTime = getTimeNumber(period.endTime)
    handleTimeChange(timeBoxNumber, time, tempEndTime)
    // setShowTimeSelectOptions(false)
  }

  const handleEndTimeSelect = (time) => {
    const tempStartTime = getTimeNumber(period.startTime)
    handleTimeChange(timeBoxNumber, tempStartTime, time)
    setShowTimeSelectOptions(false)
  }

  return (
    <div className="dayDetailsTimeSelectWrapper" ref={timeSelectRef}>
      <div
        className="dayDetailsTimeSelectCombo test"
        onClick={() => setShowTimeSelectOptions((current) => !current)}
      >
        <label>Tijdslot (van - tot)</label>
        <p>
          {getTimeString(getTimeNumber(period?.startTime?.slice(0, 5)))} -{' '}
          {getTimeString(getTimeNumber(period?.endTime?.slice(0, 5)))}
        </p>

        <TbChevronDown
          className={
            showTimeSelectOptions
              ? 'chevronTransFormed timeSelectIcon'
              : 'chevron'
          }
        />
      </div>
      <div className="timePeriodActions">
        <u onClick={deleteTimePeriod}>Verwijderen</u>
        {timeBoxNumber === timePeriods?.length - 1 &&
          getTimeNumber(timePeriods[timePeriods?.length - 1].endTime) < 23.5 &&
          timePeriods?.length < 3 && (
            <u onClick={addNewTimePeriod}>Nieuw tijdslot</u>
          )}
      </div>
      {showTimeSelectOptions && (
        <div className="dayDetailsTimeSelectOptionsContainer">
          <div className="dayDetailsTimeSelectOptionsContainerHeader">
            <p>Tijdslot (van - tot)</p>
          </div>
          <div className="timeSelectOptionsWrapper">
            <div className="dayDetailsTimeSelectOptions startTime">
              {optionsArray[0][timeBoxNumber]?.map((time, index) => (
                <div
                  key={index}
                  className="timeSelectSuggestionText"
                  onClick={() => handleStartTimeSelect(time)}
                >
                  <p
                    className={
                      getTimeNumber(period.startTime) === time
                        ? 'selectedTime'
                        : ''
                    }
                  >
                    {getTimeString(time)}
                  </p>
                </div>
              ))}
            </div>

            <div className="dayDetailsTimeSelectOptions endTime">
              {optionsArray[1][timeBoxNumber]
                ?.filter((time) => time > getTimeNumber(period.startTime))
                .map((time, index) => (
                  <div
                    key={index}
                    className="timeSelectSuggestionText"
                    onClick={() => handleEndTimeSelect(time)}
                  >
                    <p
                      className={
                        getTimeNumber(period.endTime) === time
                          ? 'selectedTime'
                          : ''
                      }
                    >
                      {getTimeString(time)}
                    </p>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default TimeSelect
