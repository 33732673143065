import TimeSelect from 'components/profile/trailer_owner/calendar_view/sidebar/elements/TimeSelect'
import {
  getTimeNumber,
  getTimeString,
} from 'components/profile/trailer_owner/calendar_view/view/utility/CalendarUtilities'
import useWindowWidth from 'hooks/useWindowWidth'
import moment from 'moment'
import { useEffect } from 'react'
import { Slider, Handles, Tracks, Rail } from 'react-compound-slider'
import { TbX } from 'react-icons/tb'
import Handle from './Handle'

const sliderStyle = {
  position: 'relative',
  width: '100%',
}

export default function IsAvailable({
  activeRanges,
  setActiveRanges,
  setShowOverview,
  timePeriods,
  setTimePeriods,
  t,
}) {
  const windowWidth = useWindowWidth()
  const update = (updateValues) => {
    setActiveRanges(updateValues)
  }

  const onChange = (updateValues) => {
    setActiveRanges(updateValues)
  }

  useEffect(() => {
    setTimePeriods(formatTimePeriods(activeRanges))
  }, [activeRanges])

  const formatTime = (time) => {
    let hours = Math.floor(time)
    let minutes = (time - hours) * 60

    // Handle special cases
    if (hours === 24) hours = 0
    if (hours === 25) hours = 1

    // Convert hours and minutes to string and padStart with '0' to get '00' format
    const hoursStr = hours.toString().padStart(2, '0')
    const minutesStr = minutes === 0 ? '00' : '30'

    return `${hoursStr}:${minutesStr}`
  }

  const addNewTimeFrame = () => {
    if (activeRanges.length < 6) {
      const firstValue = activeRanges[0]
      if (
        activeRanges.length === 2 &&
        activeRanges[0] === 5 &&
        activeRanges[activeRanges.length - 1] === 25
      ) {
        setActiveRanges([5, 14, 15, 25])
      } else if (
        activeRanges.length === 4 &&
        activeRanges[0] === 5 &&
        activeRanges[activeRanges.length - 1] === 25
      ) {
        setActiveRanges([5, 11, 12, 18, 19, 25])
      } else if (activeRanges.length === 0) {
        setActiveRanges([5, 25])
      } else if (firstValue > 6) {
        // there is space for another timeframe before the first one
        setActiveRanges([
          firstValue - 2, // new range start
          firstValue - 1, // new range end
          ...activeRanges,
        ])
      } else {
        const lastValue = activeRanges[activeRanges.length - 1]
        setActiveRanges([
          ...activeRanges,
          (lastValue + 2) % 24,
          (lastValue + 5) % 24,
        ])
      }
    }
  }

  const timeBlocks = Array.from({ length: 11 }, (_, i) => (i * 2 + 5) % 24)

  const timePeriodsNumbers = (timePeriods || [])
    .map(({ startTime, endTime }) => {
      if (!startTime || !endTime) {
        // setIsAvailable(false)
        return [7, 12]
      }
      return [getTimeNumber(startTime), getTimeNumber(endTime)]
    })
    .flat()

  const handleTimeChange = (timeBoxNumber, startTime, endTime) => {
    const index = Number(timeBoxNumber)
    let updatedTimePeriods = [...timePeriodsNumbers]
    updatedTimePeriods[index * 2] = startTime
    updatedTimePeriods[index * 2 + 1] = endTime
    for (let i = 0; i < updatedTimePeriods.length; i += 2) {
      if (updatedTimePeriods[i + 1] <= updatedTimePeriods[i]) {
        updatedTimePeriods[i + 1] = updatedTimePeriods[i] + 0.5
      }
    }
    if (updatedTimePeriods[updatedTimePeriods.length - 1] > 25) {
      for (let i = updatedTimePeriods.length - 1; i >= 0; i -= 2) {
        if (updatedTimePeriods[i] > 25) {
          updatedTimePeriods.splice(i - 1, 2)
        } else {
          break
        }
      }
    }
    setTimePeriods(
      updatedTimePeriods
        .map((timeNumber, i) => {
          if (i % 2 === 0 && updatedTimePeriods[i + 1] !== undefined) {
            return {
              startTime: getTimeString(timeNumber),
              endTime: getTimeString(updatedTimePeriods[i + 1]),
            }
          }
          return null
        })
        .filter(Boolean)
    )
  }

  function addNewTimePeriod() {
    if (timePeriods.length < 3) {
      setTimePeriods((prevTimePeriods) => {
        const lastItem = prevTimePeriods[prevTimePeriods.length - 1]
        const lastEndTime = moment(lastItem.endTime, 'HH:mm')
        const newStartTime = lastEndTime.add(60, 'minutes').format('HH:mm')
        const newEndTime = lastEndTime.add(60, 'minutes').format('HH:mm')
        return [
          ...prevTimePeriods,
          { startTime: newStartTime, endTime: newEndTime },
        ]
      })
    }
  }

  function deleteTimePeriod(index) {
    // if (timePeriods.length <= 1) {
    //   setIsAvailable(false)
    // } else {
    //   setTimePeriods((prevTimePeriods) =>
    //     prevTimePeriods.filter((_, i) => i !== index)
    //   )
    // }
  }

  useEffect(() => {
    if (windowWidth <= 680 && activeRanges.length <= 0) {
      setActiveRanges([7, 12])
    }
  }, [])

  const formatTimePeriods = (activeRanges) => {
    // If activeRanges is empty, return a default time period
    if (activeRanges?.length === 0) {
      return [{ startTime: '09:00', endTime: '12:00' }]
    }

    let periods = []
    for (let i = 0; i < activeRanges?.length; i += 2) {
      let startTime = formatTime(activeRanges[i])
      let endTime = formatTime(activeRanges[i + 1])
      periods.push({ startTime, endTime })
    }

    return periods
  }

  return (
    <div>
      <div>
        <div className="availabilityActionHeader">
          <div>
            <p>{t('availability.isAvailable.title')}</p>
            <span>{t('availability.isAvailable.subTitle')}</span>
          </div>
          {windowWidth <= 680 ? (
            <div className="mobileTimeSelectContainer">
              {timePeriods?.map((item, index) => (
                <TimeSelect
                  key={index}
                  timePeriods={timePeriods}
                  activeRanges={activeRanges}
                  timeBoxNumber={index}
                  addNewTimePeriod={addNewTimePeriod}
                  deleteTimePeriod={() => deleteTimePeriod(index)}
                  period={item}
                  handleTimeChange={handleTimeChange}
                />
              ))}
            </div>
          ) : (
            <div className="availabilityActionRow">
              <div className="activeRangesTagRow">
                {activeRanges.length >= 2 && (
                  <div
                    className="deleteTimeslot tag"
                    onClick={() => {
                      const newActiveRanges = activeRanges.slice(2)
                      setActiveRanges(newActiveRanges)
                    }}
                  >
                    {`${formatTime(activeRanges[0])} - ${formatTime(
                      activeRanges[1]
                    )}`}
                    <div className="verticalDivider" />
                    <TbX />
                  </div>
                )}
                {activeRanges.length >= 4 && (
                  <div
                    className="deleteTimeslot tag"
                    onClick={() => {
                      const newActiveRanges = [
                        ...activeRanges.slice(0, 2),
                        ...activeRanges.slice(4),
                      ]
                      setActiveRanges(newActiveRanges)
                    }}
                  >
                    {`${formatTime(activeRanges[2])} - ${formatTime(
                      activeRanges[3]
                    )}`}
                    <div className="verticalDivider" />
                    <TbX />
                  </div>
                )}
                {activeRanges.length >= 6 && (
                  <div
                    className="deleteTimeslot tag"
                    onClick={() => {
                      const newActiveRanges = activeRanges.slice(0, -2)
                      setActiveRanges(newActiveRanges)
                    }}
                  >
                    {`${formatTime(activeRanges[4])} - ${formatTime(
                      activeRanges[5]
                    )}`}
                    <div className="verticalDivider" />
                    <TbX />
                  </div>
                )}
              </div>
              <button
                className="deleteTimeslot"
                type="button"
                onClick={() => setShowOverview(true)}
              >
                {t('availability.isAvailable.actions.weekOverview')}
              </button>
              {activeRanges.length < 6 && (
                <button
                  className="primaryBtn rounded availability"
                  type="button"
                  onClick={addNewTimeFrame}
                >
                  {t('availability.isAvailable.actions.newPeriod')}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
      {windowWidth > 680 && (
        <div className="availabilitySlider" style={{ marginTop: 40 }}>
          <Slider
            rootStyle={sliderStyle}
            domain={[5, 25]}
            step={0.5}
            mode={2}
            values={activeRanges}
            onUpdate={update}
            onChange={onChange}
          >
            <Rail>
              {({ getRailProps }) => (
                <div
                  style={{
                    height: 140,
                    backgroundColor: '#f1f1f1',
                    marginTop: 40,
                    borderRadius: 5,
                    boxSizing: 'border-box',
                  }}
                  {...getRailProps()}
                />
              )}
            </Rail>
            <Handles>
              {({ handles, getHandleProps }) => (
                <div className="slider-handles">
                  {handles.map((handle) => (
                    <Handle
                      key={handle.id}
                      handle={handle}
                      getHandleProps={getHandleProps}
                    />
                  ))}
                </div>
              )}
            </Handles>
            <Tracks right={false} left={false}>
              {({ tracks, getTrackProps }) => (
                <div className="slider-tracks">
                  {tracks.map(({ id, source, target }, index) => (
                    <div
                      key={id}
                      style={{
                        position: 'absolute',
                        top: index % 2 ? 0 : -1.5,
                        height: '100%',
                        zIndex: index % 2 ? 0 : 1,
                        backgroundColor: index % 2 ? '#f1f1f1' : '#9CBBBA',
                        left: `${source.percent}%`,
                        width: `${target.percent - source.percent}%`,
                        borderRadius: index % 2 ? null : 5,
                        border:
                          index % 2 ? null : '1.5px solid var(--secondary)',
                      }}
                      {...getTrackProps()}
                    />
                  ))}
                </div>
              )}
            </Tracks>
          </Slider>
          <div className="timesContainerWrapper">
            {timeBlocks.map((value, i) => (
              <div
                key={i}
                className="slider-label"
                style={{ left: `calc(${(value / 24) * 100}% - 25px)` }}
              >
                {value === 24
                  ? '00:00'
                  : `${value.toString().padStart(2, '0')}:00`}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
