import { useState } from 'react'
import {
  TbBan,
  TbCash,
  TbFlag,
  TbInfoCircle,
  TbTicket,
  TbWriting,
} from 'react-icons/tb'
import './ReservationsSidebar.css'
import CancelReservations from './content/CancelReservations'
import ReportUser from './content/ReportUser'
import Ticket from '../content/Ticket'
import EditReservation from './content/EditReservation'
import { parseISO, isAfter } from 'date-fns'
import SidebarHeader from './general/SidebarHeader'
import SidebarShortcut from './general/SidebarShortcut'

export default function ReservationsSidebar({
  activeReservation,
  showTicket,
  setShowTicket,
  reservationType,
  setReservationType,
  reservationsToShow,
  setActiveReservation,
}) {
  const [contentInView, setContentInView] = useState('')

  const getActiveReservationType = () => {
    switch (reservationType) {
      case 'all':
        return 'Alle reserveringen'
      case 'requests':
        return 'Huur aanvragen'
      case 'upcoming':
        return 'Opkomende reserveringen'
      case 'completed':
        return 'Voltooide reserveringen'
      default:
        return ''
    }
  }

  if (!activeReservation || reservationsToShow?.length <= 0) {
    return (
      <div className="trailerTableSidebarContainer reservations">
        <SidebarHeader
          reservationType={getActiveReservationType()}
          setReservationType={setReservationType}
          setActiveReservation={setActiveReservation}
        />
        <div className="notAvailableSidebarContent paddingAdded">
          <TbTicket size={24} strokeWidth={1.2} />
          <p>
            We merken op dat er nog geen reserveringen zijn voor jouw
            aanhangers. Verhuur je aanhanger via BuurBak en kom terug op deze
            pagina.
          </p>
        </div>
      </div>
    )
  } else {
    return (
      <div className="trailerTableSidebarContainer reservations">
        {contentInView === '' && (
          <>
            <SidebarHeader
              reservationType={getActiveReservationType()}
              setReservationType={setReservationType}
              setActiveReservation={setActiveReservation}
            />
            {reservationsToShow.length > 0 && (
              <div
                className="sidebarShortcuts reservations padding"
                style={{ marginTop: 30 }}
              >
                <p>Acties</p>
                <SidebarShortcut
                  icon={<TbTicket className="sidebarIcon" />}
                  title="Bekijk reservering"
                  onClick={() => setShowTicket(true)}
                />
                {isAfter(parseISO(activeReservation.endTime), new Date()) && (
                  <>
                    <SidebarShortcut
                      icon={<TbBan className="sidebarIcon" />}
                      title="Annuleer reservering"
                      onClick={() => setContentInView('cancel')}
                    />
                  </>
                )}
              </div>
            )}
          </>
        )}
        {contentInView === 'cancel' && (
          <CancelReservations
            setContentInView={setContentInView}
            showReservation={activeReservation}
          />
        )}
        {contentInView === 'report' && (
          <ReportUser setContentInView={setContentInView} />
        )}
        {showTicket && (
          <Ticket
            activeReservation={activeReservation}
            setShowTicket={setShowTicket}
          />
        )}
        {contentInView === 'edit' && (
          <EditReservation setContentInView={setContentInView} />
        )}
      </div>
    )
  }
}
