import * as React from 'react'
import '../layout.css'
import { Range } from '../../../../types/util'
import { cva, VariantProps } from 'class-variance-authority'

const gridItemVariants = cva('grid__item', {
  variants: {
    alignSelf: {
      start: 'aself--start',
      end: 'aself--end',
      center: 'aself--center',
      stretch: 'aself--stretch',
    },
    justifySelf: {
      start: 'jself--start',
      end: 'jself--end',
      center: 'jself--center',
      stretch: 'jself--stretch',
    },
  },
  defaultVariants: {
    alignSelf: 'stretch',
    justifySelf: 'stretch',
  },
})

export interface GridItemProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof gridItemVariants> {
  xsSpan?: Range<25>
  smSpan?: Range<25>
  mdSpan?: Range<25>
  lgSpan?: Range<25>
  xsRowSpan?: Range<5>
  smRowSpan?: Range<5>
  mdRowSpan?: Range<5>
  lgRowSpan?: Range<5>
}

export const GridItem = ({
  xsSpan = 1,
  smSpan,
  mdSpan,
  lgSpan,
  xsRowSpan,
  smRowSpan,
  mdRowSpan,
  lgRowSpan,
  children,
  className,
  justifySelf,
  alignSelf,
  style,
  ...props
}: GridItemProps): React.ReactElement => {
  return (
    <div
      className={`${gridItemVariants({
        justifySelf,
        alignSelf,
        className,
      })} cspan--${xsSpan} sm__cspan--${smSpan} md__cspan--${mdSpan} lg__cspan--${lgSpan} rspan--${xsRowSpan} sm__rspan--${smRowSpan} md__rspan--${mdRowSpan} lg__rspan--${lgRowSpan}`}
      style={style}
    >
      {children}
    </div>
  )
}
