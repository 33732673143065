import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import loadGoogleMapsApi from 'load-google-maps-api'
import './components/constants/selectLanguage/i18n'

loadGoogleMapsApi({
  key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  libraries: ['places'],
})
  .then(() => {
    const rootElement = document.getElementById('root')
    if (!rootElement) throw new Error('Failed to find the root element')
    const root = createRoot(rootElement)
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    )
  })
  .catch((error) => {
    console.error('Error loading Google Maps API:', error)
  })
