import { useEffect, useState } from 'react'
import { LocalStorage } from '../types/LocalStorage'

export function useLocalStorage<Type>(key: LocalStorage) {
  const [value, setValue] = useState<Type | null>(null)

  useEffect(() => {
    getItem()
  }, [])

  function setItem(value: Type) {
    try {
      localStorage.setItem(key, JSON.stringify(value))
      setValue(value)
    } catch (error) {
      console.error('Error saving to localStorage', error)
    }
  }

  function getItem(): Type | null {
    try {
      const item = localStorage.getItem(key)
      if (item) {
        const parsedValue = JSON.parse(item)
        setValue(parsedValue)
        return parsedValue
      }
      return null // Return null if the item doesn't exist
    } catch (error) {
      console.error('Error reading from localStorage', error)
      return null // Return null if there was an error parsing the item
    }
  }

  function removeItem() {
    try {
      localStorage.removeItem(key)
      setValue(null)
    } catch (error) {
      console.error('Error removing item from localStorage', error)
    }
  }

  return { value, setItem, getItem, removeItem }
}
