import * as RDialog from '@radix-ui/react-dialog'
import './dialog.css'
import { TbPlus } from 'react-icons/tb'
import { Button } from '../button/Button'
import { Flex } from '../layout'

interface DialogProps {
  triggerElement: React.ReactElement
  dialogContent: React.ReactElement
  open?: boolean
  onOpenChange?: () => void
  title: string
}

const Dialog = ({
  triggerElement,
  dialogContent,
  open,
  title,
  onOpenChange,
}: DialogProps) => (
  <RDialog.Root open={open} onOpenChange={onOpenChange}>
    <RDialog.Trigger className="dialog__trigger" asChild>
      {triggerElement}
    </RDialog.Trigger>
    <RDialog.Portal>
      <RDialog.Overlay className="dialog__overlay" />
      <RDialog.Content className="dialog__content">
        <Flex
          justifyContent="spaceBetween"
          alignItems="center"
          className="dialog__header"
        >
          <RDialog.Title className="dialog__title">{title}</RDialog.Title>
          <RDialog.Close className="dialog__close" asChild>
            <Button size="icon" variant="gray">
              <TbPlus className="dialog__close--icon" />
            </Button>
          </RDialog.Close>
        </Flex>
        {dialogContent}
      </RDialog.Content>
    </RDialog.Portal>
  </RDialog.Root>
)

export { Dialog }
