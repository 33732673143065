import { useAuth } from 'hooks/use-auth'
import React, { useState } from 'react'
import { AuthContext } from '../context/auth-context'
import { useLocalStorage } from '../hooks/use-localstorage'
import { LocalStorage } from '../types/LocalStorage'
import { User } from '../types/User'

interface AuthProviderProps {
  children: React.ReactNode
}

export default function AuthProvider({ children }: AuthProviderProps) {
  const {
    value: user,
    setItem: setUser,
    removeItem: removeUser,
  } = useLocalStorage<User>(LocalStorage.User)
  const { isLoading } = useAuth()

  // State management for the login modal
  const [showLoginModal, setShowLoginModal] = useState(false)

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        removeUser,
        showLoginModal,
        setShowLoginModal,
        isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
