import { IconButton } from '@mui/material'
import { GoogleMap, OverlayViewF } from '@react-google-maps/api'
import {
  mapContainerStyle,
  options,
} from 'components/addTrailerForm/formsteps/PriceTime'
import Loading from 'components/constants/Loading'
import { TransportTrailer } from 'data/icons/TrailerIcons'
import { format, parseISO } from 'date-fns'
import { nl } from 'date-fns/locale'
import useAxios from 'hooks/use-axios'
import { useEffect, useState } from 'react'
import {
  TbCalendar,
  TbCash,
  TbChevronLeft,
  TbChevronRight,
  TbClock,
  TbId,
  TbMapPin,
  TbStars,
} from 'react-icons/tb'

export default function AboutReservation({ showReservation }) {
  const { response, loading, error } = useAxios({
    method: 'get',
    url: `/traileroffers/${showReservation?.trailer.id}`,
  })

  const [currentSlide, setCurrentSlide] = useState(0)
  const [trailerInformation, setTrailerInformation] = useState(null)

  useEffect(() => {
    if (response) {
      setTrailerInformation(response)
    }
  }, [response])

  const trailerImages = trailerInformation
    ? [trailerInformation.coverImage, ...trailerInformation.images]
    : []

  const isPeriod =
    format(parseISO(showReservation.startTime), 'dd MMMM yyyy', {
      locale: nl,
    }) !==
    format(parseISO(showReservation.endTime), 'dd MMMM yyyy', { locale: nl })

  const navigateSlide = (direction) => {
    setCurrentSlide(
      (prevSlide) =>
        (prevSlide + direction + trailerImages.length) % trailerImages.length
    )
  }

  if (loading) return <Loading />

  if (error) {
    console.error(error)
    return null
  }

  return (
    <div className="aboutReservationContent reservations">
      <span>Over de reservering</span>
      <div className="imagesSliderContainer reservation">
        <IconButton
          className="iconOverlayButton"
          onClick={() => navigateSlide(-1)}
        >
          <TbChevronLeft />
        </IconButton>
        <div className="carouselSlide">
          {trailerImages.map((image, index) => (
            <div
              key={index}
              className="imageWrapper"
              style={{ transform: `translateX(-${currentSlide * 100}%)` }}
            >
              <img src={image} alt={`carouselSlide-${index}`} />
            </div>
          ))}
        </div>
        <IconButton
          className="iconOverlayButton"
          onClick={() => navigateSlide(1)}
        >
          <TbChevronRight />
        </IconButton>
      </div>

      <div className="ticketDetailItem trailer">
        <TransportTrailer size={24} />
        <p>{showReservation.trailer.trailerType.name}</p>
      </div>
      <div className="ticketDetailItem">
        <TbCalendar />
        <p>
          {format(
            parseISO(showReservation.startTime),
            isPeriod ? `dd MMMM` : `dd MMMM yyyy`,
            {
              locale: nl,
            }
          )}
          {isPeriod &&
            ' - ' +
              format(parseISO(showReservation.endTime), 'dd MMMM yyyy', {
                locale: nl,
              })}
        </p>
      </div>
      <div className="ticketDetailItem">
        <TbClock />
        <p>
          Van/tot: {format(parseISO(showReservation.startTime), 'HH:mm')} -{' '}
          {format(parseISO(showReservation.endTime), 'HH:mm')}
        </p>
      </div>
      <div className="ticketDetailItem">
        <TbMapPin />
        <p>
          {showReservation.trailer.address.city},{' '}
          {showReservation.trailer.address?.province}
        </p>
      </div>
      <div className="ticketDetailItem">
        <TbCash />
        <p>
          Huurprijs: €
          {trailerInformation?.trailerWeekScheduleTemplate?.monday?.price},00
        </p>
      </div>
      {!!showReservation.trailer.driversLicenseType && (
        <div className="ticketDetailItem">
          <TbId />
          <p>
            {showReservation.trailer.driversLicenseType} rijbewijs verplicht
          </p>
        </div>
      )}
      <div className="ticketDetailItem">
        <TbStars />
        <p>Niet beschikbaar</p>
      </div>
      <div
        className="divider fullWidth"
        style={{ minHeight: 1, marginTop: 35, marginBottom: 0 }}
      />
      <span>Exacte locatie</span>
      <div className="reservationMapContainer">
        <GoogleMap
          zoom={15.5}
          center={{
            lat: showReservation.trailer.nearbyLatitude,
            lng: showReservation.trailer.nearbyLongitude,
          }}
          options={options}
          mapContainerStyle={mapContainerStyle}
        >
          <OverlayViewF
            position={{
              lat: showReservation.trailer.nearbyLatitude,
              lng: showReservation.trailer.nearbyLongitude,
            }}
            mapPaneName={'markerLayer'}
          >
            <div className="trailerTypeMarker profile">
              <TransportTrailer size={32} />
            </div>
          </OverlayViewF>
        </GoogleMap>
      </div>
    </div>
  )
}
