import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  SwipeableDrawer,
  Toolbar,
} from '@mui/material'
import { useEffect, useState } from 'react'
import Logo from './Logo'
import MenuIcon from '@mui/icons-material/Menu'
import DrawerNavigation from './DrawerNavigation'
import HeaderNavigation from './HeaderNavigation'
import LoginRegisterModal from '../auth/LoginRegisterModal'
import { NavLink, useLocation } from 'react-router-dom'
import {
  MessagesIcon,
  TrailerOwner,
  TransportTrailer,
} from 'data/icons/TrailerIcons'
import { TbSearch, TbMap } from 'react-icons/tb'
import useWindowWidth from 'hooks/useWindowWidth'
import { useAuth } from 'hooks/use-auth'
import { instance } from 'util/axios-instance'

export default function Header() {
  const [mobileOpen, setMobileOpen] = useState(false)
  const [loginRegisterModalOpen, setLoginRegisterModalOpen] = useState(false)
  const drawerWidth = 240
  const windowWidth = useWindowWidth()
  const [isScrollingUp, setIsScrollingUp] = useState(true)
  let lastScrollTop = 0
  const location = useLocation()
  const placeTrailerOnline = location?.pathname.includes('verhuren')
  const trailerOwner = location?.pathname.includes('owner/dashboard')
  const { user } = useAuth()
  const [isTrailerOwner, setIsTrailerOwner] = useState(false)

  useEffect(() => {
    if (user) {
      instance
        .get(`/customers/${user?.id}/trailers`)
        .then((response) => {
          if (response.data.content.length > 0) {
            setIsTrailerOwner(true)
          } else {
            setIsTrailerOwner(false)
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }, [user])

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return
      }

      setMobileOpen(open)
    }

  const container =
    window !== undefined ? () => window.document.body : undefined

  useEffect(() => {
    const handleScroll = () => {
      let st = window.pageYOffset || document.documentElement.scrollTop
      if (Math.abs(st - lastScrollTop) >= 50) {
        setIsScrollingUp(st <= lastScrollTop || st <= 0)
        lastScrollTop = st <= 0 ? 0 : st
      }
    }

    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <>
      {windowWidth < 768 && !placeTrailerOnline && !trailerOwner && (
        <BottomNav
          isScrollingUp={isScrollingUp}
          user={user}
          onLoginRegister={() => setLoginRegisterModalOpen(true)}
        />
      )}
      {windowWidth > 768 && (
        <Box component="nav">
          <AppBar
            color="default"
            component="nav"
            className="headerContainer"
            sx={{
              boxShadow: 'none',
              zIndex: 90,
              backgroundColor: 'var(--white)',
              borderBottom: '1px solid var(--border)',
              paddingLeft: '20px',
              paddingRight: '20px',
            }}
          >
            <Toolbar
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                height: '80px',
              }}
            >
              <Logo />

              {/* Desktop navigation */}
              <HeaderNavigation
                onLoginRegister={() => setLoginRegisterModalOpen(true)}
                isTrailerOwner={isTrailerOwner}
              />

              {/* Mobile drawer button */}
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={toggleDrawer(true)}
                sx={{ mr: 2, display: { md: 'none' } }}
              >
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Box component="nav">
            <SwipeableDrawer
              anchor="right"
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={toggleDrawer(false)}
              onOpen={toggleDrawer(true)}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { sm: 'block', md: 'none' },
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: drawerWidth,
                },
              }}
            >
              <DrawerNavigation
                onLoginRegister={() => setLoginRegisterModalOpen(true)}
              />
            </SwipeableDrawer>
          </Box>
        </Box>
      )}
      <LoginRegisterModal
        open={loginRegisterModalOpen}
        onClose={() => setLoginRegisterModalOpen(false)}
      />
    </>
  )
}

function BottomNav({ isScrollingUp, user, onLoginRegister }) {
  const [showMapButton, setShowMapButton] = useState(false)
  // const [hideBottomNav, setHideBottomNav] = useState(false)

  useEffect(() => {
    const currentURL = window.location.href

    const substringToCheck = 'aanbod'

    if (currentURL.includes(substringToCheck)) {
      setShowMapButton(true)
    }
  }, [])

  return (
    <>
      {/* TODO: Check why this component is showing up on every page */}
      {/*{showMapButton && (*/}
      {/*  <button*/}
      {/*    className={`showMapbutton ${isScrollingUp && 'bottom'}`}*/}
      {/*    style={!isScrollingUp ? { bottom: 35 } : null}*/}
      {/*  >*/}
      {/*    Kaart*/}
      {/*    <TbMap />*/}
      {/*  </button>*/}
      {/*)}*/}
      <div
        className={`bottomNavContainer ${isScrollingUp ? 'visible' : 'hidden'}`}
      >
        <NavLink
          to="/aanbod"
          className={({ isActive }) =>
            isActive
              ? 'bottomNavItem flexbox column center active'
              : 'bottomNavItem flexbox column center'
          }
        >
          <TbSearch strokeWidth={1.7} />
          <p>Aanbod</p>
        </NavLink>
        <NavLink
          to="/verhuren"
          className={({ isActive }) =>
            isActive
              ? 'bottomNavItem flexbox column center active'
              : 'bottomNavItem flexbox column center'
          }
          style={{ paddingTop: 1.5 }}
        >
          <TransportTrailer size={28} color="var(--text)" strokeWidth="1.35" />
          <p style={{ marginTop: 6.5 }}>Plaatsen</p>
        </NavLink>
        {/*<NavLink*/}
        {/*  to="/berichten"*/}
        {/*  className={({ isActive }) =>*/}
        {/*    isActive*/}
        {/*      ? 'bottomNavItem flexbox column center active'*/}
        {/*      : 'bottomNavItem flexbox column center'*/}
        {/*  }*/}
        {/*  style={{ paddingTop: 1.5 }}*/}
        {/*>*/}
        {/*  <MessagesIcon size={19} color={'var(--text)'} strokeWidth="2" />*/}
        {/*  <p style={{ marginTop: 6 }}>Berichten</p>*/}
        {/*</NavLink>*/}
        <NavLink
          to="/owner/dashboard"
          className={({ isActive }) =>
            isActive
              ? 'bottomNavItem flexbox column center active'
              : 'bottomNavItem flexbox column center'
          }
          style={{ paddingTop: 1.5 }}
        >
          <TrailerOwner size={26} color="var(--text)" strokeWidth="1.35" />
          <p style={{ marginTop: 5.5 }}>Overzicht</p>
        </NavLink>
        <NavLink
          to={user ? `/account` : null}
          // onClick={!user ? () => setLoginRegisterModalOpen(true) : null}
          className="bottomNavItem general flexbox column center"
        >
          <Avatar
            src={
              user
                ? 'https://media.licdn.com/dms/image/C5603AQE7PYPKBr2Whw/profile-displayphoto-shrink_200_200/0/1611237854176?e=1694649600&v=beta&t=mP3KCc6fPfkz1seNceCYqxg15_XkULzGiCW4pPwGptk'
                : null
            }
            className="bottomNavAvatar"
            onClick={onLoginRegister}
          />
        </NavLink>
      </div>
    </>
  )
}
