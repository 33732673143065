import { instance } from '../../../../../util/axios-instance'
import { useTrailerData } from '../context/TrailerDataProvider'

const useUpdateTrailer = (activeTrailer) => {
  const { updateField, updateNestedField, trailerData } = useTrailerData()

  const updateTrailerData = async (field, value, nestedField = null) => {
    if (nestedField) {
      updateNestedField(field, nestedField, value)
    } else {
      updateField(field, value)
    }

    const updatedTrailerData = {
      ...activeTrailer,
      accessoire: trailerData.accesoire,
      trailerType: activeTrailer.trailerType.name,
      [field]: nestedField
        ? { ...activeTrailer[field], [nestedField]: value }
        : value,
    }

    const {
      createdAt,
      updatedAt,
      owner,
      name,
      id,
      fakeLatitude,
      fakeLongitude,
      ...dataToSend
    } = updatedTrailerData

    try {
      await instance.put(`/traileroffers/${activeTrailer.id}`, dataToSend)
      console.log('sending success :)')
    } catch (error) {
      console.error('Error updating trailer:', error)
    }
  }

  return updateTrailerData
}

export default useUpdateTrailer
