import { cva, VariantProps } from 'class-variance-authority'
import '../layout.css'
import { Range } from '../../../../types/util'

const flexItemVariants = cva('flex__item', {
  variants: {
    alignSelf: {
      auto: 'aself--auto',
      start: 'aselff--start',
      end: 'aselff--end',
      center: 'aself--center',
      baseline: 'aself--baseline',
      stretch: 'aself--stretch',
    },
  },
  defaultVariants: {},
})

export interface FlexItemProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof flexItemVariants> {
  order?: Range<25>
  grow?: Range<25>
  shrink?: Range<25>
}

const FlexItem = ({
  order,
  grow = 0,
  shrink = 1,
  children,
  className,
  ...props
}: FlexItemProps): React.ReactElement => {
  return (
    <div
      className={`${flexItemVariants({
        className,
      })} order--${order} grow--${grow} shrink--${shrink}`}
    >
      {children}
    </div>
  )
}

export { FlexItem }
