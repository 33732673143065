import './OfferCategoriesSlider.css'
import { TrailerCategories } from 'data/dummy/TrailerTypes'
import { TbChevronRight } from 'react-icons/tb'
import { Tag } from 'components/ui'

export default function OfferCategoriesSlider({
  activeCategory,
  setActiveCategory,
}) {
  return (
    <div className="offerCategoriesSlider flexbox">
      <div
        className={`mobOfferCategory ${activeCategory === '' && 'active'}`}
        onClick={() => setActiveCategory('')}
      >
        <p style={{ marginLeft: 0 }}>Alle aanhangers</p>
      </div>
      {TrailerCategories.map((item) => (
        <div
          className={`mobOfferCategory ${
            activeCategory === item.trailerType && 'active'
          }`}
          key={item.id}
          onClick={() => setActiveCategory(item.trailerType)}
        >
          {item.icon}
          <p>{item.trailerType}</p>
        </div>
      ))}
    </div>
  )
}
