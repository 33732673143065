import { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
// import IncomeOverview from '../components/profile/trailer_owner/income_view/IncomeOverview'
import OwnerDasbhoardSubheader from '../components/profile/trailer_owner/constants/OwnerDasbhoardSubheader'
import TrailerTable from '../components/profile/trailer_owner/my_trailers/TrailerTable'
import './styles/TrailerOwnerDashboard.css'
import ReservationsTable from '../components/profile/trailer_owner/reservations/ReservationsTable'
import { isToday } from 'date-fns/esm'
import TodaysChecklist from '../components/profile/trailer_owner/today/TodaysChecklist'
import { AuthContext } from '../context/auth-context'
import { instance } from '../util/axios-instance'
import { parseISO } from 'date-fns'

export default function TrailerOwnerDashboard({
  contentInView,
  setContentInView,
}) {
  const location = useLocation()
  const { user } = useContext(AuthContext)
  const [myTrailers, setMyTrailers] = useState([])
  const myTrailerList = myTrailers?.[0]?.content
  const [ownerReservations, setOwnerReservations] = useState([])
  const [hasActionsToday, setHasActionsToday] = useState(true)
  const [showTodaysChecklist, setShowTodaysChecklist] = useState(false)

  useEffect(() => {
    if (user) {
      const fetchData = async () => {
        const response = await instance.get(`/reservations?ownerId=${user.id}`)

        if (response && response.data && response.data.content) {
          setOwnerReservations(response.data.content)
        }
      }

      fetchData()
    }
  }, [user])

  useEffect(() => {
    const hasReservationToday =
      !!ownerReservations &&
      ownerReservations.filter((item) => isToday(parseISO(item.startTime)))
    if (hasReservationToday.length > 0) {
      setHasActionsToday(true)
    }
  }, [])

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const reservation = params.get('reservation')
    if (reservation) {
      setContentInView('reservations')
    }
  }, [location])

  if (!user) return <p>Loading...</p>

  return (
    <div>
      {/*<OwnerDasbhoardSubheader*/}
      {/*  contentInView={contentInView}*/}
      {/*  setContentInView={setContentInView}*/}
      {/*  ownerReservations={ownerReservations}*/}
      {/*  hasActionsToday={hasActionsToday}*/}
      {/*  setShowTodaysChecklist={setShowTodaysChecklist}*/}
      {/*/>*/}
      <div className="ownerDashboardContent">
        {/*{contentInView === 'income' && (*/}
        {/*  <IncomeOverview myTrailers={myTrailers} />*/}
        {/*)}*/}
        {contentInView === 'myTrailers' && (
          <TrailerTable
            trailers={myTrailerList}
            setMyTrailers={setMyTrailers}
            user={user}
          />
        )}
        {contentInView === 'reservations' && (
          <ReservationsTable ownerReservations={ownerReservations} />
        )}
        {/*{showTodaysChecklist && (*/}
        {/*  <TodaysChecklist*/}
        {/*    ownerReservations={ownerReservations}*/}
        {/*    setShowTodaysChecklist={setShowTodaysChecklist}*/}
        {/*  />*/}
        {/*)}*/}
      </div>
    </div>
  )
}
