import Introduction from './formsteps/general/Introduction'
import TrailerFormFooter from './TrailerFormFooter'
import { useMultistepForm } from './useMultiStepForm'
import './TrailerForm.css'
import { AxiosError } from 'axios'
import TrailerType from './formsteps/TrailerType'
import { useEffect, useState } from 'react'
import DriverLicense from './formsteps/DriversLicense'
import Description from './formsteps/Description'
import TrailerStats from './formsteps/TrailerStats'
import TrailerLocation from './formsteps/TrailerLocation'
import PriceTime from './formsteps/PriceTime'
import Complete from './formsteps/general/Complete'
import TrailerFormHeader from './TrailerFormHeader'
import ImageUpload from './formsteps/ImageUpload'
import TrailerAccessoires from './formsteps/TrailerAccessoires'
import Availability from './formsteps/availability/Availability'
import { instance } from 'util/axios-instance'
import useAxios from 'hooks/use-axios'
import LoginRegisterModal from 'components/constants/auth/LoginRegisterModal'

export interface Address {
  streetName: string
  city: string
  postalCode: string
  houseNumber: string
}

export interface GeoCode {
  lat: number
  lng: number
}

export interface LocationItem {
  long_name: string
  types: string[]
}

interface WeekSchedule {
  monday: Schedule
  tuesday: Schedule
  wednesday: Schedule
  thursday: Schedule
  friday: Schedule
  saturday: Schedule
  sunday: Schedule
}

interface TimeSlot {
  startTime: string
  endTime: string
}

interface Schedule {
  price: number
  timeSlots: TimeSlot[]
}

const dayNamesMapping = {
  ma: { short: 'Ma', english: 'monday', index: 0 },
  di: { short: 'Di', english: 'tuesday', index: 1 },
  woe: { short: 'Wo', english: 'wednesday', index: 2 },
  do: { short: 'Do', english: 'thursday', index: 3 },
  vri: { short: 'Vr', english: 'friday', index: 4 },
  za: { short: 'Za', english: 'saturday', index: 5 },
  zo: { short: 'Zo', english: 'sunday', index: 6 },
}

interface IResponse {
  content: Array<any>
}

export default function TrailerForm() {
  const [loading, setLoading] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)
  const [showLogin, setShowLogin] = useState(false)
  const [trailerType, setTrailerType] = useState<string>('')
  const [license, setLicense] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [trailerImages, setTrailerImages] = useState<[]>([])
  const [trailerStats, setTrailerStats] = useState({
    length: 0,
    width: 0,
    height: 0,
  })
  const [location, setLocation] = useState<LocationItem[]>([])
  const [streetName, setStreetName] = useState('')
  const [city, setCity] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [houseNumber, setHouseNumber] = useState('')
  const [geoCode, setGeoCode] = useState<GeoCode | null>(null)
  const [price, setPrice] = useState<any>('')
  const [trailerAccessoires, setTrailerAccessoires] = useState<string[]>([])
  const [isDirty, setIsDirty] = useState(false)
  const defaultAvailability = {
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
    sunday: true,
  }
  const [availability, setAvailability] = useState(defaultAvailability)
  const [trailerWeekScheduleTemplate, setTrailerWeekScheduleTemplate] =
    useState<WeekSchedule>({
      monday: { price: price, timeSlots: [] },
      tuesday: { price: price, timeSlots: [] },
      wednesday: { price: price, timeSlots: [] },
      thursday: { price: price, timeSlots: [] },
      friday: { price: price, timeSlots: [] },
      saturday: { price: price, timeSlots: [] },
      sunday: { price: price, timeSlots: [] },
    })
  const defaultRanges = []
  const [activeRangesPerDay, setActiveRangesPerDay] = useState(
    Object.keys(dayNamesMapping).reduce(
      (obj, key) => ({ ...obj, [dayNamesMapping[key].english]: defaultRanges }),
      {}
    )
  )
  const [allTrailers, setAllTrailers] = useState<any>([])
  const { response, error } = useAxios<IResponse>({
    method: 'get',
    url: '/traileroffers',
  })

  useEffect(() => {
    if (response) {
      setLoading(true)
      setAllTrailers(response.content)
      setLoading(false)
    } else if (error) {
      console.error(error)
    }
  }, [response])

  const [activeRanges, setActiveRanges] = useState(defaultRanges)

  const address = {
    streetName: streetName,
    houseNumber: houseNumber,
    postalCode: postalCode,
    city: city,
  }

  useEffect(() => {
    const handleUnload = function (e) {
      if (isDirty) {
        e.preventDefault()
        e.returnValue = ''
      }
    }

    window.addEventListener('beforeunload', handleUnload)

    return () => {
      window.removeEventListener('beforeunload', handleUnload)
    }
  }, [isDirty])

  useEffect(() => {
    if (!!trailerType) {
      setIsDirty(true)
    }
  }, [trailerType])

  const daysOfWeek = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ]

  const trailerData = {
    length: Number(trailerStats?.length),
    height: Number(trailerStats?.height),
    width: Number(trailerStats?.width),
    description: description,
    licensePlate: 'NFDHSBF',
    driversLicenseType: license === 'none' ? 'B' : license,
    latitude: geoCode?.lat,
    longitude: geoCode?.lng,
    trailerType: trailerType,
    trailerDateSchedules: [],
    accessoire: trailerAccessoires.length > 0 ? trailerAccessoires : [],
    address: {
      city: address?.city,
      streetName: address?.streetName,
      houseNumber: address?.houseNumber,
      postalCode: address?.postalCode,
    },
    price: price,
    trailerWeekScheduleTemplate: daysOfWeek.reduce((acc, day) => {
      acc[day] = {
        price: price,
        timeSlots: trailerWeekScheduleTemplate[day].timeSlots.map((slot) => ({
          startTime: slot.startTime,
          endTime: slot.endTime,
        })),
      }
      return acc
    }, {}),
  }

  const handleSubmit = async (e, data, images) => {
    if (e?.target.id !== 'trailerForm') return
    e.preventDefault()
    try {
      setLoading(true)
      const trailerId = await createTrailerOffer(data)
      if (trailerId) {
        await uploadImages(images, trailerId)
      }
    } catch (error) {
      console.error(
        error instanceof AxiosError
          ? error.response?.data?.message
          : error.message
      )
    } finally {
      setLoading(false)
      setSuccess(true)
    }
  }

  const createTrailerOffer = async (data) => {
    const response = await instance.post('/traileroffers', data)
    return response?.data
  }

  const uploadImages = async (images, trailerId) => {
    const formData = new FormData()
    formData.append('coverImage', images[0].file)
    images.slice(1).forEach((image) => formData.append('images', image.file))
    await instance.post(`/traileroffers/${trailerId}/images`, formData)
  }

  const {
    currentStepIndex,
    isFirstStep,
    back,
    next,
    isLastStep,
    setCurrentStepIndex,
    step,
  } = useMultistepForm([
    <Introduction />,
    <TrailerType trailerType={trailerType} setTrailerType={setTrailerType} />,
    <TrailerAccessoires
      trailerAccessoires={trailerAccessoires}
      setTrailerAccessoires={setTrailerAccessoires}
    />,
    <DriverLicense license={license} setLicense={setLicense} />,
    <ImageUpload
      trailerImages={trailerImages}
      setTrailerImages={setTrailerImages}
    />,
    <TrailerStats
      trailerStats={trailerStats}
      setTrailerStats={setTrailerStats}
      trailerType={trailerType}
    />,
    <Description description={description} setDescription={setDescription} />,
    <TrailerLocation
      setGeoCode={setGeoCode}
      setLocation={setLocation}
      trailerType={trailerType}
      geoCode={geoCode}
      location={location}
      streetName={streetName}
      setStreetName={setStreetName}
      houseNumber={houseNumber}
      setHouseNumber={setHouseNumber}
      city={city}
      setCity={setCity}
      postalCode={postalCode}
      setPostalCode={setPostalCode}
    />,
    <PriceTime
      geoCode={geoCode}
      price={price}
      setPrice={setPrice}
      trailerType={trailerType}
      allTrailers={allTrailers}
    />,
    <Availability
      trailerWeekScheduleTemplate={trailerWeekScheduleTemplate}
      setTrailerWeekScheduleTemplate={setTrailerWeekScheduleTemplate}
      availability={availability}
      setAvailability={setAvailability}
      activeRangesPerDay={activeRangesPerDay}
      setActiveRangesPerDay={setActiveRangesPerDay}
      activeRanges={activeRanges}
      setActiveRanges={setActiveRanges}
      defaultRanges={defaultRanges}
    />,
    <Complete
      trailerType={trailerType}
      description={description}
      trailerImages={trailerImages}
      trailerStats={trailerStats}
      address={address}
      price={price}
      trailerAccessoires={trailerAccessoires}
      showLogin={showLogin}
      setShowLogin={setShowLogin}
      success={success}
      loading={loading}
      handleSubmit={handleSubmit}
      trailerData={trailerData}
    />,
  ])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [currentStepIndex])

  return (
    <>
      {showLogin && (
        <LoginRegisterModal
          open={showLogin}
          onClose={() => setShowLogin(false)}
        />
      )}
      <form
        className="trailerFormContainer"
        onSubmit={(e) => {
          e.preventDefault()
          if (currentStepIndex >= 10) {
            handleSubmit(e, trailerData, trailerImages)
          }
        }}
        id="trailerForm"
      >
        <TrailerFormHeader
          currentStepIndex={currentStepIndex}
          setCurrentStepIndex={setCurrentStepIndex}
          trailerType={trailerType}
          description={description}
          trailerImages={trailerImages}
          trailerStats={trailerStats}
          address={address}
          price={price}
          trailerAccessoires={trailerAccessoires}
          license={license}
          trailerWeekScheduleTemplate={trailerWeekScheduleTemplate}
        />
        {step}
        <TrailerFormFooter
          currentStepIndex={currentStepIndex}
          back={back}
          isFirstStep={isFirstStep}
          next={next}
          isLastStep={isLastStep}
          trailerType={trailerType}
          license={license}
          description={description}
          trailerImages={trailerImages}
          trailerStats={trailerStats}
          address={address}
          price={price}
          trailerWeekScheduleTemplate={trailerWeekScheduleTemplate}
        />
      </form>
    </>
  )
}
