import { TextareaAutosize } from '@mui/material'
import { CANCEL_REASONING } from 'data/dummy/CancelationData'
import useClickOutside from 'hooks/onClickOutside'
import { useRef, useState } from 'react'
import { TbBan, TbChevronDown, TbChevronRight } from 'react-icons/tb'
import { instance } from 'util/axios-instance'

export default function Cancel({ showReservation, setShowReservation }) {
  const [showCancelOptions, setShowCancelOptions] = useState(false)
  const [cancelationReason, setCancelationReason] = useState<any>({})
  const [differentReason, setDifferentReason] = useState('')
  const [messageToOwner, setMessageToOwner] = useState('')

  const selectRef = useRef(null)

  useClickOutside(selectRef, () => setShowCancelOptions(false))

  const cancelReservation = () => {
    const reason =
      cancelationReason.type === 'different'
        ? differentReason
        : cancelationReason.reason

    if (!reason || !messageToOwner) return

    instance
      .put(`/reservations/${showReservation.id}/cancel`, {
        reason,
        description: messageToOwner,
      })
      .then((res) => {
        setShowReservation(false)
        window.location.reload()
      })
  }

  return (
    <div className="aboutReservationContent">
      <div className="cancelItem">
        <p>
          Reden voor annulering <span>(verplicht)</span>
        </p>
        <div className="selectContainerWrapper" ref={selectRef}>
          <div
            className="selectContainer"
            onClick={() => setShowCancelOptions(!showCancelOptions)}
          >
            <p>{cancelationReason?.reason || 'Reden voor annulering'}</p>
            <TbChevronDown
              className={showCancelOptions ? 'chevronTranfsormed' : 'chevron'}
            />
          </div>
          {showCancelOptions && (
            <div className="selectOptionsContainer">
              {CANCEL_REASONING.map((item, index) => (
                <p
                  key={index}
                  onClick={() => {
                    setCancelationReason(item)
                    setShowCancelOptions(false)
                  }}
                >
                  {item.reason}
                </p>
              ))}
            </div>
          )}
          {cancelationReason.type === 'different' && (
            <div className="inputContainer cancel">
              <input
                placeholder="Reden..."
                value={differentReason}
                onChange={(e) => setDifferentReason(e.target.value)}
              />
            </div>
          )}
        </div>
      </div>
      <div className="cancelItem">
        <p>
          Bericht aan {showReservation.trailer.owner.name.split(' ')[0]}{' '}
          <span>(verplicht)</span>
        </p>
        <TextareaAutosize
          className="autoSizeTextarea cancelPopup"
          placeholder="Bericht aan huurder..."
          value={messageToOwner}
          onChange={(e) => setMessageToOwner(e.target.value)}
        />
      </div>
      <button
        className={`primaryBtn cancel ${
          (!cancelationReason.reason || !messageToOwner) && 'disabledBtn'
        }`}
        disabled={!cancelationReason.reason || !messageToOwner}
        onClick={cancelReservation}
      >
        Reservering annuleren
      </button>
    </div>
  )
}
