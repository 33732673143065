import { TbAlertTriangle, TbChevronLeft, TbChevronRight } from 'react-icons/tb'
import './ReportUser.css'
import { IconButton } from '@mui/material'

export default function ReportUser({ setContentInView }) {
  return (
    <div className="sideBarSettingsWrapper">
      <div className="sidebarSettingsHeader">
        <IconButton className="goBack" onClick={() => setContentInView('')}>
          <TbChevronLeft />
        </IconButton>
        <p>Rapporteer huurder</p>
        <p>
          Rapporteer een huurder wanneer ethisch onverantwoord gehandelt wordt
          of zich niet aan de richtlijnen houdt.
        </p>
      </div>
      <div className="sidebarSettingsContentContainer cancel">
        <button className="primaryBtn cancel report">Rapporteer huurder</button>
        {/* <div className="divider sidebar" /> */}
        <div className="sidebarSettingsContentContainer variable cancel">
          <p>Rapporteren</p>
          <span>
            lorem ipsum dolor set amit, de la sol de mito di. lorem ipsum dolor
            set amit.
          </span>
          <div className="sidebarShortcutItem price cancel">
            <TbAlertTriangle className="sidebarIcon" />
            <p>Meer over rapporteren</p>
            <TbChevronRight />
          </div>
        </div>
      </div>
    </div>
  )
}
