import { useEffect, useState } from 'react'
import { instance } from '../util/axios-instance'
import { AxiosError, AxiosRequestConfig } from 'axios'

type PostData<T> = {
  url: string
  data: T
  config?: AxiosRequestConfig
}

export default function useAxios<T>(axiosParams: AxiosRequestConfig) {
  const [response, setResponse] = useState<T | null>(null)
  const [error, setError] = useState<AxiosError | undefined>()
  const [loading, setLoading] = useState(true)

  function delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time))
  }

  const fetchData = async (params: AxiosRequestConfig) => {
    try {
      const result = await instance.request<T>({ ...params })
      setResponse((prevResponse) => {
        if (Array.isArray(prevResponse) && Array.isArray(result.data)) {
          return [...prevResponse, ...result.data] as unknown as T
        }
        return result.data
      })
    } catch (error) {
      if (error instanceof AxiosError) setError(error)
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const refetch = async () => {
    setLoading(true)
    try {
      const result = await instance.request<T>(axiosParams)
      setResponse(result.data)
    } catch (error) {
      if (error instanceof AxiosError) setError(error)
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData(axiosParams)
  }, [])

  return { response, error, loading, refetch }
}
