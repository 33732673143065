import { useAuth } from 'hooks/use-auth'
import {
  TbBallpen,
  TbChevronRight,
  TbHeart,
  TbHistory,
  TbNotebook,
  TbSettings,
  TbUserCircle,
} from 'react-icons/tb'
import './MobileProfileInfo.css'

export default function MobileProfileInfo({
  setContentShowing,
  setShowSettings,
}) {
  const { logout } = useAuth()

  return (
    <div className="profileInfoMobile">
      <div
        className="mobileProfileItem"
        onClick={() => setContentShowing('upcoming')}
      >
        <TbNotebook />
        <p>Opkomende reserveringen</p>
        <TbChevronRight size={19} style={{ strokeWidth: 2 }} />
      </div>
      {/*<div*/}
      {/*  className="mobileProfileItem"*/}
      {/*  onClick={() => setContentShowing('saved')}*/}
      {/*>*/}
      {/*  <TbHeart />*/}
      {/*  <p>Opgeslagen</p>*/}
      {/*  <TbChevronRight size={19} style={{ strokeWidth: 2 }} />*/}
      {/*</div>*/}
      {/*<div*/}
      {/*  className="mobileProfileItem"*/}
      {/*  onClick={() => setContentShowing('reviews')}*/}
      {/*>*/}
      {/*  <TbUserCircle />*/}
      {/*  <p>Ontvangen reviews</p>*/}
      {/*  <TbChevronRight size={19} style={{ strokeWidth: 2 }} />*/}
      {/*</div>*/}
      {/*<div*/}
      {/*  className="mobileProfileItem"*/}
      {/*  onClick={() => setContentShowing('reviews')}*/}
      {/*>*/}
      {/*  <TbBallpen />*/}
      {/*  <p>Geschreven reviews</p>*/}
      {/*  <TbChevronRight size={19} style={{ strokeWidth: 2 }} />*/}
      {/*</div>*/}
      <div className="mobileProfileItem" onClick={() => setShowSettings(true)}>
        <TbSettings />
        <p>Instellingen</p>
        <TbChevronRight size={19} style={{ strokeWidth: 2 }} />
      </div>
      <button className="filledPrimary" onClick={() => logout()}>
        Uitloggen
      </button>
    </div>
  )
}
