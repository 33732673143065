import './SidebarDescription.css'
import { IconButton, TextareaAutosize } from '@mui/material'
import {
  TbChevronLeft,
  TbChevronRight,
  TbInfoCircle,
  TbX,
} from 'react-icons/tb'
import { useEffect, useState } from 'react'
import useUpdateTrailer from '../../hooks/useUpdateTrailer'

export default function SidebarDescription({
  trailerInfo,
  setSidebarContent,
  setTrailerInfo,
}) {
  const [updatedDescription, setUpdatedDescription] = useState('')
  const updateTrailerData = useUpdateTrailer(trailerInfo)
  const [showInfo, setShowInfo] = useState(false)

  const handleUpdateField = async (field, value) => {
    const updatedData = await updateTrailerData(field, value)
    setTrailerInfo(updatedData)
    setSidebarContent('')
    window.location.reload()
  }

  useEffect(() => {
    if (!trailerInfo.description) {
      setUpdatedDescription('')
    } else {
      setUpdatedDescription(trailerInfo.description)
    }
  }, [trailerInfo])

  return (
    <div className="sideBarSettingsWrapper">
      <div className="sidebarSettingsHeader">
        <IconButton className="goBack" onClick={() => setSidebarContent('')}>
          <TbChevronLeft />
        </IconButton>
        <p>Beschrijving aanpassen</p>
        <p>
          Geef extra informatie over jouw aanhanger, bijv. wat wel en niet
          vervoerd mag worden.
        </p>
      </div>
      <div
        className="sidebarShortcutItem price type"
        style={{ marginTop: 30, marginRight: 30, marginLeft: 30 }}
        onClick={() => setShowInfo(true)}
      >
        <TbInfoCircle className="sidebarIcon" />
        <p>Meer informatie</p>
        <TbChevronRight />
      </div>
      <div className="sidebarSettingsContentContainer description">
        <TextareaAutosize
          className="textarea sidebar"
          value={updatedDescription}
          onChange={(e) => setUpdatedDescription(e.target.value)}
          maxLength={1000}
          placeholder="beschrijving..."
        />
        <p>{updatedDescription.length} / 1000</p>
      </div>
      {updatedDescription !== trailerInfo.description && (
        <div className="sidebarSettingsFooterAction updated">
          <button
            className="sidebarActionButton"
            onClick={() => handleUpdateField('description', updatedDescription)}
          >
            Wijzigingen opslaan
          </button>
        </div>
      )}
      {showInfo && <MoreInfoPopup setShowInfo={setShowInfo} />}
    </div>
  )
}

function MoreInfoPopup({ setShowInfo }) {
  return (
    <div className="blurContainer">
      <div className="blur" onClick={() => setShowInfo(false)} />
      <div className="popupContainer infoBox">
        <div className="popupContainerHeader">
          <IconButton
            className="closePopup transformed"
            onClick={() => setShowInfo(false)}
          >
            <TbX />
          </IconButton>
          <p>Meer informatie over trailers?</p>
        </div>
        <div className="popupContainerContent">
          <b>Aanhanger beschrijving</b>
          <p>
            Wanneer u uw aanhanger op ons platform aanbiedt, vragen we om een
            beschrijving. Dit is een belangrijk element omdat het potentiële
            huurders inzicht geeft in de unieke kenmerken en mogelijkheden van
            uw aanhanger.
          </p>
          <p>
            Hieronder vindt u een aantal richtlijnen voor het schrijven van uw
            beschrijving:
          </p>
          <b>Specifieke eigenschappen</b>
          <p>
            Vermeld alle bijzondere eigenschappen of functies die uw aanhanger
            uniek maken. Heeft uw aanhanger bijvoorbeeld speciale functies of
            uitrusting?
          </p>
          <b>Toegestande lading</b>
          <p>
            Duidelijkheid over welke lading wel of niet is toegestaan om te
            vervoeren is cruciaal voor de veiligheid en om schade te voorkomen.
            Is uw aanhanger bijvoorbeeld geschikt voor zware goederen of
            speciaal ingericht voor bepaalde ladingen?
          </p>
          <b>Ideale gebruik</b>
          <p>
            Vertel waar uw aanhanger ideaal voor is. Is het perfect voor een
            verhuizing, voor het transporteren van bouwmaterialen, of iets
            anders? Dit helpt huurders om te bepalen of uw aanhanger geschikt is
            voor hun behoeften.
          </p>
        </div>
      </div>
    </div>
  )
}
