import { TbInfoCircle, TbPlus, TbSearch, TbX } from 'react-icons/tb'
import './TrailerAccessoires.css'
import { AccessoireItems } from '../../../data/dummy/TrailerTypes'
import { IconButton } from '@mui/material'
import { useState, KeyboardEvent } from 'react'
import { useTranslation } from 'react-i18next'

export default function TrailerAccessoires({
  trailerAccessoires,
  setTrailerAccessoires,
}) {
  const [customAccessoire, setCustomAccessoire] = useState<string>('')
  const [activeAccessoire, setActiveAccessoire] = useState('')
  const [showExtraInfo, setShowExtraInfo] = useState(false)
  const extraInfo = AccessoireItems.filter(
    (item) => item.accessoire === activeAccessoire
  )
  const { t } = useTranslation('TrailerForm')

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()

      addAccessoire()
    }
  }

  const addAccessoire = () => {
    if (!customAccessoire) {
      return
    }
    if (!trailerAccessoires.some((acc) => acc.name === customAccessoire)) {
      setTrailerAccessoires([...trailerAccessoires, { name: customAccessoire }])
      setCustomAccessoire('')
    }
  }

  const addRegularAccessoire = (item: string) => {
    if (!trailerAccessoires.some((acc) => acc.name === item)) {
      setTrailerAccessoires([...trailerAccessoires, { name: item }])
    }
  }

  const removeAccessoire = (accessoire: string) => {
    const updatedAccessoires = trailerAccessoires.filter(
      (item: { name: string }) => item.name !== accessoire
    )
    setTrailerAccessoires(updatedAccessoires)
  }

  return (
    <div className="formStepContainer">
      <h2>{t('trailerAccessoires.title')}</h2>
      <p>{t('trailerAccessoires.introduction')}</p>
      <div className="accessoiresInput">
        <TbSearch size={20} />
        <input
          placeholder={t('trailerAccessoires.placeholder') + '...'}
          value={customAccessoire}
          onChange={(e) => setCustomAccessoire(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <button className="addBtn" type="button" onClick={addAccessoire}>
          <TbPlus size={20} />
        </button>
      </div>
      {trailerAccessoires.length > 0 && (
        <>
          <div className="divider" />
          <div className="accessoireTagRow">
            {trailerAccessoires.map((item: { name: string }, index: number) => (
              <div
                className="accessoireTag"
                onClick={() => removeAccessoire(item.name)}
                key={index}
              >
                <p>{item.name}</p>
                <TbX className="deleteTagButton" />
              </div>
            ))}
          </div>
        </>
      )}
      <div className="popularAccessoiresContainer">
        <span>{t('trailerAccessoires.subTitle')}</span>
        <div className="trailerTypeGrid addTrailerForm accessoires">
          {AccessoireItems.map((item) => (
            <div
              className="trailerTypeIcon"
              key={item.id}
              onClick={() => addRegularAccessoire(item.accessoire)}
            >
              {item.icon}
              <p>{item.accessoire}</p>
              <IconButton
                className="infoIcon"
                onClick={(e) => {
                  e.stopPropagation()
                  setShowExtraInfo(true)
                  setActiveAccessoire(item.accessoire)
                }}
              >
                <TbInfoCircle />
              </IconButton>
            </div>
          ))}
        </div>
      </div>
      {showExtraInfo && (
        <div className="blurContainer">
          <div className="blur" onClick={() => setShowExtraInfo(false)} />
          <div className="popupContainer infoBox">
            <div className="popupContainerHeader">
              <IconButton
                className="closePopup transformed"
                onClick={() => setShowExtraInfo(false)}
              >
                <TbX />
              </IconButton>
              <p>Meer accessoire informatie</p>
            </div>
            <div className="popupContainerContent accessoireInfo">
              {extraInfo[0].icon}
              <b>{extraInfo[0].accessoire}</b>
              <p>{extraInfo[0].description}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
