import React, { useEffect, useState } from 'react'
import {
  TbBan,
  TbCalendar,
  TbCircleCheck,
  TbCurrencyEuro,
  TbHourglassHigh,
  TbLink,
  TbMapPin,
  TbUser,
} from 'react-icons/tb'
import 'react-tooltip/dist/react-tooltip.css'
import './RecentRentalsTrailer.css'
import { format, parseISO } from 'date-fns'
import { nl } from 'date-fns/locale'
import useAxios from 'hooks/use-axios'
import Loading from 'components/constants/Loading'

interface TrailerProps {
  trailer: any
  setShowReservation: (trailer: any) => void
}

interface TrailerInformation {
  coverImage: string
  trailerWeekScheduleTemplate: {
    monday: {
      price: number
    }
  }
}

const RecentRentalsTrailer: React.FC<TrailerProps> = ({
  trailer,
  setShowReservation,
}) => {
  const [trailerInformation, setTrailerInformation] =
    useState<TrailerInformation | null>(null)
  const { response, loading, error } = useAxios({
    method: 'get',
    url: `/traileroffers/${trailer?.trailer?.id}`,
  })

  useEffect(() => {
    if (response) {
      setTrailerInformation(response as TrailerInformation)
    }
  }, [response])

  const formatDate = (date: string, pattern: string) =>
    format(parseISO(date), pattern, { locale: nl })

  const isPeriod =
    formatDate(trailer.startTime, 'dd MMMM yyyy') !==
    formatDate(trailer.endTime, 'dd MMMM yyyy')

  const getStatusIcon = (confirmed: boolean | null) => {
    if (confirmed === true) return <TbCircleCheck />
    if (confirmed === null) return <TbHourglassHigh />
    return <TbBan />
  }

  if (error) return <div>Error loading trailer information.</div>
  if (loading) return <Loading />

  return (
    <div
      className="rentalTrailersListView"
      onClick={() => setShowReservation(trailer)}
    >
      <img
        alt="trailer"
        className="trailerListViewImage"
        src={trailerInformation?.coverImage}
      />
      <div className="trailerListViewInformation">
        <b style={{ fontSize: '14px' }}>{trailer.trailer.trailerType.name}</b>
        <p className="smallDescription">{trailer.trailer.description}</p>
        <div className="iconGridSmall">
          <div
            className="iconGridItem"
            style={{
              color: `${
                trailer.confirmed !== null && trailer.confirmed !== false
                  ? 'green'
                  : 'red'
              }`,
            }}
          >
            <p>
              {trailer.confirmed !== null && trailer.confirmed !== false
                ? 'Huurverzoek geaccepteerd'
                : 'In behandeling'}
            </p>
          </div>
          <div className="iconGridItem">
            <TbCalendar />
            <p>
              {formatDate(
                trailer.startTime,
                isPeriod ? 'dd MMM' : 'dd MMMM yyyy'
              )}
              {isPeriod && ` - ${formatDate(trailer.endTime, 'dd MMM yyyy')}`}
            </p>
          </div>
          <div className="iconGridItem">
            <TbMapPin />
            <p>{trailer.trailer.address.city}</p>
          </div>
          <div className="iconGridItem">
            <TbCurrencyEuro />
            <p>
              {trailerInformation?.trailerWeekScheduleTemplate?.monday?.price?.toFixed(
                2
              )}{' '}
              per dag
            </p>
          </div>
          <div className="iconGridItem">
            <TbLink />
            <p>Meer info</p>
          </div>
          <div className="iconGridItem primary">
            <TbUser />
            <p>Contact met verhuurder</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RecentRentalsTrailer
