import { useRef, useEffect } from 'react'

export default function ChangePersonalInfo({
  userName,
  setUserName,
  userPhone,
  setUserPhone,
  error,
  setError,
}) {
  const firstInputRef = useRef(null)
  const phoneRef = useRef(null)

  useEffect(() => {
    if (!userName) {
      setError({ type: 'name', message: '*Geef een geldige naam op' })
    }
    if (!userPhone) {
      setError({ type: 'phone', message: '*Geef een geldig telefoonnummer op' })
    }
  }, [userName, userPhone, setError])

  return (
    <>
      <span>Persoonlijke informatie</span>
      <div
        className="inputSpanContainer"
        onClick={() => firstInputRef.current.focus()}
        style={{ marginTop: 5 }}
      >
        <span>Naam</span>
        <input
          type="text"
          value={userName}
          ref={firstInputRef}
          onChange={(e) => setUserName(e.target.value)}
        />
      </div>
      {error.type === 'name' && <p>{error.message}</p>}
      <div
        className="inputSpanContainer"
        onClick={() => phoneRef.current.focus()}
      >
        <span>Telefoonnummer</span>
        <input
          type="text"
          value={userPhone}
          onChange={(e) => setUserPhone(e.target.value)}
          ref={phoneRef}
        />
      </div>
      {error.type === 'phone' && <p>{error.message}</p>}
    </>
  )
}
