import { IconButton } from '@mui/material'
import { TrailerCapacity } from 'data/icons/TrailerIcons'
import { useEffect, useState } from 'react'
import {
  TbChevronLeft,
  TbChevronRight,
  TbHeart,
  TbHeartFilled,
  TbMapPin,
} from 'react-icons/tb'
import { instance } from 'util/axios-instance'
import './TrailerOfferCardlist.css'
import { Link } from 'react-router-dom'
import { truncateString } from '../../../util/string'

export default function TrailerOfferCardlist({
  trailer,
  user,
  savedTrailers,
  setShowLogin,
}) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const allImages = [trailer.coverImage, ...trailer.images]
  // const [isSaved, setIsSaved] = useState(false)

  // useEffect(() => {
  //   setIsSaved(
  //     savedTrailers?.some((item) => item.trailerOfferId === trailer.id)
  //   )
  // }, [savedTrailers, trailer.id])

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % allImages.length)
  }

  const prevImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + allImages.length) % allImages.length
    )
  }

  // const changeSavedState = (e) => {
  //   e.stopPropagation()
  //   e.preventDefault()
  //
  //   if (!user) {
  //     setShowLogin(true)
  //   }
  //
  //   const savedTrailerData = {
  //     userId: user.id,
  //     trailerOfferId: trailer.id,
  //   }
  //
  //   if (isSaved) {
  //     setIsSaved(false)
  //     instance.delete(`/savedtrailers/${trailer.id}`)
  //   } else {
  //     setIsSaved(true)
  //     instance.post('/savedtrailers', savedTrailerData)
  //   }
  // }
  if (!trailer) return
  return (
    <Link to={`/aanbod/${trailer.id}`} className="trailer__card_list_link">
      <div className="trailerCardListContainer">
        <div className="trailerCardListImage">
          <img alt="trailer" src={allImages[currentImageIndex]} />
        </div>
        <div className="trailerCardListInformation">
          <p>{trailer.trailerType.name}</p>
          <b>{trailer.name}</b>
          <p>{truncateString(trailer.description, 20)}</p>
          <div className="flexbox tagRow">
            <div className="trailerCardListTag flexbox">
              <p style={{ marginLeft: 0 }}>€{trailer.price} per dag</p>
            </div>
            <div className="trailerCardListTag flexbox">
              <TbMapPin />
              <p>{trailer.cityAddress.city}</p>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}
