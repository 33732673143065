import clsx from 'clsx'
import { styled } from '@mui/system'
import { useSwitch } from '@mui/base/SwitchUnstyled'

const orange = {
  500: 'var(--primary)',
}

const grey = {
  400: 'var(--border)',
  500: 'var(--border)',
  600: 'var(--border)',
}

const BasicSwitchRoot = styled('span')(
  ({ theme }) => `
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
  margin-left: auto;
  cursor: pointer;
  border-radius: 16px;
  background: ${theme.palette.mode === 'dark' ? grey[600] : grey[400]};

  &.Switch-disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &.Switch-checked {
    background: ${orange[500]};
  }
  `
)

const BasicSwitchInput = styled('input')`
  cursor: inherit;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
  margin: 0;
`

const BasicSwitchThumb = styled('span')`
  display: block;
  width: 16px;
  height: 16px;
  top: 4px;
  left: 4px;
  border-radius: 16px;
  background-color: #fff;
  position: relative;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &.Switch-focusVisible {
    background-color: ${grey[500]};
    box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
  }

  &.Switch-checked {
    left: 20px;
    top: 4px;
    background-color: #fff;
  }
`

function BasicSwitch(props) {
  const { getInputProps, checked, disabled, focusVisible } = useSwitch(props)

  const stateClasses = {
    'Switch-checked': checked,
    'Switch-disabled': disabled,
    'Switch-focusVisible': focusVisible,
  }

  return (
    <BasicSwitchRoot className={clsx(stateClasses)}>
      <BasicSwitchThumb className={clsx(stateClasses)} />
      <BasicSwitchInput {...getInputProps()} aria-label="Demo switch" />
    </BasicSwitchRoot>
  )
}

export default function Switch({ value, setValue }) {
  return (
    <BasicSwitch
      checked={value}
      onChange={() => setValue((current) => !current)}
    />
  )
}
