import * as Form from '@radix-ui/react-form'
import { FieldValues, UseFormRegister } from 'react-hook-form'

export interface TextAreaProps
  extends React.TextareaHTMLAttributes<typeof HTMLTextAreaElement> {
  label: string
  error?: string
  register: UseFormRegister<FieldValues>
}
const TextArea = ({
  name = 'textarea',
  label,
  error,
  required,
  placeholder,
  register,
  rows,
}: TextAreaProps) => {
  return (
    <Form.Field name={name} className="form__field">
      <div className="header">
        <Form.Label className="label">{label}</Form.Label>
        {error && <Form.Message className="message">{error}</Form.Message>}
      </div>
      <Form.Control asChild>
        <div
          className={`control ${error ? 'control__error' : 'control__normal'}`}
        >
          <textarea
            rows={rows || 3}
            className="textarea"
            required={required ? true : false}
            placeholder={placeholder ? placeholder : ''}
            {...register(name)}
          />
        </div>
      </Form.Control>
    </Form.Field>
  )
}

export { TextArea }
