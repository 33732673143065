import { Box } from '@mui/system'
import { useCallback, useEffect, useMemo, useState } from 'react'
import './TrailerFormFooter.css'
import { useTranslation } from 'react-i18next'
import { Button } from 'components/ui'

type TrailerStats = {
  length: number
  width: number
  height: number
}

type Address = {
  postalCode: string
  houseNumber: string
  streetName: string
  city: string
}

interface Day {
  price: number
  timeSlots: Array<{ startTime: string; endTime: string }>
}

type Props = {
  isFirstStep: boolean
  next: () => void
  back: () => void
  isLastStep: boolean
  currentStepIndex: number
  trailerType: string
  license: string
  description: string
  trailerStats: TrailerStats
  address: Address
  trailerImages: string[]
  price: any
  trailerWeekScheduleTemplate: {}
}

const TrailerFormFooter: React.FC<Props> = ({
  isFirstStep,
  next,
  back,
  isLastStep,
  currentStepIndex,
  trailerType,
  license,
  description,
  trailerStats,
  address,
  trailerImages,
  price,
  trailerWeekScheduleTemplate,
}) => {
  const [disableBtn, setDisableBtn] = useState<boolean>(false)
  const { t } = useTranslation('TrailerForm')

  const computedDisableBtn = useMemo(() => {
    if (
      (currentStepIndex === 1 && !trailerType) ||
      (currentStepIndex === 3 && !license) ||
      (currentStepIndex === 4 && trailerImages.length < 4) ||
      (currentStepIndex === 5 &&
        !Object.values(trailerStats).every((x) => !!x)) ||
      (currentStepIndex === 6 && !description) ||
      (currentStepIndex === 7 &&
        !(address ? Object.values(address).every((x) => !!x) : true)) ||
      (currentStepIndex === 8 && price === 0) ||
      (currentStepIndex === 8 && price === '') ||
      (currentStepIndex === 9 &&
        !Object.values<Day>(trailerWeekScheduleTemplate).some(
          (day) => day.timeSlots.length > 0
        ))
    ) {
      return true
    } else {
      return false
    }
  }, [
    currentStepIndex,
    trailerType,
    license,
    description,
    trailerStats,
    address,
    trailerImages,
    price,
    trailerWeekScheduleTemplate,
  ])

  useEffect(() => {
    setDisableBtn(computedDisableBtn)
  }, [computedDisableBtn])

  const handleBack = useCallback(() => {
    back()
  }, [back])

  const handleNext = useCallback(() => {
    next()
  }, [next])

  return (
    <Box>
      <div className="trailerFormFooterContainer">
        {!isFirstStep && (
          <Button variant="ghostPrimary" onClick={handleBack}>
            {t('general.previousStep')}
          </Button>
        )}
        {!isLastStep && (
          <Button
            variant={disableBtn ? 'disabled' : 'primary'}
            style={{ marginLeft: 'auto' }}
            onClick={handleNext}
            disabled={disableBtn ? true : false}
            autoFocus
          >
            {isFirstStep ? `${t('general.start')}` : `${t('general.nextStep')}`}
          </Button>
        )}
      </div>
    </Box>
  )
}

export default TrailerFormFooter
