import { useEffect, useRef, useState } from 'react'
import { TextareaAutosize } from '@mui/material'
import './Description.css'
import { useTranslation } from 'react-i18next'

type Props = {
  description: string
  setDescription: (description: string) => void
}

export default function General({ description, setDescription }: Props) {
  const { t } = useTranslation('TrailerForm')
  const [charCount, setCharCount] = useState(description.length)
  const [numCols, setNumCols] = useState(1)
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const [textWidthPercentage, setTextWidthPercentage] = useState(0)
  const prevNumColsRef = useRef<number>(0)

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value)
    setCharCount(e.target.value.length)

    if (inputRef.current) {
      const widthPercentage = getTextWidthPercentage(
        e.target.value,
        inputRef.current
      )
      setTextWidthPercentage(widthPercentage)
    }
  }

  const getTextWidthPercentage = (
    text: string,
    textarea: HTMLTextAreaElement
  ) => {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    if (!ctx) return 0

    const style = window.getComputedStyle(textarea)
    const fontSize = style.fontSize
    const fontFamily = style.fontFamily

    ctx.font = `${fontSize} ${fontFamily}`
    const textWidth = ctx.measureText(text).width + 65
    const textareaWidth = textarea.clientWidth

    const widthPercentage = (textWidth / textareaWidth) * 100
    return widthPercentage
  }

  useEffect(() => {
    if (inputRef.current) {
      const lines = description.split(/\r*\n/)
      const numberOfLines = lines.length
      prevNumColsRef.current = numberOfLines
      setNumCols(numberOfLines)
    }
  }, [description])

  useEffect(() => {
    if (textWidthPercentage >= 96) {
      const threshold = 90
      const timesCrossed = Math.floor((textWidthPercentage - 99) / threshold)
      const updatedNumCols = prevNumColsRef.current + timesCrossed
      setNumCols(updatedNumCols)
    }
  }, [textWidthPercentage])

  return (
    <div className="formStepContainer description">
      <h2>{t('description.title')}</h2>
      <p>{t('description.introduction')}</p>
      <div className="textAreaContainer">
        <TextareaAutosize
          className="textArea description"
          id="test"
          placeholder={`${t('description.placeholderExamples.example1')}
          `}
          value={description}
          onChange={handleChange}
          maxLength={1200}
          ref={inputRef}
        />
        <div className="charCountContainer">
          <p className="textAreaCount">{charCount} / 1200</p>
        </div>
      </div>
    </div>
  )
}
