import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'

i18n
  .use(HttpApi) // load translation using http -> see /public/locales
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: 'nl', // if user/computer language settings are not on your list of available languages, it will default to 'en'
    debug: false, // logs info level to console output. Helps to debug your app.

    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
  })

export default i18n
