import { instance } from '../util/axios-instance'
import { User } from '../types/User'
import { Tokens } from '../types/Tokens'
import { SignUp } from '../types/SignUp'
import { DeviceId } from '../types/DeviceId'
import { LocalStorage } from '../types/LocalStorage'
import { useLocalStorage } from './use-localstorage'
import { useCallback, useContext, useEffect, useState } from 'react'
import { AuthContext } from '../context/auth-context'
import { v4 as uuid } from 'uuid'

export function useAuth() {
  const { user, setUser, removeUser } = useContext(AuthContext)
  const { setItem: setTokens, removeItem: removeTokens } =
    useLocalStorage<Tokens>(LocalStorage.Tokens)
  const { setItem: setDeviceId, getItem: getDeviceId } =
    useLocalStorage<DeviceId>(LocalStorage.DeviceId)

  let logoutTimer: NodeJS.Timeout
  const [isLoading, setIsLoading] = useState(true)

  const register = async (data: SignUp) => {
    await instance.request<String>({
      method: 'post',
      url: '/auth/register',
      data,
    })
  }

  const login = async (data: { username: string; password: string }) => {
    const response = await instance.request<Tokens>({
      method: 'post',
      url: '/auth/login',
      data: { ...data, deviceId: await getDeviceIdentifier() },
    })
    setTokens(response.data)
    await getCurrentUser()
  }

  const logout = () => {
    removeTokens()
    removeUser()

    // Clear the timer when logging out
    if (logoutTimer) {
      clearTimeout(logoutTimer)
    }
  }

  const getCurrentUser = async () => {
    try {
      const response = await instance.request<User>({
        method: 'get',
        url: '/customers/self',
      })
      setUser(response.data)
    } catch (error) {
      console.error('Error fetching current user:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const getDeviceIdentifier = async (): Promise<string> => {
    if (getDeviceId() == null) {
      setDeviceId({
        device_id: uuid(),
      })

      return getDeviceId().device_id
    }

    return getDeviceId().device_id
  }

  const refreshUser = useCallback(async () => {
    const response = await instance.request<User>({
      method: 'get',
      url: '/customers/self',
    })
    setUser(response.data)
  }, [setUser])

  useEffect(() => {
    getCurrentUser()

    return () => {
      if (logoutTimer) {
        clearTimeout(logoutTimer)
      }
    }
  }, [])

  // Return the user object and auth methods
  return {
    user,
    login,
    register,
    logout,
    refreshUser,
    isLoading,
    getDeviceIdentifier,
  }
}
