import Switch from 'components/profile/trailer_owner/calendar_view/sidebar/elements/Switch'
import './Notifications.css'

export default function Notifications() {
  return (
    <div className="aboutReservationContent settings notifications">
      <p>
        Wijzig van welke meldingen jij een email/push notificatie wilt ontvangen
      </p>
      <span>Algemeen</span>
      <div className="changeNotificationItem flexbox">
        <div>
          <p>BuurBak nieuws</p>
          <p>Ontvang meldingen over BuurBak nieuws</p>
        </div>
        <Switch value={[]} setValue={[]} />
      </div>
      <div className="changeNotificationItem flexbox">
        <div>
          <p>Feedback</p>
          <p>Ontvang meldingen over BuurBak tips</p>
        </div>
        <Switch value={[]} setValue={[]} />
      </div>
      <div className="divider settings" style={{ minHeight: '1px' }} />
      <span>Huren</span>
      <div className="changeNotificationItem flexbox">
        <div>
          <p>Nieuwe reservering geplaatst</p>
          <p>Ontvang meldingen wanneer een reservering is geplaatst</p>
        </div>
        <Switch value={[]} setValue={[]} />
      </div>
      <div className="changeNotificationItem flexbox">
        <div>
          <p>Reservering bevestigd</p>
          <p>
            Ontvang meldingen wanneer een eigenaar jouw reservering heeft
            beantwoord
          </p>
        </div>
        <Switch value={[]} setValue={[]} />
      </div>
      <div className="changeNotificationItem flexbox">
        <div>
          <p>Reservering gewijzigd</p>
          <p>Ontvang meldingen wanneer een eigenaar jouw reservering wijzigt</p>
        </div>
        <Switch value={[]} setValue={[]} />
      </div>
      <div className="divider settings" style={{ minHeight: '1px' }} />
      <span>Verhuren</span>
      <div className="changeNotificationItem flexbox">
        <div>
          <p>Nieuwe huuraanvraag</p>
          <p>Ontvang meldingen wanneer een huuraanvraag binnen komt</p>
        </div>
        <Switch value={[]} setValue={[]} />
      </div>
    </div>
  )
}
