export function BoatTrailer({
  color = '#222222',
  size = 24,
  strokeWidth = '2',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 27 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M21 17.4994H13.5M21 17.4994V13.2C21 12.5373 20.4627 12 19.8 12H2.2C1.53726 12 1 12.5373 1 13.2V14.9994C1 16.3801 2.11929 17.4994 3.5 17.4994H8.5M21 17.4994H25.8C25.9105 17.4994 26 17.4099 26 17.2994V14.1653M13.5 17.4994V17.4994C13.5 16.1187 12.3807 14.9988 11 14.9988V14.9988C9.61929 14.9988 8.5 16.1187 8.5 17.4994V17.4994M13.5 17.4994V17.4994C13.5 18.8801 12.3807 20 11 20V20C9.61929 20 8.5 18.8801 8.5 17.4994V17.4994M15.5 4.5H21.8079C21.8888 4.5 21.9363 4.59107 21.8899 4.65735L18.7986 9.07346C18.6114 9.34079 18.3057 9.5 17.9793 9.5H2.13257C2.06614 9.5 2.01817 9.43641 2.03642 9.37253L2.79278 6.72528C2.91544 6.29598 3.30782 6 3.7543 6H5M15.5 4.5L14.2597 1.60608C14.1022 1.2384 13.7406 1 13.3406 1L6.5 1M15.5 4.5H14.1696C13.7252 4.5 13.2828 4.55925 12.854 4.67618L8.646 5.82382C8.21725 5.94075 7.77482 6 7.33041 6H5M5 1L6.5 1M6.5 1L5 6" />
    </svg>
  )
}

export function BicycleTrailer({
  color = '#222222',
  size = 24,
  strokeWidth = '2',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 27 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M21 18.4994H13.5M21 18.4994V14.2C21 13.5373 20.4627 13 19.8 13H2.2C1.53726 13 1 13.5373 1 14.2V15.9994C1 17.3801 2.11929 18.4994 3.5 18.4994H8.5M21 18.4994H25.8C25.9105 18.4994 26 18.4099 26 18.2994V15.1653M13.5 18.4994V18.4994C13.5 17.1187 12.3807 15.9988 11 15.9988V15.9988C9.61929 15.9988 8.5 17.1187 8.5 18.4994V18.4994M13.5 18.4994V18.4994C13.5 19.8801 12.3807 21 11 21V21C9.61929 21 8.5 19.8801 8.5 18.4994V18.4994M8.44008 3.16736H7.32851M8.44008 3.16736H10M8.44008 3.16736L11.1316 8.01208C11.1697 8.08066 11.2683 8.08066 11.3064 8.01208L13.9979 3.16736M8.44008 3.16736L5.50103 8.16942M13.9979 3.16736L16.5 8.16942M13.9979 3.16736V1.1C13.9979 1.04477 13.9532 1 13.8979 1H12.3306M5.50103 5.66839C4.11975 5.66839 3 6.78814 3 8.16942C3 9.55071 4.11975 10.6705 5.50103 10.6705C6.88232 10.6705 8.00207 9.55071 8.00207 8.16942C8.00207 6.78814 6.88232 5.66839 5.50103 5.66839ZM16.499 5.66839C15.1177 5.66839 13.9979 6.78814 13.9979 8.16942C13.9979 9.55071 15.1177 10.6705 16.499 10.6705C17.8802 10.6705 19 9.55071 19 8.16942C19 6.78814 17.8802 5.66839 16.499 5.66839Z" />
    </svg>
  )
}

export function BagageTrailer({
  color = '#222222',
  size = 24,
  strokeWidth = '2',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 27 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M21 12.4994H13.5M21 12.4994V5.44549M21 12.4994H25.8C25.9105 12.4994 26 12.4099 26 12.2994V9.1653M13.5 12.4994V12.4994C13.5 11.1187 12.3807 9.99883 11 9.99883V9.99883C9.61929 9.99883 8.5 11.1187 8.5 12.4994V12.4994M13.5 12.4994V12.4994C13.5 13.8801 12.3807 15 11 15V15C9.61929 15 8.5 13.8801 8.5 12.4994V12.4994M8.5 12.4994H3C1.89543 12.4994 1 11.604 1 10.4994V5.44549M1 5.44549V2.2C1 1.53726 1.53726 1 2.2 1H19.8C20.4627 1 21 1.53726 21 2.2V5.44549M1 5.44549H21" />
    </svg>
  )
}

export function Bricks({ color = '#222222', size = 24, strokeWidth = '2' }) {
  return (
    <svg
      width={size}
      viewBox="0 0 27 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M7 9H1.5C1.22386 9 1 8.77614 1 8.5V5.5C1 5.22386 1.22386 5 1.5 5H4M7 9V5H4M7 9H13M22 5H24.5C24.7761 5 25 5.22386 25 5.5V8.5C25 8.77614 24.7761 9 24.5 9H19M22 5V1.5C22 1.22386 21.7761 1 21.5 1L16 1M22 5H19M10 5V1M10 5H13M10 5H4M10 1L16 1M10 1L4.5 1C4.22386 1 4 1.22386 4 1.5V5M16 1V5M16 5H13M16 5H19M13 9V5M13 9H19M19 5V9M22 13H24.5C24.7761 13 25 13.2239 25 13.5V16.5C25 16.7761 24.7761 17 24.5 17H19M22 13V9.5C22 9.22386 21.7761 9 21.5 9H16M22 13H19M10 13V9M10 13H13M10 13H7M10 9H16M10 9H4.5C4.22386 9 4 9.22386 4 9.5V13M16 9V13M16 13H13M16 13H19M4 13H7M4 13H1.5C1.22386 13 1 13.2239 1 13.5V16.5C1 16.7761 1.22386 17 1.5 17H7M7 17V13M7 17H13M13 13V17M13 17H19M19 13V17M10 21V17M10 21H13H16M10 21H7H4.5C4.22386 21 4 20.7761 4 20.5V17.5C4 17.2239 4.22386 17 4.5 17H10M10 17H16M16 17V21M16 17H21.5C21.7761 17 22 17.2239 22 17.5V20.5C22 20.7761 21.7761 21 21.5 21H19H16" />
    </svg>
  )
}

export function Canoe({ color = '#222222', size = 24, strokeWidth = '2' }) {
  return (
    <svg
      width={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M20.7782 20.7782V20.7782C21.4316 20.1248 22.4909 20.1248 23.1443 20.7782L24.9293 22.5631C24.9683 22.6022 24.9683 22.6655 24.9293 22.7045L22.7045 24.9293C22.6655 24.9684 22.6021 24.9684 22.5631 24.9293L20.7782 23.1444C20.1248 22.491 20.1248 21.4316 20.7782 20.7782V20.7782ZM20.7782 20.7782L16.0052 16.0052M5.22192 5.22175V5.22175C5.87527 4.5684 5.87527 3.50911 5.22192 2.85576L3.43687 1.07071C3.39782 1.03166 3.3345 1.03166 3.29545 1.07071L1.07071 3.29546C1.03166 3.33451 1.03166 3.39782 1.07071 3.43688L2.85553 5.22173C3.50899 5.8752 4.56846 5.87521 5.22192 5.22175V5.22175ZM5.22192 5.22175L9.99489 9.99472M9.98863 13.0612L9.97349 13.0864C9.46758 13.9296 9.60044 15.0089 10.2958 15.7042V15.7042C10.9911 16.3996 12.0704 16.5324 12.9136 16.0265L12.9388 16.0114C13.0917 15.9196 13.2328 15.8095 13.3588 15.6834L15.6834 13.3589C15.8095 13.2328 15.9196 13.0917 16.0113 12.9388L16.0265 12.9136C16.5324 12.0704 16.3995 10.9911 15.7042 10.2958V10.2958C15.0089 9.60048 13.9296 9.46761 13.0864 9.97352L13.0612 9.98866C12.9083 10.0804 12.7672 10.1905 12.6412 10.3166L10.3166 12.6412C10.1905 12.7673 10.0804 12.9083 9.98863 13.0612ZM22.2481 7.51713L24.0932 2.54954C24.2422 2.14851 23.8515 1.75778 23.4504 1.90673L18.4829 3.75184C11.6631 6.2849 6.28496 11.663 3.75189 18.4828L1.90679 23.4504C1.75783 23.8514 2.14856 24.2421 2.54959 24.0932L7.51717 22.2481C14.337 19.715 19.7151 14.3369 22.2481 7.51713Z" />
    </svg>
  )
}

export function Wheelbarrow({
  color = '#222222',
  size = 24,
  strokeWidth = '2',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 26 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M10 7L2.5 3H1M10 7L6.39968 14.2006C5.98634 15.0273 6.58748 16 7.51176 16V16C7.82559 16 8.12781 15.8813 8.35778 15.6678L15 9.5M10 7L15 9.5M15 9.5L20.5 12.5M20.5 9V9C22.433 9 24 10.567 24 12.5V12.5C24 14.433 22.433 16 20.5 16V16C18.567 16 17 14.433 17 12.5V12.5C17 10.567 18.567 9 20.5 9ZM18.9391 6.77378H15.521C14.859 6.77378 14.2155 6.5548 13.691 6.15096L8.86262 2.43391C8.25745 1.96803 8.5869 1 9.35063 1H23.3355C24.0063 1 24.3792 1.77595 23.9602 2.29976L21.2817 5.64787C20.7124 6.35951 19.8505 6.77378 18.9391 6.77378Z" />
    </svg>
  )
}

export function WashingMachine({
  color = '#222222',
  size = 24,
  strokeWidth = '2',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M6.64706 12.2941C8.14105 11.7508 9.70858 10.6498 12.2941 13.2353C15.1176 16.0588 17.4633 13.5429 18.8824 13.2353M4.5 4.24588V5M3.07059 25H22.9294C24.073 25 25 24.073 25 22.9294V3.07059C25 1.92704 24.073 1 22.9294 1L3.07059 1C1.92703 1 1 1.92704 1 3.07059V22.9294C1 24.073 1.92703 25 3.07059 25ZM13 6.64706V6.64706C16.5086 6.64706 19.3529 9.49137 19.3529 13V13C19.3529 16.5086 16.5086 19.3529 13 19.3529V19.3529C9.49137 19.3529 6.64706 16.5086 6.64706 13V13C6.64706 9.49137 9.49137 6.64706 13 6.64706Z" />
    </svg>
  )
}

export function UnbrakedTrailer({
  color = '#222222',
  size = 24,
  strokeWidth = '2',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 27 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M21 10.4994H13.5M21 10.4994V5M21 10.4994H25.8C25.9105 10.4994 26 10.4099 26 10.2994V7.16529M13.5 10.4994V10.4994C13.5 9.1187 12.3807 7.99882 11 7.99882V7.99882C9.61929 7.99882 8.5 9.1187 8.5 10.4994V10.4994M13.5 10.4994V10.4994C13.5 11.8801 12.3807 13 11 13V13C9.61929 13 8.5 11.8801 8.5 10.4994V10.4994M8.5 10.4994H3.5C2.11929 10.4994 1 9.38012 1 7.99941V5M1 5V2.2C1 1.53726 1.53726 1 2.2 1H7.5M1 5H7.5M21 5V2.2C21 1.53726 20.4627 1 19.8 1H14.5M21 5H14.5M7.5 5V1M7.5 5H14.5M7.5 1H14.5M14.5 5V1" />
    </svg>
  )
}

export function TransportTrailerDouble({
  color = '#222222',
  size = 24,
  strokeWidth = '2',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 34 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M27.7713 7H2.2C1.53726 7 1 7.53726 1 8.2V9.99735C1 11.3781 2.11929 12.4974 3.5 12.4974H7.86M27.7713 7V12.4974M27.7713 7V1M27.7713 12.4974H32.8C32.9105 12.4974 33 12.4078 33 12.2974V9.16001M27.7713 12.4974H20.91M20.91 12.4974V12.4974C20.91 11.1181 19.7892 10 18.41 10V10C17.0307 10 15.91 11.1181 15.91 12.4974V12.4974M20.91 12.4974V12.4974C20.91 13.8781 19.7907 15 18.41 15V15C17.0293 15 15.91 13.8781 15.91 12.4974V12.4974M15.91 12.4974H12.86M12.86 12.4974V12.4974C12.86 11.1181 11.7393 10 10.36 10V10C8.98075 10 7.86 11.1181 7.86 12.4974V12.4974M12.86 12.4974V12.4974C12.86 13.8781 11.7407 15 10.36 15V15C8.97929 15 7.86 13.8781 7.86 12.4974V12.4974" />
    </svg>
  )
}

export function TrailerWidthTop({
  color = '#222222',
  size = 24,
  strokeWidth = '2',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M1 6V1.2C1 1.08954 1.08954 1 1.2 1H5M5 1V4M5 1H9M9 1V6M9 1H13M13 1V4M13 1H17M17 1V6M17 1H21M21 1V4M21 1H24.8C24.9105 1 25 1.08954 25 1.2V6M2.25 13.75H23.75M2.25 13.75V17.5M2.25 13.75L4.9474 11.7883C4.98159 11.7634 5.02277 11.75 5.06504 11.75H20.935C20.9772 11.75 21.0184 11.7634 21.0526 11.7883L23.75 13.75M23.75 13.75V17.5M2.25 17.5V17.75C2.25 18.8546 3.14543 19.75 4.25 19.75H13H21.75C22.8546 19.75 23.75 18.8546 23.75 17.75V17.5M2.25 17.5V17.5C1.55964 17.5 1 18.0596 1 18.75V22.5C1 22.7761 1.22386 23 1.5 23H1.75M23.75 17.5V17.5C24.4404 17.5 25 18.0596 25 18.75V22.5C25 22.7761 24.7761 23 24.5 23H24.25M24.25 23V23.5C24.25 24.3284 23.5784 25 22.75 25H20.25C19.4216 25 18.75 24.3284 18.75 23.5V21.75M24.25 23H21.25C20.9739 23 20.75 22.7761 20.75 22.5V22.25C20.75 21.9739 20.5261 21.75 20.25 21.75H18.75M1.75 23V23.5C1.75 24.3284 2.42157 25 3.25 25H4.25H5.75C6.57843 25 7.25 24.3284 7.25 23.5V21.75M1.75 23H4.75C5.02614 23 5.25 22.7761 5.25 22.5V22.25C5.25 21.9739 5.47386 21.75 5.75 21.75H7.25M18.75 21.75H7.25" />
    </svg>
  )
}

export function TrailerWidthBottom({
  color = '#222222',
  size = 24,
  strokeWidth = '2',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M1 20V24.8C1 24.9105 1.08954 25 1.2 25H5M5 25V22M5 25H9M9 25V20M9 25H13M13 25V22M13 25H17M17 25V20M17 25H21M21 25V22M21 25H24.8C24.9105 25 25 24.9105 25 24.8V20M2.25 3H23.75M2.25 3V6.75M2.25 3L4.9474 1.03825C4.98159 1.01339 5.02277 1 5.06504 1H20.935C20.9772 1 21.0184 1.01339 21.0526 1.03825L23.75 3M23.75 3V6.75M2.25 6.75V7C2.25 8.10457 3.14543 9 4.25 9H13H21.75C22.8546 9 23.75 8.10457 23.75 7V6.75M2.25 6.75V6.75C1.55964 6.75 1 7.30964 1 8V11.75C1 12.0261 1.22386 12.25 1.5 12.25H1.75M23.75 6.75V6.75C24.4404 6.75 25 7.30964 25 8V11.75C25 12.0261 24.7761 12.25 24.5 12.25H24.25M24.25 12.25V12.75C24.25 13.5784 23.5784 14.25 22.75 14.25H20.25C19.4216 14.25 18.75 13.5784 18.75 12.75V11M24.25 12.25H21.25C20.9739 12.25 20.75 12.0261 20.75 11.75V11.5C20.75 11.2239 20.5261 11 20.25 11H18.75M1.75 12.25V12.75C1.75 13.5784 2.42157 14.25 3.25 14.25H4.25H5.75C6.57843 14.25 7.25 13.5784 7.25 12.75V11M1.75 12.25H4.75C5.02614 12.25 5.25 12.0261 5.25 11.75V11.5C5.25 11.2239 5.47386 11 5.75 11H7.25M18.75 11H7.25" />
    </svg>
  )
}

export function TrailerWidth4({
  color = '#222222',
  size = 24,
  strokeWidth = '2',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M1 20V24.8C1 24.9105 1.08954 25 1.2 25H5M5 25V22M5 25H9M9 25V20M9 25H13M13 25V22M13 25H17M17 25V20M17 25H21M21 25V22M21 25H24.8C24.9105 25 25 24.9105 25 24.8V20M2.25 3H23.75M2.25 3V6.75M2.25 3L4.9474 1.03825C4.98159 1.01339 5.02277 1 5.06504 1H20.935C20.9772 1 21.0184 1.01339 21.0526 1.03825L23.75 3M23.75 3V6.75M2.25 6.75V7C2.25 8.10457 3.14543 9 4.25 9H13H21.75C22.8546 9 23.75 8.10457 23.75 7V6.75M2.25 6.75V6.75C1.55964 6.75 1 7.30964 1 8V11.75C1 12.0261 1.22386 12.25 1.5 12.25H1.75M23.75 6.75V6.75C24.4404 6.75 25 7.30964 25 8V11.75C25 12.0261 24.7761 12.25 24.5 12.25H24.25M24.25 12.25V12.75C24.25 13.5784 23.5784 14.25 22.75 14.25H20.25C19.4216 14.25 18.75 13.5784 18.75 12.75V11M24.25 12.25H21.25C20.9739 12.25 20.75 12.0261 20.75 11.75V11.5C20.75 11.2239 20.5261 11 20.25 11H18.75M1.75 12.25V12.75C1.75 13.5784 2.42157 14.25 3.25 14.25H4.25H5.75C6.57843 14.25 7.25 13.5784 7.25 12.75V11M1.75 12.25H4.75C5.02614 12.25 5.25 12.0261 5.25 11.75V11.5C5.25 11.2239 5.47386 11 5.75 11H7.25M18.75 11H7.25M10.5 6.5H15.5" />
    </svg>
  )
}

export function TrailerWidth3({
  color = '#222222',
  size = 24,
  strokeWidth = '2',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M1 20V24.8C1 24.9104 1.08954 25 1.2 25H5M5 25V22M5 25H9M9 25V20M9 25H13M13 25V22M13 25H17M17 25V20M17 25H21M21 25V22M21 25H24.8C24.9105 25 25 24.9104 25 24.8V20M2.25 9.74999V10C2.25 11.1046 3.14543 12 4.25 12H13H21.75C22.8546 12 23.75 11.1046 23.75 10V9.74999M2.25 9.74999V7.2C2.25 6.8134 2.5634 6.5 2.95 6.5H4M2.25 9.74999V9.74999C1.55964 9.74999 1 10.3096 1 11V14.5C1 14.7761 1.22386 15 1.5 15H1.75M23.75 9.74999V9.74999C24.4404 9.74999 25 10.3096 25 11V14.5C25 14.7761 24.7761 15 24.5 15H24.25M23.75 9.74999V7.2C23.75 6.8134 23.4366 6.5 23.05 6.5H22M24.25 15V15.45C24.25 16.4441 23.4441 17.25 22.45 17.25H20.55C19.5559 17.25 18.75 16.4441 18.75 15.45V14M24.25 15H21.25C20.9739 15 20.75 14.7761 20.75 14.5V14.5C20.75 14.2238 20.5261 14 20.25 14H18.75M1.75 15V15.7C1.75 16.6941 2.55589 17.5 3.55 17.5H5.45C6.44411 17.5 7.25 16.6941 7.25 15.7V14M1.75 15H4.75C5.02614 15 5.25 14.7761 5.25 14.5V14.5C5.25 14.2238 5.47386 14 5.75 14H7.25M18.75 14H7.25M5 9H5.01M21 9H21.01M4 6.5H22M4 6.5V3.5M4 1V3.5M22 6.5V3.5M22 1V3.5M22 3.5H4" />
    </svg>
  )
}

export function TrailerWidth2({
  color = '#222222',
  size = 24,
  strokeWidth = '2',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M1 20V24.8C1 24.9104 1.08954 25 1.2 25H5M5 25V22M5 25H9M9 25V20M9 25H13M13 25V22M13 25H17M17 25V20M17 25H21M21 25V22M21 25H24.8C24.9105 25 25 24.9104 25 24.8V20M2.25 9.74999V10C2.25 11.1046 3.14543 12 4.25 12H13H21.75C22.8546 12 23.75 11.1046 23.75 10V9.74999M2.25 9.74999V7.2C2.25 6.8134 2.5634 6.5 2.95 6.5H4.25M2.25 9.74999V9.74999C1.55964 9.74999 1 10.3096 1 11V14.5C1 14.7761 1.22386 15 1.5 15H1.75M23.75 9.74999V9.74999C24.4404 9.74999 25 10.3096 25 11V14.5C25 14.7761 24.7761 15 24.5 15H24.25M23.75 9.74999V7.2C23.75 6.8134 23.4366 6.5 23.05 6.5H21.75M24.25 15V15.45C24.25 16.4441 23.4441 17.25 22.45 17.25H20.55C19.5559 17.25 18.75 16.4441 18.75 15.45V14M24.25 15H21.25C20.9739 15 20.75 14.7761 20.75 14.5V14.5C20.75 14.2238 20.5261 14 20.25 14H18.75M1.75 15V15.7C1.75 16.6941 2.55589 17.5 3.55 17.5H5.45C6.44411 17.5 7.25 16.6941 7.25 15.7V14M1.75 15H4.75C5.02614 15 5.25 14.7761 5.25 14.5V14.5C5.25 14.2238 5.47386 14 5.75 14H7.25M18.75 14H7.25M4.25 6.5H21.75M4.25 6.5V3.5M4.25 1V3.5M21.75 6.5V3.5M21.75 1V3.5M21.75 3.5H4.25" />
    </svg>
  )
}

export function TrailerWeight({
  color = '#222222',
  size = 24,
  strokeWidth = '2',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 26 24.5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M20.5 20.9994H13.5M20.5 20.9994V14.5M20.5 20.9994H24.8C24.9105 20.9994 25 20.9098 25 20.7994V17.6653M13.5 20.9994V20.9994C13.5 19.6187 12.3807 18.4988 11 18.4988V18.4988C9.61929 18.4988 8.5 19.6187 8.5 20.9994V20.9994M13.5 20.9994V20.9994C13.5 22.3801 12.3807 23.5 11 23.5V23.5C9.61929 23.5 8.5 22.3801 8.5 20.9994V20.9994M8.5 20.9994H3.5C2.11929 20.9994 1 19.8801 1 18.4994V15.7C1 15.0373 1.53726 14.5 2.2 14.5H20.5M20.5 14.5V9.49997M10.5 4V4C11.3284 4 12 3.32843 12 2.5V2.5C12 1.67157 11.3284 1 10.5 1V1C9.67157 1 9 1.67157 9 2.5V2.5C9 3.32843 9.67157 4 10.5 4ZM6.22361 5.55279L3.3618 11.2764C3.19558 11.6088 3.43733 12 3.80902 12H17.191C17.5627 12 17.8044 11.6088 17.6382 11.2764L14.7764 5.55279C14.607 5.214 14.2607 5 13.882 5H7.11803C6.73926 5 6.393 5.214 6.22361 5.55279Z" />
    </svg>
  )
}

export function TrailerWeightDouble({
  color = '#222222',
  size = 24,
  strokeWidth = '2',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 26 24.5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M20.5 20.9994V14.5M20.5 20.9994H24.8C24.9105 20.9994 25 20.9098 25 20.7994V17.6653M20.5 20.9994H17M9.5 20.9994V20.9994C9.5 19.6187 8.38071 18.4988 7 18.4988V18.4988C5.61929 18.4988 4.5 19.6187 4.5 20.9994V20.9994M9.5 20.9994V20.9994C9.5 22.3801 8.38071 23.5 7 23.5V23.5C5.61929 23.5 4.5 22.3801 4.5 20.9994V20.9994M9.5 20.9994C11.5 20.9999 12 20.9994 12 20.9994M4.5 20.9994H3.5C2.11929 20.9994 1 19.8801 1 18.4994V15.7C1 15.0373 1.53726 14.5 2.2 14.5H20.5M20.5 14.5V9.49997M17 20.9994C17 22.3801 15.8807 23.5 14.5 23.5C13.1195 23.5 12.0003 22.3798 12 20.9994M17 20.9994C17 19.6187 15.8807 18.4988 14.5 18.4988C13.1193 18.4988 12 19.6187 12 20.9994M10.5 4V4C11.3284 4 12 3.32843 12 2.5V2.5C12 1.67157 11.3284 1 10.5 1V1C9.67157 1 9 1.67157 9 2.5V2.5C9 3.32843 9.67157 4 10.5 4ZM6.22361 5.55279L3.3618 11.2764C3.19558 11.6088 3.43733 12 3.80902 12H17.191C17.5627 12 17.8044 11.6088 17.6382 11.2764L14.7764 5.55279C14.607 5.214 14.2607 5 13.882 5H7.11803C6.73926 5 6.393 5.214 6.22361 5.55279Z" />
    </svg>
  )
}

export function TrailerLengthTop2({
  color = '#222222',
  size = 24,
  strokeWidth = '2',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M20.5 22.4994H13.5M20.5 22.4994V16M20.5 22.4994H24.8C24.9105 22.4994 25 22.4098 25 22.2994V19.1653M13.5 22.4994V22.4994C13.5 21.1187 12.3807 19.9988 11 19.9988V19.9988C9.61929 19.9988 8.5 21.1187 8.5 22.4994V22.4994M13.5 22.4994V22.4994C13.5 23.8801 12.3807 25 11 25V25C9.61929 25 8.5 23.8801 8.5 22.4994V22.4994M8.5 22.4994H3.5C2.11929 22.4994 1 21.3801 1 19.9994V17.2C1 16.5373 1.53726 16 2.2 16H20.5M20.5 16V11M1 6V1.2C1 1.08954 1.08954 1 1.2 1L5 1M5 1V4M5 1H9M9 1V6M9 1H13M13 1V4M13 1L17 1M17 1V6M17 1H21M21 1V4M21 1H24.8C24.9105 1 25 1.08954 25 1.2V6" />
    </svg>
  )
}

export function TrailerLengthDouble({
  color = '#222222',
  size = 24,
  strokeWidth = '2',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M20.5 12.4994V7.00003M20.5 12.4994H24.8C24.9105 12.4994 25 12.4099 25 12.2994V9.1653M20.5 12.4994H17M9.5 12.4994V12.4994C9.5 11.1187 8.38071 9.99883 7 9.99883V9.99883C5.61929 9.99883 4.5 11.1187 4.5 12.4994V12.4994M9.5 12.4994V12.4994C9.5 13.8801 8.38071 15 7 15V15C5.61929 15 4.5 13.8801 4.5 12.4994V12.4994M9.5 12.4994H12M4.5 12.4994H3.5C2.11929 12.4994 1 11.3801 1 9.99941V8.20003C1 7.53729 1.53726 7.00003 2.2 7.00003H20.5M20.5 7.00003V1M1 20V24.8C1 24.9105 1.08954 25 1.2 25H5M5 25V22M5 25H9M9 25V20M9 25H13M13 25V22M13 25H17M17 25V20M17 25H21M21 25V22M21 25H24.8C24.9105 25 25 24.9105 25 24.8V20M17 12.4994C17 13.8801 15.8807 15 14.5 15C13.1193 15 12 13.8801 12 12.4994M17 12.4994C17 11.1187 15.8807 9.99883 14.5 9.99883C13.1193 9.99883 12 11.1187 12 12.4994" />
    </svg>
  )
}

export function TrailerLengthDouble2({
  color = '#222222',
  size = 24,
  strokeWidth = '2',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M20.5 12.4994V6.00003M20.5 12.4994H24.8C24.9105 12.4994 25 12.4099 25 12.2994V9.1653M20.5 12.4994H17M9.5 12.4994V12.4994C9.5 11.1187 8.38071 9.99883 7 9.99883V9.99883C5.61929 9.99883 4.5 11.1187 4.5 12.4994V12.4994M9.5 12.4994V12.4994C9.5 13.8801 8.38071 15 7 15V15C5.61929 15 4.5 13.8801 4.5 12.4994V12.4994M9.5 12.4994H12M4.5 12.4994H3.5C2.11929 12.4994 1 11.3801 1 9.99941V7.20003C1 6.53729 1.53726 6.00003 2.2 6.00003H20.5M20.5 6.00003V1M1 20V24.8C1 24.9105 1.08954 25 1.2 25H5M5 25V22M5 25H9M9 25V20M9 25H13M13 25V22M13 25H17M17 25V20M17 25H21M21 25V22M21 25H24.8C24.9105 25 25 24.9105 25 24.8V20M17 12.4994C17 13.8801 15.8807 15 14.5 15C13.1193 15 12 13.8801 12 12.4994M17 12.4994C17 11.1187 15.8807 9.99883 14.5 9.99883C13.1193 9.99883 12 11.1187 12 12.4994" />
    </svg>
  )
}

export function TrailerLengthBottom({
  color = '#222222',
  size = 24,
  strokeWidth = '2',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M20.5 12.4994H13.5M20.5 12.4994V6.00003M20.5 12.4994H24.8C24.9105 12.4994 25 12.4099 25 12.2994V9.1653M13.5 12.4994V12.4994C13.5 11.1187 12.3807 9.99883 11 9.99883V9.99883C9.61929 9.99883 8.5 11.1187 8.5 12.4994V12.4994M13.5 12.4994V12.4994C13.5 13.8801 12.3807 15 11 15V15C9.61929 15 8.5 13.8801 8.5 12.4994V12.4994M8.5 12.4994H3.5C2.11929 12.4994 1 11.3801 1 9.99941V7.20003C1 6.53729 1.53726 6.00003 2.2 6.00003H20.5M20.5 6.00003V1M1 20V24.8C1 24.9105 1.08954 25 1.2 25H5M5 25V22M5 25H9M9 25V20M9 25H13M13 25V22M13 25H17M17 25V20M17 25H21M21 25V22M21 25H24.8C24.9105 25 25 24.9105 25 24.8V20" />
    </svg>
  )
}

export function TrailerHeight({
  color = '#222222',
  size = 24,
  strokeWidth = '2',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M21 22.5041H14.5M21 22.5041H24.8C24.9105 22.5041 25 22.4146 25 22.3041V19.17M21 22.5041V17.01M14.5 22.5041V22.5041C14.5 21.1234 13.3807 20.0035 12 20.0035V20.0035C10.6193 20.0035 9.5 21.1234 9.5 22.5041V22.5041M14.5 22.5041V22.5041C14.5 23.8848 13.3807 25.0047 12 25.0047V25.0047C10.6193 25.0047 9.5 23.8848 9.5 22.5041V22.5041M9.5 22.5041H3.5C2.11929 22.5041 1 21.3848 1 20.0041V17.01M6 1H1.1C1.04477 1 1 1.04477 1 1.1V5.01M1 5.01H4M1 5.01V9.01M1 9.01H6M1 9.01V13.01M1 13.01H4M1 13.01V17.01M1 17.01H21M21 17.01V10.66" />
    </svg>
  )
}

export function TrailerHeightDouble({
  color = '#222222',
  size = 24,
  strokeWidth = '2',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M21 22.5041H24.8C24.9105 22.5041 25 22.4146 25 22.3041V19.17M21 22.5041V17.01M21 22.5041H17.5M9.5 22.5041V22.5041C9.5 21.1234 8.38071 20.0035 7 20.0035V20.0035C5.61929 20.0035 4.5 21.1234 4.5 22.5041V22.5041M9.5 22.5041V22.5041C9.5 23.8848 8.38071 25.0047 7 25.0047V25.0047C5.61929 25.0047 4.5 23.8848 4.5 22.5041V22.5041M9.5 22.5041H12.5M4.5 22.5041H3.5C2.11929 22.5041 1 21.3848 1 20.0041V17.01M6 1H1.1C1.04477 1 1 1.04477 1 1.1V5.01M1 5.01H4M1 5.01V9.01M1 9.01H6M1 9.01V13.01M1 13.01H4M1 13.01V17.01M1 17.01H21M21 17.01V10.66M17.5 22.5041C17.5 23.8848 16.3807 25.0047 15 25.0047C13.6193 25.0047 12.5 23.8848 12.5 22.5041M17.5 22.5041C17.5 21.1234 16.3807 20.0035 15 20.0035C13.6193 20.0035 12.5 21.1234 12.5 22.5041" />
    </svg>
  )
}

export function TrailerCapacity({
  color = '#222222',
  size = 24,
  strokeWidth = '2',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M20.5 22.4994H13.5M20.5 22.4994V16M20.5 22.4994H24.8C24.9105 22.4994 25 22.4098 25 22.2994V19.1653M13.5 22.4994V22.4994C13.5 21.1187 12.3807 19.9988 11 19.9988V19.9988C9.61929 19.9988 8.5 21.1187 8.5 22.4994V22.4994M13.5 22.4994V22.4994C13.5 23.8801 12.3807 25 11 25V25C9.61929 25 8.5 23.8801 8.5 22.4994V22.4994M8.5 22.4994H3.5C2.11929 22.4994 1 21.3801 1 19.9994V17.2C1 16.5373 1.53726 16 2.2 16H20.5M20.5 16V11M7.5 7C8.29368 6.71139 9.12644 6.12644 10.5 7.5C12 9 13.2461 7.66344 14 7.5M7 2.99V3M5.6 13.75H16.15C16.7575 13.75 17.25 13.2575 17.25 12.65V2.1C17.25 1.49249 16.7575 1 16.15 1L5.6 1C4.99249 1 4.5 1.49249 4.5 2.1V12.65C4.5 13.2575 4.99249 13.75 5.6 13.75ZM10.875 4V4C9.01104 4 7.5 5.51104 7.5 7.375V7.375C7.5 9.23896 9.01104 10.75 10.875 10.75V10.75C12.739 10.75 14.25 9.23896 14.25 7.375V7.375C14.25 5.51104 12.739 4 10.875 4Z" />
    </svg>
  )
}

export function TrailerCapacityDouble({
  color = '#222222',
  size = 24,
  strokeWidth = '2',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M20.5 22.4994V16M20.5 22.4994H24.8C24.9105 22.4994 25 22.4098 25 22.2994V19.1653M20.5 22.4994H17M9.5 22.4994V22.4994C9.5 21.1187 8.38071 19.9988 7 19.9988V19.9988C5.61929 19.9988 4.5 21.1187 4.5 22.4994V22.4994M9.5 22.4994V22.4994C9.5 23.8801 8.38071 25 7 25V25C5.61929 25 4.5 23.8801 4.5 22.4994V22.4994M9.5 22.4994H12M4.5 22.4994H3.5C2.11929 22.4994 1 21.3801 1 19.9994V17.2C1 16.5373 1.53726 16 2.2 16H20.5M20.5 16V11M7.5 7C8.29368 6.71139 9.12644 6.12644 10.5 7.5C12 9 13.2461 7.66344 14 7.5M7 2.99V3M17 22.4994C17 23.8801 15.8807 25 14.5 25C13.1193 25 12 23.8801 12 22.4994M17 22.4994C17 21.1187 15.8807 19.9988 14.5 19.9988C13.1193 19.9988 12 21.1187 12 22.4994M5.6 13.75H16.15C16.7575 13.75 17.25 13.2575 17.25 12.65V2.1C17.25 1.49249 16.7575 1 16.15 1L5.6 1C4.99249 1 4.5 1.49249 4.5 2.1V12.65C4.5 13.2575 4.99249 13.75 5.6 13.75ZM10.875 4V4C9.01104 4 7.5 5.51104 7.5 7.375V7.375C7.5 9.23896 9.01104 10.75 10.875 10.75V10.75C12.739 10.75 14.25 9.23896 14.25 7.375V7.375C14.25 5.51104 12.739 4 10.875 4Z" />
    </svg>
  )
}

export function TipperTrailer({
  color = '#222222',
  size = 24,
  strokeWidth = '2',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 27 22.75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M1 19.2641H8.30123M1 19.2641L1 16.0388C1 15.971 1.0344 15.9077 1.09136 15.8709L6.75 12.21L9.625 10.35L12.5 8.49L23.6908 1.20135C23.8239 1.1147 24 1.21017 24 1.36894V4.22163C24 4.28923 23.9658 4.35226 23.9092 4.38918L13 11.5M1 19.2641L3.25 17.8083L5.5 16.3526L9.25 13.9263L13 11.5M13.3012 19.2641V19.2641C13.3012 17.8834 12.1819 16.7635 10.8012 16.7635V16.7635C9.42052 16.7635 8.30123 17.8834 8.30123 19.2641V19.2641M13.3012 19.2641V19.2641C13.3012 20.6448 12.1819 21.7647 10.8012 21.7647V21.7647C9.42052 21.7647 8.30123 20.6448 8.30123 19.2641V19.2641M13.3012 19.2641H16M26 15.93V19.0641C26 19.1746 25.9105 19.2641 25.8 19.2641H16M16 19.2641L13 11.5" />
    </svg>
  )
}

export function TrailerLengthTop({
  color = '#222222',
  size = 24,
  strokeWidth = '2',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    ></svg>
  )
}

export function SkiRight({ color = '#222222', size = 24, strokeWidth = '2' }) {
  return (
    <svg
      width={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M22.003 13V3.63837C22.003 3.54783 22.0276 3.45898 22.0742 3.38132L23.2871 1.35775C23.3842 1.19578 23.6189 1.19578 23.716 1.35775L24.9289 3.38132C24.9754 3.45898 25 3.54783 25 3.63837V13M22.003 13H25M22.003 13V17.5M25 13V17.5M25 17.5V24.5C25 24.7761 24.7761 25 24.5 25H22.503C22.2269 25 22.003 24.7761 22.003 24.5V17.5M25 17.5H22.003M15.5096 13V3.63837C15.5096 3.54783 15.5342 3.45898 15.5808 3.38132L16.7937 1.35775C16.8908 1.19578 17.1255 1.19578 17.2225 1.35775L18.4354 3.38132C18.482 3.45898 18.5066 3.54783 18.5066 3.63837V13M15.5096 13H18.5066M15.5096 13V17.5M18.5066 13V17.5M18.5066 17.5V24.5C18.5066 24.7761 18.2827 25 18.0066 25H16.0096C15.7335 25 15.5096 24.7761 15.5096 24.5V17.5M18.5066 17.5H15.5096M3.02228 6.20611L5.93954 14.2293M5.93954 14.2293L9.00157 22.6507M5.93954 14.2293L8.85677 6.20615M5.93954 14.2293L2.87748 22.6508M9.00157 22.6507L9.85576 25M9.00157 22.6507L7.12409 23.3348M9.00157 22.6507L10.8791 21.9667M2.87748 22.6508L2.0233 25M2.87748 22.6508L1 21.9667M2.87748 22.6508L4.75496 23.3348" />
    </svg>
  )
}

export function SkiLeft({ color = '#222222', size = 24, strokeWidth = '2' }) {
  return (
    <svg
      width={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M3.99696 13V3.63837C3.99696 3.54783 3.97237 3.45898 3.92582 3.38132L2.71291 1.35775C2.61583 1.19578 2.38113 1.19578 2.28405 1.35775L1.07114 3.38132C1.02459 3.45898 1 3.54783 1 3.63837V13M3.99696 13H1M3.99696 13V17.5M1 13V17.5M1 17.5V24.5C1 24.7761 1.22386 25 1.5 25H3.49696C3.7731 25 3.99696 24.7761 3.99696 24.5V17.5M1 17.5H3.99696M10.4904 13V3.63837C10.4904 3.54783 10.4658 3.45898 10.4192 3.38132L9.20633 1.35775C9.10925 1.19578 8.87454 1.19578 8.77747 1.35775L7.56455 3.38132C7.518 3.45898 7.49342 3.54783 7.49342 3.63837V13M10.4904 13H7.49342M10.4904 13V17.5M7.49342 13V17.5M7.49342 17.5V24.5C7.49342 24.7761 7.71727 25 7.99342 25H9.99038C10.2665 25 10.4904 24.7761 10.4904 24.5V17.5M7.49342 17.5H10.4904M22.9777 6.20611L20.0605 14.2293M20.0605 14.2293L16.9984 22.6507M20.0605 14.2293L17.1432 6.20615M20.0605 14.2293L23.1225 22.6508M16.9984 22.6507L16.1442 25M16.9984 22.6507L18.8759 23.3348M16.9984 22.6507L15.1209 21.9667M23.1225 22.6508L23.9767 25M23.1225 22.6508L25 21.9667M23.1225 22.6508L21.245 23.3348" />
    </svg>
  )
}

export function Sand({ color = '#222222', size = 24, strokeWidth = '2' }) {
  return (
    <svg
      width={size}
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M16.2567 7.83896L14.7985 6.99702C14.6537 6.91344 14.4686 6.96304 14.385 7.10781L13.4933 8.65232C12.941 9.60891 13.2687 10.8321 14.2253 11.3844C15.1819 11.9367 16.4051 11.6089 16.9574 10.6523L17.8491 9.10781C17.9327 8.96304 17.8831 8.77793 17.7383 8.69435L16.2567 7.83896ZM16.2567 7.83896L19.6317 1.99329M19.6317 1.99329L17.9113 1.00002M19.6317 1.99329L21.3754 3.00002M8.98236 12.4932V12.4932C9.96169 12.4932 10.9087 12.8435 11.6523 13.4809L13.2836 14.8792C13.7459 15.2754 14.3347 15.4932 14.9435 15.4932V15.4932C15.62 15.4932 16.2687 15.2245 16.747 14.7462L17.0372 14.456C17.3335 14.1597 17.7354 13.9932 18.1544 13.9932V13.9932C18.6827 13.9932 19.176 14.2572 19.469 14.6968L23.4453 20.6611C24.1099 21.658 23.3953 22.9932 22.1972 22.9932H3.47904C2.35157 22.9932 1.62714 21.7959 2.15029 20.7972L5.34817 14.6921C6.05629 13.3403 7.45626 12.4932 8.98236 12.4932Z" />
    </svg>
  )
}

export function Ramps({ color = '#222222', size = 24, strokeWidth = '2' }) {
  return (
    <svg
      width={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M4 4.25H8.25M4 7.75H8.25M4 11.25H8.25M4 14.75H8.25M4 18.25H8.25M4 21.75H8.25M17.75 4.25H22M17.75 7.75H22M17.75 11.25H22M17.75 14.75H22M17.75 18.25H22M17.75 21.75H22M15.75 1H24C24.5523 1 25 1.44772 25 2V24C25 24.5523 24.5523 25 24 25H15.75C15.1977 25 14.75 24.5523 14.75 24V2C14.75 1.44772 15.1977 1 15.75 1ZM2 25H10.25C10.8023 25 11.25 24.5523 11.25 24V2C11.25 1.44772 10.8023 1 10.25 1H2C1.44772 1 1 1.44772 1 2V24C1 24.5523 1.44772 25 2 25Z" />
    </svg>
  )
}

export function Ramps2({ color = '#222222', size = 24, strokeWidth = '2' }) {
  return (
    <svg
      width={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M4 4.25H4.01M8.24 4.25H8.25M4 7.75H4.01M8.24 7.75H8.25M4 11.25H4.01M8.24 11.25H8.25M4 14.75H4.01M8.24 14.75H8.25M4 18.25H4.01M8.24 18.25H8.25M4 21.75H4.01M8.24 21.75H8.25M17.75 4.25H17.76M21.99 4.25H22M17.75 7.75H17.76M21.99 7.75H22M17.76 11.25H17.77M22 11.25H22.01M17.75 14.75H17.76M21.99 14.75H22M17.74 18.25H17.75M21.98 18.25H21.99M17.76 21.75H17.77M22 21.75H22.01M15.75 1H24C24.5523 1 25 1.44772 25 2V24C25 24.5523 24.5523 25 24 25H15.75C15.1977 25 14.75 24.5523 14.75 24V2C14.75 1.44772 15.1977 1 15.75 1ZM2 25H10.25C10.8023 25 11.25 24.5523 11.25 24V2C11.25 1.44772 10.8023 1 10.25 1H2C1.44772 1 1 1.44772 1 2V24C1 24.5523 1.44772 25 2 25Z" />
    </svg>
  )
}

export function Ramps3({ color = '#222222', size = 24, strokeWidth = '2' }) {
  return (
    <svg
      width={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M18.2596 4.25H18.2696M21.5 4.25H21.51M18.2596 7.75H18.2696M21.5 7.75H21.51M18.2596 11.25H18.2696M21.5 11.25H21.51M18.2596 14.75H18.2696M21.5 14.75H21.51M18.2596 18.25H18.2696M21.5 18.25H21.51M18.2596 21.75H18.2696M21.5 21.75H21.51M4.5 4.25H4.51M7.7404 4.25H7.7504M4.5 7.75H4.51M7.7404 7.75H7.7504M4.5 11.25H4.51M7.7404 11.25H7.7504M4.5 14.75H4.51M7.7404 14.75H7.7504M4.5 18.25H4.51M7.7404 18.25H7.7504M4.5 21.75H4.51M7.7404 21.75H7.7504M15.75 1H24C24.5523 1 25 1.44772 25 2V24C25 24.5523 24.5523 25 24 25H15.75C15.1977 25 14.75 24.5523 14.75 24V2C14.75 1.44772 15.1977 1 15.75 1ZM2 25H10.25C10.8023 25 11.25 24.5523 11.25 24V2C11.25 1.44772 10.8023 1 10.25 1H2C1.44772 1 1 1.44772 1 2V24C1 24.5523 1.44772 25 2 25Z" />
    </svg>
  )
}

export function PlugRight({ color = '#222222', size = 24, strokeWidth = '2' }) {
  return (
    <svg
      width={size}
      viewBox="0 0 20 27.44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M19.1544 1.82789L16.5401 4.44314M16.5401 4.44314V4.44314C18.1026 6.00485 18.1032 8.53751 16.5415 10.1L15.8345 10.8073C15.6393 11.0026 15.3228 11.0027 15.1274 10.8074L14.0665 9.74705M16.5401 4.44314V4.44314C14.9776 2.88142 12.4449 2.88205 10.8832 4.44453L10.1763 5.15181C9.98109 5.34712 9.98116 5.6637 10.1765 5.85892L11.2374 6.91931M14.0665 9.74705L12.2992 11.5152M14.0665 9.74705L11.2374 6.91931M11.2374 6.91931L9.47006 8.68752M3.8153 17.1742V17.1742C5.37778 18.7359 7.91044 18.7353 9.47215 17.1728L10.1791 16.4655C10.3743 16.2702 10.3742 15.9536 10.1789 15.7584L5.22794 10.8099C5.03263 10.6147 4.71605 10.6147 4.52084 10.8101L3.8139 11.5173C2.25219 13.0798 2.25282 15.6125 3.8153 17.1742V17.1742ZM3.8153 17.1742L1.17073 19.8198" />
    </svg>
  )
}

export function PlugLeft({ color = '#222222', size = 24, strokeWidth = '2' }) {
  return (
    <svg
      width={size}
      viewBox="0 0 20 27.44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M1.82845 19.817L4.44321 17.2022M4.44321 17.2022V17.2022C2.88101 15.6402 2.88086 13.1075 4.44286 11.5453L5.14992 10.8382C5.34517 10.6429 5.66175 10.6429 5.85703 10.8381L6.91775 11.8987M4.44321 17.2022V17.2022C6.0054 18.7642 8.53806 18.764 10.1001 17.2018L10.8071 16.4947C11.0024 16.2994 11.0024 15.9828 10.8071 15.7876L9.74635 14.727M6.91775 11.8987L8.68541 10.1309M6.91775 11.8987L9.74635 14.727M9.74635 14.727L11.514 12.9591M17.1703 4.47349V4.47349C15.6081 2.91149 13.0755 2.91164 11.5135 4.47384L10.8064 5.18099C10.6112 5.37626 10.6112 5.69284 10.8065 5.88809L15.7565 10.8375C15.9518 11.0328 16.2684 11.0328 16.4636 10.8375L17.1707 10.1303C18.7327 8.56815 18.7325 6.03549 17.1703 4.47349V4.47349ZM17.1703 4.47349L19.8154 1.8284" />
    </svg>
  )
}

export function PlateauTrailer({
  color = '#222222',
  size = 24,
  strokeWidth = '2',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 27 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M21 6.49941H13.5M21 6.49941V2.2C21 1.53726 20.4627 1 19.8 1H2.2C1.53726 1 1 1.53726 1 2.2V3.99941C1 5.38012 2.11929 6.49941 3.5 6.49941H8.5M21 6.49941H25.8C25.9105 6.49941 26 6.40987 26 6.29941V3.16529M13.5 6.49941V6.49941C13.5 5.1187 12.3807 3.99882 11 3.99882V3.99882C9.61929 3.99882 8.5 5.1187 8.5 6.49941V6.49941M13.5 6.49941V6.49941C13.5 7.88012 12.3807 9 11 9V9C9.61929 9 8.5 7.88012 8.5 6.49941V6.49941" />
    </svg>
  )
}

export function PlateauTrailerDouble({
  color = '#222222',
  size = 24,
  strokeWidth = '2',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 34 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M27.7713 6.49735V2.2C27.7713 1.53726 27.234 1 26.5713 1H2.2C1.53726 1 1 1.53726 1 2.2V3.99736C1 5.37807 2.11929 6.49735 3.5 6.49735H7.86M27.7713 6.49735H32.8C32.9105 6.49735 33 6.40781 33 6.29735V3.16001M27.7713 6.49735H20.91M20.91 6.49735V6.49735C20.91 5.1181 19.7893 4 18.41 4V4C17.0307 4 15.91 5.1181 15.91 6.49735V6.49735M20.91 6.49735V6.49735C20.91 7.87807 19.7907 9 18.41 9V9C17.0293 9 15.91 7.87807 15.91 6.49735V6.49735M15.91 6.49735H12.86M12.86 6.49735V6.49735C12.86 5.1181 11.7393 4 10.36 4V4C8.98075 4 7.86 5.1181 7.86 6.49735V6.49735M12.86 6.49735V6.49735C12.86 7.87807 11.7407 9 10.36 9V9C8.97929 9 7.86 7.87807 7.86 6.49735V6.49735" />
    </svg>
  )
}

export function Pilon({ color = '#222222', size = 24, strokeWidth = '2' }) {
  return (
    <svg
      width={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M4 21H2C1.44772 21 1 21.4477 1 22V24C1 24.5523 1.44772 25 2 25H24C24.5523 25 25 24.5523 25 24V22C25 21.4477 24.5523 21 24 21H22M4 21H22M4 21L8.14878 2.56098C8.35412 1.64837 9.16458 1 10.1 1H14.9758C15.8761 1 16.6654 1.60158 16.9042 2.46969L22 21" />
    </svg>
  )
}

export function Pilon2({ color = '#222222', size = 24, strokeWidth = '2' }) {
  return (
    <svg
      width={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M4 21H2C1.44772 21 1 21.4477 1 22V24C1 24.5523 1.44772 25 2 25H24C24.5523 25 25 24.5523 25 24V22C25 21.4477 24.5523 21 24 21H22M4 21H22M4 21L8.14878 2.56098C8.35412 1.64837 9.16458 1 10.1 1H14.9758C15.8761 1 16.6654 1.60158 16.9042 2.46969L22 21M7.375 6H17.875M6.25 11H19.25M5.125 16H20.625" />
    </svg>
  )
}

export function Net({ color = '#222222', size = 24, strokeWidth = '2' }) {
  return (
    <svg
      width={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M1 3L25 3M1 8L25 8M1 13L25 13M1 18L25 18M1 23L25 23M3 25L3 1M8 25L8 1M13 25L13 1M18 25L18 1M23 25L23 1" />
    </svg>
  )
}

export function LongLoad({ color = '#222222', size = 24, strokeWidth = '2' }) {
  return (
    <svg
      width={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M3 3L23 23M9 1L25 17M9 1H15M9 1H6C3.23858 1 1 3.23858 1 6V8M15 1H20C22.7614 1 25 3.23858 25 6V20C25 22.7614 22.7614 25 20 25H6C3.23858 25 1 22.7614 1 20V15M15 1L25 11M1 8L18 25M1 8V15M1 15L11 25" />
    </svg>
  )
}

export function Graph({ color = '#222222', size = 24, strokeWidth = '2' }) {
  return (
    <svg
      width={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M4.5 22H2C1.44772 22 1 22.4477 1 23V24C1 24.5523 1.44772 25 2 25H24C24.5523 25 25 24.5523 25 24V23C25 22.4477 24.5523 22 24 22H21.5M4.5 22V11C4.5 10.4477 4.94772 10 5.5 10H10.25M4.5 22H10.25M10.25 22V2C10.25 1.44772 10.6977 1 11.25 1H15C15.5523 1 16 1.44772 16 2V5M10.25 22H16M16 22V5M16 22H21.5M16 5H20.5C21.0523 5 21.5 5.44772 21.5 6V22" />
    </svg>
  )
}

export function FlatTrailer({
  color = '#222222',
  size = 24,
  strokeWidth = '2',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 27 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M1 4.33412H8.5M13.5 4.33412H21H25.8C25.9105 4.33412 26 4.24457 26 4.13412V1M13.5 4.33412V4.33412C13.5 2.95341 12.3807 1.83353 11 1.83353V1.83353C9.61929 1.83353 8.5 2.95341 8.5 4.33412V4.33412M13.5 4.33412V4.33412C13.5 5.71483 12.3807 6.83471 11 6.83471V6.83471C9.61929 6.83471 8.5 5.71483 8.5 4.33412V4.33412" />
    </svg>
  )
}

export function FlatTrailerDouble({
  color = '#222222',
  size = 24,
  strokeWidth = '2',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 34 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M33 1V4.22879C33 4.33924 32.9105 4.42879 32.8 4.42879H27.7713H20.91M20.91 4.42879V4.36301C20.91 2.98229 19.7907 1.86301 18.41 1.86301V1.86301C17.0293 1.86301 15.91 2.98229 15.91 4.36301V4.42879M20.91 4.42879V4.5C20.91 5.88071 19.7907 7 18.41 7V7C17.0293 7 15.91 5.88071 15.91 4.5V4.42879M15.91 4.42879H12.86M12.86 4.42879V4.36301C12.86 2.98229 11.7407 1.86301 10.36 1.86301V1.86301C8.9793 1.86301 7.86001 2.98229 7.86001 4.36301V4.42879M12.86 4.42879V4.5C12.86 5.88071 11.7407 7 10.36 7V7C8.9793 7 7.86001 5.88071 7.86001 4.5V4.42879M7.86001 4.42879H1.00001" />
    </svg>
  )
}

export function FlatTrailerClosed({
  color = '#222222',
  size = 24,
  strokeWidth = '2',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 27 17.5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M14.5 13.8441H21H25.8C25.9105 13.8441 26 13.7546 26 13.6441V10.51M14.5 13.8441V13.8441C14.5 12.4634 13.3807 11.3435 12 11.3435V11.3435C10.6193 11.3435 9.5 12.4634 9.5 13.8441V13.8441M14.5 13.8441V13.8441C14.5 15.2248 13.3807 16.3447 12 16.3447V16.3447C10.6193 16.3447 9.5 15.2248 9.5 13.8441V13.8441M9.5 13.8441H2C1.44772 13.8441 1 13.3964 1 12.8441V1" />
    </svg>
  )
}

export function FlatTrailerClosedDouble({
  color = '#222222',
  size = 24,
  strokeWidth = '2',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 34 17.5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M33 10.5V13.6373C33 13.7478 32.9104 13.8373 32.8 13.8373H27.7713H20.91M20.91 13.8373V13.8373C20.91 12.4581 19.7892 11.34 18.41 11.34V11.34C17.0307 11.34 15.91 12.4581 15.91 13.8373V13.8373M20.91 13.8373V13.8373C20.91 15.2181 19.7907 16.34 18.41 16.34V16.34C17.0293 16.34 15.91 15.2181 15.91 13.8373V13.8373M15.91 13.8373H12.86M12.86 13.8373V13.8373C12.86 12.4581 11.7393 11.34 10.36 11.34V11.34C8.98075 11.34 7.86 12.4581 7.86 13.8373V13.8373M12.86 13.8373V13.8373C12.86 15.2181 11.7407 16.34 10.36 16.34V16.34C8.97929 16.34 7.86 15.2181 7.86 13.8373V13.8373M7.86 13.8373H2C1.44772 13.8373 1 13.3896 1 12.8373V1" />
    </svg>
  )
}

export function Closet({ color = '#222222', size = 24, strokeWidth = '2' }) {
  return (
    <svg
      width={size}
      viewBox="0 0 20.32 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M3.14263 22.8514H2C1.44771 22.8514 1 22.4036 1 21.8514V16.4054M3.14263 22.8514V25M3.14263 22.8514H17.1733M17.1733 22.8514H18.3219C18.8742 22.8514 19.3219 22.4036 19.3219 21.8514V16.4054M17.1733 22.8514V25M9.99721 1H2C1.44772 1 1 1.44772 1 2V16.4054M9.99721 1H18.3219C18.8742 1 19.3219 1.44772 19.3219 2V16.4054M9.99721 1L9.95382 16.4054M9.95382 16.4054H1M9.95382 16.4054H19.3219M9.58856 19.6284H10.6629M6.53748 10.5182V10.4967M13.4131 10.4988V10.5182" />
    </svg>
  )
}

export function ClosedTrailer({
  color = '#222222',
  size = 24,
  strokeWidth = '2',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 27 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M21 18.4994H13.5M21 18.4994V2.2C21 1.53726 20.4627 1 19.8 1H2.2C1.53726 1 1 1.53726 1 2.2V15.9994C1 17.3801 2.11929 18.4994 3.5 18.4994H8.5M21 18.4994H25.8C25.9105 18.4994 26 18.4099 26 18.2994V15.1653M13.5 18.4994V18.4994C13.5 17.1187 12.3807 15.9988 11 15.9988V15.9988C9.61929 15.9988 8.5 17.1187 8.5 18.4994V18.4994M13.5 18.4994V18.4994C13.5 19.8801 12.3807 21 11 21V21C9.61929 21 8.5 19.8801 8.5 18.4994V18.4994M5 6H11.5H17" />
    </svg>
  )
}

export function TransportTrailer({
  color = '#222222',
  size = 24,
  strokeWidth = '2',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 27 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M21 12.4994H13.5M21 12.4994V7M21 12.4994H25.8C25.9105 12.4994 26 12.4099 26 12.2994V9.16529M13.5 12.4994V12.4994C13.5 11.1187 12.3807 9.99882 11 9.99882V9.99882C9.61929 9.99882 8.5 11.1187 8.5 12.4994V12.4994M13.5 12.4994V12.4994C13.5 13.8801 12.3807 15 11 15V15C9.61929 15 8.5 13.8801 8.5 12.4994V12.4994M8.5 12.4994H3.5C2.11929 12.4994 1 11.3801 1 9.99941V8.2C1 7.53726 1.53726 7 2.2 7H21M21 7V1" />
    </svg>
  )
}

export function UnbrakedTrailerDouble({
  color = '#222222',
  size = 24,
  strokeWidth = '2',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 34 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M1 5V7.99735C1 9.37807 2.11929 10.4974 3.5 10.4974H7.86M1 5V2.2C1 1.53726 1.53726 1 2.2 1H7.5M1 5H7.5M28 5.00265V10.5M28 5.00265V2.20216C28 1.53961 27.463 1.00243 26.8005 1.00216L21.5 1M28 5.00265L21.5 5M28 10.5L32.8001 10.4975C32.9105 10.4974 33 10.4079 33 10.2975V7.16001M28 10.5L20.91 10.4974M20.91 10.4974V10.4974C20.91 9.1181 19.7892 8 18.41 8V8C17.0307 8 15.91 9.1181 15.91 10.4974V10.4974M20.91 10.4974V10.4974C20.91 11.8781 19.7907 13 18.41 13V13C17.0293 13 15.91 11.8781 15.91 10.4974V10.4974M15.91 10.4974H12.86M12.86 10.4974V10.4974C12.86 9.1181 11.7393 8 10.36 8V8C8.98075 8 7.86 9.1181 7.86 10.4974V10.4974M12.86 10.4974V10.4974C12.86 11.8781 11.7407 13 10.36 13V13C8.97929 13 7.86 11.8781 7.86 10.4974V10.4974M7.5 5V1M7.5 5H14.5M7.5 1H14.5M14.5 5V1M14.5 5H21.5M14.5 1H21.5M21.5 5V1" />
    </svg>
  )
}

export function NoResultsIcon({
  color = '#222222',
  size = 24,
  strokeWidth = '1',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 26 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M14.6 15H11M14.6 15V12.3603M14.6 15H16.8C16.9105 15 17 14.9105 17 14.8V13.3996M11 15C11 15.6627 10.4627 16.2003 9.8 16.2003C9.13726 16.2003 8.6 15.6627 8.6 15M11 15C11 14.3373 10.4627 13.7997 9.8 13.7997C9.13726 13.7997 8.6 14.3373 8.6 15M8.6 15H6.78359C5.79854 15 5 14.2015 5 13.2164C5 12.7436 5.3833 12.3603 5.85612 12.3603H14.6M14.6 12.3603V9.48028M12 27H3.5C2.11929 27 1 25.8807 1 24.5L1 6.5C1 5.11929 2.11929 4 3.5 4H7M7 4V3.5C7 2.11929 8.11929 1 9.5 1H12.5C13.8807 1 15 2.11929 15 3.5V4M7 4C7 5.38071 8.11929 6.5 9.5 6.5H12.5C13.8807 6.5 15 5.38071 15 4M15 4H18.5C19.8807 4 21 5.11929 21 6.5V13.8194M25.5 28.5459L21.25 24.3033M21.25 24.3033C22.7145 22.8388 22.7145 20.4645 21.25 19C19.7855 17.5355 17.4145 17.5355 15.95 19C14.4855 20.4645 14.4855 22.8388 15.95 24.3033C17.4145 25.7678 19.7855 25.7678 21.25 24.3033Z" />
    </svg>
  )
}

export function MessagesIcon({
  color = '#222222',
  size = 24,
  strokeWidth = '1',
}) {
  return (
    <svg
      width={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      viewBox="0 0 26 25"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M6 18H8.26393C9.02148 18 9.714 18.428 10.0528 19.1056L12.1056 23.2111C12.4741 23.9482 13.5259 23.9482 13.8944 23.2111L15.9472 19.1056C16.286 18.428 16.9785 18 17.7361 18H20C22.7614 18 25 15.7614 25 13V6C25 3.23858 22.7614 1 20 1H6C3.23858 1 1 3.23858 1 6V13C1 15.7614 3.23858 18 6 18Z"
      />
    </svg>
  )
}

export function TrailerOwner({
  color = '#222222',
  size = 24,
  strokeWidth = '1',
}) {
  return (
    <svg
      width={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
      viewBox="0 0 26 19"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M2.25 9.74999V10C2.25 11.1046 3.14543 12 4.25 12H13H21.75C22.8546 12 23.75 11.1046 23.75 10V9.74999M2.25 9.74999V7.2C2.25 6.8134 2.5634 6.5 2.95 6.5H4.25M2.25 9.74999V9.74999C1.55964 9.74999 1 10.3096 1 11V14.5C1 14.7761 1.22386 15 1.5 15H1.75M23.75 9.74999V9.74999C24.4404 9.74999 25 10.3096 25 11V14.5C25 14.7761 24.7761 15 24.5 15H24.25M23.75 9.74999V7.2C23.75 6.8134 23.4366 6.5 23.05 6.5H21.75M24.25 15V15.45C24.25 16.4441 23.4441 17.25 22.45 17.25H20.55C19.5559 17.25 18.75 16.4441 18.75 15.45V14M24.25 15H21.25C20.9739 15 20.75 14.7761 20.75 14.5V14.5C20.75 14.2238 20.5261 14 20.25 14H18.75M1.75 15V15.7C1.75 16.6941 2.55589 17.5 3.55 17.5H5.45C6.44411 17.5 7.25 16.6941 7.25 15.7V14M1.75 15H4.75C5.02614 15 5.25 14.7761 5.25 14.5V14.5C5.25 14.2238 5.47386 14 5.75 14H7.25M18.75 14H7.25M4.25 6.5H21.75M4.25 6.5V3.5M4.25 1V3.5M21.75 6.5V3.5M21.75 1V3.5M21.75 3.5H4.25"
      />
    </svg>
  )
}

export function Search({ color = '#222222', size = 24, strokeWidth = '2' }) {
  return (
    <svg
      width={size}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M16.5 16.225L22 21.5M1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10Z"
      />{' '}
    </svg>
  )
}

// ---------------------------------------------------------------- NEW CATEGORIES ----------------------------------------------------------------

export function OpenTrailer({
  color = '#222222',
  size = 24,
  strokeWidth = '2',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 34 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M27.7713 6.49735V2.2C27.7713 1.53726 27.234 1 26.5713 1H2.2C1.53726 1 1 1.53726 1 2.2V3.99736C1 5.37807 2.11929 6.49735 3.5 6.49735H7.86M27.7713 6.49735H32.8C32.9105 6.49735 33 6.40781 33 6.29735V3.16001M27.7713 6.49735H20.91M20.91 6.49735V6.49735C20.91 5.1181 19.7893 4 18.41 4V4C17.0307 4 15.91 5.1181 15.91 6.49735V6.49735M20.91 6.49735V6.49735C20.91 7.87807 19.7907 9 18.41 9V9C17.0293 9 15.91 7.87807 15.91 6.49735V6.49735M15.91 6.49735H12.86M12.86 6.49735V6.49735C12.86 5.1181 11.7393 4 10.36 4V4C8.98075 4 7.86 5.1181 7.86 6.49735V6.49735M12.86 6.49735V6.49735C12.86 7.87807 11.7407 9 10.36 9V9C8.97929 9 7.86 7.87807 7.86 6.49735V6.49735" />
    </svg>
  )
}

export function ClosedTrailerDouble({
  color = '#222222',
  size = 24,
  strokeWidth = '2',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 34 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M27.7713 20.4974V2.2C27.7713 1.53726 27.234 1 26.5713 1H2.2C1.53726 1 1 1.53726 1 2.2V17.9974C1 19.3781 2.11929 20.4974 3.5 20.4974H7.86M27.7713 20.4974H32.8C32.9105 20.4974 33 20.4078 33 20.2974V17.16M27.7713 20.4974H20.91M20.91 20.4974V20.4974C20.91 19.1181 19.7892 18 18.41 18V18C17.0307 18 15.91 19.1181 15.91 20.4974V20.4974M20.91 20.4974V20.4974C20.91 21.8781 19.7907 23 18.41 23V23C17.0293 23 15.91 21.8781 15.91 20.4974V20.4974M15.91 20.4974H12.86M12.86 20.4974V20.4974C12.86 19.1181 11.7393 18 10.36 18V18C8.98075 18 7.86 19.1181 7.86 20.4974V20.4974M12.86 20.4974V20.4974C12.86 21.8781 11.7407 23 10.36 23V23C8.97929 23 7.86 21.8781 7.86 20.4974V20.4974M5 6H14.5439H24.0877" />
    </svg>
  )
}

export function CarTrailer({
  color = '#222222',
  size = 24,
  strokeWidth = '2',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 34 22.5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M27.7713 18.9974V14.7C27.7713 14.0373 27.234 13.5 26.5713 13.5H2.2C1.53726 13.5 1 14.0373 1 14.7V16.4974C1 17.8781 2.11929 18.9974 3.5 18.9974H7.86M27.7713 18.9974H32.8C32.9105 18.9974 33 18.9078 33 18.7974V15.66M27.7713 18.9974H20.91M20.91 18.9974V18.9974C20.91 17.6181 19.7892 16.5 18.41 16.5V16.5C17.0307 16.5 15.91 17.6181 15.91 18.9974V18.9974M20.91 18.9974V18.9974C20.91 20.3781 19.7907 21.5 18.41 21.5V21.5C17.0293 21.5 15.91 20.3781 15.91 18.9974V18.9974M15.91 18.9974H12.86M12.86 18.9974V18.9974C12.86 17.6181 11.7393 16.5 10.36 16.5V16.5C8.98075 16.5 7.86 17.6181 7.86 18.9974V18.9974M12.86 18.9974V18.9974C12.86 20.3781 11.7407 21.5 10.36 21.5V21.5C8.97929 21.5 7.86 20.3781 7.86 18.9974V18.9974M8.5 9H20.5M8.5 9V9C8.5 7.89543 7.60457 7 6.5 7V7C5.39543 7 4.5 7.89543 4.5 9V9M8.5 9V9C8.5 10.1046 7.60457 11 6.5 11V11C5.39543 11 4.5 10.1046 4.5 9V9M20.5 9V9C20.5 7.89543 21.3954 7 22.5 7V7C23.6046 7 24.5 7.89543 24.5 9V9M20.5 9V9C20.5 10.1046 21.3954 11 22.5 11V11C23.6046 11 24.5 10.1046 24.5 9V9M24.5 9H26.7734C27.0185 9 27.2172 8.80131 27.2172 8.55621V8.55621C27.2172 6.47788 25.7748 4.67791 23.7464 4.22496L21.5134 3.72629C20.8396 3.57584 20.1832 3.35601 19.5547 3.07034L15.986 1.44817C15.3362 1.15281 14.6307 1 13.917 1H9.95256C8.69093 1 7.46922 1.44232 6.5 2.25V2.25C5.53078 3.05768 4.30409 3.5 3.04245 3.5V3.5C2.74363 3.5 2.5 3.74224 2.5 4.04107V4.88447C2.5 5.29328 2.44986 5.70055 2.35071 6.09715L2.14929 6.90285C2.05014 7.29945 2 7.70672 2 8.11553V8.5C2 8.77614 2.22386 9 2.5 9H4.5" />
    </svg>
  )
}

export function HorseTrailer({
  color = '#222222',
  size = 24,
  strokeWidth = '2',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 34 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M27.9474 20.5022H21M27.9474 20.5022V10.9911V7C27.9474 3.68629 25.2611 1 21.9474 1H2.5C1.67157 1 1 1.67157 1 2.5V9.88099V18.5022C1 19.6067 1.89543 20.5022 3 20.5022H7.95M27.9474 20.5022H32.8C32.9105 20.5022 33 20.4126 33 20.3022V17.1718M21 20.5022V20.5022C21 19.1227 19.8817 18.0044 18.5022 18.0044H18.47V18.0044C17.1059 18.0044 16 19.1102 16 20.4744V20.5022M21 20.5022V20.5022C21 21.8816 19.8817 22.9999 18.5022 22.9999H18.47V22.9999C17.1059 22.9999 16 21.8941 16 20.5299V20.5022M16 20.5022H12.95M12.95 20.5022V20.5022C12.95 21.8718 11.8397 22.9999 10.47 22.9999V22.9999V22.9999C9.09051 22.9999 7.95 21.8816 7.95 20.5022V20.5022M12.95 20.5022V20.5022C12.95 19.1325 11.8397 18.0044 10.47 18.0044V18.0044V18.0044C9.09052 18.0044 7.95 19.1227 7.95 20.5022V20.5022M4.92982 10.9911H14.4737H24.0175M20.6491 4.33037H20.2877C20.1773 4.33037 20.0877 4.41991 20.0877 4.53037V7.46074C20.0877 7.5712 20.1773 7.66074 20.2877 7.66074H23.8175C23.928 7.66074 24.0175 7.5712 24.0175 7.46074V7.10568V7.10568C24.0175 5.57292 22.775 4.33037 21.2422 4.33037H20.6491Z" />
    </svg>
  )
}

export function BoatTrailerDouble({
  color = '#222222',
  size = 24,
  strokeWidth = '2',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 34 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M27.7713 19.4974V15.2C27.7713 14.5373 27.234 14 26.5713 14H2.2C1.53726 14 1 14.5373 1 15.2V16.9974C1 18.3781 2.11929 19.4974 3.5 19.4974H7.86M27.7713 19.4974H32.8C32.9105 19.4974 33 19.4078 33 19.2974V16.16M27.7713 19.4974H20.91M20.91 19.4974V19.4974C20.91 18.1181 19.7892 17 18.41 17V17C17.0307 17 15.91 18.1181 15.91 19.4974V19.4974M20.91 19.4974V19.4974C20.91 20.8781 19.7907 22 18.41 22V22C17.0293 22 15.91 20.8781 15.91 19.4974V19.4974M15.91 19.4974H12.86M12.86 19.4974V19.4974C12.86 18.1181 11.7393 17 10.36 17V17C8.98075 17 7.86 18.1181 7.86 19.4974V19.4974M12.86 19.4974V19.4974C12.86 20.8781 11.7407 22 10.36 22V22C8.97929 22 7.86 20.8781 7.86 19.4974V19.4974M18.5 5.5H26.8178C26.8967 5.5 26.9446 5.58712 26.9022 5.65369L23.7947 10.5369C23.6112 10.8253 23.293 11 22.9511 11H2.62808C2.56302 11 2.51528 10.9389 2.53106 10.8757L3.31063 7.75746C3.42193 7.3123 3.82191 7 4.28078 7H5.5M18.5 5.5L17.2279 1.68377C17.0918 1.27543 16.7097 1 16.2792 1H7.5M18.5 5.5H15.9647C15.6556 5.5 15.3471 5.52867 15.0433 5.58564L7.95674 6.91436C7.6529 6.97133 7.34443 7 7.0353 7H5.5M7.5 1H5.5M7.5 1L5.5 7" />
    </svg>
  )
}

export function TipperTrailerDouble({
  color = '#222222',
  size = 24,
  strokeWidth = '2',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 34 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M1 20.2641H9.30123M1 20.2641L1 17.4786C1 17.1369 1.17446 16.8188 1.46262 16.6352L25.6925 1.19592C25.8257 1.11109 26 1.20672 26 1.36459V4.22075C26 4.28884 25.9654 4.35224 25.9081 4.38904L12.5 13M1 20.2641L12.5 13M14.3012 20.2641V20.2641C14.3012 18.8834 13.1819 17.7635 11.8012 17.7635V17.7635C10.4205 17.7635 9.30123 18.8834 9.30123 20.2641V20.2641M14.3012 20.2641V20.2641C14.3012 21.6448 13.1819 22.7647 11.8012 22.7647V22.7647C10.4205 22.7647 9.30123 21.6448 9.30123 20.2641V20.2641M14.3012 20.2641H17.35M33 16.93V20.0641C33 20.1746 32.9105 20.2641 32.8 20.2641L22.35 20.2641M17.35 20.2641V20.2641C17.35 18.8834 18.4693 17.7635 19.85 17.7635V17.7635C21.2307 17.7635 22.35 18.8834 22.35 20.2641V20.2641C22.35 21.6448 21.2307 22.7647 19.85 22.7647V22.7647C18.4693 22.7647 17.35 21.6448 17.35 20.2641V20.2641ZM17.35 20.2641L12.5 13"
      />
    </svg>
  )
}

export function BikeTrailer({
  color = '#222222',
  size = 24,
  strokeWidth = '2',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 34 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M27.7713 20.4974V16.2C27.7713 15.5373 27.234 15 26.5713 15H2.2C1.53726 15 1 15.5373 1 16.2V17.9974C1 19.3781 2.11929 20.4974 3.5 20.4974H7.86M27.7713 20.4974H32.8C32.9105 20.4974 33 20.4078 33 20.2974V17.16M27.7713 20.4974H20.91M20.91 20.4974V20.4974C20.91 19.1181 19.7892 18 18.41 18V18C17.0308 18 15.91 19.1181 15.91 20.4974V20.4974M20.91 20.4974V20.4974C20.91 21.8781 19.7907 23 18.41 23V23C17.0293 23 15.91 21.8781 15.91 20.4974V20.4974M15.91 20.4974H12.86M12.86 20.4974V20.4974C12.86 19.1181 11.7392 18 10.36 18V18C8.98075 18 7.86 19.1181 7.86 20.4974V20.4974M12.86 20.4974V20.4974C12.86 21.8781 11.7407 23 10.36 23V23C8.97929 23 7.86 21.8781 7.86 20.4974V20.4974M9.5 4.5H17.1959C17.6136 4.5 17.8472 4.98179 17.5885 5.3097L15.0714 8.5H10.8075C10.7989 8.5 10.7943 8.48998 10.7999 8.48349L14.2143 4.5M15.5 1.5H16.9545L18.0455 4.07143L19.5 7.5M11.5 10C11.5 11.3807 10.3807 12.5 9 12.5C7.61929 12.5 6.5 11.3807 6.5 10C6.5 8.61929 7.61929 7.5 9 7.5C10.3807 7.5 11.5 8.61929 11.5 10ZM22.5 10C22.5 11.3807 21.3807 12.5 20 12.5C18.6193 12.5 17.5 11.3807 17.5 10C17.5 8.61929 18.6193 7.5 20 7.5C21.3807 7.5 22.5 8.61929 22.5 10Z"
      />
    </svg>
  )
}

export function FoodTrailer({
  color = '#222222',
  size = 24,
  strokeWidth = '2',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 34 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M27.7713 20.4974V3C27.7713 1.89543 26.8758 1 25.7713 1H3C1.89543 1 1 1.89543 1 3V17.9974C1 19.3781 2.11929 20.4974 3.5 20.4974H7.86M27.7713 20.4974H32.8C32.9105 20.4974 33 20.4078 33 20.2974V17.16M27.7713 20.4974H20.91M20.91 20.4974V20.4974C20.91 19.1181 19.7892 18 18.41 18V18C17.0307 18 15.91 19.1181 15.91 20.4974V20.4974M20.91 20.4974V20.4974C20.91 21.8781 19.7907 23 18.41 23V23C17.0293 23 15.91 21.8781 15.91 20.4974V20.4974M15.91 20.4974H12.86M12.86 20.4974V20.4974C12.86 19.1181 11.7393 18 10.36 18V18C8.98075 18 7.86 19.1181 7.86 20.4974V20.4974M12.86 20.4974V20.4974C12.86 21.8781 11.7407 23 10.36 23V23C8.97929 23 7.86 21.8781 7.86 20.4974V20.4974M6 6.5H5.29541C5.15391 6.5 5.05716 6.35709 5.10971 6.22572L5.74856 4.62861C5.90042 4.24895 6.26813 4 6.67703 4H13.5439H22.323C22.7319 4 23.0996 4.24895 23.2514 4.62861L23.8903 6.22572C23.9428 6.35709 23.8461 6.5 23.7046 6.5H23M6 6.5V12.5M6 6.5H23M6 12.5H5.2C5.08954 12.5 5 12.5895 5 12.7V14C5 14.5523 5.44772 15 6 15H23C23.5523 15 24 14.5523 24 14V12.7C24 12.5895 23.9105 12.5 23.8 12.5H23M6 12.5H23M23 6.5V12.5"
      />
    </svg>
  )
}

export function BicycleTrailerDouble({
  color = '#222222',
  size = 24,
  strokeWidth = '2',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 34 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M27.7713 18.4974V14.2C27.7713 13.5373 27.234 13 26.5713 13H2.2C1.53726 13 1 13.5373 1 14.2V15.9974C1 17.3781 2.11929 18.4974 3.5 18.4974H7.86M27.7713 18.4974H32.8C32.9105 18.4974 33 18.4078 33 18.2974V15.16M27.7713 18.4974H20.91M20.91 18.4974V18.4974C20.91 17.1181 19.7892 16 18.41 16V16C17.0308 16 15.91 17.1181 15.91 18.4974V18.4974M20.91 18.4974V18.4974C20.91 19.8781 19.7907 21 18.41 21V21C17.0293 21 15.91 19.8781 15.91 18.4974V18.4974M15.91 18.4974H12.86M12.86 18.4974V18.4974C12.86 17.1181 11.7392 16 10.36 16V16C8.98075 16 7.86 17.1181 7.86 18.4974V18.4974M12.86 18.4974V18.4974C12.86 19.8781 11.7407 21 10.36 21V21C8.97929 21 7.86 19.8781 7.86 18.4974V18.4974M11.4401 3.16736H10.3285M11.4401 3.16736H13M11.4401 3.16736L14.1316 8.01208C14.1697 8.08066 14.2683 8.08066 14.3064 8.01208L16.9979 3.16736M11.4401 3.16736L8.50103 8.16942M16.9979 3.16736L19.5 8.16942M16.9979 3.16736V1.1C16.9979 1.04477 16.9532 1 16.8979 1H15.3306M8.50103 5.66839C7.11975 5.66839 6 6.78814 6 8.16942C6 9.55071 7.11975 10.6705 8.50103 10.6705C9.88232 10.6705 11.0021 9.55071 11.0021 8.16942C11.0021 6.78814 9.88232 5.66839 8.50103 5.66839ZM19.499 5.66839C18.1177 5.66839 16.9979 6.78814 16.9979 8.16942C16.9979 9.55071 18.1177 10.6705 19.499 10.6705C20.8802 10.6705 22 9.55071 22 8.16942C22 6.78814 20.8802 5.66839 19.499 5.66839Z"
      />
    </svg>
  )
}

export function DrawBarTrailer({
  color = '#222222',
  size = 24,
  strokeWidth = '2',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 34 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M27.7713 7H2.2C1.53726 7 1 7.53726 1 8.2V9.99735C1 11.3781 2.11929 12.4974 3.5 12.4974H3.86M27.7713 7V12.4974M27.7713 7V1M27.7713 12.4974H32.8C32.9105 12.4974 33 12.4078 33 12.2974V9.16001M27.7713 12.4974H24.91M24.91 12.4974V12.4974C24.91 11.1181 23.7892 10 22.41 10V10C21.0307 10 19.91 11.1181 19.91 12.4974V12.4974M24.91 12.4974V12.4974C24.91 13.8781 23.7907 15 22.41 15V15C21.0293 15 19.91 13.8781 19.91 12.4974V12.4974M19.91 12.4974H8.86M8.86 12.4974V12.4974C8.86 11.1181 7.73925 10 6.36 10V10C4.98075 10 3.86 11.1181 3.86 12.4974V12.4974M8.86 12.4974V12.4974C8.86 13.8781 7.74071 15 6.36 15V15C4.97929 15 3.86 13.8781 3.86 12.4974V12.4974" />
    </svg>
  )
}

export function CampingTrailer({
  color = '#222222',
  size = 24,
  strokeWidth = '2',
}) {
  return (
    <svg
      width={size}
      viewBox="0 0 34 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M12.86 14.8373V14.8373C12.86 13.4581 11.7393 12.34 10.36 12.34V12.34C8.98075 12.34 7.86 13.4581 7.86 14.8373V14.8373M12.86 14.8373V14.8373C12.86 16.2181 11.7407 17.34 10.36 17.34V17.34C8.97929 17.34 7.86 16.2181 7.86 14.8373V14.8373M12.86 14.8373H27.5M33 11.5V14.6373C33 14.7478 32.9104 14.8373 32.8 14.8373H27.5M7.86 14.8373H3.76747C2.23904 14.8373 1 13.5983 1 12.0699V10.783C1 5.37999 5.37999 1 10.783 1H13.2783C16.3834 1 19.4459 1.72296 22.2233 3.11163L22.5602 3.28011C23.5184 3.75922 24.4354 4.31667 25.3018 4.94679L25.8892 5.37397C28.09 6.97455 29.1581 9.70959 28.6244 12.378V12.378C28.5419 12.7903 28.3978 13.1878 28.1968 13.5571L27.5 14.8373M17.5 14.8373H23.6584V9.91421C23.6584 9.649 23.5531 9.39464 23.3655 9.20711L20.9513 6.79289C20.7638 6.60536 20.5094 6.5 20.2442 6.5H18.5C17.9477 6.5 17.5 6.94772 17.5 7.5V14.8373ZM12.5 5.75V5.75C12.5 6.7165 11.7165 7.5 10.75 7.5V7.5C9.7835 7.5 9 6.7165 9 5.75V5.75C9 4.7835 9.7835 4 10.75 4V4C11.7165 4 12.5 4.7835 12.5 5.75Z"
      />
    </svg>
  )
}

export function Hitch({ color = '#222222', size = 24, strokeWidth = '2' }) {
  return (
    <svg
      width={size}
      viewBox="0 0 25 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M10.5 19.5V19.5C10.5 20.0523 10.0523 20.5 9.5 20.5H1.5C1.22386 20.5 1 20.2761 1 20V15C1 14.7239 1.22386 14.5 1.5 14.5H9.5C10.0523 14.5 10.5 14.9477 10.5 15.5V15.5M10.5 19.5H17C19.7614 19.5 22 17.2614 22 14.5V7.36631L20.25 7.5M10.5 19.5V15.5M10.5 15.5H16.5C17.6046 15.5 18.5 14.6046 18.5 13.5V7.26282L20.25 7.5M20.25 7.5V7.5C18.4551 7.5 17 6.04493 17 4.25V4.25C17 2.45507 18.4551 1 20.25 1V1C22.0449 1 23.5 2.45507 23.5 4.25V4.25C23.5 6.04493 22.0449 7.5 20.25 7.5V7.5Z"
      />
    </svg>
  )
}
