import { useContext, useEffect, useState } from 'react'
import MainUserInfo from '../components/profile/general/information/MainUserInfo'
import RecentRentals from '../components/profile/general/information/RecentRentals'
import { useLocation } from 'react-router-dom'
import MyReservationPopup from '../components/profile/general/reservations/MyReservationPopup'
import Loading from 'components/constants/Loading'
import { AuthContext } from 'context/auth-context'
import SettingsPopup from 'components/profile/general/newSettings/SettingsPopup'
import useWindowWidth from 'hooks/useWindowWidth'
import LoginRegisterModal from 'components/constants/auth/LoginRegisterModal'

export default function Profile() {
  const [contentShowing, setContentShowing] = useState('upcoming')
  const { user } = useContext(AuthContext)
  const location = useLocation()
  const [showReservation, setShowReservation] = useState({})
  const [showSettings, setShowSettings] = useState(false)
  const [settingsContentInView, setSettingsContentInView] = useState('')
  const windowWidth = useWindowWidth()
  const [showLogin, setShowLogin] = useState(false)

  useEffect(() => {
    if (windowWidth < 768) {
      setContentShowing('')
    } else {
      setContentShowing('upcoming')
    }
  }, [windowWidth])

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const settings = params.get('settings')
    if (settings === 'true') {
      setShowSettings(true)
    }
  }, [location])

  if (!user || Object.keys(user).length <= 0)
    return (
      <div className="page profile">
        <Loading />
        <LoginRegisterModal
          open={showLogin}
          onClose={() => setShowLogin(false)}
        />
      </div>
    )

  return (
    <div
      className="page profile"
      style={{
        paddingBottom: 0,
        overflowY: 'hidden',
        paddingTop: windowWidth >= 768 ? 0 : 0,
      }}
    >
      <div className="profileInfo mobile">
        <MainUserInfo
          user={user}
          setShowSettings={setShowSettings}
          setSettingsContentInView={setSettingsContentInView}
        />
        <RecentRentals
          user={user}
          contentShowing={contentShowing}
          setContentShowing={setContentShowing}
          setShowReservation={setShowReservation}
          setShowSettings={setShowSettings}
        />
      </div>
      {showSettings && (
        <SettingsPopup
          user={user}
          setShowSettings={setShowSettings}
          contentInView={settingsContentInView}
          setContentInView={setSettingsContentInView}
        />
      )}
      {Object.keys(showReservation).length > 0 && (
        <MyReservationPopup
          showReservation={showReservation}
          setShowReservation={setShowReservation}
        />
      )}
    </div>
  )
}
