import { useEffect, useState } from 'react'
import './CalendarSidebar.css'
import { TrailerCategories } from '../../../../../data/dummy/TrailerTypes'
import CalendarDaysDetails from './CalendarDayDetails'
import useAxios from '../../../../../hooks/use-axios'
import useWindowWidth from '../../../../../hooks/useWindowWidth'
import Loading from 'components/constants/Loading'

export default function CalendarSidebar({
  selectedRange,
  setSelectedRange,
  findDateData,
  user,
  setActiveTrailer,
  monthsInView,
  getActiveReservations,
  trailerInfo,
  isAvailable,
  setIsAvailable,
}) {
  const windowWidth = useWindowWidth()
  const [tempActiveTrailer, setTempActiveTrailer] = useState('')
  const [myTrailers, setMyTrailers] = useState([])
  const myTrailerList = myTrailers?.[0]?.content
  const { response, loading, error } = useAxios({
    method: 'get',
    url: `/customers/${user.id}/trailers`,
  })

  useEffect(() => {
    if (!response) return
    setMyTrailers([response])

    return () => {
      setMyTrailers([])
    }
  }, [response])

  useEffect(() => {
    if (myTrailerList?.length > 0) {
      setTempActiveTrailer(myTrailerList?.[0]?.id)
      setActiveTrailer(myTrailerList?.[0]?.id)
    }
  }, [myTrailerList])

  useEffect(() => {
    if (tempActiveTrailer) {
      setActiveTrailer(tempActiveTrailer)
    }
  }, [tempActiveTrailer])

  if (loading) return <Loading />
  if (error) console.error(error)

  return (
    <div
      className={
        windowWidth > 744
          ? 'calendarDayDetailsContainer'
          : 'calendarDayDetailsContainer mobile'
      }
    >
      {!selectedRange.startDate && !selectedRange.endDate ? (
        <div className="calendarDayDetailsContent out">
          <b>Kalender overzicht</b>
          <p>
            Wijzig de beschikbaarheid en stel variabele prijzen in over de
            geselecteerde aanhanger.
          </p>
          {myTrailerList?.map((trailer) => {
            const trailerIcon = TrailerCategories.filter(
              (type) => type.trailerType === trailer?.trailerType?.name
            )

            return (
              <div
                className={`calendarTrailerSelectItem ${
                  tempActiveTrailer === trailer.id ? 'active' : null
                }`}
                key={trailer.id}
                onClick={() => setTempActiveTrailer(trailer.id)}
              >
                {trailerIcon?.[0]?.icon}
                <b>{trailer.name}</b>
                <span>{trailer.trailerType.name}</span>
                <div
                  className={`selectDot ${
                    tempActiveTrailer === trailer.id ? 'active' : null
                  }`}
                >
                  {tempActiveTrailer === trailer.id && (
                    <div className="activeDot" />
                  )}
                </div>
              </div>
            )
          })}
        </div>
      ) : (
        <CalendarDaysDetails
          setSelectedRange={setSelectedRange}
          selectedRange={selectedRange}
          findDateData={findDateData}
          trailerInfo={trailerInfo}
          getActiveReservations={getActiveReservations}
          isAvailable={isAvailable}
          setIsAvailable={setIsAvailable}
          myTrailerList={myTrailerList}
        />
      )}
    </div>
  )
}
