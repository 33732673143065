import React, { useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Loading from 'components/util/Loading'
import { Alert } from '@mui/material'
import { TrailerOffer } from 'types/TrailerOffer'
import useAxios from 'hooks/use-axios'
import {
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  TextField,
  TextArea,
  CheckBox,
} from '../../components/ui'
import { Notice } from '../../components/ui/notice/Notice'
import { useAuth } from '../../hooks/use-auth'
import LoginRegisterModal from '../../components/constants/auth/LoginRegisterModal'
import * as Form from '@radix-ui/react-form'
import { TbCalendar, TbMail, TbMapPin, TbPhone, TbUser } from 'react-icons/tb'
import { boolean, literal, object, string, TypeOf } from 'zod'
import { SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import '../styles/reserve.css'
import { useMatchBreakpoint, useMediaQuery } from '../../hooks/use-media-query'
import { add, format, parse } from 'date-fns'
import { instance } from '../../util/axios-instance'
import { toast } from 'react-toastify'

const reservationFormSchema = object({
  name: string()
    .min(2, 'Gebruik minimaal 2 tekens')
    .max(64, 'Gebruik maximaal 64 tekens'),
  mail: string().email('Ongeldig email adres'),
  telephone: string().regex(
    new RegExp(
      '^(?:0|(?:\\+|00) ?31 ?)(?:(?:[1-9] ?(?:[0-9] ?){8})|(?:6 ?-? ?[1-9] ?(?:[0-9] ?){7})|(?:[1,2,3,4,5,7,8,9]\\d ?-? ?[1-9] ?(?:[0-9] ?){6})|(?:[1,2,3,4,5,7,8,9]\\d{2} ?-? ?[1-9] ?(?:[0-9] ?){5}))$'
    ),
    'Ongeldig telefoon nummer'
  ),
  message: string()
    .min(10, 'Gebruik minimaal 10 tekens')
    .max(1000, 'Gebruik maximaal 1000 tekens'),
  checkbox: literal<boolean>(true, {
    errorMap: () => ({ message: 'Accepteer onze algemene voorwaarden' }),
  }),
})

type ReservationInput = TypeOf<typeof reservationFormSchema>

export default function Reserve() {
  const [showLogin, setShowLogin] = useState(false)
  const [reqLoading, setReqLoading] = useState<boolean>(false)
  const [searchParams] = useSearchParams()
  const { user } = useAuth()
  const { id } = useParams()
  const { xs } = useMatchBreakpoint()
  const navigate = useNavigate()

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ReservationInput>({
    resolver: zodResolver(reservationFormSchema),
  })

  const {
    response: trailerOffer,
    error,
    loading,
  } = useAxios<TrailerOffer>({
    url: `/traileroffers/${id}`,
    method: 'get',
  })

  const onSubmit: SubmitHandler<ReservationInput> = async (data) => {
    setReqLoading(true)

    await instance
      .post('/reservations', {
        trailerId: trailerOffer.id,
        startTime: add(
          parse(searchParams.get('startTime'), 'dd/MM/yyyy', new Date()),
          { days: 1 }
        ),
        endTime: add(
          parse(searchParams.get('endTime'), 'dd/MM/yyyy', new Date()),
          { days: 1 }
        ),
        message: data.message,
        name: data.name,
        number: data.telephone,
        email: data.mail,
      })
      .then((res) => {
        navigate('/account')
        setReqLoading(false)
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          theme: 'colored',
        })
        setReqLoading(false)
      })
  }

  if (loading) return <Loading />
  if (error) return <Alert severity="error">{error.message}</Alert>

  return (
    <>
      {showLogin && (
        <LoginRegisterModal
          open={showLogin}
          onClose={() => setShowLogin(false)}
        />
      )}
      <Container>
        <Alert severity="error" style={{ marginBottom: 32 }}>
          Gedurende de pilot fase van BuurBak moeten betalingen nog zelfstandig
          tussen de huurder en verhuurder geregeld worden.
        </Alert>
        <Flex xs="col" gap={32}>
          <h2 className="heading-2">
            Reserveer {trailerOffer.trailerType.name.toLowerCase()}
          </h2>
          <Grid xs={2} md={12} gap={32}>
            <GridItem xsSpan={2} mdSpan={8}>
              <Flex gap={24} xs="col" alignItems="start">
                <Notice
                  title={
                    user
                      ? `Voor dit type aanhanger is een rijbewijs ${trailerOffer.driversLicenseType} verplicht`
                      : `Voordat je deze aanhanger kan reserveren moet je eerst inloggen`
                  }
                  variant="error"
                />
                {user ? (
                  <>
                    <p className="paragraph-light">
                      Controleer je gegevens om je reservering van “
                      {trailerOffer.trailerType.name.toLowerCase()}” compleet te
                      maken. Je kan een bericht achterlaten voor de verhuurder
                      met eventuele vragen of opmerkingen, dit is niet
                      verplicht.
                    </p>
                    <Form.Root
                      className="form-full_width"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Grid xs={12} gap={16}>
                        <GridItem xsSpan={12} mdSpan={6}>
                          <TextField
                            defaultValue={user.name}
                            leftIcon={<TbUser size={16} />}
                            label="Naam"
                            name="name"
                            placeholder="Naam"
                            register={register}
                            error={errors.name?.message}
                          />
                        </GridItem>
                        <GridItem xsSpan={12} mdSpan={6}>
                          <TextField
                            defaultValue={user.email}
                            leftIcon={<TbMail size={16} />}
                            name="mail"
                            label="Mail"
                            placeholder="Mail"
                            register={register}
                            error={errors.mail?.message}
                          />
                        </GridItem>
                        <GridItem xsSpan={12}>
                          <TextField
                            defaultValue={user.number}
                            leftIcon={<TbPhone size={16} />}
                            placeholder="Telefoonnummer"
                            name="telephone"
                            label="Telefoonnummer"
                            register={register}
                            error={errors.telephone?.message}
                          />
                        </GridItem>
                        <GridItem xsSpan={12}>
                          <TextArea
                            rows={8}
                            name="message"
                            placeholder="Uw bericht"
                            label="Bericht aan de verhuurder"
                            register={register}
                            error={errors.message?.message}
                          />
                        </GridItem>
                        <GridItem xsSpan={12}>
                          <CheckBox
                            setValue={setValue}
                            control={control}
                            label="Ik verklaar hierbij de algemene voorwaarden gelezen te hebben en accepteer deze"
                          />
                        </GridItem>
                      </Grid>
                      <Flex
                        justifyContent="end"
                        className="reservation__form_footer"
                      >
                        <Form.Submit asChild>
                          <Button disabled={reqLoading}>
                            {reqLoading ? (
                              <p>Bezig met reserveren</p>
                            ) : (
                              'Voltooi reservering'
                            )}
                          </Button>
                        </Form.Submit>
                      </Flex>
                    </Form.Root>
                  </>
                ) : (
                  <Button onClick={() => setShowLogin(true)}>Inloggen</Button>
                )}
              </Flex>
            </GridItem>
            <GridItem xsSpan={2} mdSpan={4}>
              <div className="reservation__details">
                <img
                  className="reservation__details_image"
                  src={trailerOffer.coverImage}
                />
                <div className="reservation__details_content">
                  <p className="reservation__details_title">
                    {trailerOffer.trailerType.name}
                  </p>
                  <Flex
                    gap={4}
                    alignItems="center"
                    className="reservation__details_location"
                  >
                    <TbMapPin size={16} />
                    <p>{trailerOffer.address.city}</p>
                  </Flex>
                  <Flex
                    className="reservation__details_date"
                    alignItems="center"
                    gap={4}
                  >
                    <TbCalendar />
                    <p>
                      {format(
                        parse(
                          searchParams.get('startTime'),
                          'dd/MM/yyyy',
                          new Date()
                        ),
                        'dd-MM-yyyy'
                      )}{' '}
                      -{' '}
                      {format(
                        parse(
                          searchParams.get('endTime'),
                          'dd/MM/yyyy',
                          new Date()
                        ),
                        'dd-MM-yyyy'
                      )}
                    </p>
                  </Flex>
                  <div className="trailer__flex-seperator" />
                  <Flex
                    className="trailer__profile_hire_total"
                    xs="row"
                    justifyContent="spaceBetween"
                  >
                    <p className="paragraph-light">Totaal</p>
                    <p className="trailer__profile_hire_total_price">
                      €
                      {trailerOffer.trailerWeekScheduleTemplate.friday.price.toFixed(
                        2
                      )}{' '}
                      per dag
                    </p>
                  </Flex>
                </div>
              </div>
            </GridItem>
          </Grid>
        </Flex>
      </Container>
    </>
  )
}
