import { useEffect, useRef, useState, useCallback } from 'react'
import './ChangeUserInfo.css'
import { useAuth } from 'hooks/use-auth'
import { instance } from 'util/axios-instance'
import { TbMapPin } from 'react-icons/tb'
import UpdateLocation from './UpdateLocation'
import AutoUpdateLocation from './AutoUpdateLocation'
import UpdateProfileImage from './UpdateProfileImage'
import ChangePersonalInfo from './ChangePersonalInfo'
import useDebouncedEffect from 'components/addTrailerForm/hook/UseDebouncedEffect'

const DEFAULT_COORDINATES = { lat: 52.090736, lng: 5.12142 }

type UserInfoUpdate = {
  name?: string
  number?: string
  date_of_birth?: string
  address?: {
    streetName?: string
    postalCode?: string
    city?: string
    houseNumber?: string
  }
}

export default function ChangeUserInfo({ user, setShowSettings }) {
  const {
    id,
    name: initialName,
    number: initialNumber,
    address: initialAddress,
  } = user
  const { street_name, postal_code, city, number } = initialAddress || {}

  const mapRef = useRef(null)
  const { refreshUser } = useAuth()

  const [profileImage, setProfileImage] = useState({
    file: null,
    previewUrl: null,
  })
  const [userName, setUserName] = useState(initialName)
  const [userPhone, setUserPhone] = useState(initialNumber)
  const [streetName, setStreetName] = useState(street_name)
  const [houseNumber, setHouseNumber] = useState(number)
  const [postalCode, setPostalCode] = useState(postal_code)
  const [updatedCity, setUpdatedCity] = useState(city)
  const [editAddress, setEditAddress] = useState(false)
  const [showMap, setShowMap] = useState(true)
  const [location, setLocation] = useState([])
  const [geoCode, setGeoCode] = useState(DEFAULT_COORDINATES)
  const [debouncedCenter, setDebouncedCenter] = useState({})
  const [error, setError] = useState({ type: '', message: '' })
  const [selectedDate, setSelectedDate] = useState(
    user.date_of_birth ? new Date(user.date_of_birth) : null
  )

  const updateUserInfo = useCallback(
    async (data: UserInfoUpdate) => {
      if (!id) {
        console.error('User ID is undefined')
        return
      }

      try {
        await instance.patch(`/customers/${id}`, data)
        refreshUser()
        setShowSettings(false)
      } catch (err) {
        console.error('Error updating user info:', err)
      }
    },
    [id, refreshUser]
  )

  const changeUserInfo = useCallback(async () => {
    // Early return if the user ID is not available
    if (!id) {
      console.error('User ID is undefined')
      return
    }

    const updates = buildUpdates()
    if (Object.keys(updates).length > 0) {
      await updateUserInfo(updates)
    }

    if (profileImage.file) {
      await uploadProfileImage()
    }

    await refreshUser()
  }, [
    userName,
    userPhone,
    streetName,
    postalCode,
    updatedCity,
    houseNumber,
    profileImage,
    initialName,
    initialNumber,
    initialAddress,
    updateUserInfo,
    id,
    refreshUser,
  ])

  const buildUpdates = () => {
    const updates: UserInfoUpdate = {}

    if (userName !== initialName) updates.name = userName
    if (userPhone !== initialNumber) updates.number = userPhone
    if (selectedDate && selectedDate.toISOString() !== user.date_of_birth) {
      updates.date_of_birth = selectedDate.toISOString()
    }

    const addressChanged =
      streetName !== initialAddress.street_name ||
      postalCode !== initialAddress.postal_code ||
      updatedCity !== initialAddress.city ||
      houseNumber !== initialAddress.number

    if (addressChanged) {
      updates.address = {
        // ...initialAddress,
        streetName: streetName,
        postalCode: postalCode,
        city: updatedCity,
        houseNumber: houseNumber,
      }
    }

    return updates
  }

  const uploadProfileImage = async () => {
    const formData = new FormData()
    formData.append('file', profileImage.file)

    try {
      await instance.post(`/customers/${id}/profile-image`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    } catch (error) {
      console.error('Error uploading profile image:', error)
    }
  }

  const getAddressComponent = useCallback(
    (type: string): string => {
      const result = location.find((item) => item.types.includes(type))
      return result ? result.long_name : ''
    },
    [location]
  )

  useEffect(() => {
    if (!mapRef.current) return

    if (geoCode && isFinite(geoCode.lat) && isFinite(geoCode.lng)) {
      const { lat, lng } = geoCode
      mapRef.current.panTo({ lat, lng })

      if (location.length > 0) {
        setStreetName(getAddressComponent('route'))
        setUpdatedCity(getAddressComponent('locality'))
        setPostalCode(getAddressComponent('postal_code').replace(/\s/g, ''))
        setHouseNumber(getAddressComponent('street_number'))
      }
    } else {
      mapRef.current.panTo(debouncedCenter)
    }
  }, [location, geoCode, debouncedCenter, getAddressComponent])

  useEffect(() => {
    if (location.length > 0) {
      const newStreetName = getAddressComponent('route')
      const newHouseNumber = getAddressComponent('street_number')
      const newUpdatedCity = getAddressComponent('locality')
      const newPostalCode = getAddressComponent('postal_code').replace(
        /\s/g,
        ''
      )

      setStreetName(newStreetName)
      setHouseNumber(newHouseNumber)
      setUpdatedCity(newUpdatedCity)
      setPostalCode(newPostalCode)
    }
  }, [location, getAddressComponent])

  useDebouncedEffect(
    () => {
      if (Object.keys(geoCode).length > 0) {
        setDebouncedCenter({ lat: geoCode.lat, lng: geoCode.lng })
      }
    },
    [geoCode],
    500
  )

  const hasChanges = useCallback(() => {
    const formattedSelectedDate = selectedDate
      ? `${selectedDate.getFullYear()}-${String(
          selectedDate.getMonth() + 1
        ).padStart(2, '0')}-${String(selectedDate.getDate()).padStart(2, '0')}`
      : null

    return (
      userName !== initialName ||
      userPhone !== initialNumber ||
      profileImage.file !== null ||
      streetName !== initialAddress.street_name ||
      houseNumber !== initialAddress.number ||
      postalCode !== initialAddress.postal_code ||
      city !== initialAddress.city ||
      formattedSelectedDate !== user.date_of_birth
    )
  }, [
    userName,
    userPhone,
    profileImage,
    streetName,
    houseNumber,
    postalCode,
    city,
    initialName,
    initialNumber,
    initialAddress,
    selectedDate,
    user.date_of_birth,
  ])

  return (
    <div className="aboutReservationContent settings userInfo">
      <p>
        Op deze pagina kan je jouw persoonlijke gegevens eenvoudig aanpassen.
      </p>
      <UpdateProfileImage
        user={user}
        profileImage={profileImage}
        setProfileImage={setProfileImage}
      />
      <ChangePersonalInfo
        userName={userName}
        setUserName={setUserName}
        userPhone={userPhone}
        setUserPhone={setUserPhone}
        error={error}
        setError={setError}
      />
      <button
        className={`saveChangesBtn ${hasChanges() ? '' : 'disabledBtn'}`}
        disabled={!hasChanges()}
        style={{ marginTop: 30 }}
        onClick={() => changeUserInfo()}
      >
        Wijzigingen opslaan
      </button>
    </div>
  )
}
