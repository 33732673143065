import { Button, Divider, Menu, Stack } from '@mui/material'
import { Box } from '@mui/system'
import { useAuth } from '../../../hooks/use-auth'
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks'
import ProfilePicture from '../../util/ProfilePicture'
import './Header.css'
import {
  TbDoorExit,
  TbHelp,
  TbMessageCircle,
  TbSettings,
  TbUserCircle,
} from 'react-icons/tb'
import { TransportTrailer } from 'data/icons/TrailerIcons'

interface HeaderNavigationProps {
  onLoginRegister: () => void
  isTrailerOwner: boolean
}

export default function HeaderNavigation({
  onLoginRegister,
  isTrailerOwner,
}: HeaderNavigationProps) {
  const { user, logout } = useAuth()
  const popupState = usePopupState({ variant: 'popover', popupId: 'account' })

  return (
    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
      <Stack spacing={4} direction="row">
        <Button
          href="/aanbod"
          color="secondary"
          sx={{ fontFamily: 'Open sans', fontWeight: 'var(--semibold)' }}
        >
          Aanbod
        </Button>
        {/*<Button*/}
        {/*  href="/hoe-het-werkt"*/}
        {/*  color="secondary"*/}
        {/*  sx={{ fontFamily: 'Open sans', fontWeight: 'var(--semibold)' }}*/}
        {/*>*/}
        {/*  Hoe het werkt*/}
        {/*</Button>*/}
        <Button
          href="/verhuren"
          color="primary"
          variant="contained"
          disableElevation
          sx={{
            borderRadius: 2.5,
            fontWeight: 'var(--semibold)',
            fontFamily: 'Open sans',
            fontSize: 13,
          }}
        >
          Ik wil verhuren
        </Button>
        {isTrailerOwner ? (
          <Button
            href="/owner/dashboard"
            color="secondary"
            sx={{ fontFamily: 'Open sans', fontWeight: 'var(--semibold)' }}
          >
            Verhuur overzicht
          </Button>
        ) : (
          <Button
            href="/contact"
            color="secondary"
            sx={{ fontFamily: 'Open sans', fontWeight: 'var(--semibold)' }}
          >
            Contact
          </Button>
        )}

        {user ? (
          <div>
            <Button
              {...bindTrigger(popupState)}
              color="secondary"
              endIcon={<ProfilePicture user={user} />}
              sx={{
                fontFamily: 'Open sans',
                fontWeight: 'var(--semibold)',
              }}
            >
              {user.name ? user.name : 'Account'}
            </Button>
            <div className="headerMenuContainer">
              <Menu {...bindMenu(popupState)} sx={{ top: 10, left: -12 }}>
                <a
                  href="/account"
                  className="headerMenuItem"
                  onClick={popupState.close}
                >
                  <p>Mijn account</p>
                  <TbUserCircle size={18} strokeWidth={1.7} />
                </a>
                {/*<a*/}
                {/*  href="/berichten"*/}
                {/*  className="headerMenuItem"*/}
                {/*  onClick={popupState.close}*/}
                {/*>*/}
                {/*  <p>Berichten</p>*/}
                {/*  <TbMessageCircle size={18} strokeWidth={1.7} />*/}
                {/*</a>*/}
                {isTrailerOwner && (
                  <a
                    href="/owner/dashboard"
                    className="headerMenuItem"
                    onClick={popupState.close}
                  >
                    <p>Verhuur overzicht</p>
                    <TransportTrailer size={20} strokeWidth="1.7" />
                  </a>
                )}
                <a
                  href="/contact"
                  className="headerMenuItem"
                  onClick={popupState.close}
                >
                  <p>Contact</p>
                  <TbHelp size={18} strokeWidth={1.7} />
                </a>
                <a
                  href="/account?settings=true"
                  className="headerMenuItem"
                  onClick={popupState.close}
                >
                  <p>Instellingen</p>
                  <TbSettings size={18} strokeWidth={1.7} />
                </a>
                <Divider sx={{ mt: 0.5, mb: 0.5 }} />
                <div
                  className="headerMenuItem"
                  onClick={() => {
                    popupState.close()
                    logout()
                  }}
                >
                  <p>Uitloggen</p>
                  <TbDoorExit size={18} strokeWidth={1.7} />
                </div>
              </Menu>
            </div>
          </div>
        ) : (
          <Button
            color="secondary"
            onClick={onLoginRegister}
            sx={{ fontFamily: 'Open sans', fontWeight: 'var(--semibold)' }}
          >
            Aanmelden/ Inloggen
          </Button>
        )}
      </Stack>
    </Box>
  )
}
