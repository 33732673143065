import { IconButton } from '@mui/material'
import { TbArrowBackUp, TbChevronRight, TbTicket } from 'react-icons/tb'
import './CalendarDayDetails.css'
import { useEffect, useState, useMemo } from 'react'
import { format, isSameDay } from 'date-fns'
import useWindowWidth from '../../../../../hooks/useWindowWidth'
import AvailabilityAction from './dayDetails/AvailabilityAction'
import TrailerNotAvailable from './dayDetails/TrailerNotAvailable'
import TrailerIsAvailable from './dayDetails/TrailerIsAvailable'
import { useNavigate } from 'react-router-dom'
import Loading from 'components/constants/Loading'
import {
  setTrailerAvailable,
  setTrailerUnavailable,
} from '../view/utility/UpdateAvailability'
import { checkAvailability } from '../view/utility/CheckAvailability'
import {
  useDateDisplay,
  useReservationsInRange,
} from '../view/utility/CalendarUtilities'
import MultipleTrailerSelect from './dayDetails/MultipleTrailerSelect'

function formatPrice(price) {
  return `€${parseFloat(price).toFixed(2).replace('.', ',')}`
}

export default function CalendarDaysDetails({
  selectedRange,
  setSelectedRange,
  findDateData,
  trailerInfo,
  getActiveReservations,
  isAvailable,
  myTrailerList,
  setIsAvailable,
}) {
  const dayOfWeek = useMemo(
    () => format(selectedRange.startDate, 'eeee').toLowerCase(),
    [selectedRange.startDate]
  )
  const trailerDayInfo = trailerInfo?.trailerWeekScheduleTemplate?.[dayOfWeek]
  const [dayInfo, setDayInfo] = useState(() =>
    findDateData(selectedRange.startDate)
  )
  const [updatedPrice, setUpdatedPrice] = useState(() =>
    formatPrice(dayInfo?.price ?? trailerDayInfo?.price)
  )
  const [applyToAllDays, setApplyToAllDays] = useState(false)
  const [applyToAllTrailers, setApplyToAllTrailers] = useState(false)
  const isSingleDay = useMemo(
    () => isSameDay(selectedRange.startDate, selectedRange.endDate),
    [selectedRange]
  )
  const [showTrailerSelect, setShowTrailerSelect] = useState(false)
  const [timePeriods, setTimePeriods] = useState([])
  const [loading, setLoading] = useState(false)
  const [originalIsAvailable, setOriginalIsAvailable] = useState(null)
  const reservationsInRange = useReservationsInRange(
    selectedRange.startDate,
    selectedRange.endDate,
    getActiveReservations
  )
  const DateDisplay = useDateDisplay(selectedRange, isSingleDay)
  const windowWidth = useWindowWidth()
  const navigate = useNavigate()

  useEffect(() => {
    const dateData = findDateData(selectedRange.startDate)
    setDayInfo(dateData)
    setUpdatedPrice(formatPrice(dateData?.price ?? trailerDayInfo?.price))
    setTimePeriods(
      dateData?.timeSlots?.length > 0
        ? dateData.timeSlots
        : trailerDayInfo?.timeSlots?.length > 0
        ? trailerDayInfo.timeSlots
        : [{}]
    )
  }, [selectedRange.startDate, findDateData, trailerDayInfo])

  useEffect(() => {
    const availability = checkAvailability(
      selectedRange,
      findDateData,
      trailerInfo
    )
    setIsAvailable(availability)
    if (originalIsAvailable === null) {
      setOriginalIsAvailable(availability)
    }
  }, [selectedRange, findDateData, trailerInfo])

  if (loading) return <Loading />

  return (
    <>
      {showTrailerSelect ? (
        <MultipleTrailerSelect
          windowWidth={windowWidth}
          setShowTrailerSelect={setShowTrailerSelect}
          myTrailerList={myTrailerList}
        />
      ) : (
        <div className="calendarDayDetailsContent">
          {windowWidth > 744 && (
            <IconButton
              className="backToOverview"
              onClick={() =>
                setSelectedRange({ startDate: null, endDate: null })
              }
            >
              <TbArrowBackUp />
            </IconButton>
          )}
          <div className="calendarDayDetailsContentWrapper">
            {DateDisplay}
            {reservationsInRange.length > 0 ? (
              <div className="hasReservationContent">
                <p>
                  Vanwege een lopende reservering / huuraanvraag is het niet
                  toegestaan om de gegevens van deze specifieke data aan te
                  passen.
                </p>
              </div>
            ) : (
              ''
            )}
            {reservationsInRange.length > 0 ? (
              <HasReservation
                navigate={navigate}
                reservationsInRange={reservationsInRange}
              />
            ) : (
              <div className="hasReservationContent">
                {!isAvailable ? (
                  <p>
                    Op deze datum is uw aanhanger niet beschikbaar. Wanneer u de
                    beschikbaarheid van de aanhangen wil aanpassen kan u contact
                    opnemen met het BuurBak team.
                  </p>
                ) : (
                  <p>
                    Op deze datum is uw aanhanger beschikbaar. Wanneer u de
                    beschikbaarheid op deze datum wil aanpassen kan u contact
                    opnemen met het BuurBak team.
                  </p>
                )}
              </div>
              // <>
              //   {isAvailable ? (
              //     <TrailerIsAvailable
              //       timePeriods={timePeriods}
              //       setTimePeriods={setTimePeriods}
              //       updatedPrice={updatedPrice}
              //       setUpdatedPrice={setUpdatedPrice}
              //       applyToAllDays={applyToAllDays}
              //       setApplyToAllDays={setApplyToAllDays}
              //       applyToAllTrailers={applyToAllTrailers}
              //       setApplyToAllTrailers={setApplyToAllTrailers}
              //       setIsAvailable={setIsAvailable}
              //       setShowTrailerSelect={setShowTrailerSelect}
              //     />
              //   ) : (
              //     <div className="hasReservationContent">
              //       <p>
              //         Op deze datum is uw aanhanger niet beschikbaar. Wanneer u
              //         de beschikbaarheid van de aanhangen wil aanpassen kan u
              //         contact opnemen met het BuurBak team.
              //       </p>
              //     </div>
              //   )}
              // </>
            )}
          </div>
        </div>
      )}
    </>
  )
}

function HasReservation({ navigate, reservationsInRange }) {
  return (
    <div className="hasReservationActionsContainer">
      {reservationsInRange.map((item) => (
        <div
          className="sidebarShortcutItem"
          key={item.id}
          onClick={() => navigate(`/owner/dashboard?reservation=${item.id}`)}
          style={{ width: 'calc(100% + 20px)' }}
        >
          <TbTicket className="sidebarIcon" />
          <p>
            Bekijk {item.renter.name.split(' ')[0]}{' '}
            {item.confirmed ? 'reservering' : 'huuraanvraag'}
          </p>
          <TbChevronRight />
        </div>
      ))}
    </div>
  )
}
