import { NoResultsIcon } from 'data/icons/TrailerIcons'
import useWindowWidth from 'hooks/useWindowWidth'
import { useNavigate } from 'react-router-dom'
import './NoResults.css'
import { Button } from '../../../ui'

export default function NoResults({ text }) {
  const navigate = useNavigate()
  const windowWidth = useWindowWidth()

  return (
    <div className="noResultsContainer">
      <NoResultsIcon
        size={windowWidth >= 768 ? 76 : 62}
        color="var(--primary)"
      />
      <b>Geen {text} reserveringen</b>
      <p>
        U heeft op dit moment geen {text} reserveringen staan, huur nu je
        aanhanger via BuurBak.
      </p>
      <Button
        onClick={() => navigate('/aanbod')}
        variant="primaryOutline"
        style={{ marginTop: '30px' }}
      >
        Bekijk het aanbod
      </Button>
    </div>
  )
}
