import { IconButton } from '@mui/material'
import { useState } from 'react'
import { TbId, TbInfoCircle, TbX } from 'react-icons/tb'
import './DriversLicense.css'
import { useTranslation } from 'react-i18next'

export default function DriverLicense({ license, setLicense }) {
  const [showExtraInfo, setShowExtraInfo] = useState(false)
  const [activeInfo, setActiveInfo] = useState('')
  const { t } = useTranslation('TrailerForm')

  const licenseInfo = [
    {
      id: 'B',
      title: `${t('license.extraLicenseInfo.licenseB')}`,
      description: `${t('license.extraLicenseInfo.bLicenseContent')}`,
    },
    {
      id: 'BE',
      title: `${t('license.extraLicenseInfo.licenseBE')}`,
      description: `${t('license.extraLicenseInfo.beLicenseContent')}`,
    },
    {
      id: 'B+',
      title: `${t('license.extraLicenseInfo.licenseB+')}`,
      description: `Voor een B+ rijbewijs gelden andere regels voor het trekken van aanhangers of opleggers. Met een B+ rijbewijs mag je een aanhanger of oplegger trekken met een maximum toegestane massa van meer dan 750 kg, maar de totale combinatiegewicht (voertuig + aanhanger) mag niet hoger zijn dan 4.250 kg. Dit is anders dan bij het BE rijbewijs, waarbij het totale combinatiegewicht tot 7.000 kg kan gaan onder bepaalde omstandigheden.`,
    },
  ]

  const content = licenseInfo.filter((item) => item.id === activeInfo)

  return (
    <div className="formStepContainer">
      <h2>{t('license.title')}</h2>
      <p>{t('license.introduction')}</p>
      <div className="licenseGrid">
        <div
          className={
            license === 'none' ? 'licenseGridItemActive' : 'licenseGridItem'
          }
          onClick={() => setLicense('none')}
        >
          <TbId className="licenseIcon" />
          <div className="licenseGridItemText">
            <b>{t('license.licenseTypes.licenseB')}</b>
            <p>{t('license.licenseTypes.licenseBInfo')}</p>
          </div>
          <IconButton
            className="infoIcon"
            onClick={() => {
              setShowExtraInfo(true)
              setActiveInfo('B')
            }}
          >
            <TbInfoCircle />
          </IconButton>
        </div>
        <div
          className={
            license === 'BE' ? 'licenseGridItemActive' : 'licenseGridItem'
          }
          onClick={() => setLicense('BE')}
        >
          <TbId className="licenseIcon" />
          <div className="licenseGridItemText">
            <b>{t('license.licenseTypes.licenseBE')}</b>
            <p>{t('license.licenseTypes.licenseBEInfo')}</p>
          </div>
          <IconButton
            className="infoIcon"
            onClick={() => {
              setShowExtraInfo(true)
              setActiveInfo('BE')
            }}
          >
            <TbInfoCircle />
          </IconButton>
        </div>
        <div
          className={
            license === 'B+' ? 'licenseGridItemActive' : 'licenseGridItem'
          }
          onClick={() => setLicense('B+')}
        >
          <TbId className="licenseIcon" />
          <div className="licenseGridItemText">
            <b>{t('license.licenseTypes.licenseB+')}</b>
            <p>{t('license.licenseTypes.licenseB+Info')}</p>
          </div>
          <IconButton
            className="infoIcon"
            onClick={() => {
              setShowExtraInfo(true)
              setActiveInfo('B+')
            }}
          >
            <TbInfoCircle />
          </IconButton>
        </div>
      </div>
      {showExtraInfo && (
        <div className="blurContainer">
          <div className="blur" onClick={() => setShowExtraInfo(false)} />
          <div className="popupContainer infoBox">
            <div className="popupContainerHeader">
              <IconButton
                className="closePopup transformed"
                onClick={() => setShowExtraInfo(false)}
              >
                <TbX />
              </IconButton>
              <p>{t('license.extraLicenseInfo.title')}</p>
            </div>
            <div className="popupContainerContent accessoireInfo">
              <TbId size={30} style={{ marginLeft: -4 }} color="var(--text)" />
              <b>{content[0].title}</b>
              <p>{content[0].description}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
