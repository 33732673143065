import { useCallback, useEffect, useMemo, useState } from 'react'
import CalendarView from './view/CalendarView'
import CalendarSidebar from './sidebar/CalendarSidebar'
import './UserCalendarOverview.css'
import {
  addMonths,
  isSameDay,
  isWithinInterval,
  parseISO,
  startOfMonth,
  isAfter,
} from 'date-fns'
import { useAuth } from '../../../../hooks/use-auth'
import useWindowWidth from '../../../../hooks/useWindowWidth'
import MobileCalendarView from './mobile/MobileCalendarView'
import { instance } from '../../../../util/axios-instance'
import Loading from 'components/constants/Loading'

export default function UserCalendarOverview() {
  const [isAvailable, setIsAvailable] = useState<any>(null)
  const [calendarDateData, setCalendarDateData] = useState([])
  const [activeTrailer, setActiveTrailer] = useState('')
  const windowWidth = useWindowWidth()
  const [selectedRange, setSelectedRange] = useState({
    startDate: null,
    endDate: null,
  })
  const [currentMonthInView, setCurrentMonthInView] = useState(
    startOfMonth(new Date())
  )
  const [initialMonth] = useState(startOfMonth(new Date()))
  const { user } = useAuth()
  const [reservationsData, setReservationsData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const trailerReservations = reservationsData?.filter(
    (item) => item.trailer.id === activeTrailer
  )
  const upcoming = trailerReservations?.filter((item) =>
    isAfter(parseISO(item.endTime), new Date())
  )
  const [trailerInfo, setTrailerInfo] = useState<any>([])

  useEffect(() => {
    if (activeTrailer && activeTrailer) {
      instance
        .get(`/traileroffers/${activeTrailer}`)
        .then((response) => {
          setTrailerInfo(response.data)
        })
        .catch((error) => {
          console.error('Failed to fetch trailer information:', error)
        })
    }
  }, [activeTrailer])

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        const response = await instance.request({
          method: 'get',
          url: `/reservations?ownerId=${user?.id}`,
        })
        setReservationsData(response.data.content)
        setIsLoading(false)
      } catch (error) {
        console.error(error)
      }
    }

    if (user?.id) {
      fetchData()
    }
  }, [user?.id])

  const monthsInView = useMemo(
    () => Array.from({ length: 13 }, (_, i) => addMonths(initialMonth, i)),
    [initialMonth]
  )

  useEffect(() => {
    setCalendarDateData(trailerInfo.trailerDaySchedules)
  }, [trailerInfo])

  const findDateData = useCallback(
    (day: Date) => {
      return calendarDateData?.find((dayData) =>
        isSameDay(parseISO(dayData?.date), day)
      )
    },
    [calendarDateData]
  )

  function getActiveReservations(day) {
    return upcoming?.filter((reservation) => {
      const startDate = new Date(reservation.startTime)
      const endDate = new Date(reservation.endTime)
      return (
        isWithinInterval(day, { start: startDate, end: endDate }) ||
        isSameDay(day, startDate)
      )
    })
  }

  if (!user || isLoading) return <Loading />
  return (
    <>
      {windowWidth > 1150 ? (
        <div className="calendarOverViewContainer">
          <div className="calendarOverviewActions">
            <CalendarSidebar
              selectedRange={selectedRange}
              setSelectedRange={setSelectedRange}
              user={user}
              findDateData={findDateData}
              setActiveTrailer={setActiveTrailer}
              monthsInView={monthsInView}
              getActiveReservations={getActiveReservations}
              trailerInfo={trailerInfo}
              isAvailable={isAvailable}
              setIsAvailable={setIsAvailable}
            />
          </div>
          <div className="calendarViewGridContainer">
            <CalendarView
              selectedRange={selectedRange}
              setSelectedRange={setSelectedRange}
              monthsInView={monthsInView}
              setCurrentMonthInView={setCurrentMonthInView}
              findDateData={findDateData}
              currentMonthInView={currentMonthInView}
              getActiveReservations={getActiveReservations}
              upcoming={upcoming}
              trailerInfo={trailerInfo}
              isAvailable={isAvailable}
              setIsAvailable={setIsAvailable}
            />
          </div>
        </div>
      ) : (
        <MobileCalendarView
          selectedRange={selectedRange}
          monthsInView={monthsInView}
          setSelectedRange={setSelectedRange}
          findDateData={findDateData}
          user={user}
          setCurrentMonthInView={setCurrentMonthInView}
          setActiveTrailer={setActiveTrailer}
          currentMonthInView={currentMonthInView}
          getActiveReservations={getActiveReservations}
          upcoming={upcoming}
          trailerInfo={trailerInfo}
          isAvailable={isAvailable}
          setIsAvailable={setIsAvailable}
        />
      )}
    </>
  )
}
