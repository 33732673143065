import React, { useEffect, useState, useMemo } from 'react'
import './RecentRentals.css'
import RecentRentalsTrailer from './RecentRentalsTrailer'
import useAxios from '../../../../hooks/use-axios'
import NoResults from './NoResults'
import { TbChevronLeft, TbChevronRight } from 'react-icons/tb'
import SavedTrailerContent from '../savedTrailers/SavedTrailersContent'
// import ReviewOverview from '../reviews/ReviewOverview'
import useWindowWidth from 'hooks/useWindowWidth'
import MobileProfileInfo from '../mobile/MobileProfileInfo'
import { Alert, IconButton } from '@mui/material'

const DESKTOP_WIDTH = 768

export default function RecentRentals({
  user,
  contentShowing,
  setContentShowing,
  setShowReservation,
  setShowSettings,
}) {
  const windowWidth = useWindowWidth()
  const [activeTrailers, setActiveTrailers] = useState([])
  const { response, loading, error } = useAxios({
    method: 'get',
    url: `/reservations?renterId=${user.id}`,
  })

  const today = useMemo(() => new Date(), [])

  const upcomingTrailers = useMemo(() => {
    return activeTrailers[0]?.content.filter(
      (item) => new Date(item.startTime) > today
    )
  }, [activeTrailers, today])

  const previousTrailers = useMemo(() => {
    return activeTrailers[0]?.content.filter(
      (item) => new Date(item.startTime) < today
    )
  }, [activeTrailers, today])

  useEffect(() => {
    if (response) {
      setActiveTrailers([response])
    }
  }, [response])

  if (loading) return <p>Loading...</p>
  if (error) console.error(error)

  return (
    <div className="recentRentalsContainer">
      {/* Desktop View */}
      {windowWidth > DESKTOP_WIDTH ? (
        <div className="recentRentalsHeader main">
          <div className="mainInfoTagRow">
            <div
              className={
                contentShowing === 'upcoming'
                  ? 'contentTagActive'
                  : 'contentTag'
              }
              onClick={() => setContentShowing('upcoming')}
            >
              Opkomende reserveringen ({upcomingTrailers?.length})
            </div>
            {/*<div*/}
            {/*  className={*/}
            {/*    contentShowing === 'saved' ? 'contentTagActive' : 'contentTag'*/}
            {/*  }*/}
            {/*  onClick={() => setContentShowing('saved')}*/}
            {/*>*/}
            {/*  Opgeslagen*/}
            {/*</div>*/}
            {/*<div*/}
            {/*  className={*/}
            {/*    contentShowing === 'reviews' ? 'contentTagActive' : 'contentTag'*/}
            {/*  }*/}
            {/*  onClick={() => setContentShowing('reviews')}*/}
            {/*>*/}
            {/*  Reviews*/}
            {/*</div>*/}
          </div>
        </div>
      ) : (
        <>
          {contentShowing === '' && (
            <MobileProfileInfo
              setContentShowing={setContentShowing}
              setShowSettings={setShowSettings}
            />
          )}
        </>
      )}

      {contentShowing === 'upcoming' && (
        <div className="upcomingRentalsContainer">
          <Alert severity="error">
            Gedurende de pilot fase van BuurBak moeten betalingen nog
            zelfstandig tussen de huurder en verhuurder geregeld worden.
          </Alert>
          <div className="profileContentHeader">
            <IconButton
              className="backToProfile"
              onClick={() => setContentShowing('')}
            >
              <TbChevronLeft />
            </IconButton>
            <p>Opkomende reserveringen</p>
          </div>
          {upcomingTrailers?.map((trailer) => (
            <RecentRentalsTrailer
              key={trailer.id}
              trailer={trailer}
              setShowReservation={setShowReservation}
            />
          ))}
          {upcomingTrailers?.length <= 0 && <NoResults text="toekomstige" />}
        </div>
      )}
      {contentShowing === 'recent' && (
        <div className="upcomingRentalsContainer">
          <div className="profileContentHeader">
            <IconButton
              className="backToProfile"
              onClick={() => setContentShowing('')}
            >
              <TbChevronLeft />
            </IconButton>
            <p>Huugeschiedenis</p>
          </div>
          {previousTrailers?.map((trailer) => (
            <RecentRentalsTrailer
              key={trailer.id}
              trailer={trailer}
              setShowReservation={setShowReservation}
            />
          ))}
          {previousTrailers?.length <= 0 && <NoResults text="eerdere" />}
        </div>
      )}
      {/*{contentShowing === 'saved' && (*/}
      {/*  <SavedTrailerContent*/}
      {/*    user={user}*/}
      {/*    setContentShowing={setContentShowing}*/}
      {/*  />*/}
      {/*)}*/}
      {/*{contentShowing === 'reviews' && (*/}
      {/*  <ReviewOverview*/}
      {/*    user={user}*/}
      {/*    reviews={[]}*/}
      {/*    setContentShowing={setContentShowing}*/}
      {/*  />*/}
      {/*)}*/}
    </div>
  )
}
