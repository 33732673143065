import { IconButton } from '@mui/material'
import { TbFileAlert, TbX } from 'react-icons/tb'
import './Error.css'
import { useEffect } from 'react'

type ErrorProps = {
  setFileRejections: (fileRejections: []) => void
}

export default function Error({ setFileRejections }: ErrorProps) {
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setFileRejections([])
    }, 5000)
    return () => clearTimeout(timeoutId)
  }, [setFileRejections])

  return (
    <div className="errorContainer">
      <TbFileAlert size="30px" color="var(--error)" />
      <div className="errorMessageContainer">
        <b>Fout bestands type</b>
        <p>Alleen bestanden van een .png of .jpg type worden geaccepteerd</p>
      </div>
      <IconButton className="closeError" onClick={() => setFileRejections([])}>
        <TbX />
      </IconButton>
    </div>
  )
}
