import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  CarouselContext,
  Dot,
} from 'pure-react-carousel'
import '../../../pages/styles/TrailerProfile.css'
import 'pure-react-carousel/dist/react-carousel.es.css'
import { Flex } from '../../ui'
import { useContext, useEffect, useState } from 'react'

interface TrailerPhotosDialogProps {
  images: Array<string>
  currentSlide: number
  setCurrentSlide: (index: number) => void
}

const TrailerPhotosDialog = ({
  images,
  currentSlide,
  setCurrentSlide,
  ...props
}: TrailerPhotosDialogProps) => {
  return (
    <CarouselProvider
      naturalSlideWidth={16}
      currentSlide={currentSlide}
      naturalSlideHeight={9}
      totalSlides={images.length}
      infinite={true}
    >
      <Slider>
        {images.map((image, index) => {
          return (
            <Slide key={index} index={index} className="carousel__slide">
              <img src={image} className="carousel__image" />
            </Slide>
          )
        })}
      </Slider>
      <TrailerPhotosDialogFooter />
      <Flex gap={12} className="carousel__dot-group">
        {images.map((image, index) => {
          return (
            <Dot key={index} slide={index} className="carousel__dot">
              <img src={image} className="carousel__dot-image" />
            </Dot>
          )
        })}
      </Flex>
    </CarouselProvider>
  )
}

interface CarouselContextState {
  state: {
    currentSlide: number
    totalSlides: number
  }
  subscribe: (callback: () => void) => void
  unsubscribe: (callback: () => void) => void
}

const TrailerPhotosDialogFooter = () => {
  const carouselContext = useContext(CarouselContext) as CarouselContextState

  const [currentSlide, setCurrentSlide] = useState(
    carouselContext.state.currentSlide
  )

  useEffect(() => {
    function onChange() {
      setCurrentSlide(carouselContext.state.currentSlide)
    }
    carouselContext.subscribe(onChange)
    return () => carouselContext.unsubscribe(onChange)
  }, [carouselContext])

  useEffect(() => {
    const dotGroup = document.querySelector<HTMLElement>('.carousel__dot-group')
    const dotElement = document.querySelector<HTMLElement>('.carousel__dot')

    const scrollPosition: number =
      (dotElement.offsetWidth + 12) * carouselContext.state.currentSlide

    dotGroup.scrollTo(scrollPosition, 0)
  }, [carouselContext.state.currentSlide])

  return (
    <Flex
      justifyContent="spaceBetween"
      alignItems="center"
      className="carousel__footer__group"
    >
      <ButtonBack className={`carousel__button__back`}>Terug</ButtonBack>
      <div className="carousel__stats">
        {`${currentSlide + 1} / ${carouselContext.state.totalSlides}`}
      </div>
      <ButtonNext className={`carousel__button__next`}>Volgende</ButtonNext>
    </Flex>
  )
}

export { TrailerPhotosDialog }
