import { useAuth } from 'hooks/use-auth'
import { electronicFormatIBAN, isValidIBAN } from 'ibantools'
import { useRef, useState } from 'react'
import { TbBuildingBank, TbCash, TbChevronRight } from 'react-icons/tb'
import { instance } from 'util/axios-instance'
import './PayoutMethod.css'

export default function PayoutMethod({ user }) {
  const { iban: userIban = '' } = user
  const [showAddBankDetails, setShowAddBankDetails] = useState(false)
  const [iban, setIban] = useState(userIban)
  const [ibanError, setIbanError] = useState('')
  const bankDetailsRef = useRef(null)
  const { refreshUser } = useAuth()

  const setFirstFocus = () => bankDetailsRef.current.focus()

  const formatIBAN = (iban) =>
    iban
      .replace(/[^a-z0-9]/gi, '')
      .toUpperCase()
      .replace(/(.{4})/g, '$1 ')
      .trim()

  const handleIbanChange = (e) => {
    const rawIban = e.target.value
    const formattedIban = electronicFormatIBAN(rawIban.replace(/\s/g, ''))
    setIban(rawIban)
    setIbanError(
      isValidIBAN(formattedIban) ? null : '*Voer een geldig rekeningnummer in'
    )
  }

  const handleIbanInput = (e) => (e.target.value = formatIBAN(e.target.value))

  const updateProfileIban = async () => {
    if (!ibanError && user) {
      const { id, ...rest } = user
      await instance.put(`/customers/${id}`, { ...rest, iban })
      refreshUser()
    }
  }

  const isButtonDisabled = () => !iban || ibanError || iban === userIban

  const renderButtonText = () => (userIban ? 'wijzigen' : 'toevoegen')

  const renderIbanPlaceholder = () => userIban || 'NL00 ABCD 0123 4567 89'

  const renderCurrentPayoutMethod = () =>
    userIban || 'Geen uitbetalingsmethode ingesteld'

  return (
    <div className="aboutReservationContent settings">
      {showAddBankDetails ? (
        <>
          <button
            className="grayBackBtn black"
            style={{ marginTop: 20, marginBottom: -10 }}
            onClick={() => setShowAddBankDetails(false)}
          >
            Terug
          </button>
          <span style={{ marginBottom: 0 }}>Bankrekeningnummer toevoegen</span>
          <p>Voeg een geldig bankrekening nummer toe aan je account </p>
          <div className="inputSpanContainer" onClick={setFirstFocus}>
            <span style={{ marginBottom: 0 }}>IBAN</span>
            <input
              type="text"
              placeholder={renderIbanPlaceholder()}
              value={iban}
              ref={bankDetailsRef}
              onChange={handleIbanChange}
              onInput={handleIbanInput}
            />
          </div>
          {ibanError && <p className="error">{ibanError}</p>}
          <button
            className={`saveChangesBtn ${isButtonDisabled() && 'disabledBtn'}`}
            disabled={Boolean(isButtonDisabled())}
            onClick={() => {
              updateProfileIban()
              setShowAddBankDetails(false)
            }}
          >
            Opslaan
          </button>
        </>
      ) : (
        <>
          <p>
            Voeg een uitbetalingsmethode toe aan je account zodat wij weten waar
            je geld naartoe moet.
          </p>
          <span>Huidig ingestelde uitbetalingsmethode</span>
          <div className="currentPayoutMethod">
            <TbBuildingBank />
            <p>{renderCurrentPayoutMethod()}</p>
          </div>
          <span>Uitbetalingsmethode {renderButtonText()}</span>
          <div
            className="sidebarShortcutItem minPad"
            onClick={() => setShowAddBankDetails(true)}
          >
            <TbCash className="sidebarIcon" />
            <p>Uitbetalingsmethode {renderButtonText()}</p>
            <TbChevronRight />
          </div>
        </>
      )}
    </div>
  )
}
