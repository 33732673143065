import { useCallback } from 'react'

// Define your custom hook
const useGeneralTrailerPrice = (data) => {
  const calculateMostFrequentPrice = useCallback(() => {
    const prices = []
    for (const day in data) {
      prices.push(data[day].price)
    }

    const priceFrequency = prices.reduce((acc, curr) => {
      if (typeof acc[curr] == 'undefined') {
        acc[curr] = 1
      } else {
        acc[curr] += 1
      }
      return acc
    }, {})

    let maxCount = 0
    let mostFrequentPriceValue = null
    for (const price in priceFrequency) {
      if (priceFrequency[price] > maxCount) {
        maxCount = priceFrequency[price]
        mostFrequentPriceValue = price
      }
    }

    return mostFrequentPriceValue
  }, [data])

  return calculateMostFrequentPrice()
}

export default useGeneralTrailerPrice
