import { IconButton } from '@mui/material'
import { TransportTrailerDouble } from 'data/icons/TrailerIcons'
import { useState } from 'react'
import {
  TbAlertTriangle,
  TbArrowBackUp,
  TbBan,
  TbChevronRight,
  TbInfoCircle,
  TbSourceCode,
  TbTicket,
  TbUser,
  TbUserCircle,
  TbWriting,
  TbX,
} from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import AboutOwner from './content/AboutOwner'
import AboutReservation from './content/AboutReservation'
import Cancel from './content/ReservationCancel'
import Help from './content/ReservationHelp'
import './MyReservationPopup.css'

export default function MyReservationPopup({
  showReservation,
  setShowReservation,
}) {
  const [contentInView, setContentInView] = useState('')
  const navigate = useNavigate()

  const renderSidebarItem = (label, Icon, onClick) => (
    <div className="sidebarShortcutItem minPad reservations" onClick={onClick}>
      <Icon className="sidebarIcon reservation" />
      <p>{label}</p>
      <TbChevronRight />
    </div>
  )

  const getContent = () => {
    switch (contentInView) {
      case 'aboutReservation':
        return <AboutReservation showReservation={showReservation} />
      case 'aboutOwner':
        return <AboutOwner showReservation={showReservation} />
      case 'cancel':
        return (
          <Cancel
            showReservation={showReservation}
            setShowReservation={setShowReservation}
          />
        )
      // case 'damage':
      // case 'review':
      //   return <ComingSoon />
      case 'help':
        return <Help />
      default:
        return null
    }
  }

  return (
    <div className="blurContainer">
      <div className="blur" onClick={() => setShowReservation({})} />
      <div className="reservationPopupContainer main">
        <div className="reservationPopupContainerHeader">
          <IconButton
            className="closeBtn"
            onClick={() => setShowReservation({})}
          >
            <TbX />
          </IconButton>
          {contentInView && (
            <IconButton
              className="previousStepBtn"
              onClick={() => setContentInView('')}
            >
              <TbArrowBackUp />
            </IconButton>
          )}
          <p>Reservering {showReservation?.trailer?.trailerType?.name}</p>
        </div>
        {contentInView === '' ? (
          <div className="reservationPopupContainerMainContent">
            <span style={{ marginBottom: 0 }}>Over de reservering</span>
            {renderSidebarItem('Bekijk aanhanger', TransportTrailerDouble, () =>
              navigate(`/aanbod/${showReservation.trailer.id}`)
            )}
            {renderSidebarItem('Gegevens verhuurder', TbUser, () =>
              setContentInView('aboutOwner')
            )}
            <div className="divider fullWidth" />
            <span style={{ marginBottom: 0 }}>Acties</span>
            {renderSidebarItem('Annuleren', TbBan, () =>
              setContentInView('cancel')
            )}
            <div className="divider fullWidth" />
            {renderSidebarItem('Hulp', TbInfoCircle, () =>
              setContentInView('help')
            )}
          </div>
        ) : (
          getContent()
        )}
      </div>
    </div>
  )
}

function ComingSoon() {
  return (
    <div className="aboutReservationContent">
      <TbSourceCode size={32} strokeWidth="1.5" color="var(--primary)" />
      <p>
        Tijdens de pilot is deze functionaliteit helaas nog niet beschikbaar,
        maar niet getreurd! Het BuurBak development team werkt hard om dit toe
        te voegen.
      </p>
    </div>
  )
}
