import CalendarView from '../view/CalendarView'
import MobileCalendarSidebar from './MobileCalendarSidebar'
import './MobileCalendarView.css'

export default function MobileCalendarView({
  selectedRange,
  setSelectedRange,
  user,
  findDateData,
  monthsInView,
  setCurrentMonthInView,
  setActiveTrailer,
  currentMonthInView,
  getActiveReservations,
  upcoming,
  trailerInfo,
  isAvailable,
  setIsAvailable,
}) {
  return (
    <div>
      <div className="calendarViewMobileWrapper">
        <CalendarView
          selectedRange={selectedRange}
          setSelectedRange={setSelectedRange}
          monthsInView={monthsInView}
          setCurrentMonthInView={setCurrentMonthInView}
          findDateData={findDateData}
          currentMonthInView={currentMonthInView}
          getActiveReservations={getActiveReservations}
          upcoming={upcoming}
          trailerInfo={trailerInfo}
          isAvailable={isAvailable}
          setIsAvailable={setIsAvailable}
        />
        {(!!selectedRange.startDate || !!selectedRange.endDate) && (
          <MobileCalendarSidebar
            user={user}
            selectedRange={selectedRange}
            findDateData={findDateData}
            setSelectedRange={setSelectedRange}
            setActiveTrailer={setActiveTrailer}
            monthsInView={monthsInView}
            getActiveReservations={getActiveReservations}
            trailerInfo={trailerInfo}
            isAvailable={isAvailable}
            setIsAvailable={setIsAvailable}
          />
        )}
      </div>
    </div>
  )
}
