import { useState, useEffect, createRef, useRef } from 'react'
import {
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  endOfWeek,
  startOfWeek,
  isWithinInterval,
  format,
  isToday,
  isAfter,
  isBefore,
  addMonths,
} from 'date-fns'
import { nl } from 'date-fns/locale'
import CalendarGrid from './CalendarGrid'
import './CalendarView.css'
import CalendarMonthHeader from './CalendarMonthHeader'
import useWindowWidth from '../../../../../hooks/useWindowWidth'

export default function CalendarView({
  selectedRange,
  setSelectedRange,
  currentMonthInView,
  monthsInView,
  setCurrentMonthInView,
  findDateData,
  getActiveReservations,
  upcoming,
  trailerInfo,
  isAvailable,
  setIsAvailable,
}) {
  const [showSelectMonthOptions, setShowSelectMonthOptions] = useState(false)
  const [selectableMonths, setSelectableMonths] = useState([])
  const [isMouseDown, setIsMouseDown] = useState(false)
  const [initialDate, setInitialDate] = useState(null)
  const windowWidth = useWindowWidth()
  const calendarViewContainerRef = useRef(null)
  const monthRefs = useRef([])

  useEffect(() => {
    monthRefs.current = monthRefs.current.slice(0, monthsInView.length)
    for (let i = 0; i < monthsInView.length; i++) {
      if (!monthRefs.current[i]) {
        monthRefs.current[i] = createRef()
      }
    }
  }, [monthsInView])

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = calendarViewContainerRef.current.scrollTop + 100
      let cumulativeHeight = 0
      let monthIndex = 0

      for (let i = 0; i < monthsInView.length; i++) {
        cumulativeHeight += monthRefs.current[i].current.offsetHeight
        if (scrollPosition < cumulativeHeight) {
          monthIndex = i
          break
        }
      }

      setCurrentMonthInView(monthsInView[monthIndex])
    }

    const container = calendarViewContainerRef.current
    container.addEventListener('scroll', handleScroll)

    return () => {
      container.removeEventListener('scroll', handleScroll)
    }
  }, [monthsInView])

  useEffect(() => {
    setSelectableMonths(generateSelectableMonths())
  }, [])

  const handleMouseDown = (day) => {
    if (isToday(day) || isAfter(day, new Date())) {
      setIsMouseDown(true)
      setInitialDate(day)
      setSelectedRange({ startDate: day, endDate: day })
    }
  }

  const handleMouseEnter = (day) => {
    if (isMouseDown && (isToday(day) || isAfter(day, new Date()))) {
      setSelectedRange(createNewRange(day, initialDate))
    }
  }

  const handleMouseUp = () => {
    setIsMouseDown(false)
    setInitialDate(null)
  }

  return (
    <div className="calendarViewWrapper">
      <CalendarMonthHeader
        currentMonthInView={currentMonthInView}
        showSelectMonthOptions={showSelectMonthOptions}
        setShowSelectMonthOptions={setShowSelectMonthOptions}
        selectableMonths={selectableMonths}
        monthRefs={monthRefs}
      />
      <div className="calendarViewContainer" ref={calendarViewContainerRef}>
        {monthsInView.map((month, index) => (
          <div key={index} ref={monthRefs.current[index]}>
            <CalendarGrid
              today={new Date()}
              days={getMonthDays(month)}
              selectedRange={selectedRange}
              handleMouseDown={handleMouseDown}
              handleMouseEnter={handleMouseEnter}
              handleMouseUp={handleMouseUp}
              windowWidth={windowWidth}
              trailerInfo={trailerInfo}
              findDateData={findDateData}
              getActiveReservations={getActiveReservations}
              upcoming={upcoming}
              isAvailable={isAvailable}
              setIsAvailable={setIsAvailable}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

function generateSelectableMonths() {
  const currentMonth = new Date()
  const monthList = Array.from({ length: 13 }, (_, i) =>
    format(addMonths(currentMonth, i), 'MMMM yyyy', {
      locale: nl,
    })
  )
  return monthList
}

function createNewRange(day, initialDate) {
  if (isBefore(day, initialDate)) {
    return { startDate: day, endDate: initialDate }
  } else {
    return { startDate: initialDate, endDate: day }
  }
}

function getMonthDays(month) {
  const adjustedStart = startOfWeek(startOfMonth(month), {
    weekStartsOn: 1,
  })
  const adjustedEnd = endOfWeek(endOfMonth(month), { weekStartsOn: 1 })

  const allDays = eachDayOfInterval({
    start: adjustedStart,
    end: adjustedEnd,
  })

  const currentMonthDays = allDays.filter((day) =>
    isWithinInterval(day, {
      start: startOfMonth(month),
      end: endOfMonth(month),
    })
  )

  return currentMonthDays
}
