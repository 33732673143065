export default function Handle({ handle: { id, percent }, getHandleProps }) {
  return (
    <div
      style={{
        left: `${percent}%`,
        position: 'absolute',
        marginLeft:
          id === '$$-0' || id === '$$-2' || id === '$$-4' ? '-3.3px' : '-2px',
        transform: 'translateY(-50%)',
        top: '50%',
        zIndex: 2,
        width: 8,
        height: 40,
        border: 0,
        borderRadius: '50px',
        backgroundColor: 'var(--secondary)',
        color: 'var(--white)',
        cursor: 'pointer',
      }}
      {...getHandleProps(id)}
    ></div>
  )
}
