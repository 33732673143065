import React from 'react'
import '../layout.css'

export interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {}

export const Container = ({
  children,
  className,
  ...props
}: ContainerProps): React.ReactElement => {
  return <div className={`container ${className}`}>{children}</div>
}
