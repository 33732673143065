import { Button } from '../../../../ui'
import { Link } from 'react-router-dom'

export default function Help() {
  return (
    <div className="aboutReservationContent" style={{ paddingBottom: 30 }}>
      <span>Over de reservering</span>
      <p>
        In dit gedeelte vind je alle belangrijke informatie met betrekking tot
        je reservering. Details zoals de specificaties van de gehuurde
        aanhanger, de datums en tijden van de reservering, de locatie van de
        aanhanger, en de kosten van de huur zijn hier inbegrepen. Zorg ervoor
        dat je deze gegevens controleert voordat je je reservering bevestigt om
        mogelijke misverstanden te voorkomen.
      </p>
      <span>Over de verhuurder</span>
      <p>
        Dit gedeelte geeft je inzicht in de verhuurder van de aanhanger. Je
        vindt hier de algemene beoordeling van de verhuurder, gebaseerd op de
        recensies van eerdere huurders, evenals enige achtergrondinformatie die
        de verhuurder heeft gedeeld. Het is altijd een goed idee om de
        beoordelingen van de verhuurder te bekijken om te weten wat je kunt
        verwachten.
      </p>
      <span>Annuleren</span>
      <p>
        Als je je reservering om welke reden dan ook moet annuleren, dan is dit
        de plek waar je dat kunt doen. Je vindt hier alle informatie over het
        annuleringsproces en het annuleringsbeleid van BuurBak. Vergeet niet dat
        het annuleringsbeleid van toepassing is vanaf het moment dat je de
        reservering maakt. Lees het dus zorgvuldig door voordat je een
        reservering annuleert.
      </p>
      {/*<span>Schade melden</span>*/}
      {/*<p>*/}
      {/*  Helaas is deze functionaliteit nog niet inbegrepen in het platform maar*/}
      {/*  het BuurBak development team werkt hard om deze toe te voegen.*/}
      {/*</p>*/}
      <span>Review plaatsen</span>
      <p>
        Helaas is deze functionaliteit nog niet inbegrepen in het platform maar
        het BuurBak development team werkt hard om deze toe te voegen.
      </p>
      <span>Nog andere vragen?</span>
      <p>
        Niet de informatie gevonden die je zocht? Bekijk de veelgestelde vragen,
        of neem contact met ons op via de contact pagina!
      </p>
      <Button style={{ marginTop: '16px', marginBottom: '16px' }} asChild>
        <Link to={'/contact'}>Naar contact pagina</Link>
      </Button>
    </div>
  )
}
