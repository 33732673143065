import { useEffect, useRef, useState } from 'react'
import { TbChevronDown, TbChevronUp } from 'react-icons/tb'
import './TrailerTableHeader.css'

export default function TrailerTableHeader({
  setColumnWidths,
  onSort,
  onCreatedAtSort,
  sortColumn,
  sortOrder,
  onPriceSort,
}) {
  const gridRef = useRef(null)
  const columnWidths = useTableColumnWidths(gridRef)

  useEffect(() => {
    setColumnWidths(columnWidths)
  }, [columnWidths, setColumnWidths])

  return (
    <div className="tableHeaderContainer reservations">
      <div className="tableHeaderContainerGrid" ref={gridRef}>
        <div className="tableHeaderCategory" onClick={onSort}>
          <div
            className="checkbox"
            style={{ marginRight: 20, marginLeft: 0 }}
          />
          <p>Aanhanger</p>
          {sortColumn === 'trailerType.name' && sortOrder === 'asc' ? (
            <div>
              <TbChevronUp color="var(--primary)" />
            </div>
          ) : (
            <div>
              <TbChevronDown
                color={
                  sortColumn === 'trailerType.name' ? 'var(--primary)' : null
                }
              />
            </div>
          )}
        </div>
        <div className="tableHeaderCategory" onClick={onCreatedAtSort}>
          <p>Aangemaakt</p>
          {sortColumn === 'createdAt' && sortOrder === 'asc' ? (
            <div>
              <TbChevronUp color="var(--primary)" />
            </div>
          ) : (
            <div>
              <TbChevronDown
                color={sortColumn === 'createdAt' ? 'var(--primary)' : null}
              />
            </div>
          )}
        </div>
        <div className="tableHeaderCategory">
          <p>Locatie</p>
        </div>
        <div className="tableHeaderCategory" onClick={onPriceSort}>
          <p>Prijs</p>
          {sortColumn === 'price' && sortOrder === 'asc' ? (
            <div>
              <TbChevronUp color="var(--primary)" />
            </div>
          ) : (
            <div>
              <TbChevronDown
                color={sortColumn === 'price' ? 'var(--primary)' : null}
              />
            </div>
          )}
        </div>
        <div className="tableHeaderCategory">
          <p>Status</p>
        </div>
        {/*<div className="tableHeaderCategory">*/}
        {/*  <p>Schade</p>*/}
        {/*</div>*/}
        <div className="tableHeaderCategory">
          <p>Acties</p>
        </div>
      </div>
    </div>
  )
}

const useTableColumnWidths = (ref) => {
  const [columnWidths, setColumnWidths] = useState([])

  const updateColumnWidths = () => {
    if (ref.current) {
      const columns = Array.from(ref.current.children) as HTMLElement[]
      if (columns.length > 0) {
        setTimeout(() => {
          const widths = columns.map(
            (column) => column.getBoundingClientRect().width
          )
          setColumnWidths(widths)
        }, 100)
      }
    }
  }

  useEffect(() => {
    updateColumnWidths()

    const handleResize = () => {
      updateColumnWidths()
    }

    const mutationObserver = new MutationObserver(() => {
      updateColumnWidths()
    })

    if (ref.current) {
      window.addEventListener('resize', handleResize)
      mutationObserver.observe(ref.current, { childList: true, subtree: true })
    }

    return () => {
      if (ref.current) {
        window.removeEventListener('resize', handleResize)
        mutationObserver.disconnect()
      }
    }
  }, [ref])

  return columnWidths
}
