import { IconButton } from '@mui/material'
import {
  TrailerCapacity,
  TrailerHeightDouble,
  TrailerLengthDouble2,
  TrailerWeightDouble,
  TrailerWidth2,
} from 'data/icons/TrailerIcons'
import { TbPoint, TbX } from 'react-icons/tb'
import './TrailerPreview.css'

export default function TrailerPreview({
  setShowPreview,
  trailerType,
  description,
  trailerImages,
  trailerStats,
  price,
  trailerAccessoires,
  address,
  t,
}) {
  return (
    <div>
      <div className="backgroundBlur" onClick={() => setShowPreview(false)} />
      <div className="previewContainer">
        <div className="previewHeader">
          <p>{t('preview.title')}</p>
          <IconButton
            className="closePreviewButton"
            onClick={() => setShowPreview(false)}
          >
            <TbX />
          </IconButton>
        </div>
        <div className="previewContainerContent">
          <div className="previewImagesSlider">
            {trailerImages.map((img: any, index: number) => (
              <div className="previewImage" key={index}>
                <img alt="trailer" src={img.preview} />
              </div>
            ))}
          </div>
          <div className="previewContainerInformation">
            <div className="previewContainerInformationItem">
              <p>{trailerType}</p>
              <p>
                {address.streetName}, {address.city}
              </p>
            </div>
            <div className="previewContainerPrice">
              <p>€{price},00</p>
            </div>
          </div>
          <div className="divider preview" />
          <div className="trailerPreviewStats">
            <div className="trailerPreviewStatItem">
              <TrailerLengthDouble2 size={18} />
              <p>
                {t('preview.length')} {trailerStats.length}cm
              </p>
            </div>
            <div className="trailerPreviewStatItem">
              <TrailerWidth2 size={18} />
              <p>
                {t('preview.width')} {trailerStats.width}cm
              </p>
            </div>
            <div className="trailerPreviewStatItem">
              <TrailerHeightDouble size={18} />
              <p>
                {t('preview.height')} {trailerStats.height}cm
              </p>
            </div>
          </div>
          <div className="divider preview" />
          <p className="previewDescription">{description}</p>
          {trailerAccessoires.length > 0 && <div className="divider preview" />}
          <div className="trailerPreviewStats">
            {trailerAccessoires?.map(
              (item: { name: string }, index: number) => (
                <div className="trailerPreviewStatItem" key={index}>
                  <TbPoint />
                  <p style={{ marginLeft: 10 }}>{item.name}</p>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
