import { useEffect, useState } from 'react'
import './Availability.css'
import IsAvailable from './IsAvailable'
import IsNotAvailable from './IsNotAvailable'
import { TbCalendarPlus, TbCircleCheck, TbInfoCircle } from 'react-icons/tb'
import { Tooltip } from 'react-tooltip'
import AvailabilityOverview from './AvailabilityOverview'
import useWindowWidth from 'hooks/useWindowWidth'
import { useTranslation } from 'react-i18next'

export default function Availability({
  trailerWeekScheduleTemplate,
  setTrailerWeekScheduleTemplate,
  availability,
  setAvailability,
  activeRangesPerDay,
  setActiveRangesPerDay,
  activeRanges,
  setActiveRanges,
  defaultRanges,
}) {
  const { t } = useTranslation('TrailerForm')
  const [showOverview, setShowOverview] = useState(false)
  const [multiSelectActive, setMultiSelectActive] = useState(false)
  const windowWidth = useWindowWidth()
  const [timePeriods, setTimePeriods] = useState<any>([])

  const dayNamesMapping = {
    ma: {
      short: `${t('availability.daysShort.monday')}`,
      english: 'monday',
      index: 0,
    },
    di: {
      short: `${t('availability.daysShort.tuesday')}`,
      english: 'tuesday',
      index: 1,
    },
    woe: {
      short: `${t('availability.daysShort.wednesday')}`,
      english: 'wednesday',
      index: 2,
    },
    do: {
      short: `${t('availability.daysShort.thursday')}`,
      english: 'thursday',
      index: 3,
    },
    vri: {
      short: `${t('availability.daysShort.friday')}`,
      english: 'friday',
      index: 4,
    },
    za: {
      short: `${t('availability.daysShort.saturday')}`,
      english: 'saturday',
      index: 5,
    },
    zo: {
      short: `${t('availability.daysShort.sunday')}`,
      english: 'sunday',
      index: 6,
    },
  }

  const [selectedDays, setSelectedDays] = useState(
    ['ma'].map((key) => dayNamesMapping[key])
  )

  const daysOfWeek = Object.values(dayNamesMapping)

  const handleSelectedDays = (item) => {
    setSelectedDays((currentDays) => {
      if (multiSelectActive) {
        const isSelected = currentDays.find((day) => day.short === item.short)
        const updatedDays = isSelected
          ? currentDays.filter((day) => day.short !== item.short)
          : [...currentDays, item].sort((a, b) => a.index - b.index)
        return updatedDays
      } else {
        return [item]
      }
    })

    const activeRangesForDay = activeRangesPerDay[item.english]
    setActiveRanges(activeRangesForDay || defaultRanges)
  }

  useEffect(() => {
    setActiveRangesPerDay((currentRanges) => {
      let updatedActiveRangesPerDay = { ...currentRanges }
      selectedDays.forEach((day) => {
        updatedActiveRangesPerDay[day.english] = activeRanges
      })
      return updatedActiveRangesPerDay
    })
  }, [activeRanges, selectedDays])

  useEffect(() => {
    setTrailerWeekScheduleTemplate((currentSchedule) => {
      let updatedSchedule = { ...currentSchedule }

      selectedDays.forEach((day) => {
        updatedSchedule[day.english].timeSlots = []

        for (let i = 0; i < activeRanges?.length; i += 2) {
          let startHour = Math.floor(activeRanges[i])
          let startMinutes = (activeRanges[i] % 1) * 60

          let endHour = Math.floor(activeRanges[i + 1])
          let endMinutes = (activeRanges[i + 1] % 1) * 60

          const startTime =
            (startHour === 24
              ? '00'
              : startHour < 10
              ? `0${startHour}`
              : `${startHour}`) +
            ':' +
            (startMinutes === 30 ? '30' : '00') +
            ':00'

          const endTime =
            (endHour === 24
              ? '00'
              : endHour === 25
              ? '01'
              : endHour < 10
              ? `0${endHour}`
              : `${endHour}`) +
            ':' +
            (endMinutes === 30 ? '30' : '00') +
            ':00'

          updatedSchedule[day.english].timeSlots.push({
            startTime: startTime,
            endTime: endTime,
          })
        }
      })

      return updatedSchedule
    })
  }, [activeRanges, selectedDays])

  const handleMultiSelectClick = () => {
    if (!multiSelectActive) {
      const lastSelectedDay = selectedDays[selectedDays.length - 1]
      const nextDayIndex = (lastSelectedDay.index + 1) % daysOfWeek.length

      const nextDay = daysOfWeek.find((day) => day.index === nextDayIndex)
      setSelectedDays([nextDay])
    } else {
      const lastDay = daysOfWeek[daysOfWeek.length - 1]
      setSelectedDays([lastDay])
    }

    setMultiSelectActive((current) => !current)
  }

  return (
    <div
      className="formStepContainer"
      style={{
        minWidth: windowWidth < 680 ? null : '37vw',
        height: 'fit-content',
        paddingBottom: windowWidth > 680 ? 100 : 70,
      }}
    >
      <h2>{t('availability.title')}</h2>
      <p>{t('availability.introduction')}</p>
      <div className="availabilityContent">
        <div className="availabilityContentHeader">
          <p>{t('availability.subtitle')}</p>
          <div className="infoCircleContainer">
            <TbInfoCircle className="info" />
          </div>
        </div>
        <div className="availabilitySelectRow">
          {daysOfWeek.map((item, index) => {
            const dayTimeSlots =
              trailerWeekScheduleTemplate[item.english].timeSlots

            let className = 'availabilitySelectItem'
            if (selectedDays.find((day) => day.short === item.short)) {
              className += ' active'
            }

            if (dayTimeSlots.length > 0) {
              className += ' hasContent'
            }

            return (
              <div
                className={className}
                key={index}
                onClick={() => handleSelectedDays(item)}
              >
                {item.short}
                <div className="dash" />
                <TbCircleCheck />
              </div>
            )
          })}
          <button
            className={multiSelectActive ? 'multiSelect active' : 'multiSelect'}
            type="button"
            onClick={() => handleMultiSelectClick()}
            data-tooltip-id="multiSelect"
            data-tooltip-content="Pas de beschikbaarheid voor meerdere tagen telijk aan"
          >
            <TbCalendarPlus />
            <Tooltip id="multiSelect" />
          </button>
        </div>
      </div>
      <div className="divider" style={{ backgroundColor: 'transparent' }} />
      {selectedDays.length &&
      availability[selectedDays[selectedDays.length - 1].english] ? (
        <IsAvailable
          activeRanges={activeRanges}
          setActiveRanges={setActiveRanges}
          setShowOverview={setShowOverview}
          t={t}
          timePeriods={timePeriods}
          setTimePeriods={setTimePeriods}
        />
      ) : (
        <IsNotAvailable
          selectedDays={selectedDays}
          setShowOverview={setShowOverview}
          t={t}
        />
      )}
      {showOverview && (
        <AvailabilityOverview
          setShowOverview={setShowOverview}
          activeRangesPerDay={activeRangesPerDay}
          t={t}
        />
      )}
    </div>
  )
}
