import { Avatar, IconButton } from '@mui/material'
import { TrailerCategories } from 'data/dummy/TrailerTypes'
import { useEffect, useRef, useState } from 'react'
import { TbHeart, TbHeartFilled, TbMapPin } from 'react-icons/tb'
import { instance } from 'util/axios-instance'
import './MobileCard.css'

const coverImagesList = [
  'https://www.care4more.nl/sites/default/files/styles/small/public/2019-09/Duofiets%20aanhanger%201.jpg?itok=lV2CL0MF',
  'https://www.tralert.com/wp-content/uploads/2021/11/regels-verlichting-aanhanger.jpg',
  'https://www.aanhangerhuren-zwolle.nl/wp-content/uploads/2020/06/aanhanger-huren-zwolle-met-zeil.jpg',
  'https://www.care4more.nl/sites/default/files/styles/medium/public/2022-10/Aanhanger%20Easy%20Sport%20-%20Rider%201.jpg?itok=e5e1VICO',
  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSuoCySpo2Na5zXnea8XAli_qVn0rWJ_DSh0A&usqp=CAU',
  'https://titanjelsum.nl/wp-content/uploads/2019/02/Humbaur-HA102111-5-205x110x50cm-1000kg-geremd-deksel-imperial-6-640-x-480.jpg',
  'https://www.deboer-aanhangwagen.nl/thumb/?src=templates/images/uploads/231nn-10.jpg&size=494x329',
  'https://voskamphall.nl/t/wp-content/uploads/2016/02/aanhangwagen-verhuur-tandemasser-kipper-handpomp-3.jpg',
  'https://titanjelsum.nl/wp-content/uploads/2019/02/Hapert-Cobalt-HM-2-Ferro-305x180cm-30cm-opzetborden-24-1-450x450.jpg',
  'https://www.aanhangwagensderyck.be/Areas/Website/UploadData/235/TinyMce/Aanhangwagens/Kippers%20profit/Foto%2027-08-2020%2018%2047%2053.jpg',
  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRzH-QRuFG3QTsJ3jgyEWLNheP7q0MacKlaUQIy-q75Vimu3A0FMNgGvgSNgnyvU63pZgc&usqp=CAU',
  'https://www.nemaco.nl/storage/products/aggregaat-40-kva-aanhanger-model-241365-1200px-900px.webp',
  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5f0mZntK1nv2blitFyxiUE_4GY6HTuhxJ1A&usqp=CAU',
  'https://www.ezendam.nl/wp-content/uploads/2022/02/afbeelding-aanhanger.jpg',
  'https://www.jekuntmijhuren.nl/wp-content/uploads/2016/05/H-1-1000kg-305x160-huif-150cm-hoog-195-50R13-spiraal-r-wiel-slot-1-708x518.jpg',
  'https://www.konag.nl/wp-content/uploads/2018/07/Proline-Lithium-2-paardstrailer-2-407x271.jpg',
]

export default function MobileCard({ trailer, user, setShowLogin }) {
  const trailerImages = [coverImagesList[11], ...coverImagesList]
  const dots = [1, 2, 3, 4]
  const trailerIcon = TrailerCategories?.find(
    (item) => item.trailerType === trailer.trailerType.name
  )
  const [isSaved, setIsSaved] = useState(false)
  const [savedTrailers, setSavedTrailer] = useState([])
  const sliderRef = useRef(null)
  const [activeDot, setActiveDot] = useState(0)

  useEffect(() => {
    const handleScroll = () => {
      if (sliderRef.current) {
        const container = sliderRef.current
        const childWidth = container.firstChild.offsetWidth
        const activeIndex = Math.round(container.scrollLeft / childWidth)
        setActiveDot(activeIndex)
      }
    }

    if (sliderRef.current) {
      sliderRef.current.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (sliderRef.current) {
        sliderRef.current.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  useEffect(() => {
    if (user) {
      const response = instance.get('/savedtrailers')
      setSavedTrailer((response as any).content)
    }
  }, [user])

  useEffect(() => {
    setIsSaved(
      savedTrailers?.some((item) => item.trailerOfferId === trailer.id)
    )
  }, [savedTrailers, trailer.id])

  const changeSavedState = (e) => {
    if (user) {
      e.stopPropagation()
      e.preventDefault()

      const savedTrailerData = {
        userId: user.id,
        trailerOfferId: trailer.id,
      }

      if (isSaved) {
        setIsSaved(false)
        instance.delete(`/savedtrailers/${trailer.id}`)
      } else {
        setIsSaved(true)
        instance.post('/savedtrailers', savedTrailerData)
      }
    } else {
      setShowLogin(true)
    }
  }

  if (!trailer) return

  return (
    <div className="mobileTrailerCardContainer">
      <div className="mobileCardOwner flexbox">
        <Avatar className="mobileCardAvatar" />
        <p>{trailer.name}</p>
        <IconButton
          className="saveTrailerIconMob"
          onClick={(e) => changeSavedState(e)}
        >
          {isSaved ? <TbHeartFilled /> : <TbHeart />}
        </IconButton>
      </div>
      <div className="trailerImagesSliderMob" ref={sliderRef}>
        {trailerImages.slice(0, 4).map((image, index) => (
          <div className="mobileTrailerImagesContainer" key={index}>
            <img alt="trailerImages" src={image} />
            {activeDot === index && (
              <div
                className="mobilePriceLabel"
                style={{ backgroundImage: 'url(/images/price_tag.svg)' }}
              >
                <p>€50 per dag</p>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="dotsContainer flexbox">
        {dots.map((item, index) => (
          <div
            className={`dot ${activeDot === index ? 'active' : ''}`}
            key={item}
          />
        ))}
      </div>

      <div className="mobileCardFooterContainer flexbox">
        <div className="mobileCardFooterItem flexbox">
          {trailerIcon?.icon}
          <p>{trailer.trailerType.name}</p>
        </div>
        <div className="mobileCardFooterItem flexbox">
          <TbMapPin />
          <p>{trailer.cityAddress.city}</p>
        </div>
      </div>
    </div>
  )
}
