import Footer from '../components/constants/footer/Footer'
import ContactForm from '../components/contact/ContactForm'
import FAQ from '../components/contact/FAQ'
import { Flex } from '../components/ui'

export default function Contact() {
  return (
    <Flex
      xs="col"
      justifyContent="spaceBetween"
      style={{ height: '100vh', backgroundColor: 'red' }}
    >
      <ContactForm />
      <Footer />
    </Flex>
  )
}
