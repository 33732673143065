import { createContext, useState, useContext } from 'react'

const OverlayContext = createContext(null)

export const OverlayProvider = ({ children }) => {
  const [overlay, setOverlay] = useState(false)

  return (
    <OverlayContext.Provider value={{ overlay, setOverlay }}>
      {children}
      {overlay && <div className="pageContainer overlay">{overlay}</div>}
    </OverlayContext.Provider>
  )
}

export const useOverlay = () => useContext(OverlayContext)
