import { TbCalendarOff } from 'react-icons/tb'

export default function IsNotAvailable({ selectedDays, setShowOverview, t }) {
  return (
    <div className="trailerNotAvailableContent">
      <TbCalendarOff />
      <p>
        {t('availability.isNotAvailable.content')}
        {/* {selectedDays.length === 1 ? 'dag' : 'dagen'} (
        {selectedDays.map((item, index) => (
          <b key={index}>
            {item.short}
            {index !== selectedDays.length - 1 ? ', ' : ''}
          </b>
        ))}
        ) */}
      </p>
      <button
        className="borderBtn isNotAvailable"
        type="button"
        onClick={() => setShowOverview(true)}
      >
        {t('availability.isNotAvailable.action')}
      </button>
    </div>
  )
}
