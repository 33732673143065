import { Avatar, IconButton } from '@mui/material'
import { TrailerCategories } from 'data/dummy/TrailerTypes'
import { TransportTrailer } from 'data/icons/TrailerIcons'
import { parseISO, format } from 'date-fns'
import { nl } from 'date-fns/locale'
import useCheckTimestamp from 'hooks/withinCancelation'
import { useState } from 'react'
import {
  TbBan,
  TbCash,
  TbCheckupList,
  TbCircleCheck,
  TbClock,
  TbHourglass,
  TbHourglassEmpty,
  TbMail,
  TbMapPin,
  TbMessage,
  TbPhone,
  TbStars,
  TbUser,
  TbX,
} from 'react-icons/tb'
import { instance } from '../../../../../util/axios-instance'
import './Ticket.css'
import { useNavigate } from 'react-router-dom'

export default function Ticket({ activeReservation, setShowTicket }) {
  const navigate = useNavigate()

  const trailerIcon = TrailerCategories.filter(
    (item) =>
      item?.trailerType === activeReservation?.trailer?.trailerType?.name
  )

  const formatDate = (date, pattern) =>
    format(parseISO(date), pattern, { locale: nl })

  const isPeriod =
    formatDate(activeReservation.startTime, 'dd MMMM yyyy') !==
    formatDate(activeReservation.endTime, 'dd MMMM yyyy')

  const acceptReservationRequest = async () => {
    await instance
      .put(`/reservations/${activeReservation.id}/confirm`)
      .then((res) => {
        setShowTicket(false)
        window.location.reload()
      })
  }

  const denyReservationRequest = async () => {
    await instance
      .put(`/reservations/${activeReservation.id}/cancel`, {
        reason: '-',
        description: '-',
      })
      .then((res) => {
        setShowTicket(false)
        window.location.reload()
      })
  }

  const is12HoursPassed = useCheckTimestamp(
    activeReservation.canceledAt,
    activeReservation.confirmedAt
  )

  const TicketDetailItem = ({ icon, children }) => (
    <div className="ticketDetailItem">
      {icon}
      <p>{children}</p>
    </div>
  )

  return (
    <div className="blurContainer">
      <div className="blur" onClick={() => setShowTicket(false)} />
      <div className="reservationPopupContainer main">
        <div className="reservationPopupContainerHeader">
          <div className="ticketCardContent">
            <IconButton
              className="closeBtn reservationPopup"
              onClick={() => setShowTicket(false)}
            >
              <TbX />
            </IconButton>
            <div className="aboutUserCardWrapper">
              <div className="aboutOwnerCard">
                <div className="ticketPopupCardActions flexbox">
                  {activeReservation.confirmed === null && (
                    <>
                      <button
                        className="borderBtn"
                        onClick={() => denyReservationRequest()}
                      >
                        Afwijzen
                      </button>
                      <button
                        className="borderBtn secondary"
                        onClick={() => acceptReservationRequest()}
                      >
                        Goedkeuren
                      </button>
                    </>
                  )}
                  {activeReservation.confirmed !== null && (
                    <>
                      {activeReservation.confirmed ? (
                        <div className="iconGridItem ticket flexbox">
                          <TbCheckupList size={18} strokeWidth={1.5} />
                          <p>Huuraanvraag goedgekeurd</p>
                        </div>
                      ) : (
                        <div className="iconGridItem ticket denied flexbox">
                          <TbBan size={18} strokeWidth={1.5} />
                          <p>Huuraanvraag geweigerd</p>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ticketInfoContainer">
          <b>Over de huuraanvraag</b>
          <TicketDetailItem icon={<TbUser />}>
            {activeReservation.renter.name}
          </TicketDetailItem>
          <TicketDetailItem icon={<TbMail />}>
            {activeReservation.renter.email}
          </TicketDetailItem>
          <TicketDetailItem icon={<TbPhone />}>
            {activeReservation.renter.number}
          </TicketDetailItem>
          <TicketDetailItem icon={<TbHourglass />}>
            Ophalen:{' '}
            {formatDate(
              activeReservation.startTime,
              isPeriod ? 'dd MMMM' : 'dd MMMM yyyy'
            )}
          </TicketDetailItem>
          <TicketDetailItem icon={<TbHourglassEmpty />}>
            Terugbrengen:{' '}
            {formatDate(
              activeReservation.endTime,
              isPeriod ? 'dd MMMM' : 'dd MMMM yyyy'
            )}{' '}
          </TicketDetailItem>
          <TicketDetailItem icon={<TbMapPin />}>
            {`${activeReservation.trailer.address.city}, ${activeReservation.trailer.address.streetName} ${activeReservation.trailer.address.number}, ${activeReservation.trailer.address.postalCode}`}
          </TicketDetailItem>
          <TicketDetailItem icon={<TbCash />}>
            Dag prijs: €{activeReservation.price},00
          </TicketDetailItem>
          <TicketDetailItem icon={<TbMessage />}>
            {activeReservation.message}
          </TicketDetailItem>
        </div>
      </div>
    </div>
  )
}
