import { IconButton } from '@mui/material'
import { format, parseISO } from 'date-fns'
import { nl } from 'date-fns/locale'
import { TbArrowBackUp } from 'react-icons/tb'
import './MultipleTrailerSelect.css'

export default function MultipleTrailerSelect({
  windowWidth,
  setShowTrailerSelect,
  myTrailerList,
}) {
  return (
    <div className="calendarDayDetailsContent">
      {windowWidth > 744 && (
        <IconButton
          className="backToOverview"
          onClick={() => setShowTrailerSelect(false)}
        >
          <TbArrowBackUp />
        </IconButton>
      )}
      <div className="dayDetailsTimeSelectHeader nested">
        <span>Wijzig meerdere aanhangers</span>
        <p>Selecteer aanhangers voor welke je de gegevens wilt wijzigen.</p>
      </div>
      <div className="notAvailableSidebarContent infoBox">
        <p>
          Gedurende de geselecteerde periode, zullen de prijzen en beschikbare
          tijdsloten worden aangepast op de geselcteerde aanhangers.
        </p>
      </div>
      <div className="multipleTrailerSelectWrapper">
        {myTrailerList.map((item) => (
          <div className="multipleTrailerSelectItem" key={item.id}>
            <img alt="trailer" src={item.coverImage} />
            <div>
              <p>{item.trailerType.name}</p>
              <p>
                {format(parseISO(item.createdAt), 'dd MMMM yyyy', {
                  locale: nl,
                })}
              </p>
            </div>
            <div className="multiSelectCheckbox"></div>
          </div>
        ))}
      </div>
      <button className="primaryBtn sidebar">Aanhangers opslaan</button>
    </div>
  )
}
