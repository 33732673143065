import './ReservationTableListItem.css'
import { TrailerCategories } from '../../../../../data/dummy/TrailerTypes'
import { Avatar, IconButton } from '@mui/material'
import { TbCircleCheck, TbHourglass, TbTicket } from 'react-icons/tb'
import { format, isBefore, parseISO } from 'date-fns'
import { nl } from 'date-fns/locale'
import Loading from 'components/constants/Loading'

const formatDate = (date, pattern) =>
  format(parseISO(date), pattern, { locale: nl })

const getStatus = (confirmed, endTime) => {
  if (!confirmed)
    return <TbHourglass size={21} strokeWidth="1.5" color="var(--secondary)" />
  if (isBefore(parseISO(endTime), new Date())) return 'Voltooid'
  return <TbCircleCheck size={21} strokeWidth="1.5" color="var(--secondary)" />
}

export default function ReservationTableListItem({
  columnWidths,
  reservation,
  activeReservation,
  setActiveReservation,
  setShowTicket,
}) {
  const { id, startTime, endTime, renter, trailer, confirmed } = reservation

  const gridTemplateColumns = columnWidths
    .map((width) => `${width}px`)
    .join(' ')

  const trailerTypeIcon = TrailerCategories.find(
    (type) => type.trailerType === trailer.trailerType.name
  )

  const isPeriod =
    formatDate(startTime, 'dd MMMM yyyy') !==
    formatDate(endTime, 'dd MMMM yyyy')

  const isActive = activeReservation?.id === id

  const getClassName = (base, activeClass) =>
    `${base} ${isActive ? activeClass : ''}`

  if (!activeReservation) return

  return (
    <div
      className={getClassName(
        'trailerTableListItemContainer reservations',
        'active'
      )}
      style={{ gridTemplateColumns }}
      onClick={() => setActiveReservation(reservation)}
    >
      <div className="reservationTableListItem">
        <div
          className={getClassName('reservationTableListItemSelect', 'active')}
        >
          {isActive && (
            <div className="reservationTableListItemSelectIndicator" />
          )}
        </div>
        <Avatar src={renter?.photoUrl} className="reservationsTableAvatar" />
        <div className="reservationTableListItemInfo">
          <p>{trailer.name}</p>
          <p>{renter.name}</p>
        </div>
      </div>
      <div className="reservationTableListItem">
        {trailerTypeIcon?.icon}
        <p>{trailer.trailerType.name}</p>
      </div>
      <div className="reservationTableListItem">
        <p>€{reservation.price},00 per dag</p>
      </div>
      <div className="reservationTableListItem">
        <p>
          {formatDate(startTime, isPeriod ? 'dd MMM' : 'dd MMMM yyyy')}
          {isPeriod && ' - ' + formatDate(endTime, 'dd MMM yyyy')}
        </p>
      </div>
      {/*<div className="reservationTableListItem">*/}
      {/*  <p>{formatDate(startTime, 'HH:mm')}</p>*/}
      {/*</div>*/}
      {/*<div className="reservationTableListItem">*/}
      {/*  <p>{formatDate(endTime, 'HH:mm')}</p>*/}
      {/*</div>*/}
      <div className="reservationTableListItem">
        <p className={`status ${getStatus(confirmed, endTime)}`}>
          {getStatus(confirmed, endTime)}
        </p>
      </div>
      <div className="reservationTableListItem">
        <IconButton
          className="reservationTableListItemAction"
          onClick={() => setShowTicket(true)}
        >
          <TbTicket />
        </IconButton>
      </div>
    </div>
  )
}
