import { compareAsc, isAfter, isBefore, parseISO } from 'date-fns'
import useWindowWidth from 'hooks/useWindowWidth'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { instance } from '../../../../util/axios-instance'
import ReservationsTableHeader from './content/ReservationsTableHeader'
import ReservationTableListItem from './content/ReservationTableListItem'
import './ReservationsTable.css'
import ReservationsSidebar from './sidebar/ReservationsSidebar'

export default function ReservationsTable({ ownerReservations }) {
  const [columnWidths, setColumnWidths] = useState([])
  const [activeReservation, setActiveReservation] = useState<any>(null)
  const [showTicket, setShowTicket] = useState(false)
  const [reservationType, setReservationType] = useState('all')
  const [reservationsToShow, setReservationsToShow] = useState([])
  const location = useLocation()
  const windowWidth = useWindowWidth()

  const filterReservations = (type) => {
    if (!ownerReservations) return []
    switch (type) {
      case 'all':
        return ownerReservations
      case 'completed':
        return ownerReservations.filter(
          (item) => !isAfter(parseISO(item.endTime), new Date())
        )
      case 'requests':
        return ownerReservations.filter((item) => !item.confirmed)
      case 'upcoming':
        return ownerReservations.filter(
          (item) =>
            isAfter(parseISO(item.startTime), new Date()) &&
            item.confirmed !== null
        )
      default:
        return []
    }
  }

  useEffect(() => {
    if (!ownerReservations) return
    const params = new URLSearchParams(location.search)
    const reservation = params.get('reservation')
    const selectedReservation = ownerReservations.find(
      (item) => item.id === reservation
    )

    if (selectedReservation) {
      setActiveReservation(selectedReservation)
    } else {
      setActiveReservation(
        ownerReservations.length > 0 ? ownerReservations[0] : null
      )
    }

    setReservationsToShow(filterReservations(reservationType))
  }, [location, ownerReservations, reservationType])

  useEffect(() => {
    const reservationsToDelete = ownerReservations.filter(
      (item) => !item.confirmed && item.confirmed !== null
    )

    if (reservationsToDelete.length > 0) {
      reservationsToDelete.forEach((reservation) => {
        instance.delete(`/reservations/${reservation.id}`)
      })
    }
  }, [ownerReservations])

  return (
    <div
      className="trailerTableViewContainer"
      style={showTicket ? { zIndex: 99 } : null}
    >
      <div className="trailerTableGrid reservations">
        {windowWidth > 767 && (
          <ReservationsSidebar
            showTicket={showTicket}
            setShowTicket={setShowTicket}
            activeReservation={activeReservation}
            reservationType={reservationType}
            setReservationType={setReservationType}
            reservationsToShow={reservationsToShow}
            setActiveReservation={setActiveReservation}
          />
        )}
        <div>
          <ReservationsTableHeader setColumnWidths={setColumnWidths} />
          {reservationsToShow?.length > 0 ? (
            reservationsToShow
              .sort((a, b) =>
                compareAsc(parseISO(a.startTime), parseISO(b.startTime))
              )
              .map((reservation) => (
                <ReservationTableListItem
                  key={reservation.id}
                  reservation={reservation}
                  columnWidths={columnWidths}
                  activeReservation={activeReservation}
                  setActiveReservation={setActiveReservation}
                  setShowTicket={setShowTicket}
                />
              ))
          ) : (
            <div className="noListResults">
              <p>Er zijn geen gegevens gevonden om te weergeven</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
