import { useParams } from 'react-router-dom'
import useAxios from '../../hooks/use-axios'
import { TrailerOffer } from '../../types/TrailerOffer'
import Loading from '../../components/util/Loading'
import { Alert } from '@mui/material'
import useMediumBreakpoint from '../../hooks/use-medium-breakpoint'
import '../styles/TrailerProfile.css'
import { Container } from '../../components/ui'
import { TrailerHeader } from '../../components/trailers/trailerProfile/TrailerHeader'
import './trailerprofile.css'
import { TrailerContent } from '../../components/trailers/trailerProfile/TrailerContent'

export default function TrailerProfile() {
  const { id } = useParams()
  const {
    response: trailerOffer,
    error,
    loading,
  } = useAxios<TrailerOffer>({
    url: `/traileroffers/${id}`,
    method: 'get',
  })

  if (loading) return <Loading />
  if (error) return <Alert severity="error">{error.message}</Alert>

  return (
    <Container className="tprofile__container">
      <TrailerHeader trailerOffer={trailerOffer} />
      <TrailerContent trailerOffer={trailerOffer} />
    </Container>
  )
}
