import { useEffect, useState } from 'react'
import { isAfter, addHours, parseISO } from 'date-fns'

const useCheckTimestamp = (
  canceledAt: string | null,
  confirmedAt: string | null
) => {
  const [is12HoursPassed, setIs12HoursPassed] = useState(false)

  useEffect(() => {
    const currentTime = new Date()

    let canceledTime = canceledAt ? parseISO(canceledAt) : null
    let confirmedTime = confirmedAt ? parseISO(confirmedAt) : null

    let comparisonTime = null

    if (canceledTime && confirmedTime) {
      // Both are not null, get the one closest to current time
      comparisonTime =
        Math.abs(currentTime.getTime() - canceledTime.getTime()) <
        Math.abs(currentTime.getTime() - confirmedTime.getTime())
          ? canceledTime
          : confirmedTime
    } else if (canceledTime || confirmedTime) {
      // Either of them is not null
      comparisonTime = canceledTime || confirmedTime
    } else {
      // Both are null, return
      return
    }

    if (isAfter(currentTime, addHours(comparisonTime, 12))) {
      setIs12HoursPassed(true)
    } else {
      setIs12HoursPassed(false)
    }
  }, [canceledAt, confirmedAt])

  return is12HoursPassed
}

export default useCheckTimestamp
