import './Hero.css'
import { FiCheckCircle } from 'react-icons/fi'
import { Dayjs } from 'dayjs'
import AutoComplete from '../addTrailerForm/hook/AutoComplete'
import { TrailerCategories } from '../../data/dummy/TrailerTypes'

import { useEffect, useState } from 'react'
import {
  TbBuildingSkyscraper,
  TbCalendar,
  TbChevronDown,
  TbChevronRight,
} from 'react-icons/tb'
import { DatePicker } from '@mui/x-date-pickers'
import { Link } from 'react-router-dom'
import { Stack } from '@mui/material'
import { TransportTrailerDouble } from 'data/icons/TrailerIcons'
import { Button, Flex } from 'components/ui'

export default function Hero() {
  const [trailerTypes, setTrailerTypes] = useState([])
  const [showTrailerSuggestions, setShowTrailerSuggestions] = useState(false)
  const [trailerTypeFilter, setTrailerTypeFilter] = useState([])
  // const [selectRentalPeriod, setSelectRentalPeriod] = useState(false)
  const [huurmoment, setHuurmoment] = useState<Dayjs | null>()

  useEffect(() => {
    setTrailerTypes(TrailerCategories)
  }, [TrailerCategories])

  return (
    <div>
      <div className="heroContainer">
        <div className="heroOverlay" />
        <h1>Huur en verhuur je aanhanger via BuurBak</h1>
        <Flex
          className="heroIncluded"
          justifyContent="center"
          alignContent="center"
          gap={64}
          style={{ maxWidth: 780, margin: 'auto' }}
        >
          <div className="heroIncludedItem">
            <FiCheckCircle size="26px" color="#66CC99" />
            <p>Altijd in de buurt</p>
          </div>
          <div className="heroIncludedItem">
            <FiCheckCircle size="26px" color="#66CC99" />
            <p>Altijd duurzaam</p>
          </div>
          <div className="heroIncludedItem">
            <FiCheckCircle size="26px" color="#66CC99" />
            <p>Altijd eenvoudig</p>
          </div>
        </Flex>
        <Flex alignContent="center" justifyContent="center" className="heroCta">
          <Button
            asChild
            size="lg"
            style={{
              marginTop: '24px',
              alignSelf: 'center',
              justifySelf: 'center',
            }}
          >
            <Link to="/aanbod">Bekijk het aanbod</Link>
          </Button>
        </Flex>
      </div>
    </div>
  )
}
