import useClickOutside from 'hooks/onClickOutside'
import { useRef, useState } from 'react'
import { TbChevronDown, TbTrash } from 'react-icons/tb'
import { instance } from 'util/axios-instance'
import './DeleteProfile.css'

const DELETION_OPTIONS = [
  'Ik ben ontevreden over BuurBak',
  'Ik verwachtte iets anders bij BuurBak',
  'Ik heb een dubbel account',
  'Anders',
]

export default function DeleteProfile({ user }) {
  const [showOptions, setShowOptions] = useState(false)
  const deletionOptionsRef = useRef(null)
  const [reason, setReason] = useState('Selecteer een reden')

  useClickOutside(deletionOptionsRef, () => {
    setShowOptions(false)
  })

  const deleteUser = () => {
    if (user) {
      instance.delete(`/customers/${user.id}`)
    }
  }

  return (
    <div className="aboutReservationContent settings transactions">
      <p>
        Voordat je je account ({user.email}) verwijdert zouden we graag willen
        weten waarom je jouw BuurBak account wilt verwijderen.
      </p>
      <div
        className="selectReservationSelectContainerWrapper"
        onClick={() => setShowOptions((current) => !current)}
        ref={deletionOptionsRef}
        style={{ marginTop: 30 }}
      >
        <div className="selectTrailerSelectContainer reservations delete">
          <TbTrash />
          <p>{reason}</p>
          <TbChevronDown
            className={showOptions ? 'chevronTranfsormed' : 'chevron'}
          />
        </div>
        {showOptions && (
          <div className="selectTrailerOptionContainer reservations">
            {DELETION_OPTIONS.map((item, index) => (
              <div
                className="reservationTypeOption"
                onClick={() => setReason(item)}
                key={index}
              >
                <p>{item}</p>
              </div>
            ))}
          </div>
        )}
      </div>
      <button className="saveChangesBtn" onClick={() => deleteUser()}>
        Account verwijderen
      </button>
    </div>
  )
}
