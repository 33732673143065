import {
  BagageTrailer,
  BicycleTrailer,
  PlateauTrailer,
} from 'data/icons/TrailerIcons'
import { format } from 'date-fns'
import nl from 'date-fns/esm/locale/nl/index.js'
import { useState } from 'react'
import {
  TbAlertTriangle,
  TbChevronRight,
  TbPremiumRights,
} from 'react-icons/tb'
import './MyPayments.css'

const paymentItems = [
  {
    trailer: 'Bakwagen ongeremd',
    icon: <PlateauTrailer size={23} />,
    date: '08/12/2022',
    price: 34.66,
  },
  {
    trailer: 'Fietsen aanhanger',
    icon: <BicycleTrailer size={23} />,
    date: '01/12/2023',
    price: 58.34,
  },
  {
    trailer: 'Bagage aanhanger',
    icon: <BagageTrailer size={23} />,
    date: '04/21/2023',
    price: 18.12,
  },
]

export default function MyPayments({ setContentInView }) {
  const [activePaymentDetails, setActivePaymentDetails] = useState<any>({})

  return (
    <div className="aboutReservationContent settings myPayments">
      <p>
        Bekijk hier een overzicht van jouw betalingen, klik op een betaling om
        meer acties & details te kunnen zien.
      </p>
      <div className="transactionHistoryContainer">
        {Object.keys(activePaymentDetails).length > 0 ? (
          <>
            <button
              className="grayBackBtn black"
              style={{ marginBottom: 20, marginLeft: 8 }}
              onClick={() => setActivePaymentDetails({})}
            >
              Terug
            </button>
            <div className="transactionHistoryItem active">
              <div className="transactionHistoryIconWrapper">
                {activePaymentDetails.icon}
              </div>
              <div className="transActionHistoryDetails">
                <p>{activePaymentDetails.trailer}</p>
                <span>
                  {format(new Date(activePaymentDetails.date), 'dd MMMM yyyy', {
                    locale: nl,
                  })}
                </span>
              </div>
              <b>€{activePaymentDetails.price}</b>
            </div>
            <div className="paymentDetailsActions">
              <span>Acties</span>
              <div
                className="sidebarShortcutItem minPad"
                onClick={() => setContentInView('restitution')}
              >
                <TbPremiumRights className="sidebarIcon" />
                <p>Restitutie aanvragen</p>
                <TbChevronRight />
              </div>
              {/*<div className="sidebarShortcutItem minPad">*/}
              {/*  <TbAlertTriangle className="sidebarIcon" />*/}
              {/*  <p>Schade melden</p>*/}
              {/*  <TbChevronRight />*/}
              {/*</div>*/}
            </div>
          </>
        ) : (
          <>
            {paymentItems.map((payment, index) => (
              <div
                className="transactionHistoryItem payment"
                onClick={() => setActivePaymentDetails(payment)}
                key={index}
              >
                <div className="transactionHistoryIconWrapper">
                  {payment.icon}
                </div>
                <div className="transActionHistoryDetails">
                  <p>{payment.trailer}</p>
                  <span>
                    {format(new Date(payment.date), 'dd MMMM yyyy', {
                      locale: nl,
                    })}
                  </span>
                </div>
                <b>€{payment.price}</b>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  )
}
