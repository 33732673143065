import { Outlet, useNavigate } from 'react-router-dom'
import { useContext, useEffect } from 'react'
import { AuthContext } from '../context/auth-context'
import Loading from 'components/constants/Loading'

const PrivateRoute: React.FC = () => {
  const { user, setShowLoginModal, isLoading } = useContext(AuthContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (!isLoading && (!user || Object.keys(user).length <= 0)) {
      setShowLoginModal(true)
      navigate('/')
    }
  }, [user, setShowLoginModal, isLoading])

  if (isLoading) {
    return <Loading />
  }

  if (!user) {
    return null
  }

  return <Outlet />
}

export default PrivateRoute
