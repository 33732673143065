import { Avatar } from '@mui/material'
import {
  TbAddressBook,
  TbMail,
  TbMapPin,
  TbPhone,
  TbStar,
  TbUser,
} from 'react-icons/tb'

export default function AboutOwner({ showReservation }) {
  return (
    <div className="aboutReservationContent">
      <span>Gegevens verhuurder</span>
      <div className="ticketDetailItem" style={{ marginTop: 4 }}>
        <TbUser />
        <p>{showReservation.trailer.owner.name}</p>
      </div>
      <div className="ticketDetailItem" style={{ marginTop: 16 }}>
        <TbMail />
        <p>{showReservation.trailer.owner.email}</p>
      </div>
      <div className="ticketDetailItem" style={{ marginTop: 16 }}>
        <TbPhone />
        <p>{showReservation.trailer.owner.number}</p>
      </div>
      <div className="ticketDetailItem" style={{ marginTop: 16 }}>
        <TbAddressBook />
        <p>
          {`${showReservation.trailer.address.city}, ${showReservation.trailer.address.streetName} ${showReservation.trailer.address.number}, ${showReservation.trailer.address.postalCode}`}
        </p>
      </div>
    </div>
  )
}
