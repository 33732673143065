import './SidebarType.css'
import { IconButton } from '@mui/material'
import { TbChevronLeft, TbInfoCircle } from 'react-icons/tb'
import { TrailerCategories } from '../../../../../../data/dummy/TrailerTypes'
import { useEffect, useState } from 'react'
import useUpdateTrailer from '../../hooks/useUpdateTrailer'

export default function SidebarType({
  trailerInfo,
  setSidebarContent,
  setTrailerInfo,
}) {
  const [updatedType, setUpdatedType] = useState('')
  const updateTrailerData = useUpdateTrailer(trailerInfo)

  const handleUpdateField = async (field, value) => {
    const updatedData = await updateTrailerData(field, value)
    setTrailerInfo(updatedData)
    setSidebarContent('')
    window.location.reload()
  }

  useEffect(() => {
    if (!trailerInfo.trailerType.name) {
      setUpdatedType('')
    } else {
      setUpdatedType(trailerInfo.trailerType.name)
    }
  }, [trailerInfo])

  return (
    <div
      className="sideBarSettingsWrapper"
      style={
        updatedType !== trailerInfo.trailerType.name
          ? { paddingBottom: '50px' }
          : null
      }
    >
      <div className="sidebarSettingsHeader">
        <IconButton className="goBack" onClick={() => setSidebarContent('')}>
          <TbChevronLeft />
        </IconButton>
        <p>Aanhanger type aanpassen</p>
        <p>
          Verander het type aanhanger dat te huur wordt aangeboden in deze
          advertentie.
        </p>
      </div>
      <div className="sidebarSettingsContentContainer ">
        <div className="sidebarTrailerTypeGrid">
          {TrailerCategories.map((item) => (
            <div
              className={
                item.trailerType === updatedType
                  ? 'trailerTypeSelectContainer active'
                  : 'trailerTypeSelectContainer'
              }
              key={item.id}
              onClick={() => setUpdatedType(item.trailerType)}
            >
              <div className="iconWrapper">{item.icon}</div>
              <p>{item.trailerType}</p>
              <TbInfoCircle className="infoIcon" />
            </div>
          ))}
        </div>
      </div>
      {updatedType !== trailerInfo.trailerType.name && (
        <div className="sidebarSettingsFooterAction updated">
          <button
            className="sidebarActionButton"
            onClick={() => handleUpdateField('trailerType', updatedType)}
          >
            Wijzigingen opslaan
          </button>
        </div>
      )}
    </div>
  )
}
