import { useState, useEffect, useRef } from 'react'
import './TrailerLocation.css'
import AutoComplete from '../hook/AutoComplete'
import { GoogleMap, OverlayViewF } from '@react-google-maps/api'
import mapStyles from '../../../data/mapStyles'
import { TrailerCategories } from '../../../data/dummy/TrailerTypes'
import { Address, GeoCode, LocationItem } from '../TrailerForm'
import useDebouncedEffect from '../hook/UseDebouncedEffect'
import useUpdateGeoCode from '../hook/updateGeoCode'
import { useTranslation } from 'react-i18next'

interface Props {
  trailerType: string
  setLocation: (location: LocationItem[]) => void
  setGeoCode: (geoCode: GeoCode) => void
  geoCode: GeoCode
  location: LocationItem[]
  streetName: string
  city: string
  postalCode: string
  houseNumber: string
  setStreetName: (streetName: string) => void
  setHouseNumber: (houseNumber: string) => void
  setPostalCode: (postalCode: string) => void
  setCity: (city: string) => void
}

const mapContainerStyle = {
  width: '100%',
  height: '100%',
  borderRadius: 15,
  webKitBorderRadius: 15,
}
const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  gestureHandling: 'none',
  clickableIcons: false,
}

const Location: React.FC<Props> = ({
  trailerType,
  setLocation,
  setGeoCode,
  location,
  geoCode,
  streetName,
  setStreetName,
  houseNumber,
  setHouseNumber,
  city,
  setCity,
  setPostalCode,
  postalCode,
}) => {
  const { t } = useTranslation('TrailerForm')
  const [showLocationDetails, setShowLocationDetails] = useState(false)
  const [debouncedCenter, setDebouncedCenter] = useState({
    lat: 52.090736,
    lng: 5.12142,
  })
  const updatedGeoCode = useUpdateGeoCode(
    streetName,
    houseNumber,
    postalCode,
    city
  )
  const mapRef = useRef<google.maps.Map | null>(null)
  const trailerTypeIcon = TrailerCategories.find(
    (type) => type.trailerType === trailerType
  )

  useEffect(() => {
    if (Object.keys(updatedGeoCode).length > 0) {
      setGeoCode(updatedGeoCode)
    } else {
      setGeoCode(debouncedCenter)
    }
  }, [updatedGeoCode, setGeoCode])

  const updateAddress = (fieldName: keyof Address, fieldValue: string) => {
    switch (fieldName) {
      case 'streetName':
        setStreetName(fieldValue)
        break
      case 'city':
        setCity(fieldValue)
        break
      case 'postalCode':
        const numericValue = fieldValue.replace(/[^\d]/g, '')
        const alphabeticValue = fieldValue.replace(/[^a-zA-Z]/g, '')

        if (numericValue.length <= 4) {
          setPostalCode(
            numericValue + alphabeticValue.slice(0, 2).toUpperCase()
          )
        }
        break
      case 'houseNumber':
        setHouseNumber(fieldValue)
        break
    }
  }

  useEffect(() => {
    if (geoCode && mapRef.current) {
      mapRef.current.panTo({ lat: geoCode.lat, lng: geoCode.lng })
      if (!streetName) setStreetName(getAddressComponent('route'))
      if (!city) setCity(getAddressComponent('locality'))
      if (!postalCode)
        setPostalCode(getAddressComponent('postal_code').replace(/\s/g, ''))
      if (!houseNumber) setHouseNumber(getAddressComponent('street_number'))
    } else if (mapRef.current) {
      mapRef.current.panTo(debouncedCenter)
    }
  }, [geoCode, mapRef.current])

  function getAddressComponent(type: string): string {
    const result = location.find((item) => item.types.includes(type))
    return result ? result.long_name : ''
  }

  useEffect(() => {
    setShowLocationDetails(location.length > 0)
  }, [location])

  useDebouncedEffect(
    () => {
      if (Object.keys(geoCode).length > 0) {
        setDebouncedCenter({ lat: geoCode.lat, lng: geoCode.lng })
      }
    },
    [geoCode],
    500
  )

  return (
    <div className="formStepContainer">
      <h2>{t('location.title')}</h2>
      <p>{t('location.introduction')}</p>
      {showLocationDetails && (
        <div className="exactLocationInput">
          {[
            ['streetName', streetName, `${t('location.fields.streetName')}`],
            ['houseNumber', houseNumber, `${t('location.fields.houseNumber')}`],
            ['postalCode', postalCode, `${t('location.fields.postalCode')}`],
            ['city', city, `${t('location.fields.city')}`],
          ].map(([name, value, label], index) => (
            <div key={index} className="exactLocationInputItem">
              <input
                type="text"
                value={value}
                required
                onChange={(e) =>
                  updateAddress(name as keyof Address, e.target.value)
                }
                id={name}
                name={name}
                pattern={
                  name === 'postalCode' ? '^[0-9]{4}s?[a-zA-Z]{2}$' : undefined
                }
              />
              <span>{label}</span>
            </div>
          ))}
        </div>
      )}
      <div
        className="trailerFormMap"
        style={location?.length > 0 ? { height: '420px' } : null}
      >
        <GoogleMap
          zoom={15}
          center={debouncedCenter}
          options={options}
          mapContainerStyle={mapContainerStyle}
          onLoad={(map) => {
            mapRef.current = map
          }}
        >
          {geoCode && (houseNumber || city || houseNumber || postalCode) && (
            <OverlayViewF
              mapPaneName={'markerLayer'}
              position={{
                lat: geoCode.lat,
                lng: geoCode.lng,
              }}
            >
              <div className="trailerTypeMarkerWrapper">
                <div className="trailerTypeMarkerRadius"></div>
                <div className="trailerTypeMarker">{trailerTypeIcon.icon}</div>
              </div>
            </OverlayViewF>
          )}
        </GoogleMap>
        {location.length <= 0 && (
          <AutoComplete
            setLocation={setLocation}
            setGeoCode={setGeoCode}
            customStyle={'form'}
          />
        )}
      </div>
    </div>
  )
}

export default Location
