import {
  format,
  getDay,
  isToday,
  isBefore,
  isAfter,
  parseISO,
  isSameDay,
  isSameWeek,
  startOfDay,
  eachDayOfInterval,
  startOfWeek,
  endOfWeek,
  addDays,
  subDays,
  addWeeks,
  subWeeks,
  endOfMonth,
  startOfMonth,
  addMonths,
} from 'date-fns'
import { isSameMonth } from 'date-fns/esm'

export const colStartClasses = [
  'col-start-1',
  'col-start-2',
  'col-start-3',
  'col-start-4',
  'col-start-5',
  'col-start-6',
  'col-start-7',
]

export const dayContainerClass = (windowWidth, dayIdx, day) =>
  `${
    windowWidth > 744
      ? 'calenderViewDayContainer'
      : 'calenderViewDayContainer mobile'
  } ${dayIdx === 0 ? colStartClasses[getDay(day)] : ''}`

export const formatDayItem = (days, day) =>
  `calendarViewDayItem ${
    format(days[days.length - 1], 'dd/MM/yy') === format(day, 'dd/MM/yy') &&
    'lastDayOfMont'
  }`

const getDayOfWeek = (date) => (getDay(date) + 6) % 7

const checkDaysOfWeekInInterval = (startDate, endDate) => {
  const interval = {
    start: startOfDay(startDate),
    end: startOfDay(endDate),
  }
  const daysInInterval = eachDayOfInterval(interval)
  const dayCounts = Array(7).fill(0)

  daysInInterval.forEach((day) => {
    const dayOfWeek = getDayOfWeek(day)
    dayCounts[dayOfWeek]++
  })

  return dayCounts.map((count) => count > 1)
}

export const contentClass = (
  windowWidth,
  day,
  today,
  isDisabled,
  dayReservation,
  selectedRange,
  isAvailable
) => {
  let classes = [
    windowWidth > 1150 ? 'dayItemContent' : 'dayItemContent mobile',
    isToday(day) && windowWidth > 744 ? 'currentDay' : 'currentDay mobile',
    isBefore(day, today) && !isToday(day) && 'beforeCurrentDay',
    isDisabled && isAfter(day, today) && 'isNotAvailable',
    // !isAvailable && isSameDay(day, selectedRange.startDate) && 'isNotAvailable',
    // !isAvailable && isAfter(day, today) && 'isNotAvailable',
    dayReservation && Object.keys(dayReservation).length > 0 && 'isReserved',
    (isSameDay(day, selectedRange.startDate) ||
      isSameDay(day, selectedRange.endDate) ||
      (isAfter(day, selectedRange.startDate) &&
        isBefore(day, selectedRange.endDate))) &&
      'withinRange',
  ]

  // If the day is in range, add the corresponding edge classes
  if (selectedRange && selectedRange.startDate && selectedRange.endDate) {
    let ranges = []

    // If the startDate and endDate are not in the same month, split the range into multiple ranges
    if (!isSameMonth(selectedRange.startDate, selectedRange.endDate)) {
      let currentMonth = startOfMonth(selectedRange.startDate)

      while (!isAfter(currentMonth, selectedRange.endDate)) {
        let endCurrentMonth = endOfMonth(currentMonth)

        if (isAfter(endCurrentMonth, selectedRange.endDate)) {
          endCurrentMonth = selectedRange.endDate
        }

        ranges.push({
          startDate: isAfter(currentMonth, selectedRange.startDate)
            ? currentMonth
            : selectedRange.startDate,
          endDate: endCurrentMonth,
        })

        currentMonth = addMonths(currentMonth, 1)
      }
    } else {
      // If they are in the same month, use the original range
      ranges.push(selectedRange)
    }

    // Process each range separately
    ranges.forEach((range, index) => {
      if (
        !isSameWeek(range.startDate, range.endDate, {
          weekStartsOn: 1,
        })
      ) {
        const isStartDay = isSameDay(day, range.startDate)
        const isEndDay = isSameDay(day, range.endDate)
        const dayOfWeek = getDayOfWeek(day)
        const doesDayOfWeekOccurMultipleTimes = checkDaysOfWeekInInterval(
          range.startDate,
          range.endDate
        )[dayOfWeek]
        const getDayOfWeekIndex = (date) => (getDay(date) + 6) % 7

        const startWeek =
          isSameWeek(day, range.startDate, { weekStartsOn: 1 }) &&
          isAfter(day, range.startDate)
        const endWeek =
          isSameWeek(day, range.endDate, { weekStartsOn: 1 }) &&
          isBefore(day, range.endDate)

        const isFirstDayOfWeek = (date) => {
          // Get the first day of the week for the given date
          const startOfWeekDate = startOfWeek(date, { weekStartsOn: 1 })

          // Check if the given date is the same as the start of the week
          return isSameDay(date, startOfWeekDate)
        }

        const isLastDayOfWeek = (date) => {
          // Get the first day of the week for the given date
          const endOfWeekDate = endOfWeek(date, { weekStartsOn: 1 })

          // Check if the given date is the same as the start of the week
          return isSameDay(date, endOfWeekDate)
        }
        const isMonday = (date) => getDay(date) === 1
        const isSunday = (date) => getDay(date) === 0

        let current = range.startDate
        while (!isMonday(current)) {
          current = addDays(current, 1)
        }
        range.firstMonday = current

        let find = range.endDate
        while (true) {
          if (isSunday(find)) {
            break
          }
          find = subDays(find, 1)
        }
        range.firstSunday = find

        if (isStartDay) classes.push('topLeftBorderRounded')
        if (isEndDay) classes.push('bottomRightBorderRounded')
        if (isStartDay && doesDayOfWeekOccurMultipleTimes)
          classes.push('startDateAfter')
        if (isEndDay && doesDayOfWeekOccurMultipleTimes)
          classes.push('endDateAfter')
        if (endWeek) classes.push('bottomBorder')
        if (startWeek) classes.push('topBorder')
        if (
          isSameDay(day, range.firstMonday) &&
          !doesDayOfWeekOccurMultipleTimes
        )
          classes.push('leftBorder topLeftRounded bottomLeftRounded topBorder')
        else if (
          isSameDay(day, range.firstMonday) &&
          doesDayOfWeekOccurMultipleTimes
        )
          classes.push('leftBorder topLeftRounded topBorder')
        if (
          isFirstDayOfWeek(day) &&
          isSameWeek(day, range.endDate, { weekStartsOn: 1 })
        )
          classes.push('leftBorder bottomLeftRounded')
        else if (
          isFirstDayOfWeek(day) &&
          isAfter(day, range.startDate) &&
          isBefore(day, range.endDate)
        )
          classes.push('leftBorder')
        if (
          isSameWeek(day, range.startDate, { weekStartsOn: 1 }) &&
          !doesDayOfWeekOccurMultipleTimes &&
          isAfter(day, range.startDate)
        )
          classes.push('bottomBorder')
        if (isStartDay && !doesDayOfWeekOccurMultipleTimes)
          classes.push('bottomBorder bottomLeftRounded')
        if (isEndDay && !doesDayOfWeekOccurMultipleTimes)
          classes.push('topBorder topRightRounded')
        if (
          isSameWeek(day, range.endDate, { weekStartsOn: 1 }) &&
          !doesDayOfWeekOccurMultipleTimes &&
          isBefore(day, range.endDate)
        )
          classes.push('topBorder')
        if (
          isSameDay(day, range.firstSunday) &&
          doesDayOfWeekOccurMultipleTimes &&
          index === ranges.length - 1
        )
          classes.push('rightBorder bottomRightRounded bottomBorder')
        if (
          isLastDayOfWeek(day) &&
          isSameWeek(day, range.startDate, { weekStartsOn: 1 })
        )
          classes.push('rightBorder topRightRounded')
        else if (
          isLastDayOfWeek(day) &&
          isAfter(day, range.startDate) &&
          isBefore(day, range.endDate)
        )
          classes.push('rightBorder')
        else if (
          isSameWeek(day, range.endDate, { weekStartsOn: 1 }) &&
          isAfter(day, range.endDate) &&
          !isLastDayOfWeek(day) &&
          !isSameWeek(day, range.startDate, { weekStartsOn: 1 })
        )
          classes.push('topBorder moreHeight')
        else if (
          isSameWeek(day, range.startDate, { weekStartsOn: 1 }) &&
          isBefore(day, range.startDate) &&
          !isFirstDayOfWeek(day)
        )
          classes.push('bottomBorder lessHeight')
        if (isSameDay(day, range.firstSunday) && index === ranges.length - 1)
          classes.push('bottomRightRounded')
        else if (
          (isSameWeek(day, range.startDate, { weekStartsOn: 1 }) &&
            isAfter(day, range.startDate)) ||
          (isAfter(day, range.startDate) &&
            isSameWeek(day, addWeeks(range.startDate, 1), {
              weekStartsOn: 1,
            }) &&
            getDayOfWeekIndex(day) < getDayOfWeekIndex(range.startDate))
        ) {
          classes.push('topBorder')
        } else if (
          (isSameWeek(day, range.endDate, { weekStartsOn: 1 }) &&
            isBefore(day, range.endDate)) ||
          (isBefore(day, range.endDate) &&
            isSameWeek(day, subWeeks(range.endDate, 1), {
              weekStartsOn: 1,
            }) &&
            getDayOfWeekIndex(day) > getDayOfWeekIndex(range.endDate))
        ) {
          classes.push('bottomBorder')
        }
      } else {
        const isStartDay = isSameDay(day, range.startDate)
        const isEndDay = isSameDay(day, range.endDate)

        if (isStartDay)
          classes.push('topLeftBorderRounded bottomBorder bottomLeftRounded')
        if (isEndDay)
          classes.push('bottomRightBorderRounded topBorder topRightRounded')
        if (isAfter(day, range.startDate) && isBefore(day, range.endDate))
          classes.push('topBorder bottomBorder')
      }
    })
  }

  return classes.filter(Boolean).join(' ')
}

export const constructPrice = (
  windowWidth,
  day,
  dayReservation,
  updatedDayInfo,
  trailerDayInfo
) => {
  let dayPrice
  if (updatedDayInfo) {
    dayPrice = updatedDayInfo.price
  } else if (trailerDayInfo) {
    dayPrice = trailerDayInfo.price
  }

  const timeSlots = updatedDayInfo?.timeSlots || trailerDayInfo?.timeSlots || []
  return (
    <div className="calendarDayContentTimePrice">
      <time dateTime={format(day, 'yyyy-MM-dd')}>
        {isToday(day) && windowWidth > 744 ? (
          <p>Vandaag</p>
        ) : (
          <span>{format(day, 'd')}</span>
        )}
      </time>
      {timeSlots.length > 0 ? (
        <>
          {dayPrice && (
            <p
              className={windowWidth > 744 ? null : 'mobileCalendarDayPrice'}
              style={
                isSameDay(day, parseISO(dayReservation?.startTime)) ||
                (isBefore(day, parseISO(dayReservation?.endTime)) &&
                  isAfter(day, parseISO(dayReservation?.startTime))) ||
                isBefore(day, startOfDay(new Date())) ||
                (updatedDayInfo?.timeSlots.length === 0 ??
                  trailerDayInfo?.timeSlots.length === 0)
                  ? { display: 'none' }
                  : null
              }
            >
              €{dayPrice}
              {windowWidth > 420 && ',00'}
            </p>
          )}
        </>
      ) : (
        <>
          {isAfter(day, startOfDay(new Date())) ||
            (isSameDay(day, startOfDay(new Date())) && (
              <div className="calendar dash" />
            ))}
        </>
      )}
    </div>
  )
}
