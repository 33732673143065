import './PriceTime.css'
import { useCallback, useEffect, useRef, useState } from 'react'
import { GoogleMap, OverlayViewF } from '@react-google-maps/api'
import mapStyles from '../../../data/mapStyles'
import { IconButton, Slider } from '@mui/material'
import { TbCurrencyEuro, TbX } from 'react-icons/tb'
import { TrailerCategories } from '../../../data/dummy/TrailerTypes'
import { useTranslation } from 'react-i18next'
import CustomMarker from 'components/trailerOffers/map/CustomMarker'

export const mapContainerStyle = {
  width: '100%',
  height: '100%',
  borderRadius: 15,
}

export const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  clickableIcons: false,
  gestureHandling: 'none',
}

export const DEFAULT_ZOOM = 10.5
export const DEFAULT_COORDINATES = { lat: 52.090736, lng: 5.12142 }

export default function PriceTime({
  geoCode,
  price,
  setPrice,
  trailerType,
  allTrailers,
}) {
  const { t } = useTranslation('TrailerForm')
  const [visibleItems, setVisibleItems] = useState([])
  const [priceInfo, setPriceInfo] = useState(false)
  const mapRef = useRef<google.maps.Map | null>(null)
  const inputRef = useRef(null)
  const center = geoCode
    ? { lat: geoCode.lat, lng: geoCode.lng }
    : DEFAULT_COORDINATES
  const [inputWidth, setInputWidth] = useState('auto')
  const inputMirrorRef = useRef(null)
  const trailerRentalPrice = TrailerCategories.find(
    (item) => item.trailerType === trailerType
  )

  useEffect(() => {
    if (!!inputMirrorRef && inputMirrorRef.current) {
      if (price) {
        setInputWidth(`${(price?.toString()?.length + 1) * 20}px`)
      } else {
        setInputWidth(`${inputMirrorRef.current.offsetWidth}px`)
      }
    }
  }, [price])

  const handlePriceChange = useCallback(
    (event: Event, value: number | number[]) => {
      setPrice(value as number)
    },
    [setPrice]
  )

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.width = (price?.toString()?.length + 1) * 20 + 'px'
    } else {
      setInputWidth(
        (price?.length === 1 && '26px') ||
          (price?.length === 2 && '52px') ||
          (price?.length === 3 && '78px')
      )
    }
  }, [price])

  const getVisibleTrailers = (bounds, allTrailers, trailerType) => {
    const ne = bounds.getNorthEast()
    const sw = bounds.getSouthWest()
    const sameTypeOfTrailers = allTrailers.filter(
      (trailer) => trailer.trailerType === trailerType
    )
    const visible = sameTypeOfTrailers.filter((trailer) => {
      const { lat, lng } = trailer.location
      return (
        lat <= ne.lat() && lat >= sw.lat() && lng <= ne.lng() && lng >= sw.lng()
      )
    })

    return visible
  }

  const handleMapIdle = useCallback(() => {
    if (!mapRef.current) return

    const bounds = mapRef.current.getBounds()
    const visible = getVisibleTrailers(bounds, allTrailers, trailerType)

    setVisibleItems(visible)
  }, [mapRef, allTrailers, trailerType])

  const totalPrice = visibleItems.reduce(
    (total, item) => total + item.price / visibleItems?.length,
    0
  )

  return (
    <>
      {priceInfo && <AveragePriceInfo t={t} setPriceInfo={setPriceInfo} />}
      <div className="formStepContainer price">
        <h2>{t('price.title')}</h2>
        <p>{t('price.introduction')}</p>
        <div className="priceTimeGrid">
          <div className="priceTimeItem">
            <b>{t('price.content.title')}</b>
            <p>{t('price.content.subTitle')}</p>
            <div className="priceSliderContainer">
              <div className="salaryItem">
                <span>€</span>
                <input
                  value={price}
                  type="number"
                  onChange={(e) => {
                    const value = e.target.value
                    if (value?.length <= 3) {
                      setPrice(value ? Number(value) : '')
                    }
                  }}
                  className={`priceInputContainer trailerForm ${
                    !price && 'restricted'
                  }`}
                  ref={inputRef}
                  style={{ width: inputWidth }}
                  autoFocus
                />
                <div ref={inputMirrorRef} className="input-mirror" aria-hidden>
                  {price}
                </div>
              </div>
            </div>
            <div className="rangeSliderContainer">
              <Slider
                value={parseFloat(price) || 0}
                aria-label="Small"
                color="primary"
                onChange={handlePriceChange}
                min={10}
                max={100}
              />
              <div className="rangeSliderMarks">
                <p>€10</p>
                <p>€100</p>
              </div>
            </div>
            <u className="moreInfoButton" onClick={() => setPriceInfo(true)}>
              {t('price.content.moreInfo')}
            </u>
          </div>
          <div className="priceCompareContainer">
            <GoogleMap
              zoom={DEFAULT_ZOOM}
              center={center}
              options={options}
              mapContainerStyle={mapContainerStyle}
              id="overlay-view-example"
              onLoad={(map) => {
                mapRef.current = map
              }}
              onIdle={handleMapIdle}
            >
              {allTrailers
                .filter((trailer) => trailer.trailerType === trailerType)
                .map((trailer) => (
                  <OverlayViewF
                    key={trailer.id}
                    position={{
                      lat: trailer.location.lat,
                      lng: trailer.location.lng,
                    }}
                    mapPaneName={'markerLayer'}
                  >
                    <CustomMarker trailer={trailer} />
                  </OverlayViewF>
                ))}
              {price && (
                <OverlayViewF
                  position={{
                    lat: geoCode.lat,
                    lng: geoCode.lng,
                  }}
                  mapPaneName={'markerLayer'}
                >
                  <div className="myTrailerPriceContainer">€{price}</div>
                </OverlayViewF>
              )}
            </GoogleMap>
          </div>
        </div>
      </div>
    </>
  )
}

function AveragePriceInfo({ setPriceInfo, t }) {
  return (
    <div className="blurContainer">
      <div className="blur" onClick={() => setPriceInfo(false)} />
      <div className="popupContainer infoBox">
        <div className="popupContainerHeader">
          <IconButton
            className="closePopup transformed"
            onClick={() => setPriceInfo(false)}
          >
            <TbX />
          </IconButton>
          <p>{t('price.popup.title')}</p>
        </div>
        <div className="popupContainerContent">
          <b>{t('price.popup.setRentalPrice')}</b>
          <p>{t('price.popup.rentalPriceInfo')}</p>
        </div>
      </div>
    </div>
  )
}
