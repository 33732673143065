import './Introduction.css'
import { useTranslation } from 'react-i18next'

export default function Introduction() {
  const { t } = useTranslation('TrailerForm')

  return (
    <div className="formInformationContainer">
      <div className="formInformationContent">
        <div className="introductionTitle">
          <p>BuurBak</p>
          <h2>{t('introduction.title')}</h2>
          <p>{t('introduction.introduction')}</p>
        </div>
        <div className="introductionVideo">
          <img
            className="introduction__image"
            src={'/images/verhuurfoto.png'}
          />
        </div>
      </div>
    </div>
  )
}
