import { useEffect, useRef, useState } from 'react'
import {
  TbChevronDown,
  TbMessageDots,
  TbCalendar,
  TbDots,
  TbCurrencyEuro,
  TbWriting,
  TbUserCircle,
  TbChevronRight,
  TbSettings,
  TbInfoCircle,
  TbX,
  TbMessageCircle,
} from 'react-icons/tb'
import { NavLink, useNavigate } from 'react-router-dom'
import useClickOutside from '../../../../hooks/onClickOutside'
import { useAuth } from '../../../../hooks/use-auth'
import Logo from '../../../constants/header/Logo'
import ProfilePicture from '../../../util/ProfilePicture'
import './TrailerOwnerHeader.css'
import useWindowWidth from '../../../../hooks/useWindowWidth'
import { HorseTrailer, TransportTrailer } from 'data/icons/TrailerIcons'
import { IconButton } from '@mui/material'

const ownerMenuItems = [
  {
    indicator: 'myTrailers',
    label: 'Mijn aanhangers',
  },
  {
    indicator: 'reservations',
    label: 'Reserveringen',
  },
  // {
  //   indicator: 'income',
  //   label: 'Inkomsten',
  // },
  // {
  //   indicator: 'ownerReviews',
  //   label: 'Reviews',
  // },
]

export default function TrailerOwnerHeader({ setContentInView }) {
  const [showOwnerMenu, setShowOwnerMenu] = useState(false)
  const { user } = useAuth()
  const navigate = useNavigate()
  const windowWidth = useWindowWidth()
  const [isScrollingUp, setIsScrollingUp] = useState(true)
  let lastScrollTop = 0

  useEffect(() => {
    const handleScroll = () => {
      let st = window.pageYOffset || document.documentElement.scrollTop
      if (Math.abs(st - lastScrollTop) >= 50) {
        setIsScrollingUp(st <= lastScrollTop || st <= 0)
        lastScrollTop = st <= 0 ? 0 : st
      }
    }

    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  if (!user) return

  return (
    <>
      {windowWidth <= 768 ? (
        <OwnerDashboardBottomNav
          user={user}
          isScrollingUp={isScrollingUp}
          setContentInView={setContentInView}
        />
      ) : (
        <div className="ownerDashboardHeaderContainer">
          <Logo />
          <div className="ownerDashboardHeaderContent">
            <NavLink
              to="/owner/dashboard"
              className={({ isActive }) =>
                isActive ? 'navlinkActive' : 'navlink'
              }
            >
              Overzicht
            </NavLink>
            {/*<NavLink*/}
            {/*  to="/owner/berichten"*/}
            {/*  className={({ isActive }) =>*/}
            {/*    isActive ? 'navlinkActive' : 'navlink'*/}
            {/*  }*/}
            {/*>*/}
            {/*  Berichten*/}
            {/*</NavLink>*/}
            <NavLink
              to="/owner/kalender"
              className={({ isActive }) =>
                isActive ? 'navlinkActive' : 'navlink'
              }
            >
              Kalender
            </NavLink>
            <OwnerDashboardMenu
              showOwnerMenu={showOwnerMenu}
              setShowOwnerMenu={setShowOwnerMenu}
              setContentInView={setContentInView}
              navigate={navigate}
            />
          </div>
          <div
            className="ownerDashboardHeaderUser"
            onClick={() => navigate('/account')}
          >
            <p>{user?.name}</p>
            <ProfilePicture user={user} />
          </div>
        </div>
      )}
    </>
  )
}

function OwnerDashboardMenu({
  showOwnerMenu,
  setShowOwnerMenu,
  setContentInView,
  navigate,
}) {
  const menuRef = useRef(null)

  useClickOutside(menuRef, () => {
    setShowOwnerMenu(false)
  })

  return (
    <div className="profileSubheaderMenuWrapper rentalOverview" ref={menuRef}>
      <div onClick={() => setShowOwnerMenu((current) => !current)}>
        <p>Menu</p>
        <TbChevronDown
          className={showOwnerMenu ? 'chevronTranfsormed' : 'chevron'}
        />
      </div>
      {showOwnerMenu && (
        <div className="dropdown__menu">
          {ownerMenuItems.map((item, index) => (
            <div
              key={index}
              className={'ownerMenuItem rentalOverview'}
              onClick={() => {
                navigate('/owner/dashboard')
                setContentInView(item.indicator)
                setShowOwnerMenu(false)
              }}
            >
              <p>{item.label}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

function OwnerDashboardBottomNav({ user, isScrollingUp, setContentInView }) {
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const navigate = useNavigate()

  return (
    <>
      <div
        className={`bottomNavContainer ${isScrollingUp ? 'visible' : 'hidden'}`}
      >
        <div
          className="bottomNavItem flexbox column center"
          onClick={() => navigate('/owner/dashboard')}
        >
          <TransportTrailer size={24} strokeWidth="1.7" />
          <p style={{ marginTop: 10 }}>Mijn aanhangers</p>
        </div>
        {/*<div*/}
        {/*  className="bottomNavItem flexbox column center"*/}
        {/*  onClick={() => navigate('/owner/berichten')}*/}
        {/*>*/}
        {/*  <TbMessageDots />*/}
        {/*  <p>Berichten</p>*/}
        {/*</div>*/}
        <div
          className="bottomNavItem flexbox column center"
          onClick={() => navigate('/owner/kalender')}
        >
          <TbCalendar />
          <p>Kalender</p>
        </div>
        <div
          className="bottomNavItem flexbox column center"
          onClick={() => navigate('/account')}
        >
          <ProfilePicture dimension={42} user={user} />
        </div>
        <div
          className="bottomNavItem flexbox column center"
          onClick={() => setShowMobileMenu((current) => !current)}
        >
          <TbDots color={showMobileMenu ? 'var(--primary)' : null} />
        </div>
      </div>
      {showMobileMenu && (
        <OwnerDashboardMobileMenu
          user={user}
          setShowMobileMenu={setShowMobileMenu}
          setContentInView={setContentInView}
        />
      )}
    </>
  )
}

function OwnerDashboardMobileMenu({
  user,
  setShowMobileMenu,
  setContentInView,
}) {
  const navigate = useNavigate()

  return (
    <div className="ownerDashboardMobileMenuContainer">
      <p>Menu</p>
      <div
        className="mobileMenuItem"
        onClick={() => {
          navigate('/owner/dashboard')
          setShowMobileMenu(false)
        }}
      >
        <TransportTrailer size={26} strokeWidth="1.5" />
        <p>Mijn aanhangers</p>
        <TbChevronRight />
      </div>
      <div
        className="mobileMenuItem"
        onClick={() => {
          navigate('/owner/kalender')
          setShowMobileMenu(false)
        }}
      >
        <TbCalendar size={23} strokeWidth="1.3px" />
        <p>Kalender</p>
        <TbChevronRight />
      </div>
      {/*<div*/}
      {/*  className="mobileMenuItem"*/}
      {/*  onClick={() => {*/}
      {/*    navigate('/owner/berichten')*/}
      {/*    setShowMobileMenu(false)*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <TbMessageCircle size={23} strokeWidth="1.3px" />*/}
      {/*  <p>Berichten</p>*/}
      {/*  <TbChevronRight />*/}
      {/*</div>*/}
      <div
        className="mobileMenuItem"
        onClick={() => {
          setContentInView('reservations')
          setShowMobileMenu(false)
        }}
      >
        <TbUserCircle size={23} strokeWidth="1.3px" />
        <p>Reserveringen</p>
        <TbChevronRight />
      </div>
      {/*<div*/}
      {/*  className="mobileMenuItem"*/}
      {/*  onClick={() => {*/}
      {/*    setContentInView('income')*/}
      {/*    setShowMobileMenu(false)*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <TbCurrencyEuro size={23} strokeWidth="1.3px" />*/}
      {/*  <p>Inkomsten</p>*/}
      {/*  <TbChevronRight />*/}
      {/*</div>*/}
      {/*<div*/}
      {/*  className="mobileMenuItem"*/}
      {/*  onClick={() => {*/}
      {/*    setContentInView('reviews')*/}
      {/*    setShowMobileMenu(false)*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <TbWriting size={24} strokeWidth="1.3px" />*/}
      {/*  <p>Reviews</p>*/}
      {/*  <TbChevronRight />*/}
      {/*</div>*/}
      <div className="mobileMenuItem" onClick={() => navigate('/verhuren')}>
        <HorseTrailer size={26} strokeWidth="1.9" />
        <p>Nieuwe aanhanger plaatsen</p>
        <TbChevronRight />
      </div>
      <div className="divider fullWidth" />
      <span>Account</span>
      <div className="mobileMenuItem" onClick={() => navigate('/account')}>
        <ProfilePicture dimension={32} user={user} />
        <p style={{ marginLeft: 18 }}>{user.name}</p>
        <TbChevronRight />
      </div>
      <div
        className="mobileMenuItem"
        onClick={() => navigate('/account?settings=true')}
      >
        <TbSettings size={23} strokeWidth="1.3px" />
        <p>Instellingen</p>
        <TbChevronRight />
      </div>
      <div className="mobileMenuItem">
        <TbInfoCircle size={23} strokeWidth="1.3px" />
        <p>Meer informatie</p>
        <TbChevronRight />
      </div>
      <button
        className="primaryBtn border primary menuBtn"
        onClick={() => navigate('/aanbod')}
      >
        Naar aanbod
      </button>
      <IconButton className="closeBtn" onClick={() => setShowMobileMenu(false)}>
        <TbX />
      </IconButton>
    </div>
  )
}
