import { Avatar } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import { User } from '../../types/User'
import { useEffect, useState } from 'react'
import { instance } from 'util/axios-instance'

interface ProfilePictureProps {
  user: User
  dimension?: number
}

export default function ProfilePicture({
  user,
  dimension = 32,
}: ProfilePictureProps) {
  const [currentProfileImage, setCurrentProfileImage] = useState<any>()

  useEffect(() => {
    if (user) {
      instance
        .get(`/customers/${user.id}/profile-image`)
        .then((response) => {
          setCurrentProfileImage(response.data)
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }, [user])

  if (user && currentProfileImage)
    return (
      <Avatar
        sx={{ width: dimension, height: dimension }}
        alt={user.name}
        src={currentProfileImage}
      />
    )
  else
    return (
      <Avatar sx={{ width: dimension, height: dimension }}>
        <PersonIcon />
      </Avatar>
    )
}
