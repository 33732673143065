import { useEffect, useRef, useState } from 'react'
import { IconButton } from '@mui/material'
import { format, parseISO } from 'date-fns'
import { nl } from 'date-fns/locale'
import { TbAlertTriangle, TbCheck, TbDots, TbTrash } from 'react-icons/tb'
import './TrailerTableListItem.css'
import useClickOutside from '../../../../../hooks/onClickOutside'
import { useNavigate } from 'react-router-dom'
import { instance } from 'util/axios-instance'
import { TrailerCategories } from '../../../../../data/dummy/TrailerTypes'
import useWindowWidth from 'hooks/useWindowWidth'

export default function TrailerTableListItem({
  trailer,
  columnWidths,
  activeTrailer,
  setActiveTrailer,
}) {
  const {
    id,
    name,
    trailerType,
    createdAt,
    address,
    status,
    rentData,
    // damage,
  } = trailer
  const [showActionMenu, setShowActionMenu] = useState(false)
  const [showWarning, setShowWarning] = useState(false)
  const [trailerInfo, setTrailerInfo] = useState(null)
  const menuRef = useRef(null)
  const navigate = useNavigate()
  const windowWidth = useWindowWidth()

  useEffect(() => {
    const fetchTrailerInfo = async () => {
      try {
        const response = await instance.get(`/traileroffers/${id}`)
        setTrailerInfo(response.data)
      } catch (error) {
        console.error('Failed to fetch trailer information:', error)
      }
    }

    if (id) {
      fetchTrailerInfo()
    }
  }, [id])

  useClickOutside(menuRef, () => setShowActionMenu(false))

  const gridTemplateColumns =
    columnWidths?.map((width) => `${width}px`).join(' ') ?? ''
  const trailerTypeIcon = TrailerCategories.find(
    (type) => type.trailerType === trailerType?.name
  )

  const showTrailerDetails = () => navigate(`/aanbod/${id}`)
  const triggerWarning = () => setShowWarning(true)

  const isActive = activeTrailer === trailer.id
  const containerClass = isActive
    ? `trailerTableListItemContainerActive ${
        windowWidth < 768 ? 'mobile' : null
      }`
    : `trailerTableListItemContainer ${windowWidth < 768 ? 'mobile' : null}`

  return (
    <div
      className={containerClass}
      style={
        windowWidth > 768
          ? { gridTemplateColumns, justifyContent: 'space-between' }
          : null
      }
      onClick={() => setActiveTrailer(trailer.id)}
    >
      <ImageContainer
        coverImage={trailerInfo?.coverImage}
        trailerTypeIcon={trailerTypeIcon}
        name={name}
        trailerType={trailerType}
        windowWidth
      />
      {windowWidth > 768 && <DateItem createdAt={createdAt} />}
      {windowWidth > 768 && <AddressItem address={address} />}
      {windowWidth > 768 && <PriceItem trailerInfo={trailerInfo} />}
      {windowWidth > 768 && <StatusItem status={status} rentData={rentData} />}
      {/*{windowWidth > 768 && <DamageItem damage={damage} />}*/}
      <ActionMenu
        menuRef={menuRef}
        isActive={isActive}
        showActionMenu={showActionMenu}
        setShowActionMenu={setShowActionMenu}
        showTrailerDetails={showTrailerDetails}
        triggerWarning={triggerWarning}
        trailerTypeIcon={trailerTypeIcon}
      />
    </div>
  )
}

const ImageContainer = ({
  coverImage,
  trailerTypeIcon,
  name,
  trailerType,
  windowWidth,
}) => (
  <div
    className="trailerTableImageContainer"
    style={windowWidth > 768 ? { width: 430, maxWidth: 430 } : null}
  >
    <div className="checkbox" style={{ marginLeft: 0 }}>
      <TbCheck className="check" />
    </div>

    {coverImage ? (
      <img alt="trailer" src={coverImage} />
    ) : (
      <div className="myTrailerIcon">{trailerTypeIcon?.white_icon}</div>
    )}
    <div>
      <p>{name}</p>
      <p style={{ color: 'var(--primary)' }}>{trailerType.name}</p>
    </div>
  </div>
)

const DateItem = ({ createdAt }) => (
  <div className="trailerTableItem">
    <p>{format(parseISO(createdAt), 'dd MMMM yyyy', { locale: nl })}</p>
  </div>
)

const AddressItem = ({ address }) => (
  <div className="trailerTableItem">
    {Object.keys(address.city).length > 0 ? (
      <p>{address.city}</p>
    ) : (
      <div className="dash" />
    )}
  </div>
)

const PriceItem = ({ trailerInfo }) => (
  <div className="trailerTableItem">
    {trailerInfo?.price > 0 ? (
      <p>€{trailerInfo?.price},00</p>
    ) : (
      <div className="dash" />
    )}
  </div>
)

const StatusItem = ({ status, rentData }) => (
  <div
    className={`trailerTableItem ${
      status !== 'Beschikbaar' ? 'inActive' : 'status'
    }`}
  >
    {status === 'Concept' ? (
      <div className="conceptContainer">
        <p>{status}</p>
      </div>
    ) : (
      <p>{status}</p>
    )}
    {status === 'Verhuurd' && (
      <p>
        {rentData.pickupTime} - {rentData.returnTime}
      </p>
    )}
    {!status && <p style={{ color: 'var(--secondary)' }}>Beschikbaar</p>}
  </div>
)

// const DamageItem = ({ damage }) => (
//   <div className="trailerTableIconItem">
//     {damage > 0 ? (
//       <TbAlertTriangle
//         className="trailerTableIcon"
//         size={20}
//         color="var(--error)"
//       />
//     ) : (
//       <div className="dash" style={{ margin: '0 auto' }} />
//     )}
//   </div>
// )

const ActionMenu = ({
  menuRef,
  isActive,
  showActionMenu,
  setShowActionMenu,
  showTrailerDetails,
  triggerWarning,
  trailerTypeIcon,
}) => (
  <div className="trailerTableIconItem action" ref={menuRef}>
    <IconButton
      className="trailerTableIcon"
      onClick={(e) => {
        setShowActionMenu((prev) => !prev)
        e.preventDefault()
        e.stopPropagation()
      }}
    >
      <TbDots
        size={20}
        color={isActive ? 'var(--primary)' : 'var(--subheader)'}
      />
    </IconButton>
    {showActionMenu && (
      <div className="trailerActionMenu">
        <div className="trailerActionMenuItem" onClick={showTrailerDetails}>
          <p>Bekijken</p>
          {trailerTypeIcon?.icon}
        </div>
      </div>
    )}
  </div>
)
