import './notice.css'
import { cva, VariantProps } from 'class-variance-authority'
import { TbAlertTriangle } from 'react-icons/tb'
import { Flex } from '../layout'

const noticeVariants = cva('notice', {
  variants: {
    variant: {
      normal: 'notice__normal',
      error: 'notice__error',
    },
  },
  defaultVariants: {
    variant: 'normal',
  },
})

export interface NoticeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof noticeVariants> {
  title: string
  description?: string
}

const Notice = ({ variant, title, description, ...props }: NoticeProps) => {
  return (
    <div className={`${noticeVariants({ variant })}`}>
      <Flex gap={16} alignItems="center">
        {variant === 'error' && (
          <TbAlertTriangle size={24} className="notice__error_triangle" />
        )}
        <span className="notice__title">{title}</span>
        {description && <p>{description}</p>}
      </Flex>
    </div>
  )
}

export { Notice }
