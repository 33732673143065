import { Alert, Box, Grid, TextField } from '@mui/material'
import { useForm, SubmitHandler } from 'react-hook-form'
import { object, string, TypeOf } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { useAuth } from '../../../hooks/use-auth'
import { AxiosError } from 'axios'
import './Auth.css'

const registerSchema = object({
  name: string()
    .min(1, 'Naam is verplicht')
    .max(100, 'Naam moet minder dan 100 characters lang zijn'),
  email: string()
    .min(1, 'E-mail is verplicht')
    .email('E-mail is niet een email'),
  // postalCode: string()
  //   .min(6, 'Postcode is verplicht')
  //   .max(6, 'Postcode mag niet langer dan zes characters zijn'),
  // houseNumber: string().min(1, 'Huisnummer is verplicht').max(100),
  // streetName: string().min(1, 'Straat is verplicht').max(100),
  // city: string()
  //   .min(1, 'Plaatsnaam is verplicht')
  //   .max(100, 'Plaatsnaam mag niet langer dan 100 characters zijn'),
  phoneNumber: string()
    .min(1, 'Telefoonnummer is verplicht')
    .max(10, 'Telefoonnummer is te lang'),
  password: string()
    .min(8, 'Wachtwoord moet langer dan 8 characters zijn')
    .max(32, 'Wachtwoord moet korter dan 32 characters zijn'),
  // passwordConfirm: string().min(1, 'Herhaal uw wachtwoord'),
  // Algemene voorwaarden
  // terms: literal(true, {
  //   invalid_type_error: 'Accept Terms is required',
  // }),
  // }).refine((data) => data.password === data.passwordConfirm, {
  //   path: ['passwordConfirm'],
  //   message: 'Wachtwoorden komen niet overeen',
})

type RegisterInput = TypeOf<typeof registerSchema>

interface RegisterProps {
  onClose: () => void
  handleChange: () => void
}

export default function Register({ onClose, handleChange }: RegisterProps) {
  const { register: registerAuth } = useAuth()
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState('')
  const ACCOUNT_CREATION_ERROR_MESSAGE =
    'Iets ging verkeerd bij het maken van uw account, probeer het alstublieft opnieuw'
  const ACCOUNT_CREATION_EMAIL_TAKEN_MESSAGE = 'E-mail is al in gebruik'

  const {
    register,
    formState: { errors, isSubmitSuccessful },
    reset,
    handleSubmit,
  } = useForm<RegisterInput>({
    resolver: zodResolver(registerSchema),
  })

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset()
    }
  }, [isSubmitSuccessful, reset])

  const onSubmitHandler: SubmitHandler<RegisterInput> = async (values) => {
    try {
      setLoading(true)
      setError('')
      await registerAuth({
        email: values.email,
        password: values.password,
        name: values.name,
        number: values.phoneNumber,
        address: {
          city: 'test',
          houseNumber: '12',
          postalCode: '1234AB',
          streetName: 'test',
        },
      })
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response.status === 409) {
          setError(ACCOUNT_CREATION_EMAIL_TAKEN_MESSAGE)
        } else {
          setError(ACCOUNT_CREATION_ERROR_MESSAGE)
        }
      } else {
        setError(ACCOUNT_CREATION_ERROR_MESSAGE)
      }
      throw error
    } finally {
      setSuccess(true)
      setLoading(false)
    }
  }

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmitHandler)}
      className="loginContent"
    >
      <TextField
        label="Volledige naam"
        fullWidth
        className="loginTextField"
        required
        error={!!errors['name']}
        helperText={errors['name'] ? errors['name'].message : ''}
        {...register('name')}
      />
      <TextField
        label="E-mail"
        fullWidth
        className="loginTextField"
        required
        type="email"
        error={!!errors['email']}
        helperText={errors['email'] ? errors['email'].message : ''}
        {...register('email')}
        sx={{ mt: '1.4rem' }}
      />
      <TextField
        label="Telefoonnummer"
        fullWidth
        className="loginTextField"
        required
        type="text"
        error={!!errors['email']}
        helperText={errors['phoneNumber'] ? errors['phoneNumber'].message : ''}
        {...register('phoneNumber')}
        sx={{ mt: '1.4rem' }}
      />
      <TextField
        label="Wachtwoord"
        fullWidth
        className="loginTextField"
        required
        type="password"
        error={!!errors['password']}
        helperText={errors['password'] ? errors['password'].message : ''}
        {...register('password')}
        sx={{ mt: '1.4rem' }}
      />
      {!!error ? (
        <Grid item xs={12}>
          <Alert severity="error">{error}</Alert>
        </Grid>
      ) : null}
      {success ? (
        <Grid item xs={12}>
          <Alert severity="success">
            Account aanmaken is succesvol gelukt. Controleer uw mail voor de
            email verificatie.
          </Alert>
        </Grid>
      ) : null}
      <p className="loginRegisterSelect">
        Heb je al een account? <span onClick={handleChange}>Inloggen</span>
      </p>

      <LoadingButton
        variant="contained"
        fullWidth
        type="submit"
        loading={loading}
        size="large"
        sx={{ py: '0.8rem', mt: '1.5rem' }}
      >
        Aanmelden
      </LoadingButton>
    </Box>
  )
}
