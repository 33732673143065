import { Alert, IconButton } from '@mui/material'
import useClickOutside from 'hooks/onClickOutside'
import useWindowWidth from 'hooks/useWindowWidth'
import React, { useRef, useState } from 'react'
import {
  TbCalendarTime,
  TbChevronDown,
  TbClipboardList,
  TbClipboardPlus,
  TbTimelineEventExclamation,
  TbX,
} from 'react-icons/tb'

export default function SidebarHeader({
  reservationType,
  setReservationType,
  setActiveReservation,
}) {
  const [showResOptions, setShowResOptions] = useState(false)
  const reservationSelectRef = useRef(null)
  const windowWidth = useWindowWidth()

  useClickOutside(reservationSelectRef, () => {
    setShowResOptions(false)
  })

  return (
    <div className="trailerSidebarHeader reservations">
      <p>Reserveringen</p>
      <p>
        Bekijk en wijzig jouw huur aanvragen, aankomende- & voltooide
        reserveringen.
      </p>
      <Alert severity="error" style={{ marginTop: 16 }}>
        Gedurende de pilot fase van BuurBak moeten betalingen nog zelfstandig
        tussen de huurder en verhuurder geregeld worden.
      </Alert>
      <div className="reservationsHeaderActions">
        <div
          className="selectReservationSelectContainerWrapper"
          onClick={() => setShowResOptions(!showResOptions)}
          ref={reservationSelectRef}
        >
          <div className="selectTrailerSelectContainer reservations">
            <TbClipboardList />
            <p>{reservationType}</p>
            <TbChevronDown
              className={showResOptions ? 'chevronTranfsormed' : 'chevron'}
            />
          </div>
          {showResOptions && (
            <div className="selectTrailerOptionContainer reservations">
              <div
                className="reservationTypeOption"
                onClick={() => setReservationType('requests')}
              >
                <TbClipboardPlus />
                <p>Huur aanvragen</p>
              </div>
              <div
                className="reservationTypeOption"
                onClick={() => setReservationType('upcoming')}
              >
                <TbTimelineEventExclamation />
                <p>Aankomende reserveringen</p>
              </div>
              <div
                className="reservationTypeOption"
                onClick={() => setReservationType('completed')}
              >
                <TbCalendarTime />
                <p>Voltooide reserveringen</p>
              </div>
              <div
                className="reservationTypeOption"
                onClick={() => setReservationType('all')}
              >
                <TbClipboardList />
                <p>Alle reserveringen</p>
              </div>
            </div>
          )}
        </div>
      </div>
      {windowWidth <= 767 && (
        <IconButton
          className="closeBtn"
          onClick={() => setActiveReservation({})}
        >
          <TbX />
        </IconButton>
      )}
    </div>
  )
}
