import { PlateauTrailer } from 'data/icons/TrailerIcons'
import './TransactionHistory.css'

export default function TransactionHistory() {
  return (
    <div className="aboutReservationContent settings transactions">
      <p>
        Bekijk hier een overzicht van jouw transactie geschiedenis. Klik{' '}
        <u>hier</u> het volledige overzicht
      </p>
      <div className="transactionHistoryContainer">
        <div className="transactionHistoryItem">
          <div className="transactionHistoryIconWrapper">
            <PlateauTrailer size={22} />
          </div>
          <div className="transActionHistoryDetails">
            <p>Bakwagen ongeremd</p>
            <span>21 april 2023</span>
          </div>
          <b>+€34,66</b>
        </div>
        <div className="transactionHistoryItem">
          <div className="transactionHistoryIconWrapper">
            <PlateauTrailer size={22} />
          </div>
          <div className="transActionHistoryDetails">
            <p>Bakwagen ongeremd</p>
            <span>21 april 2023</span>
          </div>
          <b>+€34,66</b>
        </div>
        <div className="transactionHistoryItem">
          <div className="transactionHistoryIconWrapper">
            <PlateauTrailer size={22} />
          </div>
          <div className="transActionHistoryDetails">
            <p>Bakwagen ongeremd</p>
            <span>21 april 2023</span>
          </div>
          <b>+€34,66</b>
        </div>
        <div className="transactionHistoryItem">
          <div className="transactionHistoryIconWrapper">
            <PlateauTrailer size={22} />
          </div>
          <div className="transActionHistoryDetails">
            <p>Bakwagen ongeremd</p>
            <span>21 april 2023</span>
          </div>
          <b>+€34,66</b>
        </div>
        <div className="transactionHistoryItem">
          <div className="transactionHistoryIconWrapper">
            <PlateauTrailer size={22} />
          </div>
          <div className="transActionHistoryDetails">
            <p>Bakwagen ongeremd</p>
            <span>21 april 2023</span>
          </div>
          <b>+€34,66</b>
        </div>
        <div className="transactionHistoryItem">
          <div className="transactionHistoryIconWrapper">
            <PlateauTrailer size={22} />
          </div>
          <div className="transActionHistoryDetails">
            <p>Bakwagen ongeremd</p>
            <span>21 april 2023</span>
          </div>
          <b>+€34,66</b>
        </div>
        <div className="transactionHistoryItem">
          <div className="transactionHistoryIconWrapper">
            <PlateauTrailer size={22} />
          </div>
          <div className="transActionHistoryDetails">
            <p>Bakwagen ongeremd</p>
            <span>21 april 2023</span>
          </div>
          <b>+€34,66</b>
        </div>
        <div className="transactionHistoryItem">
          <div className="transactionHistoryIconWrapper">
            <PlateauTrailer size={22} />
          </div>
          <div className="transActionHistoryDetails">
            <p>Bakwagen ongeremd</p>
            <span>21 april 2023</span>
          </div>
          <b>+€34,66</b>
        </div>
        <div className="transactionHistoryItem">
          <div className="transactionHistoryIconWrapper">
            <PlateauTrailer size={22} />
          </div>
          <div className="transActionHistoryDetails">
            <p>Bakwagen ongeremd</p>
            <span>21 april 2023</span>
          </div>
          <b>+€34,66</b>
        </div>
        <div className="transactionHistoryItem">
          <div className="transactionHistoryIconWrapper">
            <PlateauTrailer size={22} />
          </div>
          <div className="transActionHistoryDetails">
            <p>Bakwagen ongeremd</p>
            <span>21 april 2023</span>
          </div>
          <b>+€34,66</b>
        </div>
        <div className="transactionHistoryItem">
          <div className="transactionHistoryIconWrapper">
            <PlateauTrailer size={22} />
          </div>
          <div className="transActionHistoryDetails">
            <p>Bakwagen ongeremd</p>
            <span>21 april 2023</span>
          </div>
          <b>+€34,66</b>
        </div>
        <div className="transactionHistoryItem">
          <div className="transactionHistoryIconWrapper">
            <PlateauTrailer size={22} />
          </div>
          <div className="transActionHistoryDetails">
            <p>Bakwagen ongeremd</p>
            <span>21 april 2023</span>
          </div>
          <b>+€34,66</b>
        </div>
        <div className="transactionHistoryItem">
          <div className="transactionHistoryIconWrapper">
            <PlateauTrailer size={22} />
          </div>
          <div className="transActionHistoryDetails">
            <p>Bakwagen ongeremd</p>
            <span>21 april 2023</span>
          </div>
          <b>+€34,66</b>
        </div>
      </div>
    </div>
  )
}
