import * as React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import '../layout.css'
import { Range } from '../../../../types/util'

const gridVariants = cva('grid', {
  variants: {
    justifyItems: {
      start: 'jitem--start',
      end: 'jitem--end',
      center: 'jitem--center',
      stretch: 'jitem--stretch',
    },
    alignItems: {
      start: 'aitem--start',
      end: 'aitem--end',
      center: 'aitem--center',
      stretch: 'aitem--stretch',
    },
    justifyContent: {
      start: 'jcontent--start',
      end: 'jcontent--end',
      center: 'jcontent--center',
      stretch: 'jcontent--stretch',
      spaceAround: 'jcontent--space_around',
      spaceBetween: 'jcontent--space_between',
      spaceEvenly: 'jcontent--space_evenly',
    },
    alignContent: {
      start: 'acontent--start',
      end: 'acontent--end',
      center: 'acontent--center',
      stretch: 'acontent--stretch',
      spaceAround: 'acontent--space_around',
      spaceBetween: 'acontent--space_between',
      spaceEvenly: 'acontent--space_evenly',
    },
  },
  defaultVariants: {
    justifyItems: 'stretch',
    alignItems: 'stretch',
    alignContent: 'start',
    justifyContent: 'start',
  },
})

export interface GridProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof gridVariants> {
  gap?: Range<65>
  xs?: Range<25>
  sm?: Range<25>
  md?: Range<25>
  lg?: Range<25>
}

const Grid = ({
  justifyItems,
  alignItems,
  justifyContent,
  alignContent,
  gap = 0,
  className,
  children,
  xs = 1,
  sm,
  md,
  lg,
  ...props
}: GridProps): React.ReactElement => {
  return (
    <div
      className={`${gridVariants({
        justifyItems,
        alignItems,
        justifyContent,
        alignContent,
        className,
      })} gap--${gap} columns--${xs} sm__columns--${sm} md__columns--${md} lg__columns--${lg}`}
    >
      {children}
    </div>
  )
}

export { Grid, gridVariants }
