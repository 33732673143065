import { TrailerOffer } from '../../../types/TrailerOffer'
import '../../../pages/styles/TrailerProfile.css'
import { Button, DatePicker, Flex, FlexItem, Grid, GridItem } from '../../ui'
import { TbMapPin } from 'react-icons/tb'
import {
  TrailerHeightDouble,
  TrailerLengthDouble,
  TrailerWidth2,
  TransportTrailer,
} from '../../../data/icons/TrailerIcons'
import { Tag } from '../../ui/tag/Tag'
import { convertMeterToCentimeter } from '../../../util/conversion'
import { useMatchBreakpoint } from '../../../hooks/use-media-query'
import { Notice } from '../../ui/notice/Notice'
import { AccessoireItems } from '../../../data/dummy/TrailerTypes'
import { GoogleMap, OverlayViewF } from '@react-google-maps/api'
import { FormEvent, useEffect, useRef, useState } from 'react'
import mapStyles from '../../../data/mapStyles'
import * as Form from '@radix-ui/react-form'
import {
  dayPickerConfig,
  dayPickerFromTo,
  Rule,
} from '../../ui/datepicker/DatePicker'
import { add, getTime, sub } from 'date-fns'
import {
  allDatesAfter180Days,
  getDatesInRange,
  getIndexOfDayInTheWeek,
} from '../../../util/date'
import { Link, useNavigate } from 'react-router-dom'
import { instance } from '../../../util/axios-instance'

interface TrailerContentProps {
  trailerOffer: TrailerOffer
}

const TrailerContent = ({ trailerOffer, ...props }: TrailerContentProps) => {
  const [firstDate, setFirstDate] = useState<Date>(null)
  const [secondDate, setSecondDate] = useState<Date>(null)
  const [unavailableApiDatesFirstDate, setUnavailableApiDatesFirstDate] =
    useState<Date[]>([])
  const [unavailableApiDatesSecondDate, setUnavailableApiDatesSecondDate] =
    useState<Date[]>([])
  const [firstDatePickerError, setFirstDatePickerError] = useState<string>(null)
  const [secondDatePickerError, setSecondDatePickerError] =
    useState<string>(null)
  const navigate = useNavigate()

  const { xs, md } = useMatchBreakpoint()
  const mapRef = useRef<google.maps.Map | null>(null)

  const options = {
    styles: mapStyles,
    disableDefaultUI: true,
    clickableIcons: false,
    gestureHandling: 'cooperative',
  }

  const mapContainerStyle = {
    width: '100%',
    height: '100%',
    borderRadius: 8,
  }

  useEffect(() => {
    const getNotAvailableDatesFromApi = async () => {
      const result = await instance
        .get(`/reservations/unavailable_dates/${trailerOffer.id}`)
        .then((res) => res)
        .catch((err) => {
          throw new Error('error')
        })

      const dates: Date[] = result.data.map((date: string) => new Date(date))

      setUnavailableApiDatesFirstDate([...dates, ...allDatesAfter180Days])
      setUnavailableApiDatesSecondDate([...dates, ...allDatesAfter180Days])
    }

    getNotAvailableDatesFromApi()
  }, [])

  useEffect(() => {
    if (firstDate !== null) {
      const allUnavailableDatesInEpoch = unavailableApiDatesFirstDate.map(
        (date) => getTime(date)
      )

      const nearestUnavailableDateAfterFirstDate = Math.min(
        ...allUnavailableDatesInEpoch.filter((num) => num > getTime(firstDate))
      )

      if (nearestUnavailableDateAfterFirstDate !== Infinity) {
        const allNewUnavailableDates = getDatesInRange(
          new Date(nearestUnavailableDateAfterFirstDate),
          add(new Date(), { days: 180 })
        )

        setUnavailableApiDatesSecondDate([
          ...allNewUnavailableDates,
          ...allDatesAfter180Days,
        ])
      } else {
        setUnavailableApiDatesSecondDate([...allDatesAfter180Days])
      }
    }
  }, [firstDate])

  useEffect(() => {
    setSecondDate(null)
  }, [firstDate])

  // TODO: Add normal React-hook-form validation, fast MVP solution
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    firstDate === null
      ? setFirstDatePickerError('Vul dit veld in')
      : setFirstDatePickerError(null)
    secondDate === null
      ? setSecondDatePickerError('Vul dit veld in')
      : setSecondDatePickerError(null)

    if (firstDate !== null && secondDate !== null) {
      navigate(
        `/reserveren/${
          trailerOffer.id
        }?startTime=${firstDate.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })}&endTime=${secondDate.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })}`
      )
    }
  }

  const getNotAvailableDates = () => {
    const notAvailableDates: Array<number> = []

    Object.keys(trailerOffer.trailerWeekScheduleTemplate).forEach(
      (key, index) => {
        if (
          trailerOffer.trailerWeekScheduleTemplate[key].timeSlots.length < 1
        ) {
          notAvailableDates.push(getIndexOfDayInTheWeek(key))
        }
      }
    )

    return notAvailableDates
  }

  const disabledDays: [dayPickerConfig, dayPickerFromTo, ...Array<Date>] = [
    {
      dayOfWeek: getNotAvailableDates(),
    },
    {
      from: new Date(1970, 1, 1),
      to: sub(new Date(), { days: 1 }),
    },
    ...unavailableApiDatesFirstDate,
  ]

  const disabledDaysSecondDatePicker: [
    dayPickerConfig,
    dayPickerFromTo,
    ...Array<Date>
  ] = [
    {
      dayOfWeek: getNotAvailableDates(),
    },
    {
      from: new Date(1970, 1, 1),
      to: sub(firstDate ? firstDate : new Date(), { days: 1 }),
    },
    ...unavailableApiDatesSecondDate,
  ]

  return (
    <>
      <Flex xs="col" lg="row" justifyContent="spaceBetween">
        <div className="trailer__content-title">
          <h2 className="heading-2">{trailerOffer.trailerType.name}</h2>
        </div>
        <Flex gap={16} alignItems="center" wrap="wrap">
          <Tag size={md ? 'sm' : 'sm'} variant="primaryOutline">
            <p>
              €
              {trailerOffer.trailerWeekScheduleTemplate.friday.price.toFixed(2)}
            </p>
          </Tag>
          <Tag size={md ? 'sm' : 'sm'} variant="outline">
            <Flex gap={16} alignItems="center">
              <p>{convertMeterToCentimeter(trailerOffer.length)} m</p>
              <TrailerLengthDouble size={16} color="#000000" />
            </Flex>
          </Tag>
          <Tag size={md ? 'sm' : 'sm'} variant="outline">
            <Flex gap={16} alignItems="center">
              <p>{convertMeterToCentimeter(trailerOffer.height)} m</p>
              <TrailerHeightDouble size={16} color="#000000" />
            </Flex>
          </Tag>
          <Tag size={md ? 'sm' : 'sm'} variant="outline">
            <Flex gap={16} alignItems="center">
              <p>{convertMeterToCentimeter(trailerOffer.width)} m</p>
              <TrailerWidth2 size={16} color="#000000" />
            </Flex>
          </Tag>
          <Tag size={md ? 'sm' : 'sm'} variant="outline">
            <Flex gap={16} alignItems="center">
              <p>{trailerOffer.address.city}</p>
              <TbMapPin size={16} />
            </Flex>
          </Tag>
        </Flex>
      </Flex>
      <Grid xs={2} md={12} gap={32} className="trailer__content_body">
        <GridItem xsSpan={2} mdSpan={8}>
          <Flex gap={24} xs="col">
            <Notice
              title={`Voor dit type aanhanger is een rijbewijs ${trailerOffer.driversLicenseType} verplicht`}
              variant="error"
            />
            <Flex xs="col" gap={12}>
              <h3 className="heading-4">Omschrijving</h3>
              <p className="paragraph-light">{trailerOffer.description}</p>
              <div className="trailer__flex-seperator" />
            </Flex>
            <Flex xs="col" gap={16}>
              <h3 className="heading-4">Accesoires</h3>
              {trailerOffer.accesoires.map((name) => {
                const element = AccessoireItems.find(
                  ({ accessoire }) => accessoire === name
                )

                if (element) {
                  return (
                    <Flex
                      key={element.id}
                      xs="row"
                      gap={12}
                      alignItems="center"
                    >
                      {element.icon}
                      <p className="paragraph-light">{element.accessoire}</p>
                    </Flex>
                  )
                }

                return (
                  <Flex key={name} xs="row" gap={12} alignItems="center">
                    <p className="paragraph-light">{name}</p>
                  </Flex>
                )
              })}
              <div className="trailer__flex-seperator" />
            </Flex>
            <Flex xs="col" gap={16}>
              <h3 className="heading-4">
                Locatie <span>| Omgeving {trailerOffer.address.city}</span>
              </h3>
              <div className="trailer__map">
                <GoogleMap
                  zoom={15.5}
                  center={{
                    lat: trailerOffer.nearbyLatitude,
                    lng: trailerOffer.nearbyLongitude,
                  }}
                  options={options}
                  mapContainerStyle={mapContainerStyle}
                >
                  <OverlayViewF
                    position={{
                      lat: trailerOffer.nearbyLatitude,
                      lng: trailerOffer.nearbyLongitude,
                    }}
                    mapPaneName={'markerLayer'}
                  >
                    <div className="trailer__map_marker">
                      <TransportTrailer size={24} />
                    </div>
                  </OverlayViewF>
                </GoogleMap>
              </div>
            </Flex>
          </Flex>
        </GridItem>
        <GridItem xsSpan={2} mdSpan={4}>
          <Flex
            className="trailer__profile_header"
            alignItems="center"
            gap={16}
          >
            <img
              className="trailer__profile_header_image"
              src={trailerOffer.owner.profilePictureUrl}
            />
            <Flex xs="col" gap={4}>
              <span className="">{trailerOffer.owner.name}</span>
              <p>Verhuurder</p>
            </Flex>
          </Flex>
          <Flex className="trailer__profile_hire" xs="col" gap={8}>
            <Form.Root>
              <Flex xs="col" gap={8}>
                <DatePicker
                  selected={firstDate}
                  setSelected={setFirstDate}
                  label="Van"
                  rules={[
                    Rule.shouldNotAllowDateBeforeCurrentDate,
                    Rule.shouldDisallowSpecificWeekDays,
                    Rule.shouldDisallowDisabledApiDays,
                    Rule.shouldDisallowAllDatesAfterSpecifiedDuration,
                  ]}
                  fromMonth={new Date()}
                  toMonth={add(new Date(), { days: 180 })}
                  disabledDays={disabledDays}
                  duration={{ days: 180 }}
                  passedError={firstDatePickerError}
                />
                <DatePicker
                  selected={secondDate}
                  setSelected={setSecondDate}
                  label="Tot"
                  rules={[
                    Rule.shouldDisallowDisabledApiDays,
                    Rule.shouldNotAllowDateBeforeCurrentDate,
                    Rule.shouldBeAfterSpecifiedDate,
                    Rule.shouldDisallowSpecificWeekDays,
                    Rule.shouldDisallowAllDatesAfterSpecifiedDuration,
                  ]}
                  fromMonth={new Date()}
                  toMonth={add(new Date(), { days: 180 })}
                  specifiedDate={firstDate}
                  disabledDays={disabledDaysSecondDatePicker}
                  duration={{ days: 180 }}
                  passedError={secondDatePickerError}
                />
                <div className="trailer__flex-seperator" />
                <Flex
                  className="trailer__profile_hire_total"
                  xs="row"
                  justifyContent="spaceBetween"
                >
                  <p className="paragraph-light">Totaal</p>
                  <p className="trailer__profile_hire_total_price">
                    €
                    {trailerOffer.trailerWeekScheduleTemplate.friday.price.toFixed(
                      2
                    )}{' '}
                    per dag
                  </p>
                </Flex>
                <Form.Submit asChild>
                  <Button
                    type="submit"
                    fullWidth
                    size="sm"
                    onClick={handleSubmit}
                  >
                    Reserveer nu
                  </Button>
                </Form.Submit>
              </Flex>
            </Form.Root>
          </Flex>
        </GridItem>
      </Grid>
    </>
  )
}

export { TrailerContent }
