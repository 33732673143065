import { Flex } from '../../layout'
import * as Checkbox from '@radix-ui/react-checkbox'
import { TbCheck } from 'react-icons/tb'
import '../form.css'
import { Controller } from 'react-hook-form'

export interface CheckBoxProps
  extends React.InputHTMLAttributes<typeof HTMLInputElement> {
  label?: string
  name?: string
  control
  setValue
}

const CheckBox = ({
  name = 'checkbox',
  label,
  control,
  setValue,
}: CheckBoxProps) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, formState: { errors } }) => (
        <>
          {errors[name]?.message && (
            <p className="checkbox__error" style={{ marginBottom: '4px' }}>
              {errors[name].message.toString()}
            </p>
          )}
          <Flex xs="row" wrap="wrap" gap={8}>
            <Checkbox.Root
              {...field}
              value={undefined}
              checked={field.value}
              className={`form__checkbox ${
                field.value === true ? 'checked' : ''
              }`}
              onCheckedChange={() => setValue(name, !field.value)}
              id="c1"
            >
              <Checkbox.Indicator className="checkbox__indicator">
                <TbCheck size={12} />
              </Checkbox.Indicator>
            </Checkbox.Root>
            <label className="checkbox__label" htmlFor="c1">
              {label}
            </label>
          </Flex>
        </>
      )}
    />
  )
}

export { CheckBox }
