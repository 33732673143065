import { useCallback, useRef } from 'react'
import { Avatar } from '@mui/material'
import ProfilePicture from 'components/util/ProfilePicture'
import { TbPencil } from 'react-icons/tb'

export default function UpdateProfileImage({
  user,
  profileImage,
  setProfileImage,
}) {
  const fileInputRef = useRef(null)

  const handleFileInput = useCallback((e) => {
    const file = e.target.files[0]
    if (file) {
      const previewUrl = URL.createObjectURL(file)
      setProfileImage({ file, previewUrl })
    }
  }, [])

  return (
    <div
      className="changeProfileImageContainer"
      onClick={() => fileInputRef.current.click()}
    >
      {profileImage.previewUrl ? (
        <Avatar
          src={profileImage.previewUrl}
          style={{ width: 110, height: 110 }}
        />
      ) : (
        <ProfilePicture dimension={110} user={user} />
      )}
      <div className="editProfilePicture">
        <TbPencil />
      </div>
      <input
        type="file"
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={handleFileInput}
        accept="image/*"
      />
    </div>
  )
}
