import './TrailerFormHeader.css'
import Logo from '../constants/header/Logo'
import {
  Stack,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepIconProps,
  StepLabel,
  Stepper,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import {
  TbCalendar,
  TbCurrencyEuro,
  TbDeviceDesktopAnalytics,
  TbFileDescription,
  TbId,
  TbMapPin,
  TbPolaroid,
  TbStars,
} from 'react-icons/tb'
import LanguagePopup from 'components/constants/selectLanguage/LanguagePopup'
import { useTranslation } from 'react-i18next'
import { Button } from 'components/ui'

type CustomStepIconProps = StepIconProps & {
  disabled?: boolean
}

interface Day {
  price: number
  timeSlots: Array<{ startTime: string; endTime: string }>
}

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 19,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: 'var(--primary)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: 'var(--primary)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#ccc',
    borderRadius: 1,
  },
  [`&.${stepConnectorClasses.disabled} .${stepConnectorClasses.line}`]: {
    backgroundColor: '#ccc',
  },
}))

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean; disabled?: boolean }
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 40,
  height: 40,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: 'var(--primary)',
    cursor: 'pointer',
  }),
  ...(ownerState.completed && {
    backgroundColor: 'var(--primary)',
    cursor: 'pointer',
  }),
  ...(ownerState.disabled && {
    backgroundColor: '#ccc',
    cursor: 'default',
  }),
}))

function ColorlibStepIcon(props: CustomStepIconProps) {
  const { active, completed, className, icon, disabled } = props

  const icons: { [index: string]: React.ReactElement } = {
    1: (
      <img
        width={21}
        alt="icon"
        src={'/trailerIcons/white/plateau_icon_small_w.svg'}
      />
    ),
    2: <TbStars />,
    3: <TbId />,
    4: <TbPolaroid />,
    5: <TbDeviceDesktopAnalytics />,
    6: <TbFileDescription />,
    7: <TbMapPin />,
    8: <TbCurrencyEuro />,
    9: <TbCalendar />,
  }

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active, disabled }}
      className={className}
    >
      {icons[String(icon)]}
    </ColorlibStepIconRoot>
  )
}

export default function TrailerFormHeader({
  currentStepIndex,
  setCurrentStepIndex,
  trailerType,
  description,
  trailerImages,
  trailerStats,
  address,
  price,
  trailerAccessoires,
  license,
  trailerWeekScheduleTemplate,
}) {
  const { t } = useTranslation('TrailerForm')
  const steps = [
    { label: 'Type', isCompleted: currentStepIndex >= 1 || trailerType !== '' },
    {
      label: 'Accessoires',
      isCompleted:
        currentStepIndex >= 2 ||
        (currentStepIndex < 2 && trailerType && license !== ''),
      disabled: trailerType === !trailerType,
    },
    {
      label: 'Rijbewijs',
      isCompleted: currentStepIndex >= 3 || license !== '',
      disabled: trailerType === '',
    },
    {
      label: 'Fotos',
      isCompleted: currentStepIndex >= 4 || trailerImages.length >= 4,
      disabled: trailerType === '' || license === '',
    },
    {
      label: 'Statistieken',
      isCompleted:
        currentStepIndex >= 5 || Object.values(trailerStats).every((x) => !!x),
      disabled:
        trailerType === '' || license === '' || trailerImages.length < 4,
    },
    {
      label: 'Omschrijving',
      isCompleted: currentStepIndex >= 6 || description.length > 0,
      disabled:
        trailerType === '' ||
        license === '' ||
        trailerImages.length < 4 ||
        !Object.values(trailerStats).every((x) => !!x),
    },
    {
      label: 'Locatie',
      isCompleted:
        currentStepIndex >= 7 || Object.values(address).every((x) => !!x),
      disabled:
        trailerType === '' ||
        license === '' ||
        !Object.values(trailerStats).every((x) => !!x) ||
        description.length <= 0,
    },
    {
      label: 'Prijs',
      isCompleted: currentStepIndex === 8 || (price !== '' && price !== 0),
      disabled:
        trailerType === '' ||
        license === '' ||
        trailerImages.length < 4 ||
        !Object.keys(trailerStats).every((x) => !!x) ||
        description.length <= 0 ||
        Object.keys(address).every((x) => !x),
    },
    {
      label: 'Availability',
      isCompleted: currentStepIndex === 9,
      disabled:
        trailerType === '' ||
        license === '' ||
        trailerImages.length < 4 ||
        !Object.keys(trailerStats).every((x) => !!x) ||
        description.length <= 0 ||
        Object.keys(address).every((x) => !x || (price === 0 && price === '')),
    },
  ]

  function handleClick(stepIndex: number) {
    const clickedStep = steps[stepIndex]
    if (clickedStep.disabled) {
      return
    }

    const canAccessStep =
      (stepIndex === 0 && trailerType) ||
      (stepIndex === 1 && trailerAccessoires.length >= 0) ||
      (stepIndex === 2 && license) ||
      (stepIndex === 3 && trailerImages.length > 0) ||
      (stepIndex === 4 && Object.values(trailerStats).every((x) => !!x)) ||
      (stepIndex === 5 && description) ||
      (stepIndex === 6 && Object.values(address).every((x) => !!x)) ||
      (stepIndex === 7 && price !== 0 && price !== '') ||
      (stepIndex === 8 &&
        Object.values<Day>(trailerWeekScheduleTemplate).some(
          (day) => day.timeSlots.length > 0
        ))
    if (canAccessStep) {
      setCurrentStepIndex(stepIndex + 1)
    }
  }

  return (
    <div className="trailerFormHeaderContainer">
      <Logo />
      {currentStepIndex !== 0 && currentStepIndex !== 10 && (
        <div className="progressBar">
          <Stack sx={{ width: '100%' }} spacing={4}>
            <Stepper
              alternativeLabel
              activeStep={currentStepIndex - 1}
              connector={<ColorlibConnector />}
            >
              {steps.map(({ label, isCompleted, disabled }, index) => (
                <Step
                  key={label}
                  onClick={() => handleClick(index)}
                  completed={Boolean(isCompleted)}
                  disabled={disabled}
                >
                  <StepLabel
                    StepIconComponent={(props) => (
                      <ColorlibStepIcon
                        disabled={steps[props.icon - 1].disabled}
                        {...props}
                      />
                    )}
                  />
                </Step>
              ))}
            </Stepper>
          </Stack>
        </div>
      )}
      <div className="trailerFormHeaderActions flexbox">
        {currentStepIndex > 0 && (
          <Button
            variant="blackOutline"
            radius="full"
            style={{ marginLeft: '10px' }}
          >
            {t('general.saveConcept')}
          </Button>
        )}
      </div>
    </div>
  )
}
