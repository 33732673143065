import { TbChevronRight } from 'react-icons/tb'

export default function SidebarShortcut({ icon, title, onClick }) {
  return (
    <div className="sidebarShortcutItem" onClick={onClick}>
      {icon}
      <p>{title}</p>
      <TbChevronRight />
    </div>
  )
}
