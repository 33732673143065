import { IconButton, TextareaAutosize } from '@mui/material'
import { useRef, useState } from 'react'
import {
  TbBan,
  TbChevronDown,
  TbChevronLeft,
  TbChevronRight,
  TbX,
} from 'react-icons/tb'
import './CancelReservations.css'
import { CANCEL_REASONING } from '../../../../../../data/dummy/CancelationData'
import useClickOutside from '../../../../../../hooks/onClickOutside'
import { instance } from '../../../../../../util/axios-instance'

export default function CancelReservations({
  setContentInView,
  showReservation,
}) {
  const [showCancelInfo, setShowCancelInfo] = useState(false)

  const [showCancelOptions, setShowCancelOptions] = useState(false)
  const [cancelationReason, setCancelationReason] = useState<any>({})
  const [differentReason, setDifferentReason] = useState('')
  const [messageToOwner, setMessageToOwner] = useState('')

  const selectRef = useRef(null)

  useClickOutside(selectRef, () => setShowCancelOptions(false))

  const cancelReservation = () => {
    const reason =
      cancelationReason.type === 'different'
        ? differentReason
        : cancelationReason.reason

    if (!reason || !messageToOwner) return

    instance
      .put(`/reservations/${showReservation.id}/cancel`, {
        reason,
        description: messageToOwner,
      })
      .then((res) => {
        window.location.reload()
      })
  }

  return (
    <div className="sideBarSettingsWrapper">
      <div className="sidebarSettingsHeader">
        <IconButton className="goBack" onClick={() => setContentInView('')}>
          <TbChevronLeft />
        </IconButton>
        <p>Reservering annuleren</p>
        <p>
          Annuleer de reservering van Matthijs voor bakwagen ongeremd op 23
          april.
        </p>
      </div>
      <div className="aboutReservationContent">
        <div className="cancelItem">
          <p>
            Reden voor annulering <span>(verplicht)</span>
          </p>
          <div className="selectContainerWrapper" ref={selectRef}>
            <div
              className="selectContainer"
              onClick={() => setShowCancelOptions(!showCancelOptions)}
            >
              <p>{cancelationReason?.reason || 'Reden voor annulering'}</p>
              <TbChevronDown
                className={showCancelOptions ? 'chevronTranfsormed' : 'chevron'}
              />
            </div>
            {showCancelOptions && (
              <div className="selectOptionsContainer">
                {CANCEL_REASONING.map((item, index) => (
                  <p
                    key={index}
                    onClick={() => {
                      setCancelationReason(item)
                      setShowCancelOptions(false)
                    }}
                  >
                    {item.reason}
                  </p>
                ))}
              </div>
            )}
            {cancelationReason.type === 'different' && (
              <div className="inputContainer cancel">
                <input
                  placeholder="Reden..."
                  value={differentReason}
                  onChange={(e) => setDifferentReason(e.target.value)}
                />
              </div>
            )}
          </div>
        </div>
        <div className="cancelItem">
          <p>
            Bericht aan {showReservation.renter.name} <span>(verplicht)</span>
          </p>
          <TextareaAutosize
            className="autoSizeTextarea cancelPopup"
            placeholder="Bericht aan huurder..."
            value={messageToOwner}
            onChange={(e) => setMessageToOwner(e.target.value)}
          />
        </div>
        <button
          className={`primaryBtn cancel ${
            (!cancelationReason.reason || !messageToOwner) && 'disabledBtn'
          }`}
          disabled={!cancelationReason.reason || !messageToOwner}
          onClick={cancelReservation}
        >
          Reservering annuleren
        </button>
      </div>
      {showCancelInfo && (
        <SidebarOverlay setShowCancelInfo={setShowCancelInfo} />
      )}
    </div>
  )
}

function SidebarOverlay({ setShowCancelInfo }) {
  return (
    <div className="sidebarOverlayWrapper">
      <div className="blur" onClick={() => setShowCancelInfo(false)} />
      <div className="sidebarOverlayContainer">
        <IconButton
          className="closeOverlay"
          onClick={() => setShowCancelInfo(false)}
        >
          <TbX />
        </IconButton>
        <p>Annuleer reservering</p>
        <div className="sidebarSettingsContentContainer variable cancel">
          <p>Hoe werkt annuleren</p>
          <span>
            Aan het annuleren van een reservering zitten een aantal richtlijnen
            en voorwaarden gekoppeld, voor meer informatie klik op de link.
          </span>
          <div className="sidebarShortcutItem price cancel">
            <TbBan className="sidebarIcon" />
            <p>Bekijk annuleringsvoorwaarden</p>
            <TbChevronRight />
          </div>
        </div>
        <div className="cancelItem">
          <p>
            Reden voor annulering <span>(verplicht)</span>
          </p>
          <div className="selectContainer">
            <p>Reden voor annulering</p>
            <TbChevronDown />
          </div>
        </div>
        <div className="cancelItem">
          <p>
            Bericht aan Matthijs <span>(verplicht)</span>
          </p>
          <TextareaAutosize
            className="autoSizeTextarea"
            placeholder="Bericht aan huurder..."
          />
        </div>
        <div className="overlayFooter">
          <button className="primaryBtn cancel">Bevestig annulering</button>
        </div>
      </div>
    </div>
  )
}
