import { useState, useCallback, Key } from 'react'
import { IoIosImages } from 'react-icons/io'
import './ImageUpload.css'
import { useDropzone, FileRejection } from 'react-dropzone'
import { TbDots, TbPolaroid, TbTrash } from 'react-icons/tb'
import Error from '../Error'
import { useTranslation } from 'react-i18next'

type ImagePreview = {
  file: File
  preview: string
}

type ImageUploadProps = {
  trailerImages: ImagePreview[]
  setTrailerImages: React.Dispatch<React.SetStateAction<ImagePreview[]>>
}

export default function ImageUpload({
  trailerImages,
  setTrailerImages,
}: ImageUploadProps) {
  const { t } = useTranslation('TrailerForm')
  const [activeMenu, setActiveMenu] = useState('')
  const [fileRejections, setFileRejections] = useState<FileRejection[]>([])
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpg'],
      'image/jpeg': ['.jpeg'],
      'image/heic': ['.heic'],
    },
    onDrop: useCallback(
      (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
        const newImages = acceptedFiles.map((file) => ({
          file,
          preview: URL.createObjectURL(file),
        }))
        setFileRejections(rejectedFiles)
        setTrailerImages([...trailerImages, ...newImages])
      },
      [trailerImages, setTrailerImages]
    ),
  })

  const handleImageMenuState = (image: any) => {
    if (activeMenu === image.preview) {
      setActiveMenu('')
    } else {
      setActiveMenu(image.preview)
    }
  }

  const changeMainPicture = (image: ImagePreview) => {
    setActiveMenu(image.preview)
    const imagesCopy = [...trailerImages]
    const index = imagesCopy.indexOf(image)
    imagesCopy.splice(index, 1)
    imagesCopy.unshift(image)
    setTrailerImages(imagesCopy)
    setActiveMenu('')
  }

  const images = trailerImages.map((image: ImagePreview, index: Key) => (
    <div
      key={index}
      className="imageUploadContainerSmall"
      {...getRootProps()}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <img src={image.preview} alt="preview" className="previewImages" />
      {index === 0 && (
        <div className="coverFoto">{t('images.imageOptions.coverImage')}</div>
      )}
      <div>
        <button
          type="button"
          className="showImageMenu"
          onClick={(e) => {
            e.stopPropagation()
            handleImageMenuState(image)
          }}
        >
          <TbDots size="16px" />
        </button>
        {activeMenu === image.preview && (
          <div className="imageMenu" onClick={(e) => e.stopPropagation()}>
            <div
              className="imageMenuItem"
              onClick={() => changeMainPicture(image)}
            >
              <p>{t('images.imageOptions.setCoverImage')}</p>
              <TbPolaroid />
            </div>
            <div
              className="imageMenuItem"
              onClick={(event) => {
                event.stopPropagation()
                setTrailerImages(
                  trailerImages.filter((img: ImagePreview) => img !== image)
                )
              }}
            >
              <p>{t('images.imageOptions.deleteImage')}</p>
              <TbTrash />
            </div>
          </div>
        )}
      </div>
    </div>
  ))

  const imageGridSmall = images.slice(0, 2)
  const imageGridBottom = images.slice(2)

  return (
    <div className="formStepContainer">
      <h2>{t('images.title')}</h2>
      <p>{t('images.introduction')}</p>
      {images.length >= 4 && (
        <div
          className="imageUploadFullWidth"
          {...getRootProps()}
          style={{ height: '250px' }}
        >
          <IoIosImages size="42px" />
          <div className="imageUploadText">
            <p>{t('images.dropzoneTitle')}</p>
            <span>{t('images.dropzoneSubTitle')}</span>
          </div>
          <input {...getInputProps()} />
        </div>
      )}
      <div
        className={
          images.length < 4 ? 'imageUploadGrid' : 'extraImageUploadContainer'
        }
      >
        {images.length < 4 && (
          <div
            className="imageUploadContainer"
            {...getRootProps()}
            style={
              images.length === 0 ? { width: '150%', height: '550px' } : null
            }
          >
            <IoIosImages size="56px" />
            <p>{t('images.dropzoneTitle')}</p>
            <p>{t('images.dropzoneSubTitle')}</p>
            <span>{t('images.dropzoneCTA')}</span>
            <input {...getInputProps()} />
          </div>
        )}
        <div className="imageUploadFlexBox">{imageGridSmall}</div>
      </div>
      {images.length > 2 && (
        <div className="extraImageUploadContainer">
          <div>{imageGridBottom}</div>
        </div>
      )}
      {fileRejections.length > 0 && (
        <Error setFileRejections={setFileRejections} />
      )}
    </div>
  )
}
