import { IconButton } from '@mui/material'
import { useAuth } from 'hooks/use-auth'
import { useRef, useState } from 'react'
import { TbEye, TbEyeOff } from 'react-icons/tb'
import { instance } from 'util/axios-instance'
import './ChangePassword.css'

export default function ChangePassword() {
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [error, setError] = useState(null)
  const currentPasswordRef = useRef(null)
  const newPasswordRef = useRef(null)
  const { logout } = useAuth()

  const setFirstFocus = () => {
    currentPasswordRef.current.focus()
  }

  const setSecondFocus = () => {
    newPasswordRef.current.focus()
  }

  const updateUserPassword = async () => {
    try {
      await instance.put('/auth/change-password', {
        currentPassword: currentPassword,
        newPassword: newPassword,
      })
      setError(null)
      logout()
    } catch (e) {
      if (e.response && e.response.status === 400) {
        setError(
          'Het opgegeven wachtwoord en het huidige wachtwoord komen niet overeen'
        )
      }
    }
  }

  return (
    <div className="aboutReservationContent settings changePassword">
      <span>Huidig wachtwoord</span>
      <div className="inputSpanContainer" onClick={() => setFirstFocus()}>
        <span style={{ marginBottom: 0 }}>Huidig wachtwoord</span>
        <input
          type={showCurrentPassword ? 'text' : 'password'}
          placeholder="********"
          value={currentPassword}
          ref={currentPasswordRef}
          onChange={(e) => setCurrentPassword(e.target.value)}
        />
        <IconButton
          className="inputIcon"
          onClick={() => setShowCurrentPassword((current) => !current)}
        >
          {showCurrentPassword ? <TbEyeOff /> : <TbEye />}
        </IconButton>
      </div>
      {error && <span className="errorMessage">* {error}</span>}
      <div className="divider settings" />
      <span>Nieuw wachtwoord</span>
      <div className="inputSpanContainer" onClick={() => setSecondFocus()}>
        <span>Nieuw wachtwoord</span>
        <input
          type={showNewPassword ? 'text' : 'password'}
          placeholder="********"
          value={newPassword}
          ref={newPasswordRef}
          onChange={(e) => setNewPassword(e.target.value)}
        />
        <IconButton
          className="inputIcon"
          onClick={() => setShowNewPassword((current) => !current)}
        >
          {showNewPassword ? <TbEyeOff /> : <TbEye />}
        </IconButton>
      </div>
      <button
        className={`saveChangesBtn ${
          (!currentPassword || !newPassword) && 'disabledBtn'
        }`}
        style={{ marginTop: 'auto' }}
        onClick={() => updateUserPassword()}
        disabled={!currentPassword || !newPassword}
      >
        Wijzigingen opslaan
      </button>
    </div>
  )
}
