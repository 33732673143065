import './PaymentMethods.css'

export default function PaymentMethods() {
  return (
    <div className="aboutReservationContent settings transactions">
      <p>
        Op dit moment is het helaas nog niet mogelijk om een standaard
        betaalmethode in te stellen, alle betalingen moeten via Ideal gedaan
        worden. Kom snel terug op deze pagina om standaard betaalmethodes in te
        stellen.
      </p>
    </div>
  )
}
