import { Alert, Button, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { TbCircleCheck } from 'react-icons/tb'
import Loading from '../components/util/Loading'
import useAxios from '../hooks/use-axios'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import { instance } from 'util/axios-instance'

export default function Success() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [result, setResult] = useState()

  useEffect(() => {
    async function fetchData() {
      const reservation = await instance.get(
        `/reservations/${searchParams.get('id')}`
      )
      const payment = await instance.get(
        `/payment/${reservation.data.transactionDetails.transactionId}/status`
      )
      setResult(payment.data)
    }
    fetchData()
  }, [])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '25vh',
      }}
    >
      {result == 'succeeded' && (
        <>
          <TbCircleCheck color="green" size="106px" />
          <Typography color="primary" fontSize="23px">
            Betaling was succesvol!
          </Typography>
          <Typography color="primary" fontSize="23px">
            Uw reservering is gemaakt.
          </Typography>
        </>
      )}
      {result == 'payment_failed' && (
        <>
          <TbCircleCheck color="green" size="106px" />
          <Typography color="primary" fontSize="23px">
            Betaling is gefaald!
          </Typography>
          <Typography color="primary" fontSize="23px">
            Ga naar uw reservering om het opnieuw te proberen.
          </Typography>
        </>
      )}
      {result == 'processing' && (
        <>
          <Loading />
          <Typography color="primary" fontSize="23px">
            Wachten op betaling.
          </Typography>
        </>
      )}
      {!result && (
        <>
          <Loading />
          <Typography color="primary" fontSize="23px">
            Wachten op betaling.
          </Typography>
        </>
      )}
      <Button variant="contained" style={{ marginTop: '20px' }} href="/">
        Home
      </Button>
    </div>
  )
}
