import '../form.css'

import * as Form from '@radix-ui/react-form'
import { FieldValues, RegisterOptions, UseFormRegister } from 'react-hook-form'

export interface TextFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string
  leftIcon?: React.ReactElement
  rightIcon?: React.ReactElement
  register: UseFormRegister<FieldValues>
  error?: string
}

const TextField = ({
  name = 'input',
  type = 'text',
  required,
  label,
  placeholder,
  leftIcon,
  rightIcon,
  register,
  error,
  ...props
}: TextFieldProps) => {
  return (
    <Form.Field className="form__field" name={name}>
      <div className="header">
        <Form.Label className="label">{label}</Form.Label>
        {error && <Form.Message className="message">{error}</Form.Message>}
      </div>
      <Form.Control asChild>
        <div
          className={`control ${error ? 'control__error' : 'control__normal'}`}
        >
          {leftIcon && <div className="left__icon">{leftIcon}</div>}
          <input
            className="input"
            type={type}
            required={required ? true : false}
            placeholder={placeholder ? placeholder : ''}
            {...register(name)}
            {...props}
          />
          {rightIcon && <div className="right__icon">{rightIcon}</div>}
        </div>
      </Form.Control>
    </Form.Field>
  )
}

export { TextField }
