import { IconButton } from '@mui/material'
import { useEffect, useState } from 'react'
import {
  TbCalendar,
  TbChevronLeft,
  TbChevronRight,
  TbCurrencyEuro,
} from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import useUpdateTrailer from '../../hooks/useUpdateTrailer'
import './SidebarPrice.css'
import useGeneralTrailerPrice from '../../../../../../hooks/useGeneralTrailerPrice'

export default function SidebarPrice({
  trailerInfo,
  setTrailerInfo,
  setSidebarContent,
}) {
  const [trailerPrice, setTrailerPrice] = useState<any>(0)
  const navigate = useNavigate()
  const mostFrequentPrice = useGeneralTrailerPrice(
    trailerInfo.trailerWeekScheduleTemplate
  )
  const updateTrailerData = useUpdateTrailer(trailerInfo)

  const handleUpdateField = async (field, value) => {
    if (field === 'price') {
      for (const day in trailerInfo.trailerWeekScheduleTemplate) {
        trailerInfo.trailerWeekScheduleTemplate[day].price = value
      }
    }

    const updatedData = await updateTrailerData(field, value)
    setTrailerInfo(updatedData)
    setSidebarContent('')
    window.location.reload()
  }

  useEffect(() => {
    if (mostFrequentPrice) {
      setTrailerPrice(mostFrequentPrice)
    } else {
      setTrailerPrice(0)
    }
  }, [trailerInfo])

  return (
    <div className="sideBarSettingsWrapper">
      <div className="sidebarSettingsHeader">
        <IconButton className="goBack" onClick={() => setSidebarContent('')}>
          <TbChevronLeft />
        </IconButton>
        <p>Algemene huurprijs</p>
        <p>Wijzig de algemene dagelijkse huurprijs van de aanhanger.</p>
      </div>
      <div className="sidebarSettingsContentContainer price">
        <p>Huurprijs per dag</p>
        <div className="searchbox price">
          <TbCurrencyEuro />
          <input
            type="number"
            placeholder=""
            value={trailerPrice}
            onChange={(e) =>
              setTrailerPrice(e.target.value ? Number(e.target.value) : '')
            }
          />
        </div>
      </div>
      <div className="divider sidebar" />
      <div className="sidebarSettingsContentContainer variable">
        <p>Huurprijs per dag</p>
        <span>
          Variabele huurprijzen kunnen via het kalender overzicht worden
          ingesteld en aangepast.
        </span>
        <div
          className="sidebarShortcutItem price"
          onClick={() => navigate('/owner/kalender')}
        >
          <TbCalendar className="sidebarIcon" />
          <p>Bekijk kalenderoverzicht</p>
          <TbChevronRight />
        </div>
      </div>
      <div className="divider sidebar" />
      {trailerPrice !== mostFrequentPrice && (
        <div className="sidebarSettingsFooterAction updated">
          <button
            className="sidebarActionButton"
            onClick={() => handleUpdateField('price', trailerPrice)}
          >
            Wijzigingen opslaan
          </button>
        </div>
      )}
    </div>
  )
}
