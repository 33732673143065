import './Restitution.css'
import { useState, useRef } from 'react'
import { TbUpload } from 'react-icons/tb'
import { PlateauTrailer } from 'data/icons/TrailerIcons'

export default function Restitution() {
  const [linkToAd, setLinkToAd] = useState('')
  const [restitutionDescription, setRestitutionDescription] = useState('')
  const firstInputRef = useRef(null)

  return (
    <div className="aboutReservationContent settings transactions">
      <p>
        Het spijt ons om te horen dat jouw reservering van Bakwagen ongeremd op
        12 aug 2023 niet naar wens is verlopen. Om een restitutie aanvraag te
        doen vragen we je wat extra informatie op te geven.
      </p>
      <div className="transactionHistoryItem restitution">
        <div className="transactionHistoryIconWrapper">
          <PlateauTrailer size={22} />
        </div>
        <div className="transActionHistoryDetails">
          <p>Bakwagen ongeremd</p>
          <span>21 april 2023</span>
        </div>
        <b>€34,66</b>
      </div>
      <span>Reserveringsdetails</span>
      <div
        className="inputSpanContainer"
        onClick={() => firstInputRef.current.focus()}
        style={{ marginTop: 5 }}
      >
        <span>Link naar advertentie</span>
        <input
          type="text"
          value={linkToAd}
          ref={firstInputRef}
          placeholder="www.buurbak.nl/relevante-advertentie"
          onChange={(e) => setLinkToAd(e.target.value)}
        />
      </div>
      <span>Extra informatie</span>
      <p style={{ marginTop: 0 }}>
        Beschrijf de ondervonden problemen tijdens je reservering.
      </p>
      <textarea
        placeholder="Omschrijving restitutie aanvraag..."
        value={restitutionDescription}
        onChange={(e) => setRestitutionDescription(e.target.value)}
      />
      <span>Upload relevante bestanden</span>
      <p style={{ marginTop: 0 }}>
        Upload alle relevante bestanden & afbeeldingen omtrent jouw restitutie
        aanvraag.
      </p>
      <div className="uploadFilesContainer">
        <p>Relevante bestanden uploaden</p>
        <TbUpload />
      </div>
      <button
        className={`saveChangesBtn ${
          (!linkToAd || !restitutionDescription) && 'disabledBtn'
        }`}
      >
        Aanvraag versturen
      </button>
    </div>
  )
}
