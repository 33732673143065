import { TbChevronUp, TbPlus, TbSearch } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'

export function MobileTrailerHeader({
  search,
  setSearch,
  sortColumn,
  setShowSortBy,
  startTransition,
}) {
  const navigate = useNavigate()

  const filterText =
    (sortColumn === 'price' && 'prijs') ||
    (sortColumn === 'createdAt' && 'datum') ||
    (sortColumn === 'trailerType.name' && 'type')

  return (
    <div className="mobileTrailerTableHeader">
      <div className="mobileTrailerTableTitleContainer">
        <p>Mijn aanhangers</p>
        <button
          className="borderIconBtn"
          onClick={() => {
            startTransition(() => {
              navigate('/verhuren')
            })
          }}
        >
          <TbPlus />
        </button>
      </div>
      <div className="searchbox mobile">
        <TbSearch />
        <input
          placeholder="Zoeken door mijn aanhangers..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className="mobileTrailerSortByContainer">
        <p>Aanhanger</p>
        <div
          className="customSelect mobile"
          onClick={() => setShowSortBy(true)}
        >
          <p>Sorteren op: {filterText}</p>
          <TbChevronUp />
        </div>
      </div>
    </div>
  )
}
