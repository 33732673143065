import { format, isSameMonth } from 'date-fns'
import { nl } from 'date-fns/locale'
import { useMemo } from 'react'

export function useReservationsInRange(
  startDate,
  endDate,
  getActiveReservations
) {
  return useMemo(() => {
    let date = new Date(startDate)
    let activeReservationsInRange = []
    let reservationIds = new Set()

    while (date <= endDate) {
      const activeReservations = getActiveReservations(new Date(date))

      for (const reservation of activeReservations) {
        if (!reservationIds.has(reservation.id)) {
          activeReservationsInRange.push(reservation)
          reservationIds.add(reservation.id)
        }
      }

      date.setDate(date.getDate() + 1)
    }

    return activeReservationsInRange
  }, [startDate, endDate, getActiveReservations])
}

export function useDateDisplay(selectedRange, isSingleDay) {
  return useMemo(() => {
    if (isSingleDay) {
      return (
        <b>
          {format(selectedRange.startDate, 'EEEE dd MMMM', {
            locale: nl,
          }).replace(/^./, (match) => match.toUpperCase())}
        </b>
      )
    } else {
      return (
        <div>
          <b>
            {format(
              selectedRange.startDate,
              `${
                isSameMonth(selectedRange.startDate, selectedRange.endDate)
                  ? 'EEE dd'
                  : 'EEE dd MMM'
              }`,
              {
                locale: nl,
              }
            ).replace(/^./, (match) => match.toUpperCase())}
          </b>
          <div className="dateDivider" />
          <b>
            {format(selectedRange.endDate, `EEE dd MMM`, {
              locale: nl,
            }).replace(/^./, (match) => match.toUpperCase())}
          </b>
        </div>
      )
    }
  }, [selectedRange, isSingleDay])
}

export const getTimeNumber = (timeString) => {
  const [hours, minutes] = timeString?.split(':')
  let hoursNumber = Number(hours)
  if (hoursNumber < 5) {
    hoursNumber += 24 // adjust hours that are less than 5
  }
  return hoursNumber + Number(minutes) / 60
}

export const getTimeString = (timeNumber) => {
  let hours = Math.floor(timeNumber % 24) // wrap around every 24 hours
  const minutes = Math.round((timeNumber - Math.floor(timeNumber)) * 60)
  return `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`
}
