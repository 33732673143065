import { createContext, useContext, useState, useEffect } from 'react'
const TrailerDataContext = createContext(null)

export const useTrailerData = () => {
  const context = useContext(TrailerDataContext)
  if (!context) {
    throw new Error('useTrailerData must be used within a TrailerDataProvider')
  }
  return context
}

export const TrailerDataProvider = ({ activeTrailer, children }) => {
  const [trailerData, setTrailerData] = useState(null)

  useEffect(() => {
    if (activeTrailer) {
      setTrailerData({
        accesoire: activeTrailer?.accesoires,
        trailerType: activeTrailer?.trailerType?.name || '',
        address: activeTrailer?.address || {
          city: '' || '',
          number: '' || '',
          street_name: '' || '',
          postal_code: '' || '',
        },
        length: activeTrailer?.trailerStats?.length || 0,
        height: activeTrailer?.trailerStats?.height || 0,
        width: activeTrailer?.trailerStats?.width || 0,
        licensePlateNumber: activeTrailer?.licensePlateNumber || 'NFDHSBF',
        pickUpTimeStart: activeTrailer?.pickupTimeStart || '',
        pickUpTimeEnd: activeTrailer?.pickupTimeEnd || '',
        dropOffTimeStart: activeTrailer?.returnTimeStart || '',
        dropOffTimeEnd: activeTrailer?.returnTimeEnd || '',
        latitude: activeTrailer?.fakeLatitude || 0,
        longitude: activeTrailer?.fakeLongitude || 0,
        price: activeTrailer?.price || '',
        description: activeTrailer?.description || '',
        available: activeTrailer?.available || true,
      })
    }
  }, [activeTrailer])

  const updateField = (field, value) => {
    setTrailerData((prevData) => ({
      ...prevData,
      [field]: value,
    }))
  }

  const updateNestedField = (field, nestedField, value) => {
    setTrailerData((prevData) => ({
      ...prevData,
      [field]: {
        ...prevData[field],
        [nestedField]: value,
      },
    }))
  }

  if (!activeTrailer || !trailerData) {
    return null
  }

  return (
    <TrailerDataContext.Provider
      value={{ trailerData, updateField, updateNestedField }}
    >
      {children}
    </TrailerDataContext.Provider>
  )
}

export default TrailerDataProvider
