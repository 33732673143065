import { IconButton } from '@mui/material'
import Loading from 'components/constants/Loading'
import { useEffect, useState } from 'react'
import {
  TbAlertTriangle,
  TbCalendarTime,
  TbCash,
  TbChevronRight,
  TbLockOpen,
  TbSearch,
  TbTrash,
  TbX,
} from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import useAxios from '../../../../../hooks/use-axios'
import useWindowWidth from '../../../../../hooks/useWindowWidth'
import TrailerDataProvider from '../context/TrailerDataProvider'
import DeleteTrailer from './sidebar_settings/DeleteTrailer'
import SidebarAccessoires from './sidebar_settings/SidebarAccessoires'
import SidebarDescription from './sidebar_settings/SidebarDescription'
import SidebarLicense from './sidebar_settings/SidebarLicense'
import SidebarLocation from './sidebar_settings/SidebarLocation'
import SidebarPrice from './sidebar_settings/SidebarPrice'
import SidebarStats from './sidebar_settings/SidebarStats'
import SidebarType from './sidebar_settings/SIdebarType'
import './TrailerTableSidebar.css'

interface TrailerTableSidebarProps {
  search?: string
  setSearch?: (search: string) => void
  activeTrailer: string
  setActiveTrailer: (activeTrailer: string) => void
  trailerInfo: any
  setTrailerInfo: any
}

export default function TrailerTableSidebar({
  search,
  setSearch,
  activeTrailer,
  setActiveTrailer,
  trailerInfo,
  setTrailerInfo,
}: TrailerTableSidebarProps) {
  const [sidebarContent, setSidebarContent] = useState('')
  const [showDeleteWarning, setShowDeleteWarning] = useState(false)
  const { response, loading, error, refetch } = useAxios({
    method: 'get',
    url: `/traileroffers/${activeTrailer}`,
  })
  const windowWidth = useWindowWidth()
  const navigate = useNavigate()

  const handleSidebarContent = (content: string) => {
    setSidebarContent(content)
  }

  useEffect(() => {
    if (response) {
      setTrailerInfo(response)
    }
    if (error) {
      console.error(error)
    }
  }, [response])

  useEffect(() => {
    refetch()
  }, [activeTrailer])

  if (loading || (windowWidth > 768 && !activeTrailer)) return <Loading />

  const sidebarComponents = {
    price: (
      <SidebarPrice
        trailerInfo={trailerInfo}
        setTrailerInfo={setTrailerInfo}
        setSidebarContent={setSidebarContent}
      />
    ),
    accessoires: (
      <SidebarAccessoires
        setSidebarContent={setSidebarContent}
        trailerInfo={trailerInfo}
        setTrailerInfo={setTrailerInfo}
      />
    ),
    trailerType: (
      <SidebarType
        trailerInfo={trailerInfo}
        setSidebarContent={setSidebarContent}
        setTrailerInfo={setTrailerInfo}
      />
    ),
    license: (
      <SidebarLicense
        trailerInfo={trailerInfo}
        setTrailerInfo={setTrailerInfo}
        setSidebarContent={setSidebarContent}
      />
    ),
    stats: (
      <SidebarStats
        setSidebarContent={setSidebarContent}
        trailerInfo={trailerInfo}
        setTrailerInfo={setTrailerInfo}
      />
    ),
    description: (
      <SidebarDescription
        trailerInfo={trailerInfo}
        setTrailerInfo={setTrailerInfo}
        setSidebarContent={setSidebarContent}
      />
    ),
    location: (
      <SidebarLocation
        trailerInfo={trailerInfo}
        setTrailerInfo={setTrailerInfo}
        setSidebarContent={setSidebarContent}
      />
    ),
  }

  const sidebarItems = [
    { label: 'Aanhanger type', content: 'trailerType' },
    { label: 'Verplicht rijbewijs', content: 'license' },
    { label: 'Aanhanger gegevens', content: 'stats' },
    { label: 'Beschrijving', content: 'description' },
    { label: 'Locatie', content: 'location' },
  ]

  return (
    <TrailerDataProvider activeTrailer={trailerInfo}>
      <div
        className={
          windowWidth > 768
            ? 'trailerTableSidebarContainer'
            : 'mobileTrailerTableSidebarContainer'
        }
      >
        {sidebarContent === '' && (
          <>
            <div
              className={`trailerSidebarHeader ${
                windowWidth <= 768 && 'mobile'
              }`}
            >
              <p>{trailerInfo?.trailerType?.name}</p>
              {windowWidth > 768 && (
                <div className="searchbox">
                  <TbSearch />
                  <input
                    placeholder="Zoeken door mijn aanhangers..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              )}
              {windowWidth <= 768 && (
                <IconButton
                  className="closeMobileSidebar"
                  onClick={() => setActiveTrailer('')}
                >
                  <TbX />
                </IconButton>
              )}
            </div>
            <div
              className={`sidebarShortcuts ${windowWidth <= 768 && 'mobile'}`}
            >
              {/*{trailerInfo?.damage > 0 && (*/}
              {/*  <div className="sidebarShortcutItem damage">*/}
              {/*    <TbAlertTriangle className="alertIcon" />*/}
              {/*    <p>Schade melding ontvangen</p>*/}
              {/*    <TbChevronRight />*/}
              {/*  </div>*/}
              {/*)}*/}
              <p>Algemene instellingen</p>
              <div
                className="sidebarShortcutItem"
                onClick={() => navigate('/owner/kalender')}
              >
                <TbCalendarTime className="sidebarIcon" />
                <p>Beschikbaarheid</p>
                <TbChevronRight />
              </div>
              <div
                className="sidebarShortcutItem"
                onClick={() => handleSidebarContent('price')}
              >
                <TbCash className="sidebarIcon" />
                <p>Algemene huurprijs</p>
                <TbChevronRight />
              </div>
              <div
                className="sidebarShortcutItem"
                onClick={() => handleSidebarContent('accessoires')}
              >
                <TbLockOpen className="sidebarIcon" />
                <p>Accessoires</p>
                <TbChevronRight />
              </div>
            </div>
            <div className="sidebar divider" />
            <div className="sidebarShortcuts">
              <p>Advertentie aanpassen</p>
              {sidebarItems.map((item) => (
                <div
                  key={item.content}
                  className="sidebarShortcutItem"
                  onClick={() => handleSidebarContent(item.content)}
                >
                  <p>{item.label}</p>
                  <TbChevronRight />
                </div>
              ))}
              <div
                onClick={() => setShowDeleteWarning(true)}
                className="sidebarShortcutItem error"
              >
                <p>Advertentie verwijderen</p>
                <TbTrash />
              </div>
            </div>
            {/*<div className="sidebar divider" />*/}
            {/*<div className="sidebarShortcuts">*/}
            {/*  <p>Annuleringsvoorwaarden</p>*/}
            {/*  <div className="sidebarShortcutItem">*/}
            {/*    <p>Richtlijnen van BuurBak</p>*/}
            {/*    <TbChevronRight />*/}
            {/*  </div>*/}
            {/*  <div className="sidebarShortcutItem">*/}
            {/*    <p>Annulerings opties</p>*/}
            {/*    <TbChevronRight />*/}
            {/*  </div>*/}
            {/*</div>*/}
          </>
        )}
        {sidebarComponents[sidebarContent]}
        {showDeleteWarning && (
          <DeleteTrailer
            trailerInfo={trailerInfo}
            setShowDeleteWarning={setShowDeleteWarning}
          />
        )}
      </div>
    </TrailerDataProvider>
  )
}
