import { cva, VariantProps } from 'class-variance-authority'
import '../layout.css'
import { Range } from '../../../../types/util'

const flexVariants = cva('flex', {
  variants: {
    justifyItems: {
      start: 'jitem--start',
      end: 'jitem--end',
      center: 'jitem--center',
      stretch: 'jitem--stretch',
    },
    alignItems: {
      start: 'aitemf--start',
      end: 'aitemf--end',
      center: 'aitem--center',
      stretch: 'aitem--stretch',
      baseline: 'aitem--baseline',
    },
    justifyContent: {
      start: 'jcontentf--start',
      end: 'jcontentf--end',
      center: 'jcontent--center',
      stretch: 'jcontent--stretch',
      spaceAround: 'jcontent--space_around',
      spaceBetween: 'jcontent--space_between',
      spaceEvenly: 'jcontent--space_evenly',
    },
    alignContent: {
      start: 'acontentf--start',
      end: 'acontentf--end',
      center: 'acontent--center',
      stretch: 'acontent--stretch',
      spaceAround: 'acontent--space_around',
      spaceBetween: 'acontent--space_between',
      spaceEvenly: 'acontent--space_evenly',
    },
    wrap: {
      nowrap: 'flex--nowrap',
      wrap: 'flex--wrap',
      wrapReverse: 'flex--wrap-reverse',
    },
  },
  defaultVariants: {
    wrap: 'nowrap',
  },
})

type FlexDirections = 'row' | 'row-reverse' | 'col' | 'col-reverse'

export interface FlexProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof flexVariants> {
  gap?: Range<65>
  xs?: FlexDirections
  sm?: FlexDirections
  md?: FlexDirections
  lg?: FlexDirections
}

const Flex = ({
  gap = 0,
  xs = 'row',
  sm,
  md,
  lg,
  justifyItems,
  alignItems,
  justifyContent,
  alignContent,
  wrap,
  className,
  children,
  ...props
}: FlexProps): React.ReactElement => {
  return (
    <div
      className={`${flexVariants({
        justifyItems,
        alignItems,
        justifyContent,
        alignContent,
        wrap,
        className,
      })} gap--${gap} flex--${xs} sm__flex-${sm} md__flex--${md} lg__flex--${lg}`}
    >
      {children}
    </div>
  )
}

export { Flex, flexVariants }
