import { TbCurrencyEuro } from 'react-icons/tb'
import './PriceGraph.css'
import ReactSlider from 'react-slider'
import { useState } from 'react'

const trailers = [
  { id: 1, price: 100 },
  { id: 2, price: 150 },
  { id: 3, price: 220 },
  { id: 4, price: 250 },
  { id: 5, price: 300 },
  { id: 6, price: 100 },
  { id: 7, price: 150 },
  { id: 8, price: 210 },
  { id: 9, price: 250 },
  { id: 10, price: 300 },
  { id: 11, price: 70 },
  { id: 12, price: 150 },
  { id: 13, price: 300 },
  { id: 14, price: 300 },
  { id: 15, price: 300 },
  { id: 16, price: 100 },
  { id: 17, price: 150 },
  { id: 18, price: 480 },
  { id: 19, price: 230 },
  { id: 20, price: 420 },
]

export function PriceGraph() {
  const [minPrice, setMinPrice] = useState(0)
  const [maxPrice, setMaxPrice] = useState(500)

  const calculateHistogramData = (bins) => {
    const histogramData = Array(bins).fill(0)
    const binSize = (maxPrice - minPrice) / bins

    trailers.forEach((trailer) => {
      const bin = Math.floor((trailer.price - minPrice) / binSize)
      if (bin < bins) {
        histogramData[bin]++
      }
    })

    return histogramData
  }

  const histogramData = calculateHistogramData(11)

  return (
    <div className="priceGraphContainer">
      <div className="histogram">
        {histogramData.map((count, index) => (
          <div
            key={index}
            className="histogram-bar"
            style={{ height: `${count * 10}px` }}
          />
        ))}
      </div>
      <ReactSlider
        className="priceSlider"
        thumbClassName="example-thumb"
        trackClassName="example-track"
        min={0}
        max={500}
        step={10}
        defaultValue={[minPrice, maxPrice]}
        onChange={(values) => {
          setMinPrice(values[0])
          setMaxPrice(values[1])
        }}
      />
      <div className="sliderValues">
        <div className="sliderValueItem">
          <TbCurrencyEuro />
          <input
            value={minPrice}
            onChange={(e) => setMinPrice(e.target.valueAsNumber)}
          />
        </div>
        <div className="sliderValueItem">
          <TbCurrencyEuro />
          <input
            value={maxPrice}
            onChange={(e) => setMaxPrice(e.target.valueAsNumber)}
          />
        </div>
      </div>
    </div>
  )
}
