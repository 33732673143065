import { IconButton } from '@mui/material'
import { TrailerCategories } from 'data/dummy/TrailerTypes'
import {
  BicycleTrailer,
  TrailerHeightDouble,
  TrailerLengthDouble2,
  TrailerWidth3,
} from 'data/icons/TrailerIcons'
import { useEffect, useState } from 'react'
import { TbChevronDown, TbId, TbX } from 'react-icons/tb'
import './MobileOfferFilters.css'
import { PriceGraph } from './PriceGraph'

export default function MobileOfferFilters({ setShowFilters }) {
  const [activeTrailerFilter, setActiveTrailerFilter] = useState<any>({})
  const [showTypeSuggestions, setShowTypeSuggestions] = useState(false)

  useEffect(() => {
    if (Object.keys(activeTrailerFilter).length <= 0) {
      setActiveTrailerFilter({
        trailerType: 'Alle aanhangers',
        icon: <BicycleTrailer size={18} />,
      })
    }
  }, [])

  return (
    <div className="blurContainer">
      <div className="blur" />
      <div className="mobileOfferFiltersContainer">
        <h3>Filters</h3>
        <span>Huurprijs</span>
        <PriceGraph />
        <div className="divider filters" />
        <span>Aanhanger type</span>
        <p>
          Meer informatie over verschillende aanhanger types en wat ze inhouden?
          Klik <u>hier</u>
        </p>
        <div className="filterSelectWrapper">
          <div className="filterSelectContainer">
            {activeTrailerFilter.icon}
            <p>{activeTrailerFilter.trailerType}</p>
            <TbChevronDown />
          </div>
          {showTypeSuggestions && (
            <div className="suggestionsContainer">
              {TrailerCategories.map((item, index) => (
                <div
                  className="filterSelectSuggestion"
                  key={index}
                  onClick={() => setActiveTrailerFilter(item)}
                >
                  {item.icon}
                  <p>{item.trailerType}</p>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="divider filters" />
        <span>Rijbewijsverplichting</span>
        <p>
          Meer informatie over welke aanhangers je mag besturen met bepaalde
          rijbewijzen? Klik <u>hier</u>
        </p>
        <div className="offerFilterItem">
          <TbId />
          <p>Geen rijbewijs verplichting</p>
          <div className="checkbox"></div>
        </div>
        <div className="offerFilterItem">
          <TbId />
          <p>Rijbewijs BE verplicht</p>
          <div className="checkbox"></div>
        </div>
        <div className="offerFilterItem">
          <TbId />
          <p>Rijbewijs B+ verplicht</p>
          <div className="checkbox"></div>
        </div>
        <div className="divider filters" />
        <span>Afmetingen</span>
        <p>
          Filter aanhangers op gewenste afmetingen zodat jouw lading altijd
          past.
        </p>
        <div className="offerFilterItem">
          <TrailerLengthDouble2 size={22} />
          <p>Lengte in cm</p>
          <input className="offerFilterInput" placeholder="lengte" />
        </div>
        <div className="offerFilterItem">
          <TrailerWidth3 size={22} />
          <p>Breedte in cm</p>
          <input className="offerFilterInput" placeholder="breedte" />
        </div>
        <div className="offerFilterItem">
          <TrailerHeightDouble size={22} />
          <p>Hoogte in cm</p>
          <input className="offerFilterInput" placeholder="hoogte" />
        </div>
        <div className="dimensionFilterAnimationContainer"></div>
        <IconButton className="closeBtn" onClick={() => setShowFilters(false)}>
          <TbX />
        </IconButton>
      </div>
    </div>
  )
}
