import axios, { AxiosError } from 'axios'
import { LocalStorage } from '../types/LocalStorage'
import { Tokens } from '../types/Tokens'
import { AxiosConfig } from '../types/AxiosConfig'
import { DeviceId } from '../types/DeviceId'

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:8000/api/v1'
      : '/api/v1',
  timeout: 0, // 3 seconds
})

instance.interceptors.request.use((config) => {
  const stringifiedTokens: string | null = localStorage.getItem(
    LocalStorage.Tokens
  )

  if (!stringifiedTokens) return config

  const tokens: Tokens = JSON.parse(stringifiedTokens)

  config.headers = {
    ...config.headers,
    Authorization:
      'Bearer ' +
      (config.url === '/auth/refresh'
        ? tokens.refresh_token
        : tokens.access_token),
  }

  return config
})

instance.interceptors.response.use(
  (response) => {
    return response
  },
  async (err: AxiosError) => {
    const originalConfig: AxiosConfig = err.config

    const deviceIdentifier: string | null = localStorage.getItem(
      LocalStorage.DeviceId
    )

    if (!deviceIdentifier) return originalConfig

    const deviceId: DeviceId = JSON.parse(deviceIdentifier)

    if (err.response.status === 401 && !originalConfig._retry) {
      originalConfig._retry = true

      const response = await instance.request<Tokens>({
        method: 'post',
        url: '/auth/refresh',
        data: {
          deviceId: deviceId.device_id,
        },
      })

      if (response.status == 200) {
        localStorage.setItem(LocalStorage.Tokens, JSON.stringify(response.data))

        return instance(originalConfig)
      }
    } else {
      return Promise.reject(err)
    }

    return Promise.reject(err)
  }
)

export { instance }
