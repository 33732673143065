import { TbX } from 'react-icons/tb'
import './TrailerStats.css'
import { TrailerCategories } from '../../../data/dummy/TrailerTypes'
import { useState } from 'react'
import { IconButton } from '@mui/material'
import {
  Graph,
  TrailerCapacityDouble,
  TrailerHeightDouble,
  TrailerLengthDouble,
  TrailerWeightDouble,
  TrailerWidth2,
} from 'data/icons/TrailerIcons'
import { useTranslation } from 'react-i18next'

export default function TrailerStats({
  trailerStats,
  setTrailerStats,
  trailerType,
}) {
  const { t } = useTranslation('TrailerForm')
  const [showInfo, setShowInfo] = useState(false)
  const typeInfo = TrailerCategories?.find(
    (trailer) => trailer?.trailerType === trailerType
  )

  const updateStats = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fieldName = e.target.name
    const fieldValue = e.target.value === '' ? '' : Number(e.target.value)

    setTrailerStats((stats: any) => ({
      ...stats,
      [fieldName]: fieldValue,
    }))
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const fieldName = e.target.name
    const fieldValue = e.target.value

    // If fieldValue is an empty string, just set it and return early
    if (fieldValue === '') {
      setTrailerStats((stats: any) => ({
        ...stats,
        [fieldName]: fieldValue,
      }))
      return
    }

    const numFieldValue = Number(fieldValue)

    // Get min and max values for this field from typeInfo
    const minFieldName =
      'min' + fieldName.charAt(0).toUpperCase() + fieldName.slice(1)
    const maxFieldName =
      'max' + fieldName.charAt(0).toUpperCase() + fieldName.slice(1)
    const minFieldValue = typeInfo ? typeInfo[minFieldName] : 0
    const maxFieldValue = typeInfo
      ? typeInfo[maxFieldName]
      : Number.MAX_SAFE_INTEGER

    // Ensure field value is within min and max
    let newValue = numFieldValue
    if (numFieldValue < minFieldValue) {
      newValue = minFieldValue
    } else if (numFieldValue > maxFieldValue) {
      newValue = maxFieldValue
    }

    setTrailerStats((stats: any) => ({
      ...stats,
      [fieldName]: newValue,
    }))
  }

  return (
    <div className="formStepContainer">
      <h2 className="maxWidthHeader">{t('stats.title')}</h2>
      <p>
        {t('stats.introduction')}{' '}
        <u onClick={() => setShowInfo(true)}>{t('stats.textBtn')}</u>
      </p>
      <div className="statsInputItem column">
        <div className="statsInputItemFlexbox">
          <TrailerLengthDouble size={26} strokeWidth="1.5" />
          <p>{t('stats.fields.length')}</p>
        </div>
        <input
          value={trailerStats.length === 0 ? '' : trailerStats.length}
          onChange={updateStats}
          id="length"
          name="length"
          type="number"
          className="statInput"
          onBlur={handleBlur}
          placeholder={
            trailerStats.length === 0 || trailerStats.length === ''
              ? `${typeInfo.minLength} - ${typeInfo.maxLength}cm`
              : ''
          }
        />
      </div>
      <div className="statsInputItem column">
        <div className="statsInputItemFlexbox">
          <TrailerWidth2 size={26} strokeWidth="1.5" />
          <p>{t('stats.fields.width')}</p>
        </div>
        <input
          value={trailerStats.width === 0 ? '' : trailerStats.width}
          onChange={updateStats}
          id="width"
          name="width"
          type="number"
          className="statInput"
          onBlur={handleBlur}
          placeholder={
            trailerStats.width === 0 || trailerStats.width === ''
              ? `${typeInfo.minWidth} - ${typeInfo.maxWidth}cm`
              : ''
          }
        />
      </div>
      <div className="statsInputItem column">
        <div className="statsInputItemFlexbox">
          <TrailerHeightDouble size={26} strokeWidth="1.5" />
          <p>{t('stats.fields.height')}</p>
        </div>
        <input
          value={trailerStats.height === 0 ? '' : trailerStats.height}
          onChange={updateStats}
          id="height"
          name="height"
          type="number"
          className="statInput"
          onBlur={handleBlur}
          placeholder={
            trailerStats.height === 0 || trailerStats.height === ''
              ? `${typeInfo.minHeight} - ${typeInfo.maxHeight}cm`
              : ''
          }
        />
      </div>
      {showInfo && <MoreInfoPopup setShowInfo={setShowInfo} />}
    </div>
  )
}

function MoreInfoPopup({ setShowInfo }) {
  return (
    <div className="blurContainer">
      <div className="blur" onClick={() => setShowInfo(false)} />
      <div className="popupContainer infoBox stats">
        <div className="popupContainerHeader">
          <IconButton
            className="closePopup transformed"
            onClick={() => setShowInfo(false)}
          >
            <TbX />
          </IconButton>
          <p>Meer informatie over trailerStatistieken?</p>
        </div>
        <div className="popupContainerContent stats">
          <div className="popupContainerInfoGrid">
            {trailerStats.map((item) => (
              <div key={item.value} className="popupContainerGridItem">
                {item.icon}
                <b>{item.value}</b>
                <p>{item.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export const trailerStats = [
  {
    value: 'Statistieken',
    description:
      'Gebaseerd op de gebruikelijke gegevens van elk aanhangertype, zijn er minimum- en maximum waardes ingesteld.',
    icon: <Graph size={28} strokeWidth="1.6" />,
  },
  {
    value: 'Lengte',
    description:
      'De lengte is belangrijk om te bepalen welke soorten lading passen. Het helpt huurders ook bij navigatie en manoeuvreren.',
    icon: <TrailerLengthDouble size={28} strokeWidth="1.6" />,
  },
  {
    value: 'Breedte',
    description:
      'De breedte van de aanhanger helpt huurders te bepalen of hun lading past en of de aanhanger stabiel zal zijn. Het informeert ook over de doorgankelijkheid door smalle paden.',
    icon: <TrailerWidth2 size={28} strokeWidth="1.6" />,
  },
  {
    value: 'Hoogte',
    description:
      'De hoogte is cruciaal voor huurders die volumineuze items willen vervoeren. Het is ook belangrijk voor het passeren van lage bruggen of het rijden in parkeergarages.',
    icon: <TrailerHeightDouble size={28} strokeWidth="1.6" />,
  },
  {
    value: 'Gewicht',
    description:
      'Het lege gewicht van de aanhanger is essentieel om te weten of het trekkende voertuig de aanhanger veilig kan vervoeren.',
    icon: <TrailerWeightDouble size={28} strokeWidth="1.6" />,
  },
  {
    value: 'Draagcapaciteit',
    description:
      'De draagcapaciteit geeft aan hoeveel gewicht de aanhanger veilig kan dragen. Dit voorkomt overbelasting en draagt bij aan de veiligheid.',
    icon: <TrailerCapacityDouble size={28} strokeWidth="1.6" />,
  },
]
