import {
  TbBrandFacebook,
  TbBrandInstagram,
  TbBrandLinkedin,
} from 'react-icons/tb'
import { Link } from 'react-router-dom'
import Logo from '../header/Logo'
import './Footer.css'
import FooterData from './FooterData.json'

export default function Footer() {
  return (
    <div className="footerWrapper">
      <div className="footerContainer">
        <div className="footerLinks">
          {FooterData?.map(({ id, title, links }) => {
            return (
              <div className="footerGrid" key={id}>
                <p>{title}</p>
                {links?.map((link) => (
                  <p className="footerLinkContent" key={link.title}>
                    <Link
                      to={link.to}
                      style={{
                        fontSize: 13.5,
                        textDecoration: 'none',
                        color: '#747474',
                      }}
                    >
                      {link.title}
                    </Link>
                  </p>
                ))}
              </div>
            )
          })}
        </div>
        <div className="bbFooterSocials">
          <Logo />
          <p>Duurzaam door te delen</p>
          <div className="socialContainer">
            <a
              href="https://www.facebook.com/BuurBak"
              className="socialContainerItem"
            >
              <TbBrandFacebook />
            </a>
            <a
              href="https://www.linkedin.com/company/buurbak/"
              className="socialContainerItem"
            >
              <TbBrandLinkedin />
            </a>
            <a
              href="https://www.instagram.com/buurbak/"
              className="socialContainerItem"
            >
              <TbBrandInstagram />
            </a>
          </div>
        </div>
      </div>
      <div className="divider footer" />
      <div className="bbFooterLegal">
        <p>
          <a
            href="https://drive.google.com/file/d/1D9S05Qn7hC3bsEi_ElAqz8uX1s6Se5UZ/view?usp=sharing"
            target="_blank"
          >
            Algemene voorwaarden
          </a>
        </p>
        <p>
          <a
            href="https://drive.google.com/file/d/12uOHI1prSnsfgaYo3nR8YhIy0UNVzukr/view?usp=sharing"
            target="_blank"
          >
            Privacystatement
          </a>
        </p>
        <p>©Alle rechten gereserveerd BuurBak</p>
      </div>
    </div>
  )
}
