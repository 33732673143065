import Loading from 'components/constants/Loading'
import { useAuth } from 'hooks/use-auth'
import useAxios from 'hooks/use-axios'
import { useCallback, useEffect, useRef, useState } from 'react'
import TrailerOfferCardlist from '../card/TrailerOfferCardlist'
import './TrailerOffers.css'
import MobileOfferFilters from '../filters/MobileOfferFilters'
import Map from '../map/Map'
import useWindowWidth from 'hooks/useWindowWidth'
import MobileCard from '../mobile/MobileCard'
import LoginRegisterModal from 'components/constants/auth/LoginRegisterModal'
import OfferCategoriesSlider from '../mobile/OfferCategoriesSlider'
import MobileHeader from '../mobile/MobileHeader'
import { instance } from 'util/axios-instance'
import { TrailerList } from 'types/TrailerList'

const DESKTOP_MIN_WIDTH = 980
const DEFAULT_CENTER = {
  lat: 52.131401,
  lng: 5.42747,
}

const haversineDistance = (coords1, coords2) => {
  const R = 6371 // Radius of the Earth in km
  const dLat = (coords2.lat - coords1.lat) * (Math.PI / 180)
  const dLon = (coords2.lng - coords1.lng) * (Math.PI / 180)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((coords1.lat * Math.PI) / 180) *
      Math.cos((coords2.lat * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const distance = R * c
  return distance
}

export default function TrailerOffers() {
  const [showLogin, setShowLogin] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [activeCategory, setActiveCategory] = useState('')
  const [rawTrailers, setRawTrailers] = useState<TrailerList[]>([])
  const [centerCoordinates, setCenterCoordinates] = useState(DEFAULT_CENTER)
  const [showFilters, setShowFilters] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [isFetching, setIsFetching] = useState(false)
  const [totalPages, setTotalPages] = useState<number | null>(null)
  const [savedTrailers, setSavedTrailers] = useState([])
  const [filteredTrailers, setFilteredTrailers] = useState<TrailerList[]>([])

  const windowWidth = useWindowWidth()
  const { response, loading, error } = useAxios({
    method: 'get',
    url: '/traileroffers',
  })
  const { user } = useAuth()

  const scrollContainerRef = useRef<HTMLDivElement | null>(null)
  const observer = useRef<IntersectionObserver | null>(null)

  // useEffect(() => {
  //   if (user) {
  //     const fetchSavedTrailers = async () => {
  //       const response = await instance.get('/savedtrailers')
  //       setSavedTrailers(response.data.content)
  //     }
  //     fetchSavedTrailers()
  //   }
  // }, [user])

  useEffect(() => {
    if (response) {
      const newTrailers = (response as any).content
      const seen = new Set(rawTrailers.map((trailer) => trailer.id))
      const uniqueNewTrailers = newTrailers.filter(
        (trailer) => !seen.has(trailer.id)
      )
      setRawTrailers((prev) => [...uniqueNewTrailers, ...prev])
      setTotalPages((response as any).totalPages)
    }
  }, [response])

  // Filter trailers
  useEffect(() => {
    let filteredAndReordered = [...rawTrailers].filter((trailer) => {
      return (
        (!searchTerm ||
          trailer.name.toLowerCase().includes(searchTerm.toLowerCase())) &&
        (!searchTerm ||
          trailer.trailerType.name
            .toLowerCase()
            .includes(searchTerm.toLowerCase())) &&
        (!searchTerm ||
          trailer.cityAddress.city
            .toLowerCase()
            .includes(searchTerm.toLowerCase())) &&
        (!activeCategory || trailer.trailerType.name === activeCategory)
      )
    })

    if (
      centerCoordinates.lat !== DEFAULT_CENTER.lat ||
      centerCoordinates.lng !== DEFAULT_CENTER.lng
    ) {
      filteredAndReordered = filteredAndReordered.sort((a, b) => {
        const distanceA = haversineDistance(
          { lat: a.nearbyLatitude, lng: a.nearbyLongitude },
          centerCoordinates
        )
        const distanceB = haversineDistance(
          { lat: b.nearbyLatitude, lng: b.nearbyLongitude },
          centerCoordinates
        )
        return distanceA - distanceB
      })
    }

    setFilteredTrailers(filteredAndReordered)
  }, [rawTrailers, searchTerm, activeCategory, centerCoordinates])

  const fetchMoreData = useCallback(async () => {
    if (isFetching || (totalPages !== null && currentPage >= totalPages)) return // Check if reached the last page

    setIsFetching(true)
    const nextPage = currentPage + 1
    try {
      const result = await instance.get(`/traileroffers?page=${nextPage}`)
      setRawTrailers((prev) => [...prev, ...result.data.content])
      setCurrentPage(result.data.number)
    } catch (fetchError) {
      console.error('Error fetching more data:', fetchError)
    }
    setIsFetching(false)
  }, [currentPage, isFetching, totalPages])

  const lastTrailerElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isFetching) {
          fetchMoreData()
        }
      })
      if (node) observer.current.observe(node)
    },
    [fetchMoreData, isFetching]
  )

  if (error) {
    return <Loading />
  }

  return (
    <div className="trailerOfferPage">
      <>
        <MobileHeader
          setShowFilters={setShowFilters}
          setSearchTerm={setSearchTerm}
          user={user}
        />
        <div className="trailerOfferResults">
          <div className="trailerOffersContent" ref={scrollContainerRef}>
            <OfferCategoriesSlider
              activeCategory={activeCategory}
              setActiveCategory={setActiveCategory}
            />
            {filteredTrailers.map((item, index) => {
              const isLastItem = filteredTrailers.length === index + 1
              const commonProps = {
                trailer: item,
                key: item.id,
                user,
                savedTrailers,
              }
              return isLastItem ? (
                <div ref={lastTrailerElementRef} key={item.id}>
                  {windowWidth > DESKTOP_MIN_WIDTH ? (
                    <TrailerOfferCardlist
                      {...commonProps}
                      setShowLogin={setShowLogin}
                    />
                  ) : (
                    <MobileCard {...commonProps} setShowLogin={setShowLogin} />
                  )}
                </div>
              ) : windowWidth > DESKTOP_MIN_WIDTH ? (
                <TrailerOfferCardlist
                  {...commonProps}
                  setShowLogin={setShowLogin}
                />
              ) : (
                // <MobileCard {...commonProps} setShowLogin={setShowLogin} />
                <TrailerOfferCardlist
                  {...commonProps}
                  setShowLogin={setShowLogin}
                />
              )
            })}
          </div>
        </div>
      </>
      <div className="trailerOfferMap">
        <Map
          filteredTrailers={filteredTrailers}
          centerCoordinates={centerCoordinates}
          setCenterCoordinates={setCenterCoordinates}
        />
      </div>
      {loading && <Loading />}
      {showFilters && <MobileOfferFilters setShowFilters={setShowFilters} />}
      {showLogin && (
        <LoginRegisterModal
          open={showLogin}
          onClose={() => setShowLogin(false)}
        />
      )}
    </div>
  )
}
