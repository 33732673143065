import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import './button.css'

const buttonVariants = cva('button', {
  variants: {
    variant: {
      primary: 'primary',
      secondary: 'secondary',
      primaryOutline: 'primary__outline',
      blackOutline: 'black__outline',
      ghostPrimary: 'primary__ghost',
      ghostSecondary: 'secondary__ghost',
      disabled: 'disabled',
      underline: 'underline',
      gray: 'gray',
    },
    size: {
      zero: 'zero',
      sm: 'small',
      md: 'medium',
      lg: 'large',
      full: 'full',
      icon: 'icon',
    },
    radius: {
      sm: 'radius__small',
      md: 'radius__medium',
      lg: 'radius__large',
      xl: 'radius__xl',
      full: 'radius__full',
    },
  },
  defaultVariants: {
    variant: 'primary',
    size: 'md',
    radius: 'sm',
  },
})

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  fullWidth?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      radius,
      asChild = false,
      fullWidth = false,
      disabled,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button'

    return (
      <Comp
        className={`${buttonVariants({ variant, size, radius, className })} ${
          fullWidth ? 'is__full-width' : ''
        } ${disabled ? 'is__disabled' : ''}`}
        ref={ref}
        disabled={disabled}
        {...props}
      />
    )
  }
)
Button.displayName = 'Button'

export { Button, buttonVariants }
