import { useEffect, useState } from 'react'
import CalendarSidebar from '../sidebar/CalendarSidebar'
import './MobileCalendarView.css'

export default function MobileCalendarSidebar({
  user,
  findDateData,
  selectedRange,
  setSelectedRange,
  setActiveTrailer,
  monthsInView,
  getActiveReservations,
  trailerInfo,
  isAvailable,
  setIsAvailable,
}) {
  const [showContent, setShowContent] = useState(false)

  useEffect(() => {
    if (Object.keys(selectedRange).length > 0) {
      setShowContent(true)
    }
  }, [selectedRange])

  return (
    <>
      {showContent && (
        <div className="mobileTrailerTableSidebarWrapper">
          <div className="blur" onClick={() => setSelectedRange('')} />
          <div className="mobileTrailerTableSidebarContainer">
            <CalendarSidebar
              selectedRange={selectedRange}
              setSelectedRange={setSelectedRange}
              findDateData={findDateData}
              user={user}
              setActiveTrailer={setActiveTrailer}
              monthsInView={monthsInView}
              getActiveReservations={getActiveReservations}
              trailerInfo={trailerInfo}
              isAvailable={isAvailable}
              setIsAvailable={setIsAvailable}
            />
          </div>
        </div>
      )}
    </>
  )
}
