import Loading from 'components/constants/Loading'
import { useEffect, useState, useTransition } from 'react'
import useAxios from '../../../../hooks/use-axios'
import useWindowWidth from '../../../../hooks/useWindowWidth'
import { MobileTrailerHeader } from './content/MobileTrailerHeader'
import TrailerTableHeader from './content/TrailerTableHeader'
import TrailerTableListItem from './content/TrailerTableListItem'
import SortBy from './sidebar/mobile/SortBy'
import TrailerTableSidebar from './sidebar/TrailerTableSidebar'
import './TrailerTable.css'

export default function TrailerTable({ trailers, setMyTrailers, user }) {
  const [showSortBy, setShowSortBy] = useState(false)
  const [_, startTransition] = useTransition()
  const [columnWidths, setColumnWidths] = useState([])
  const { response, loading, error } = useAxios({
    method: 'get',
    url: `/customers/${user?.id}/trailers`,
  })
  const [activeTrailer, setActiveTrailer] = useState('')
  const [sortColumn, setSortColumn] = useState('createdAt')
  const [sortOrder, setSortOrder] = useState('asc')
  const [search, setSearch] = useState('')
  const filteredTrailers = trailers?.filter((trailer) =>
    trailer.trailerType.name.toLowerCase().includes(search.toLowerCase())
  )
  const [trailerInfo, setTrailerInfo] = useState<any>()
  const windowWidth = useWindowWidth()

  const sortByTrailerType = () => {
    setSortColumn('trailerType.name')
    setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'))
  }

  const sortByCreatedAt = () => {
    setSortColumn('createdAt')
    setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'))
  }

  const sortByPrice = () => {
    setSortColumn('price')
    setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'))
  }

  const sortFunction = (a, b) => {
    const valueA = sortColumn.split('.').reduce((o, i) => o[i], a)
    const valueB = sortColumn.split('.').reduce((o, i) => o[i], b)

    const compareValues =
      sortColumn === 'price'
        ? Number(valueA) - Number(valueB)
        : valueA.toString().toLowerCase() < valueB.toString().toLowerCase()
        ? -1
        : valueA.toString().toLowerCase() > valueB.toString().toLowerCase()
        ? 1
        : 0

    return sortOrder === 'asc' ? compareValues : -compareValues
  }

  let sortedTrailers = []

  if (Array.isArray(filteredTrailers)) {
    sortedTrailers = [...filteredTrailers].sort(sortFunction)
  }

  useEffect(() => {
    if (!response) return
    if (response) {
      setMyTrailers([response])
    }
    if (error) {
      console.error(error)
    }
  }, [response])

  useEffect(() => {
    if (trailers?.length > 0 && windowWidth > 768) {
      setActiveTrailer(trailers[0].id)
    }
  }, [trailers, windowWidth])

  if (
    loading ||
    !trailers ||
    (windowWidth > 768 && !activeTrailer) ||
    !columnWidths
  )
    return <Loading />

  return (
    <>
      <div className="trailerTableViewContainer">
        <div
          className={
            windowWidth > 768 ? 'trailerTableGrid' : 'mobileTrailerTable'
          }
        >
          <TrailerTableSidebar
            search={search}
            setSearch={setSearch}
            activeTrailer={activeTrailer}
            setActiveTrailer={setActiveTrailer}
            trailerInfo={trailerInfo}
            setTrailerInfo={setTrailerInfo}
          />
          <div
            className={`trailerTableContent ${
              windowWidth < 768 ? 'mobile' : null
            }`}
          >
            {windowWidth < 768 ? (
              <MobileTrailerHeader
                sortColumn={sortColumn}
                setShowSortBy={setShowSortBy}
                search={search}
                setSearch={setSearch}
                startTransition={startTransition}
              />
            ) : (
              <TrailerTableHeader
                setColumnWidths={setColumnWidths}
                onSort={sortByTrailerType}
                onCreatedAtSort={sortByCreatedAt}
                onPriceSort={sortByPrice}
                sortColumn={sortColumn}
                sortOrder={sortOrder}
              />
            )}
            <div className="myTrailerContainer">
              {sortedTrailers.map((trailer) => (
                <TrailerTableListItem
                  activeTrailer={activeTrailer}
                  setActiveTrailer={setActiveTrailer}
                  columnWidths={columnWidths}
                  key={trailer.id}
                  trailer={trailer}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      {showSortBy && windowWidth < 768 && (
        <SortBy
          setSortOrder={setSortOrder}
          setSortColumn={setSortColumn}
          setShowSortBy={setShowSortBy}
        />
      )}
    </>
  )
}
