import { instance } from '../../../../../../util/axios-instance'
import './DeleteTrailer.css'
import { useNavigate } from 'react-router-dom'

export default function DeleteTrailer({ trailerInfo, setShowDeleteWarning }) {
  const navigate = useNavigate()

  const deleteTrailer = async () => {
    await instance.delete(`/traileroffers/${trailerInfo.id}`)
    navigate('/account')
  }

  return (
    <div className="blurContainer">
      <div className="blur" onClick={() => setShowDeleteWarning(false)} />
      <div className="popupContainer">
        <div className="popupContent">
          <p>Advertentie verwijderen</p>
          <p>
            Je staat op het punt om jouw {trailerInfo.trailerType.name} te
            verwijderen.
          </p>
        </div>
        <div className="actionRow">
          <button
            className="completeAction secondary"
            onClick={() => setShowDeleteWarning(false)}
          >
            Annuleren
          </button>
          <button className="completeAction" onClick={() => deleteTrailer()}>
            Verwijderen
          </button>
        </div>
      </div>
    </div>
  )
}
