import { IconButton } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { TbX } from 'react-icons/tb'
import './AvailabilityOverview.css'

const daysOfWeek = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
]

export default function AvailabilityOverview({
  setShowOverview,
  activeRangesPerDay,
  t,
}) {
  const timeBlocks = Array.from({ length: 11 }, (_, i) => (i * 2 + 5) % 24)
  const scrollRef = useRef<HTMLDivElement>(null)
  const [isScrolled, setIsScrolled] = useState(false)

  const dayNamesInDutch = {
    monday: `${t('availability.days.monday')}`,
    tuesday: `${t('availability.days.tuesday')}`,
    wednesday: `${t('availability.days.wednesday')}`,
    thursday: `${t('availability.days.thursday')}`,
    friday: `${t('availability.days.friday')}`,
    saturday: `${t('availability.days.saturday')}`,
    sunday: `${t('availability.days.sunday')}`,
  }

  useEffect(() => {
    const scrollHandler = () => {
      if (scrollRef.current) {
        scrollRef.current.scrollTop > 0
          ? setIsScrolled(true)
          : setIsScrolled(false)
      }
    }

    const scrollableElement = scrollRef.current
    if (scrollableElement) {
      scrollableElement.addEventListener('scroll', scrollHandler)
    }

    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener('scroll', scrollHandler)
      }
    }
  }, [])

  const formatTime = (time) => {
    let hours = Math.floor(time)
    let minutes = (time - hours) * 60

    // Handle special cases
    if (hours === 24) hours = 0
    if (hours === 25) hours = 1

    // Convert hours and minutes to string and padStart with '0' to get '00' format
    const hoursStr = hours.toString().padStart(2, '0')
    const minutesStr = minutes === 0 ? '00' : '30'

    return `${hoursStr}:${minutesStr}`
  }

  return (
    <div className="blurContainer">
      <div className="blur" onClick={() => setShowOverview(false)} />
      <div className="availabilityOverviewContainer">
        <div
          className="availabilityOverviewHeader"
          style={{
            borderBottom: isScrolled ? '1px solid var(--border)' : 'none',
          }}
        >
          <p>{t('availability.days.title')}</p>
          <IconButton
            className="closePopup availability"
            onClick={() => setShowOverview(false)}
          >
            <TbX />
          </IconButton>
        </div>
        <div className="availabilityOverviewContent" ref={scrollRef}>
          {daysOfWeek.map((day) => (
            <div className="availabilityOverviewItem" key={day}>
              <p>{dayNamesInDutch[day]}</p>
              <div className="availabilityOverviewSlider">
                {activeRangesPerDay[day].map((time, i) => {
                  if (i % 2 === 0) {
                    const endTime = activeRangesPerDay[day][i + 1]
                    const left = ((time - 5) / 20) * 100
                    const width = ((endTime - time) / 20) * 100
                    return (
                      <div
                        key={i}
                        className="activeRange"
                        style={{ left: `${left}%`, width: `${width}%` }}
                      >
                        <p>{`${formatTime(time)} - ${formatTime(endTime)}`}</p>
                      </div>
                    )
                  }
                  return null
                })}
              </div>
              <div className="timesContainerWrapper preview">
                {timeBlocks.map((value, i) => (
                  <div
                    key={i}
                    className="slider-label"
                    style={{ left: `calc(${(value / 24) * 100}% - 25px)` }}
                  >
                    {value === 24
                      ? '00:00'
                      : `${value.toString().padStart(2, '0')}:00`}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
