import { useRef, useEffect } from 'react'
import { LocationItem, GeoCode } from '../TrailerForm'

interface Props {
  setLocation: (location: LocationItem[]) => void
  setGeoCode: (geoCode: GeoCode) => void
  customStyle: string
}

const AutoComplete: React.FC<Props> = ({
  setLocation,
  setGeoCode,
  customStyle,
}) => {
  const autoCompleteRef = useRef<google.maps.places.Autocomplete | null>(null)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const heroOptions = {
    componentRestrictions: { country: 'nl' },
    fields: ['address_components', 'geometry.location'],
    types: ['locality'],
  }
  const options = {
    componentRestrictions: { country: 'nl' },
    fields: ['address_components', 'geometry.location'],
  }

  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      customStyle === 'hero' ? heroOptions : options
    )
    autoCompleteRef.current.addListener('place_changed', function () {
      const place = autoCompleteRef.current.getPlace()
      const lat = place?.geometry?.location?.lat()
      const lng = place?.geometry?.location?.lng()
      setGeoCode({ lat, lng })
      setLocation(
        place.address_components.map((component) => ({
          long_name: component.long_name,
          types: component.types,
        }))
      )
    })
  }, [])

  return (
    <>
      <input
        ref={inputRef}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault()
            e.stopPropagation()
            const place = autoCompleteRef.current?.getPlace()
            if (!place) {
              return
            }
            const lat = place?.geometry?.location?.lat()
            const lng = place?.geometry?.location?.lng()
            setGeoCode({ lat, lng })
            setLocation(place.address_components)
          }
        }}
        className={
          customStyle === 'hero'
            ? 'heroLocationAutoComplete'
            : customStyle === 'settings'
            ? 'locationSettingAutocomplete'
            : `locationInput ${customStyle === 'sidebar' ? 'sidebar' : null}`
        }
        placeholder="Locatie..."
      />
    </>
  )
}

export default AutoComplete
