import { add, eachDayOfInterval } from 'date-fns'

const getIndexOfDayInTheWeek = (day: string): number => {
  const weekDays: Array<string> = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ]

  return weekDays.indexOf(day)
}

function getDatesInRange(startDate: Date, endDate: Date) {
  return eachDayOfInterval({
    start: new Date(startDate),
    end: new Date(endDate),
  })
}

const allDatesAfter180Days = getDatesInRange(
  add(new Date(), { days: 180 }),
  add(new Date(), { days: 210 })
)

export { getIndexOfDayInTheWeek, getDatesInRange, allDatesAfter180Days }
