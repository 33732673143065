import React from 'react'
import './SettingsPopup.css'
import {
  TbX,
  TbArrowBackUp,
  TbUserCircle,
  TbChevronRight,
  TbLockOpen,
  TbBell,
  TbWorld,
  TbCash,
  TbReceipt,
  TbTrash,
  TbClipboardList,
  TbAlertTriangle,
  TbReceiptRefund,
} from 'react-icons/tb'
import { IconButton } from '@mui/material'
import ChangeLanguage from './general/ChangeLanguage'
import Notifications from './general/Notifications'
import ChangePassword from './general/ChangePassword'
import ChangeUserInfo from './general/changeUserInfo/ChangeUserInfo'
import PaymentMethods from './payments/PaymentMethods'
import MyPayments from './payments/MyPayments'
import PayoutMethod from './payout/PayoutMethod'
import TransactionHistory from './payout/TransactionHistory'
import DeleteProfile from './privacy/DeleteProfile'
import Restitution from './subContent/Restitution'

interface SettingsPopupProps {
  user: any
  setShowSettings: (show: boolean) => void
  contentInView: string
  setContentInView: (content: string) => void
}

const SettingsPopup: React.FC<SettingsPopupProps> = ({
  user,
  setShowSettings,
  contentInView,
  setContentInView,
}) => {
  const titles: { [key: string]: string } = {
    myInfo: 'Mijn gegevens aanpassen',
    changePassword: 'Wachtwoord wijzigen',
    notifications: 'Meldingen',
    payOut: 'Uitbetalingsmethode',
    transactionHistory: 'Transactiegeschiedenis',
    deleteProfile: 'Account verwijderen',
    myPayments: 'Mijn betalingen',
    restitution: 'Restitutie aanvragen',
    language: 'Ingestelde taal',
    paymentMethod: 'Betaalmethoden',
  }

  const title = titles[contentInView] || 'Instellingen'

  const renderSidebarItem = (
    label: string,
    Icon: React.ElementType,
    onClick: () => void
  ) => (
    <div className="sidebarShortcutItem minPad" onClick={onClick}>
      <Icon className="sidebarIcon" />
      <p>{label}</p>
      <TbChevronRight />
    </div>
  )

  return (
    <div className="blurContainer">
      <div className="blur" onClick={() => setShowSettings(false)} />
      <div className="reservationPopupContainer main">
        <div className="reservationPopupContainerHeader">
          <IconButton
            className="closeBtn"
            style={{ right: 20, top: 20 }}
            onClick={() => setShowSettings(false)}
          >
            <TbX />
          </IconButton>
          {contentInView !== '' && (
            <IconButton
              className="previousStepBtn"
              onClick={() =>
                setContentInView(
                  contentInView === 'restitution' ? 'myPayments' : ''
                )
              }
            >
              <TbArrowBackUp />
            </IconButton>
          )}
          <p>{title}</p>
        </div>
        {contentInView === '' && (
          <div className="reservationPopupContainerMainContent settings">
            <span>Algemene instellingen</span>
            {renderSidebarItem('Mijn gegevens aanpassen', TbUserCircle, () =>
              setContentInView('myInfo')
            )}
            {renderSidebarItem('Wachtwoord wijzigen', TbLockOpen, () =>
              setContentInView('changePassword')
            )}
          </div>
        )}
        {contentInView === 'myInfo' && (
          <ChangeUserInfo user={user} setShowSettings={setShowSettings} />
        )}
        {contentInView === 'changePassword' && <ChangePassword />}
        {contentInView === 'notifications' && <Notifications />}
        {contentInView === 'payOut' && <PayoutMethod user={user} />}
        {contentInView === 'transactionHistory' && <TransactionHistory />}
        {contentInView === 'deleteProfile' && <DeleteProfile user={user} />}
        {contentInView === 'myPayments' && (
          <MyPayments setContentInView={setContentInView} />
        )}
        {contentInView === 'restitution' && <Restitution />}
        {contentInView === 'paymentMethod' && <PaymentMethods />}
      </div>
    </div>
  )
}

export default SettingsPopup
