import { IconButton } from '@mui/material'
import { useState } from 'react'
import { TbX } from 'react-icons/tb'
import './TrailerTypesInformation.css'
import { Button } from '../../ui'

const trailerTypeInformation = [
  {
    type: 'Open aanhangers',
    description:
      'De open aanhanger is een eenvoudige, maar effectieve oplossing voor diverse transportbehoeften. Zonder zij- of bovenwanden kun je makkelijk grote of ongewoon gevormde objecten laden. Dit maakt het een ideale keuze voor tuinwerkzaamheden, het verhuizen van meubels, of het afvoeren van bouwafval. De maximale laadcapaciteit gaat meestal tot 750 kg, waardoor deze aanhanger heel toegankelijk is. Met betrekking tot het trekken van de aanhanger voldoet een gemiddelde personenauto meestal al aan de eisen. Als veiligheidstip is het belangrijk op te merken dat je lading niet beschermd is tegen de elementen. Bij slecht weer is het dus aan te raden om een zeil te gebruiken om je spullen te beschermen.',
    image: '/images/trailerTypes/open-trailer.png',
  },
  {
    type: 'Gesloten aanhangers',
    description:
      'Als je iets meer beveiliging nodig hebt, is een gesloten aanhanger de beste keuze. Deze is perfect voor het vervoeren van elektronica, kunstwerken, meubels en andere kostbare of kwetsbare items. De afgesloten structuur zorgt voor extra veiligheid en beschermt je lading tegen weersinvloeden. De laadcapaciteit varieert, maar ligt meestal tussen de 750 kg en 2.000 kg. Voor het trekken van een gesloten aanhanger is vaak een SUV of een kleine vrachtwagen meer geschikt. Vergeet niet om de deuren goed te vergrendelen voor vertrek om het risico op diefstal te minimaliseren.',
    image: '/images/trailerTypes/closed-trailer.png',
  },
  {
    type: 'Autotransporters',
    description:
      'Voor het vervoer van voertuigen is er geen betere optie dan een autotransporter. Deze aanhangers zijn speciaal ontworpen met oprijplaten en bevestigingspunten om het laden en vastzetten van voertuigen te vergemakkelijken. Qua laadcapaciteit kun je rekenen op een maximum tot ongeveer 2.500 kg. Een stevige SUV of een vrachtwagen is in dit geval de meest aanbevolen trekauto. Als het om veiligheid gaat, zorg er dan voor dat het voertuig goed is vastgemaakt met bevestigingsriemen om ongelukken tijdens de rit te voorkomen.',
    image: '/images/trailerTypes/car-trailer.png',
  },
  {
    type: 'Paardentrailers',
    description:
      'Een paardentrailer is meer dan een transportmiddel; het is een speciaal ontworpen verblijf voor je paard tijdens het vervoer. Van een zachte vloer tot ruimtes voor voer en water, alles is gericht op het welzijn van het dier. De laadcapaciteit kan variëren maar ligt meestal tussen de 1.200 en 2.000 kg. Sterke SUVs of pick-up trucks zijn het meest geschikt voor het trekken van een paardentrailer. Vergeet niet om alle veiligheidssluitingen en ventilatiesystemen te controleren voordat je vertrekt.',
    image: '/images/trailerTypes/horse-trailer.png',
  },
  {
    type: 'Boottrailers',
    description:
      'Voor waterliefhebbers biedt een boottrailer het gemak van transport naar en van de waterkant. Deze aanhangers zijn specifiek ontworpen om een boot veilig te vervoeren, met een maximale laadcapaciteit variërend van 1.000 tot 2.500 kg. In dit geval zijn vooral SUVs en vrachtwagens aan te raden als trekvoertuig. Zorg ervoor dat je boot goed is bevestigd en dat je de nodige vergunningen hebt voordat je aan je reis begint.',
    image: '/images/trailerTypes/boat-trailer.png',
  },
  {
    type: 'Kippers',
    description:
      'De kipper is je beste optie als je losse materialen zoals zand, grind of aarde wilt vervoeren. Dankzij een hydraulisch kantelmechanisme is lossen een fluitje van een cent. Deze aanhangers hebben meestal een maximale laadcapaciteit van ongeveer 2.000 kg. Een stevige SUV of zelfs een kleine vrachtwagen is meestal de beste keuze voor het trekken van een kipper. Let erop dat je de hydraulica correct gebruikt om ongelukken te voorkomen.',
    image: '/images/trailerTypes/kipper-trailer.png',
  },
  {
    type: 'Motorfietsaanhangers',
    description:
      'Voor wie een motorfiets of scooter wil vervoeren, is de motorfietsaanhanger de ideale oplossing. Deze aanhangers zijn vaak voorzien van speciale haken en riemen voor veilig transport. De maximale laadcapaciteit is meestal rond de 500 kg, wat betekent dat een gemiddelde personenauto in veel gevallen voldoet. Zorg er wel voor dat je motorfiets stevig vastgezet is om beweging tijdens het rijden te minimaliseren.',
    image: '/images/trailerTypes/motorcycle-trailer.png',
  },
  {
    type: 'Flatbed aanhangers',
    description:
      'Als je grote, lange objecten zoals houten planken of staalconstructies moet vervoeren, kijk dan naar een flatbed aanhanger. Met zijn open structuur en een laadcapaciteit die kan oplopen tot 3.000 kg, is deze aanhanger ontzettend veelzijdig. Voor het trekken ervan is een zware SUV of een vrachtwagen aanbevolen. Het is cruciaal om je lading goed te beveiligen, gezien de open structuur van de aanhanger.',
    image: '/images/trailerTypes/flatbed-trailer.png',
  },
  {
    type: 'Bagage aanhangers',
    description:
      'Voor extra bagageruimte tijdens kampeertrips of grote gezinsuitjes is de bagage aanhanger ideaal. Deze compacte aanhangers kunnen verrassend veel bagage bevatten en hebben meestal een laadcapaciteit van rond de 500 kg. Een gewone personenauto is meestal voldoende om deze aanhanger te trekken. Als veiligheidstip is het aan te raden om te controleren of de aanhanger afsluitbaar is om diefstal te voorkomen',
    image: '/images/trailerTypes/bagage-trailer.png',
  },
  {
    type: 'Verkoopwagens',
    description:
      'De verkoopwagen doet dubbel dienst als transportmiddel en mobiele bedrijfsruimte, ideaal voor foodtrucks of marktverkopers. Met een laadcapaciteit die kan variëren, meestal tussen de 1.500 en 3.000 kg, is een vrachtwagen of een zware SUV het meest geschikt om deze te trekken. Vergeet niet om na te gaan of je speciale vergunningen nodig hebt om te opereren.',
    image: '/images/trailerTypes/food-trailer.png',
  },
  {
    type: 'Fietsenaanhangers',
    description:
      'De fietsenaanhanger is perfect voor het vervoeren van meerdere fietsen. Met speciale rekken en bevestigingsmechanismen kun je fietsen veilig en schadevrij transporteren. De maximale laadcapaciteit is meestal ongeveer 500 kg, wat betekent dat een gemiddelde personenauto voldoende is. Zorg dat de fietsen stevig vastzitten om schade tijdens het rijden te vermijden.',
    image: '',
  },
  {
    type: 'Schamel aanhangers',
    description:
      'De schamel aanhanger is zeer geschikt voor het vervoeren van zware en lange ladingen zoals bouwmaterialen of machines. De laadcapaciteit kan oplopen tot 3.500 kg, wat betekent dat je een vrachtwagen nodig zult hebben om deze te trekken. Zorg ervoor dat je de lading evenredig verdeelt voor een stabiele rit.',
    image: '',
  },
  {
    type: 'Plateauwagens',
    description:
      'De plateauwagen biedt met zijn vlakke laadvloer gemak bij het laden en lossen van grote objecten, zoals pallets en bouwmaterialen. Met een laadcapaciteit die kan variëren tussen de 750 kg en 3.000 kg, is een zwaardere SUV of vrachtwagen meestal de beste keuze voor het trekken. Vergeet niet om de lading goed vast te zetten om verschuiving te voorkomen.',
    image: '/images/trailerTypes/plateau-trailer.png',
  },
  {
    type: 'Kampeer aanhangers',
    description:
      'De kampeeraanhanger is je thuis weg van huis, ideaal voor outdoor avonturen en gezinsvakanties. Deze aanhangers zijn vaak uitgerust met basisvoorzieningen zoals een bed, keuken, en soms zelfs een badkamer. Afhankelijk van het model kan de laadcapaciteit variëren, maar ligt meestal tussen de 750 kg en 1.500 kg. Een SUV of een sterke personenauto is over het algemeen geschikt voor het trekken van een kampeeraanhanger. Voordat je op pad gaat, controleer of alle voorzieningen naar behoren werken en of je over de juiste vergunningen beschikt.',
    image: '/images/trailerTypes/camping-trailer.png',
  },
]

export default function TrailerTypesInformation() {
  const [activeTrailer, setActiveTrailer] = useState<any>({})

  return (
    <div className="trailerTypeInformationWrapper">
      <div className="trailerTypeInfoHeader">
        <b>Vind de perfecte aanhanger voor jouw klus</b>
        <p>Alle aanhanger types op een rij</p>
      </div>
      <div className="trailerTypeGrid">
        {trailerTypeInformation.map((item, index) => (
          <div
            className="trailerTypeGridItem"
            key={index}
            onClick={() => setActiveTrailer(item)}
          >
            <div className="trailerTypeInfoImageWrapper">
              <img alt="trailer_model" src={item.image} />
            </div>
            <b>{item.type}</b>
            <p>{item.description}</p>
            <div>
              <Button
                size="zero"
                variant="underline"
                style={{ marginTop: '16px' }}
              >
                Meer lezen
              </Button>
            </div>
          </div>
        ))}
      </div>
      {Object.keys(activeTrailer).length > 0 && (
        <TrailerInfoPopup
          activeTrailer={activeTrailer}
          setActiveTrailer={setActiveTrailer}
        />
      )}
    </div>
  )
}

function TrailerInfoPopup({ activeTrailer, setActiveTrailer }) {
  return (
    <div className="blurContainer">
      <div className="blur" onClick={() => setActiveTrailer({})} />
      <div className="trailerTypeInfoPopup">
        <IconButton className="closeBtn" onClick={() => setActiveTrailer({})}>
          <TbX />
        </IconButton>
        <div className="trailerPopupModelContainer">
          <img alt="trailer_model" src={activeTrailer.image} />
        </div>
        <div className="trailerPopupModelInformation">
          <b>{activeTrailer.type}</b>
          <p>{activeTrailer.description}</p>
        </div>
      </div>
    </div>
  )
}
