import { TbChevronLeft } from 'react-icons/tb'
import './EditReservation.css'
import { IconButton } from '@mui/material'
import TimeSelect from '../../../calendar_view/sidebar/elements/TimeSelect'
import { useCallback, useReducer, useState } from 'react'
import { Address } from '../../../../../addTrailerForm/TrailerForm'

const addressReducer = (state, action) => {
  switch (action.type) {
    case 'update':
      return { ...state, [action.field]: action.value }
    default:
      return state
  }
}

export default function EditReservation({ setContentInView }) {
  const [timePeriods, setTimePeriods] = useState([
    { id: 0, start: '07:00', end: '09:00' },
  ])

  const labelMap = {
    streetName: 'Straatnaam',
    city: 'Stad',
    postalCode: 'Postcode',
    houseNumber: 'Huisnummer',
  }

  const [address, dispatch] = useReducer(addressReducer, {
    streetName: '',
    city: '',
    postalCode: '',
    houseNumber: '',
  })

  const updateAddress = useCallback(
    (fieldName: keyof Address, fieldValue: string) => {
      dispatch({ type: 'update', field: fieldName, value: fieldValue })
    },
    []
  )

  return (
    <div className="sideBarSettingsWrapper">
      <div className="sidebarSettingsHeader">
        <IconButton className="goBack" onClick={() => setContentInView('')}>
          <TbChevronLeft />
        </IconButton>
        <p>Reservering wijzigen</p>
        <p>
          Wijzig de gevevens van Matthijs reservering op 23 april 2023 van jouw
          bakwagen ongeremd.
        </p>
      </div>
      <div className="sidebarSettingsContentContainer edit">
        <span>Wijzig datum van verhuur</span>
        <div className="customInput">
          <input placeholder="" value="28 april 2023" />
          <span>Huur datum</span>
        </div>
        <span>Wijzig ophaal/terugbreng tijden</span>
        <div className="dayDetailsTimeSelectContainer">
          {timePeriods.map((item, index) => (
            <div className="dayDetailsTimeSelectWrapper fullWidth" key={index}>
              {/* <TimeSelect
                value={item.start}
                setValue={(newValue) => {
                  const updatedTimePeriods = [...timePeriods]
                  updatedTimePeriods[index] = { ...item, start: newValue }
                  setTimePeriods(updatedTimePeriods)
                }}
                type={'pickupStart'}
                label={'Vanaf'}
              />
              <TimeSelect
                value={item.end}
                setValue={(newValue) => {
                  const updatedTimePeriods = [...timePeriods]
                  updatedTimePeriods[index] = { ...item, end: newValue }
                  setTimePeriods(updatedTimePeriods)
                }}
                type={'pickupEnd'}
                label={'Tot'}
              /> */}
            </div>
          ))}
        </div>
        <span>Wijzig exacte locatie</span>
        <div className="exactLocationSidebarGrid">
          {Object.keys(address).map((key) => (
            <div className="locationSideBarGridItem" key={key}>
              <span>{labelMap[key]}</span>
              <input
                placeholder=""
                type="text"
                value={address[key]}
                required
                onChange={(e) =>
                  updateAddress(key as keyof Address, e.target.value)
                }
                id={key}
                name={key}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
