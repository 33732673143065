import { IconButton } from '@mui/material'
import { TbX } from 'react-icons/tb'
import './SortBy.css'

export default function SortBy({ setShowSortBy, setSortColumn, setSortOrder }) {
  const sortByTypeAsc = () => {
    setSortColumn('trailerType.name')
    setSortOrder('asc')
    setShowSortBy(false)
  }

  const sortByTypeDesc = () => {
    setSortColumn('trailerType.name')
    setSortOrder('desc')
    setShowSortBy(false)
  }

  const sortByCreatedAsc = () => {
    setSortColumn('createdAt')
    setSortOrder('asc')
    setShowSortBy(false)
  }

  const sortByCreatedDesc = () => {
    setSortColumn('createdAt')
    setSortOrder('desc')
    setShowSortBy(false)
  }

  const sortByPriceAsc = () => {
    setSortColumn('price')
    setSortOrder('asc')
    setShowSortBy(false)
  }

  const sortByPriceDesc = () => {
    setSortColumn('price')
    setSortOrder('desc')
    setShowSortBy(false)
  }

  return (
    <div className="myTrailersSortByWrapper">
      <div className="mobileSortByPopup">
        <p>Sorteren</p>
        <IconButton
          className="closeBtn sortBy"
          onClick={() => setShowSortBy(false)}
        >
          <TbX />
        </IconButton>
        <div className="sortByItem">
          <p></p>
        </div>
      </div>
    </div>
  )
}
