import { eachDayOfInterval, format } from 'date-fns'

export const checkAvailability = (selectedRange, findDateData, trailerInfo) => {
  if (!selectedRange?.startDate || !selectedRange?.endDate) {
    return null
  }

  const daysInRange = eachDayOfInterval({
    start: selectedRange?.startDate,
    end: selectedRange?.endDate,
  })

  let isAllAvailable = true
  let isAllUnavailable = true

  for (const day of daysInRange) {
    const dayInfo = findDateData(day)
    const dayOfWeek = format(day, 'eeee').toLowerCase()
    const trailerDayInfo = trailerInfo?.trailerWeekScheduleTemplate?.[dayOfWeek]

    const isAvailable =
      (dayInfo &&
        Object.keys(dayInfo).length > 0 &&
        dayInfo?.timeSlots?.length > 0) ||
      (!dayInfo && trailerDayInfo?.timeSlots?.length > 0)

    if (!isAvailable) {
      isAllAvailable = false
    } else {
      isAllUnavailable = false
    }

    if (!isAllAvailable && !isAllUnavailable) {
      break
    }
  }

  return isAllAvailable ? true : isAllUnavailable ? false : null
}
