import { createContext } from 'react'
import { User } from '../types/User'

type AuthContextType = {
  user: User | null
  setUser: (user: User) => void
  removeUser: () => void
  showLoginModal: boolean
  setShowLoginModal: (value: boolean) => void
  isLoading: boolean
}

const defaultContextValues: AuthContextType = {
  user: null,
  setUser: () => {},
  removeUser: () => {},
  showLoginModal: false,
  setShowLoginModal: () => {},
  isLoading: true,
}

export const AuthContext = createContext<AuthContextType>(defaultContextValues)
