import './App.css'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom'
import Home from './pages/Home'
import TrailerProfile from './pages/trailers/TrailerProfile'
import Contact from './pages/Contact'
import Header from './components/constants/header/Header'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from './themes/light-theme'
import AuthProvider from './providers/auth-provider'
import { Box } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import TrailerForm from './components/addTrailerForm/TrailerForm'
import Profile from './pages/Profile'
import TrailerOwnerHeader from './components/profile/trailer_owner/constants/TrailerOwnerHeader'
import TrailerOwnerDashboard from './pages/TrailerOwnerDashboard'
import UserCalendarOverview from './components/profile/trailer_owner/calendar_view/UserCalendarOverview'
import Reserve from './pages/trailers/Reserve'
import 'react-toastify/dist/ReactToastify.css'
// import TawkMessengerReact from '@tawk.to/tawk-messenger-react'
import { useState } from 'react'
import { OverlayProvider } from './hooks/OverlayProvider'
import Success from './pages/Success'
import NewProfile from 'pages/trailers/NewProfile'
import Messenger from 'pages/Messenger'
import HowItWorks from 'components/howItWorks/HowItWorks'
import TrailerTypesInformation from 'components/howItWorks/content/TrailerTypesInformation'
import HIWOwner from 'components/howItWorks/content/HIWOwner'
import TrailerOffers from 'components/trailerOffers/offer/TrailerOffers'
import { ToastContainer } from 'react-toastify'
import PrivateRoute from 'util/PrivateRoute'

function App() {
  const [contentInView, setContentInView] = useState('myTrailers')

  const ConditionalHeader = () => {
    const location = useLocation()
    const isOwner = location.pathname.includes('owner')
    return isOwner ? (
      <TrailerOwnerHeader setContentInView={setContentInView} />
    ) : (
      <Header />
    )
  }

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <ThemeProvider theme={theme}>
        {/* <TawkMessengerReact
          propertyId="64883b6b94cf5d49dc5d5fa2"
          widgetId="1h2q2g6tl"
        /> */}
        <div className="pilot__box-mobile">
          <p>
            BuurBak is gedurende de pilot niet beschikbaar op mobiele devices.
            <br />
            <br />
            Gebruik je desktop of tablet om BuurBak te gebruiken.
          </p>
        </div>
        <div className="pilot__box">
          <Router>
            <AuthProvider>
              <OverlayProvider>
                <ToastContainer />
                <ConditionalHeader />
                <Box component="main" sx={{ paddingTop: '80px' }}>
                  {/* Public routes */}
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/aanbod" element={<TrailerOffers />} />
                    <Route path="/verhuren" element={<TrailerForm />} />
                    <Route path="/aanbod/:id" element={<TrailerProfile />} />
                    {/*<Route path="/aanbod-nieuw/:id" element={<NewProfile />} />*/}
                    {/*<Route path="/hoe-het-werkt" element={<HowItWorks />} />*/}
                    {/*<Route path="/hoe-werkt-verhuren" element={<HIWOwner />} />*/}
                    <Route
                      path="/aanhanger-types"
                      element={<TrailerTypesInformation />}
                    />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/" element={<Home />}></Route>
                    <Route path="/verhuren" element={<TrailerForm />}></Route>
                    <Route
                      path="/aanbod/:id"
                      element={<TrailerProfile />}
                    ></Route>
                    <Route path="/reserveren/:id" element={<Reserve />}></Route>
                    <Route path="/success" element={<Success />}></Route>

                    {/* Private routes */}
                    <Route path="/account" element={<PrivateRoute />}>
                      <Route index element={<Profile />} />
                    </Route>
                    {/*<Route path="/berichten" element={<PrivateRoute />}>*/}
                    {/*  <Route index element={<Messenger />} />*/}
                    {/*</Route>*/}
                    <Route path="/owner/dashboard" element={<PrivateRoute />}>
                      <Route
                        index
                        element={
                          <TrailerOwnerDashboard
                            contentInView={contentInView}
                            setContentInView={setContentInView}
                          />
                        }
                      />
                    </Route>
                    <Route path="/owner/kalender" element={<PrivateRoute />}>
                      <Route index element={<UserCalendarOverview />} />
                    </Route>
                  </Routes>
                </Box>
              </OverlayProvider>
            </AuthProvider>
          </Router>
        </div>
      </ThemeProvider>
    </LocalizationProvider>
  )
}

export default App
