import React from 'react'
import './MainUserInfo.css'
import { IconButton } from '@mui/material'
import {
  TbBell,
  TbMail,
  TbMapPin,
  TbPhone,
  TbBalloon,
  TbStar,
} from 'react-icons/tb'
import useWindowWidth from '../../../../hooks/useWindowWidth'
import { useNavigate } from 'react-router-dom'
import ProfilePicture from 'components/util/ProfilePicture'
import { format } from 'date-fns'
import { nl } from 'date-fns/locale'
import { Button } from '../../../ui'

interface MainUserInfoProps {
  user: any
  setShowSettings: (show: boolean) => void
  setSettingsContentInView: (content: string) => void
}

const MainUserInfo: React.FC<MainUserInfoProps> = ({
  user,
  setShowSettings,
  setSettingsContentInView,
}) => {
  const windowWidth = useWindowWidth()
  const navigate = useNavigate()

  const handleSettingsClick = () => {
    setShowSettings(true)
    setSettingsContentInView('myInfo')
  }

  const renderAccountInformationItem = (
    label: string,
    value: string | null,
    Icon: React.ElementType
  ) => (
    <div className="accountInformationItem">
      <div>
        <span>{label}</span>
        {!value ? (
          <u onClick={handleSettingsClick}>Toevoegen</u>
        ) : (
          <p>{value}</p>
        )}
      </div>
      <Icon />
    </div>
  )

  if (!user) return null

  return (
    <div className="mainUserInfoContainer">
      <div className="currentUserInformation mobile">
        <div className="profileAvatarWrapper">
          <ProfilePicture user={user} dimension={110} />
        </div>
        <div className={windowWidth < 768 ? 'currentUserInfoMobile' : ''}>
          <b>{user.name}</b>
          <div className="userProfileActions">
            {windowWidth > 768 && (
              <>
                <Button
                  onClick={() => setShowSettings(true)}
                  radius="lg"
                  fullWidth={true}
                  variant="blackOutline"
                >
                  Instellingen
                </Button>
                {/*<Button*/}
                {/*  onClick={() => navigate('/berichten')}*/}
                {/*  radius="lg"*/}
                {/*  variant="primaryOutline"*/}
                {/*>*/}
                {/*  Berichten*/}
                {/*</Button>*/}
              </>
            )}
          </div>
        </div>
        {windowWidth > 768 && (
          <>
            {renderAccountInformationItem('Email', user.email, TbMail)}
            {/*{renderAccountInformationItem(*/}
            {/*  'Rating',*/}
            {/*  '4.1 rating van 36 reviews',*/}
            {/*  TbStar*/}
            {/*)}*/}
          </>
        )}
      </div>
    </div>
  )
}

export default MainUserInfo
