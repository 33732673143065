import { useContext, useState } from 'react'
import {
  TbCalendar,
  TbInfoCircle,
  TbPig,
  TbPolaroid,
  TbUserCircle,
} from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import TrailerPreview from '../TrailerPreview'
import './Complete.css'
import Loading from 'components/constants/Loading'
import { useAuth } from 'hooks/use-auth'
import { AuthContext } from 'context/auth-context'
import { useTranslation } from 'react-i18next'
import { Button } from '../../../ui'

export default function Complete({
  trailerType,
  description,
  trailerImages,
  trailerStats,
  price,
  trailerAccessoires,
  address,
  showLogin,
  setShowLogin,
  loading,
  success,
  handleSubmit,
  trailerData,
}) {
  const [showPreview, setShowPreview] = useState<boolean>(false)
  const { refreshUser } = useAuth()
  const { user } = useContext(AuthContext)
  const navigate = useNavigate()
  const { t } = useTranslation('TrailerForm')

  return (
    <div className="addTrailerFormWrapper">
      <p>{t('complete.title')}</p>
      <h2>{t('complete.subTitle')}</h2>
      <p>{t('complete.introduction')}</p>
      <div className="additionalInformationContainer">
        <div className="additionalInformationGrid">
          {!user && (
            <div className="additionalInformationGridItem">
              <div className="additionalInformationIconContainer">
                <TbUserCircle size={22} color="var(--primary)" />
              </div>
              <div className="additionalInformationGridItemContent">
                <p>{t('complete.information.noUser')}</p>
                <p>{t('complete.information.noUserContent')}</p>
                <Button
                  style={{ marginTop: 16 }}
                  type="button"
                  onClick={() => setShowLogin(true)}
                >
                  {t('complete.information.noUserCTA')}
                </Button>
              </div>
            </div>
          )}
          {user?.iban && (
            <div className="additionalInformationGridItem">
              <div className="additionalInformationIconContainer">
                <TbPolaroid size={22} color="var(--primary)" />
              </div>
              <div className="additionalInformationGridItemContent">
                <p>{t('complete.information.allClear')}</p>
                <p>{t('complete.information.allClearContent')}</p>
              </div>
            </div>
          )}
          {!!user && (
            <div className="additionalInformationGridItem">
              <div className="additionalInformationIconContainer">
                <TbCalendar size={22} color="var(--primary)" />
              </div>
              <div className="additionalInformationGridItemContent">
                <p>{t('complete.information.priceAvailability')}</p>
                <p>{t('complete.information.priceAvailabilityContent')}</p>
              </div>
            </div>
          )}
        </div>
        <div className="completeFormTrailerPreview">
          <img
            className="trailerPreviewImage"
            alt="trailer"
            src={trailerImages[0].preview}
          />
          <div className="completeFormTrailerPreviewInfo">
            <p>{trailerType}</p>
            <p>
              €{price},00 {t('preview.pricePerDay')} • {address.city}
            </p>
          </div>
          <div className="divider" />
          <button
            className="primaryBtn preview"
            onClick={() => setShowPreview(true)}
            type="button"
          >
            {t('complete.preview')}
          </button>
        </div>
        <div className="completeFormActions">
          <button
            className={`primaryBtn submit ${!user && 'disabledBtn'}`}
            type="submit"
            onClick={(e) => handleSubmit(e, trailerData, trailerImages)}
            disabled={!user}
          >
            {t('general.complete')}
          </button>
        </div>
      </div>
      {showPreview && (
        <TrailerPreview
          price={price}
          trailerAccessoires={trailerAccessoires}
          trailerType={trailerType}
          description={description}
          trailerStats={trailerStats}
          trailerImages={trailerImages}
          setShowPreview={setShowPreview}
          address={address}
          t={t}
        />
      )}
      {success && (
        <div className="blurContainer">
          <div className="blur" />
          <div className="popupContainer infoBox">
            <div className="popupContainerHeader">
              <p>{t('succesPopup.title')}</p>
            </div>
            <div className="popupContainerContent succes">
              <div className="shareTrailerContainer">
                <img alt="trailer" src={trailerImages[0].preview} />
              </div>
              <p>{t('succesPopup.content')}</p>
              <button
                className="primaryBtn preview primary"
                onClick={() => navigate('/')}
                style={{ marginLeft: 'auto', marginRight: 'auto' }}
              >
                {t('succesPopup.action')}
              </button>
            </div>
          </div>
        </div>
      )}
      {loading && <Loading />}
    </div>
  )
}
