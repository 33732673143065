import { Button, Input } from 'components/ui'
import ProfilePicture from 'components/util/ProfilePicture'
import { Search } from 'data/icons/TrailerIcons'
import useWindowWidth from 'hooks/useWindowWidth'
import { TbAdjustments, TbSearch } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import './MobileHeader.css'

export default function MobileHeader({ setShowFilters, setSearchTerm, user }) {
  const navigate = useNavigate()
  const windowWidth = useWindowWidth()

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value)
  }

  return (
    <div className="mobileOfferHeader">
      <div className="flexbox spaceBetween">
        <h3>Aanbod</h3>
        {windowWidth <= 768 && (
          <div onClick={() => navigate('/account')}>
            <ProfilePicture dimension={42} user={user} />
          </div>
        )}
      </div>
      {/*<div className="mobileOfferHeaderSearch flexbox">*/}
      {/*  <div className="mobileInputWrapper flexbox">*/}
      {/*    <Search size={15} strokeWidth="1.5" />*/}
      {/*    <input*/}
      {/*      placeholder="Zoeken op aanhanger of locatie..."*/}
      {/*      onChange={(e) => setSearchTerm(e.target.value)}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/* <Input
          name="offerSearch"
          label="Search"
          placeholder="Zoeken op aanhanger of locatie..."
          handleChange={handleInputChange}
          icon={<TbSearch size={15} strokeWidth="1.5" />}
        /> */}
      {/*<Button onClick={() => setShowFilters(true)} className="radius__medium">*/}
      {/*  <TbAdjustments*/}
      {/*    style={{ transform: 'rotate(90deg)', marginRight: 5 }}*/}
      {/*  />*/}
      {/*  {windowWidth > 480 && 'Filters'}*/}
      {/*</Button>*/}
      {/*</div>*/}
    </div>
  )
}
