import './TrailerType.css'
import { TbInfoCircle, TbX } from 'react-icons/tb'
import { useEffect, useState } from 'react'
import { IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TrailerCategories } from '../../../data/dummy/TrailerTypes'

export default function TrailerType({ trailerType, setTrailerType }) {
  const [types, setTypes] = useState([])
  const [trailerInfo, setTrailerInfo] = useState(false)
  const [customTrailer, setCustomTrailer] = useState(false)
  const [customTrailerTitle, setCustomTrailerTitle] = useState('')
  const extraTrailerInfo = types.filter(
    (trailer) => trailer.trailerType === trailerType
  )
  const { t } = useTranslation('TrailerForm')

  useEffect(() => {
    setTypes(TrailerCategories)
  }, [])

  return (
    <div className="formStepContainer">
      <h2>{t('trailerType.title')}</h2>
      <p>{t('trailerType.introduction')}</p>
      <div className="trailerTypeGrid addTrailerForm">
        {types.map((type) => (
          <div
            className={
              trailerType === type.trailerType
                ? 'trailerTypeIconActive'
                : 'trailerTypeIcon'
            }
            key={type.id}
            onClick={() => {
              setTrailerType(type.trailerType)
              setCustomTrailerTitle('')
            }}
          >
            {type.icon}
            <p>{type.trailerType}</p>
            <IconButton
              className="infoIcon"
              onClick={() => setTrailerInfo(true)}
            >
              <TbInfoCircle />
            </IconButton>
          </div>
        ))}
        {customTrailer && (
          <div>
            <div
              className="backgroundBlur"
              onClick={() => setCustomTrailer(false)}
            />
            <div className="addCustomTrailerContainer">
              <img
                className="addTrailerImg"
                alt="illustration"
                src={'/customTrailer.svg'}
              />
              <div className="addTrailerContent">
                <IconButton
                  className="closePopup"
                  onClick={() => setCustomTrailer(false)}
                >
                  <TbX />
                </IconButton>
                <p>Ander aanhanger type</p>
                <p>
                  Staat jouw aanhanger type er niet tussen? Geef de titel van
                  jouw aanhanger op.
                </p>
                <input
                  className="customTrailerInput"
                  placeholder="Aanhanger type"
                  onChange={(e) => setCustomTrailerTitle(e.target.value)}
                  value={customTrailerTitle}
                  maxLength={22}
                />
                <button
                  className="saveCustomTrailer"
                  onClick={() => {
                    setTrailerType(customTrailerTitle)
                    setCustomTrailer(false)
                  }}
                  type="button"
                >
                  Opslaan
                </button>
              </div>
            </div>
          </div>
        )}

        {/* More trailer info + img */}
        {trailerType && trailerInfo && (
          <div className="blurContainer">
            <div className="blur" onClick={() => setTrailerInfo(false)} />
            <div className="popupContainer infoBox">
              <div className="popupContainerHeader">
                <IconButton
                  className="closePopup transformed"
                  onClick={() => setTrailerInfo(false)}
                >
                  <TbX />
                </IconButton>
                <p>Meer aanhanger informatie</p>
              </div>
              <div className="popupContainerContent">
                <img
                  className="popupContainerImage"
                  alt="trailer"
                  src={extraTrailerInfo[0].image}
                />
                <b>{extraTrailerInfo[0].trailerType}</b>
                <p>{extraTrailerInfo[0].description}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
