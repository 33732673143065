import { IconButton } from '@mui/material'
import { trailerStats } from 'components/addTrailerForm/formsteps/TrailerStats'
import {
  TrailerCapacityDouble,
  TrailerHeightDouble,
  TrailerLengthDouble,
  TrailerWeight,
  TrailerWidth2,
} from 'data/icons/TrailerIcons'
import { useEffect, useState } from 'react'
import {
  TbChevronLeft,
  TbChevronRight,
  TbInfoCircle,
  TbX,
} from 'react-icons/tb'
import useUpdateTrailer from '../../hooks/useUpdateTrailer'
import './SidebarStats.css'

interface SidebarStatsProps {
  setSidebarContent: (content: string) => void
  trailerInfo: {
    length: number
    width: number
    height: number
  }
  setTrailerInfo: (info: {
    length: number
    width: number
    height: number
  }) => void
}

interface Field {
  name: keyof SidebarStatsProps['trailerInfo']
  label: string
  placeholder: string
  icon: JSX.Element
}

const fields: Field[] = [
  {
    name: 'length',
    label: 'Lengte',
    placeholder: 'cm',
    icon: <TrailerLengthDouble size={21} strokeWidth="1.5" />,
  },
  {
    name: 'width',
    label: 'Breedte',
    placeholder: 'cm',
    icon: <TrailerWidth2 size={21} strokeWidth="1.5" />,
  },
  {
    name: 'height',
    label: 'Hoogte',
    placeholder: 'cm',
    icon: <TrailerHeightDouble size={21} strokeWidth="1.5" />,
  },
]

export default function SidebarStats({
  setSidebarContent,
  trailerInfo,
  setTrailerInfo,
}: SidebarStatsProps) {
  const [updatedData, setUpdatedData] = useState(trailerInfo)
  const updateTrailerData = useUpdateTrailer(trailerInfo)
  const [showInfo, setShowInfo] = useState(false)

  const handleSaveChanges = async () => {
    for (const field of Object.keys(updatedData)) {
      if (updatedData[field] !== trailerInfo[field]) {
        await updateTrailerData(field, updatedData[field])
      }
    }
    setTrailerInfo(updatedData)
    setSidebarContent('')
    window.location.reload()
  }

  useEffect(() => {
    setUpdatedData(trailerInfo)
  }, [trailerInfo])

  if (!trailerInfo) {
    return <p>Loading...</p>
  }

  return (
    <div className="sideBarSettingsWrapper">
      <div className="sidebarSettingsHeader">
        <IconButton className="goBack" onClick={() => setSidebarContent('')}>
          <TbChevronLeft />
        </IconButton>
        <p>Aanhanger gegevens</p>
        <p>
          Geef wat aanvullende informatie over jouw aanhanger, zodat huurders
          weten of de aanhanger geschikt is.
        </p>
      </div>
      <div className="sidebarSettingsContentContainer stats">
        <div
          className="sidebarShortcutItem price type"
          style={{ marginTop: 30 }}
          onClick={() => setShowInfo(true)}
        >
          <TbInfoCircle className="sidebarIcon" />
          <p>Meer informatie</p>
          <TbChevronRight />
        </div>
        {fields.map((field) => (
          <div key={field.name} className="sidebarStatItem">
            {field.icon}
            <p>{field.label}</p>
            <input
              type="number"
              placeholder={field.placeholder}
              value={updatedData[field.name] ?? ''}
              onChange={(e) =>
                setUpdatedData((prevState) => ({
                  ...prevState,
                  [field.name]:
                    e.target.value === '' ? '' : e.target.valueAsNumber,
                }))
              }
            />
          </div>
        ))}
      </div>
      {Object.keys(updatedData).some(
        (key) => updatedData[key] !== trailerInfo[key]
      ) && (
        <div className="sidebarSettingsFooterAction updated">
          <button className="sidebarActionButton" onClick={handleSaveChanges}>
            Wijzigingen opslaan
          </button>
        </div>
      )}
      {showInfo && <MoreInfoPopup setShowInfo={setShowInfo} />}
    </div>
  )
}

function MoreInfoPopup({ setShowInfo }) {
  return (
    <div className="blurContainer">
      <div className="blur" onClick={() => setShowInfo(false)} />
      <div className="popupContainer infoBox stats">
        <div className="popupContainerHeader">
          <IconButton
            className="closePopup transformed"
            onClick={() => setShowInfo(false)}
          >
            <TbX />
          </IconButton>
          <p>Meer informatie over trailerStatistieken?</p>
        </div>
        <div className="popupContainerContent">
          <div className="popupContainerInfoGrid">
            {trailerStats.map((item) => (
              <div key={item.value} className="popupContainerGridItem">
                {item.icon}
                <b>{item.value}</b>
                <p>{item.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
