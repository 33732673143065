import { useState } from 'react'
import axios from 'axios'
import useDebouncedEffect from './UseDebouncedEffect'
import { GeoCode } from '../TrailerForm'

const useUpdateGeoCode = (
  streetName: string,
  houseNumber: string,
  postalCode: string,
  city: string
): GeoCode | undefined => {
  const [geoCode, setGeoCode] = useState<GeoCode | undefined>({
    lat: 52.08744,
    lng: 5.26395,
  })

  const fetchGeoCode = async () => {
    if (!streetName || !houseNumber || !postalCode || !city) {
      return
    }

    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${streetName}+${houseNumber},+${postalCode}+${city}&key=${process.env.REACT_APP_GOOGLE_GEOCODING_API_KEY}`
      )

      if (response.data.status === 'OK') {
        const location = response.data.results[0].geometry.location
        setGeoCode({ lat: location.lat, lng: location.lng })
      }
    } catch (error) {
      console.error('Error fetching geocode:', error)
    }
  }

  useDebouncedEffect(
    () => {
      fetchGeoCode()
    },
    [streetName, houseNumber, postalCode, city],
    500
  )

  return geoCode
}

export default useUpdateGeoCode
