import { ReactElement, useContext, useState } from 'react'
import { Button, Dialog, Flex, Grid, GridItem } from '../../ui'
import { TrailerOffer } from '../../../types/TrailerOffer'
import '../../../pages/trailers/trailerprofile.css'
import { TrailerPhotosDialog } from './TrailerPhotosDialog'
import { TbPhoto } from 'react-icons/tb'
import { CarouselContext } from 'pure-react-carousel'

interface TrailerHeaderProps {
  trailerOffer: TrailerOffer
}

export const TrailerHeader = ({
  trailerOffer,
}: TrailerHeaderProps): ReactElement => {
  const carouselContext = useContext(CarouselContext)

  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [currentSlide, setCurrentSlide] = useState<number>(0)

  const handleClick = (index: number) => {
    if (modalOpen) {
      setCurrentSlide(0)
      setModalOpen(!modalOpen)
    } else {
      setCurrentSlide(index)
      setModalOpen(!modalOpen)
    }
  }

  return (
    <div>
      <Grid xs={12} gap={16}>
        <GridItem
          xsRowSpan={2}
          xsSpan={12}
          mdSpan={6}
          className="grid__item__cover"
        >
          <img
            className="trailer__offer__image cover"
            onClick={() => handleClick(0)}
            src={trailerOffer.coverImage}
          />
        </GridItem>
        {trailerOffer.images.slice(0, 4).map((image, index) => {
          return (
            <GridItem
              xsSpan={6}
              mdSpan={3}
              key={index}
              className="grid__item__normal"
            >
              <img
                className="trailer__offer__image"
                src={image}
                onClick={() => handleClick(index + 1)}
              />
              {trailerOffer.images.slice(0, 4).length - 1 === index && (
                <Dialog
                  title="Foto's"
                  open={modalOpen}
                  onOpenChange={() => handleClick(index + 1)}
                  triggerElement={
                    <div className="trailer__modal__button--wrapper">
                      <Button
                        variant="gray"
                        size="sm"
                        className="trailer__modal__button"
                      >
                        <Flex gap={8} alignItems="center">
                          <TbPhoto /> Meer foto's
                        </Flex>
                      </Button>
                    </div>
                  }
                  dialogContent={
                    <TrailerPhotosDialog
                      currentSlide={currentSlide}
                      setCurrentSlide={setCurrentSlide}
                      images={[trailerOffer.coverImage, ...trailerOffer.images]}
                    />
                  }
                />
              )}
            </GridItem>
          )
        })}
      </Grid>
    </div>
  )
}
