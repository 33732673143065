import { TbChevronLeft, TbId } from 'react-icons/tb'
import './SidebarLicense.css'
import { IconButton } from '@mui/material'
import useUpdateTrailer from '../../hooks/useUpdateTrailer'
import { useState } from 'react'

export default function SidebarLicense({
  trailerInfo,
  setTrailerInfo,
  setSidebarContent,
}) {
  const updateTrailerData = useUpdateTrailer(trailerInfo)
  const [updatedLicense, setUpdatedLicense] = useState(
    trailerInfo.driversLicenseType
  )

  const handleUpdateField = async (field, value) => {
    const updatedData = await updateTrailerData(field, value)
    setTrailerInfo(updatedData)
    setSidebarContent('')
    window.location.reload()
  }

  return (
    <div className="sideBarSettingsWrapper">
      <div className="sidebarSettingsHeader">
        <IconButton className="goBack" onClick={() => setSidebarContent('')}>
          <TbChevronLeft />
        </IconButton>
        <p>Rijbewijs verplichting</p>
        <p>Geef de rijbewijsverplichting voor de geselecteerde aanhanger op.</p>
      </div>
      <div className="sidebarSettingsContentContainer license">
        <div
          className="sideBarLicenseSelectContainer"
          onClick={() => setUpdatedLicense('B')}
        >
          <TbId size={22} strokeWidth={1.5} color="var(--text)" />
          <div>
            <p>Geen rijbewijs verplicht</p>
            <p>B rijbewijs volstaat</p>
          </div>
          <div
            className={`select license ${updatedLicense === 'B' && 'active'}`}
          >
            {updatedLicense === 'B' && <div className="selectIndicator" />}
          </div>
        </div>
        <div
          className="sideBarLicenseSelectContainer"
          onClick={() => setUpdatedLicense('BE')}
        >
          <TbId size={22} strokeWidth={1.5} color="var(--text)" />
          <div>
            <p>Rijbewijs BE verplicht</p>
            <p>Max. trekgewicht 3500 kg.</p>
          </div>
          <div
            className={`select license ${updatedLicense === 'BE' && 'active'}`}
          >
            {updatedLicense === 'BE' && <div className="selectIndicator" />}
          </div>
        </div>
        <div
          className="sideBarLicenseSelectContainer"
          onClick={() => setUpdatedLicense('B+')}
        >
          <TbId size={22} strokeWidth={1.5} color="var(--text)" />
          <div>
            <p>Rijbewijs B+ verplicht</p>
            <p>Max. trekgewicht 4250 kg.</p>
          </div>
          <div
            className={`select license ${updatedLicense === 'B+' && 'active'}`}
          >
            {updatedLicense === 'B+' && <div className="selectIndicator" />}
          </div>
        </div>
      </div>
      {updatedLicense !== trailerInfo.driversLicenseType && (
        <div className="sidebarSettingsFooterAction updated">
          <button
            className="sidebarActionButton"
            onClick={() =>
              handleUpdateField('driversLicenseType', updatedLicense)
            }
          >
            Wijzigingen opslaan
          </button>
        </div>
      )}
    </div>
  )
}
