import { Box, Modal, Slide, Toolbar, IconButton } from '@mui/material'
import { useState } from 'react'
import Login from './Login'
import Register from './Register'
import './Auth.css'
import { TbX } from 'react-icons/tb'

interface LoginRegisterModalProps {
  open: boolean
  onClose: () => void
}

export default function LoginRegisterModal({
  open,
  onClose,
}: LoginRegisterModalProps) {
  const [login, setLogin] = useState(true)

  const handleChange = () => {
    setLogin((prevState) => !prevState)
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'scroll',
      }}
    >
      <Slide direction="up" in={open} mountOnEnter unmountOnExit>
        <Box className="loginRegisterModalContainer">
          <IconButton className="closeLoginRegister" onClick={onClose}>
            <TbX />
          </IconButton>
          <Toolbar className="loginRegisterModalHeader">
            <p>
              <span>Inloggen</span> of <span>registreren</span> bij BuurBak
            </p>
          </Toolbar>
          <div className="loginRegisterContainerContent">
            {login ? (
              <Login onClose={onClose} handleChange={handleChange} />
            ) : (
              <Register onClose={onClose} handleChange={handleChange} />
            )}
          </div>
        </Box>
      </Slide>
    </Modal>
  )
}
