import { useState } from 'react'
import './ContactForm.css'
import { Button } from '../ui'

export default function ContactForm() {
  const [typeOfUser, setTypeOfUser] = useState()
  const [email, setEmail] = useState('')
  const [topic, setTopic] = useState()
  const [question, setQuestion] = useState('')
  const [description, setDescription] = useState('')
  const [url, setUrl] = useState('')

  return (
    <div className="formContainer">
      <b>
        Niet gevonden wat je zocht? <span>Neem contact op!</span>
      </b>
      <p>
        Geen antwoord gekregen op je vraag? Neem contact op en we komen zo snel
        mogelijk bij je terug.
      </p>
      <p style={{ marginTop: 16 }}>
        Via <a href="mailto:info@buurbak.nl">info@buurbak.nl</a> kan je contact
        met ons opnemen, wij beantwoorden je vraag zo spoedig mogelijk.
        Daarnaast is het ook mogelijk ons te bereiken via de telefoon{' '}
        <a href="tel:+31625411606">+31 6 25411606</a>
      </p>
    </div>
  )
}
