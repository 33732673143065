import './CalendarMonthHeader.css'
import { format, isSameMonth, parse } from 'date-fns'
import { nl } from 'date-fns/locale'
import { TbChevronDown } from 'react-icons/tb'
import { useRef } from 'react'
import useClickOutside from '../../../../../hooks/onClickOutside'

export default function CalendarMonthHeader({
  currentMonthInView,
  showSelectMonthOptions,
  setShowSelectMonthOptions,
  selectableMonths,
  monthRefs,
}) {
  const handleMonthClick = (index) => {
    const monthElement = monthRefs.current[index].current
    monthElement.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <div className="calendarMontHeader">
      <CalendarMonthHeaderContent
        currentMonthInView={currentMonthInView}
        showSelectMonthOptions={showSelectMonthOptions}
        setShowSelectMonthOptions={setShowSelectMonthOptions}
        selectableMonths={selectableMonths}
        handleMonthClick={handleMonthClick}
      />
      <div className="calendarMonthDays">
        {['Ma', 'Di', 'Woe', 'Do', 'Vri', 'Za', 'Zo'].map((item, index) => (
          <p key={index}>{item}</p>
        ))}
      </div>
    </div>
  )
}

function CalendarMonthHeaderContent({
  currentMonthInView,
  showSelectMonthOptions,
  setShowSelectMonthOptions,
  selectableMonths,
  handleMonthClick,
}) {
  const monthSelectRef = useRef(null)

  useClickOutside(monthSelectRef, () => {
    setShowSelectMonthOptions(false)
  })

  return (
    <div className="calendarMonthHeaderContent">
      <div className="calendarMonthSelectWrapper" ref={monthSelectRef}>
        <div
          className="calendarMonthSelect"
          onClick={() => setShowSelectMonthOptions((current) => !current)}
        >
          <p>{format(currentMonthInView, 'MMMM yyyy', { locale: nl })}</p>
          <TbChevronDown
            className={
              showSelectMonthOptions ? 'chevronTranfsormed' : 'chevron'
            }
          />
        </div>
        {showSelectMonthOptions && (
          <CalendarMonthOptions
            currentMonthInView={currentMonthInView}
            selectableMonths={selectableMonths}
            handleMonthClick={handleMonthClick}
          />
        )}
      </div>
    </div>
  )
}

function CalendarMonthOptions({
  currentMonthInView,
  selectableMonths,
  handleMonthClick,
}) {
  return (
    <div className="calendarMonthOptionsContainer">
      {selectableMonths.map((month, index) => {
        const parsedMonth = parse(month, 'MMMM yyyy', new Date(), {
          locale: nl,
        })
        const isSelected = isSameMonth(currentMonthInView, parsedMonth)

        return (
          <div
            className={`calendarMonthOptionItem ${
              isSelected ? 'isSelected' : ''
            }`}
            key={index}
            onClick={() => handleMonthClick(index)}
          >
            <div
              className={
                isSelected
                  ? 'isSelectedIndicatorCircleActive left'
                  : 'isSelectedIndicatorCircle left'
              }
            >
              {isSelected && <div className="isSelectedActive" />}
            </div>
            <p>{month}</p>
          </div>
        )
      })}
    </div>
  )
}
