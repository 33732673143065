import { useEffect, useRef, useState } from 'react'
import './ReservationsTableHeader.css'

export default function ReservationsTableHeader({ setColumnWidths }) {
  const gridRef = useRef(null)
  const columnWidths = useTableColumnWidths(gridRef)

  useEffect(() => {
    setColumnWidths(columnWidths)
  }, [columnWidths, setColumnWidths])

  const headers = [
    'Huurder',
    'Aanhanger',
    'Prijs',
    'Datum/periode',
    // 'Start tijd',
    // 'Eind tijd',
    'Status',
    'Acties',
  ]

  return (
    <div className="tableHeaderContainer">
      <div className="tableHeaderContainerGrid reservations" ref={gridRef}>
        {headers.map((header, index) => (
          <div className="tableHeaderCategory" key={index}>
            <p>{header}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

const useTableColumnWidths = (ref) => {
  const [columnWidths, setColumnWidths] = useState([])

  const updateColumnWidths = () => {
    if (ref.current) {
      const columns = Array.from(ref.current.children) as HTMLElement[]
      if (columns.length > 0) {
        setTimeout(() => {
          const widths = columns.map(
            (column: HTMLElement) => column.getBoundingClientRect().width
          )
          setColumnWidths(widths)
        }, 100)
      }
    }
  }

  useEffect(() => {
    updateColumnWidths()

    const resizeObserver = new ResizeObserver(() => {
      updateColumnWidths()
    })

    const mutationObserver = new MutationObserver(() => {
      updateColumnWidths()
    })

    if (ref.current) {
      resizeObserver.observe(ref.current)
      mutationObserver.observe(ref.current, { childList: true, subtree: true })
    }

    return () => {
      if (ref.current) {
        resizeObserver.unobserve(ref.current)
        mutationObserver.disconnect()
      }
    }
  }, [ref])

  return columnWidths
}
