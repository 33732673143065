import './CalendarGrid.css'
import { TbClock } from 'react-icons/tb'
import {
  format,
  isToday,
  isBefore,
  isAfter,
  isWithinInterval,
  parseISO,
  isSameDay,
  isPast,
} from 'date-fns'
import { Avatar } from '@mui/material'
import {
  dayContainerClass,
  formatDayItem,
  contentClass,
  constructPrice,
} from './utility/CalendarHelper'

const DayItemDetails = ({
  windowWidth,
  dayReservation,
  updatedDayInfo,
  trailerDayInfo,
}) => {
  if (
    windowWidth <= 1280 ||
    (dayReservation && Object.keys(dayReservation).length > 0)
  ) {
    return null
  }
  const timeSlots = updatedDayInfo?.timeSlots || trailerDayInfo?.timeSlots || []
  return (
    <>
      {timeSlots.length > 0 && (
        <div className="dayItemDetails">
          <TbClock />
          {timeSlots.length > 1 ? (
            <p>{timeSlots.length} tijdsloten</p>
          ) : (
            <p>
              {timeSlots[0]?.startTime.slice(0, 5)} -{' '}
              {timeSlots[0]?.endTime.slice(0, 5)}
            </p>
          )}
        </div>
      )}
    </>
  )
}

const ReservedContent = ({
  day,
  dayReservation,
  isInRange,
  getActiveReservations,
  parseISO,
}) => {
  const activeReservations = getActiveReservations(day)
  const removeTime = (date) =>
    new Date(date.getFullYear(), date.getMonth(), date.getDate())

  if (
    !dayReservation?.startTime ||
    format(day, 'dd MMMM yyyy') !==
      format(parseISO(dayReservation.startTime), 'dd MMMM yyyy')
  ) {
    return (
      <div>
        {activeReservations?.map((activeReservation, reservationIdx) => {
          const reservationStartDate = parseISO(dayReservation.startTime)
          if (
            isSameDay(
              removeTime(day),
              removeTime(
                dayReservation.endTime
                  ? parseISO(activeReservation.endTime)
                  : reservationStartDate
              )
            )
          ) {
            return (
              <div
                key={reservationIdx}
                className={`isReservedContentDuring end
                  ${!dayReservation.confirmed && 'request'}
                  ${isInRange && 'reservationInRange'}
                  ${
                    isPast(parseISO(activeReservation.startTime)) && 'current'
                  }`}
              />
            )
          }

          return (
            <div
              key={reservationIdx}
              className={`isReservedContentDuring
                ${!dayReservation.confirmed && 'request'}
                ${isInRange && 'reservationInRange'}
                ${isPast(parseISO(activeReservation.startTime)) && 'current'}`}
            />
          )
        })}
      </div>
    )
  }

  const baseClassName = `isReservedContent 
    ${!dayReservation.confirmed && 'request'} 
    ${
      !isSameDay(
        parseISO(dayReservation.startTime),
        parseISO(dayReservation.endTime)
      ) && 'rangeStart'
    }
    ${isInRange && 'reservationInRange'}
    ${isPast(parseISO(dayReservation.startTime)) && 'current'}`

  return (
    <div
      className={baseClassName}
      style={{
        ...(isPast(parseISO(dayReservation.startTime))
          ? {
              backgroundColor: 'var(--secondary)',
              borderColor: 'var(--secondary)',
            }
          : {}),
        ...(!isSameDay(
          parseISO(dayReservation.startTime),
          parseISO(dayReservation.endTime)
        )
          ? { overflow: 'visible' }
          : {}),
      }}
    >
      <Avatar
        className="isReservedAvatar"
        src={dayReservation?.renter?.photoUrl}
      />
      {dayReservation?.confirmed ? (
        <p>
          {dayReservation?.renter?.name} • €{dayReservation?.price}
          ,00
        </p>
      ) : (
        <p>Huuraanvraag {dayReservation?.renter?.name}</p>
      )}
    </div>
  )
}

export default function CalendarGrid({
  today,
  days,
  selectedRange,
  handleMouseDown,
  handleMouseEnter,
  handleMouseUp,
  windowWidth,
  trailerInfo,
  findDateData,
  getActiveReservations,
  upcoming,
  isAvailable,
  setIsAvailable,
}) {
  return (
    <div className="calendarViewDaysGrid">
      {days.map((day, dayIdx) => {
        const updatedDayInfo = findDateData(day)
        const dayReservation = upcoming?.find(
          (reservation) =>
            isSameDay(day, parseISO(reservation?.startTime)) ||
            (isAfter(day, parseISO(reservation?.startTime)) &&
              isBefore(day, parseISO(reservation?.endTime)))
        )
        const dayOfWeek = format(day, 'eeee').toLowerCase()
        const trailerDayInfo =
          trailerInfo?.trailerWeekScheduleTemplate?.[dayOfWeek]
        const isInReservationInterval =
          selectedRange && selectedRange.startDate && selectedRange.endDate
            ? isWithinInterval(day, {
                start: selectedRange?.startDate,
                end: selectedRange?.endDate,
              })
            : false
        const isInRange = isToday(day)
          ? isToday(day) && isInReservationInterval
          : isInReservationInterval

        return (
          <div
            key={day}
            className={`
            ${dayContainerClass(windowWidth, dayIdx, day)}
          `}
            onMouseDown={() => handleMouseDown(day)}
            onMouseEnter={() => handleMouseEnter(day)}
            onMouseUp={handleMouseUp}
          >
            <div className={formatDayItem(days, day)} draggable="false">
              <div
                className={contentClass(
                  windowWidth,
                  day,
                  today,
                  updatedDayInfo
                    ? updatedDayInfo.timeSlots.length === 0
                    : trailerDayInfo?.timeSlots?.length === 0,
                  dayReservation,
                  selectedRange,
                  isAvailable
                )}
              >
                {constructPrice(
                  windowWidth,
                  day,
                  dayReservation,
                  updatedDayInfo,
                  trailerDayInfo
                )}
                <DayItemDetails
                  windowWidth={windowWidth}
                  dayReservation={dayReservation}
                  updatedDayInfo={updatedDayInfo}
                  trailerDayInfo={trailerDayInfo}
                />
                <ReservedContent
                  day={day}
                  dayReservation={dayReservation}
                  isInRange={isInRange}
                  parseISO={parseISO}
                  getActiveReservations={getActiveReservations}
                />
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
