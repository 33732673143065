import './input.css'
import * as Form from '@radix-ui/react-form'
import { cva, VariantProps } from 'class-variance-authority'
import { Flex } from '../layout'
import { PatternFormat } from 'react-number-format'

const inputVariants = cva('input', {
  variants: {
    variant: {
      default: 'input__default',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
})

export interface InputProps
  extends React.HTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputVariants> {
  name: string
  icon?: React.ReactElement
  label: string
  error?: string
  placeholder: string
  value?: string
  handleChange?: (e: React.FormEvent<HTMLInputElement>) => void
  pattern?: string
}

export interface SingleInputProps {
  error?: string
  placeholder: string
  value?: string
  handleChange?: (e: React.FormEvent<HTMLInputElement>) => void
  pattern?: string
}

const Input = ({
  variant,
  name,
  value,
  label,
  error,
  placeholder,
  icon,
  handleChange,
  pattern,
  ...props
}: InputProps) => {
  return (
    <Form.Field
      {...props}
      name={name}
      className={`${inputVariants({ variant })}`}
    >
      <Flex xs="col" gap={8}>
        <Form.Label className="input__label">{label}</Form.Label>
        {icon ? (
          <Form.Control
            className={`input__field_icon ${error !== null && 'input__error'}`}
            asChild
          >
            <div>
              <SingleInput
                placeholder={placeholder}
                handleChange={handleChange}
                error={error}
                value={value}
                pattern={pattern}
              />
              {icon}
            </div>
          </Form.Control>
        ) : (
          <Form.Control asChild>
            <SingleInput
              placeholder={placeholder}
              handleChange={handleChange}
              error={error}
              value={value}
              pattern={pattern}
            />
          </Form.Control>
        )}
        <p className="input__error_text">{error !== null && error}</p>
      </Flex>
    </Form.Field>
  )
}

const SingleInput = ({
  value,
  handleChange,
  placeholder,
  error,
  pattern,
}: SingleInputProps) => {
  return pattern ? (
    <PatternFormat
      format={pattern}
      placeholder={placeholder}
      className={`input__field`}
      type="text"
      required
      value={value ? value : ''}
      onChange={handleChange}
    />
  ) : (
    <input
      placeholder={placeholder}
      className={`input__field ${error !== null && 'input__error'}`}
      type="email"
      required
      value={value ? value : ''}
      onChange={handleChange}
    />
  )
}

export { Input }
