import React, { SetStateAction, useEffect } from 'react'
import {
  ActiveModifiers,
  DayPicker,
  SelectSingleEventHandler,
} from 'react-day-picker'
import 'react-day-picker/dist/style.css'
import * as Popover from '@radix-ui/react-popover'
import './datepicker.css'
import { Input } from '../input/Input'
import { TbCalendar } from 'react-icons/tb'
import { add, format, isAfter, isBefore, parse, sub } from 'date-fns'

export enum Rule {
  shouldNotAllowDateBeforeCurrentDate = 'shouldNotAllowDateBeforeCurrentDate',
  shouldNotAllowDateAfterCurrentDate = 'shouldNotAllowDateAfterCurrentDate',
  shouldBeAfterSpecifiedDate = 'shouldBeAfterSpecifiedDate',
  shouldDisallowSpecificWeekDays = 'shouldDisallowSpecificWeekDays',
  shouldDisallowDisabledApiDays = 'shouldDisallowDisabledApiDays',
  shouldDisallowAllDatesAfterSpecifiedDuration = 'shouldDisallowAllDatesAfterSpecifiedDuration',
}

export interface dayPickerConfig {
  dayOfWeek: Array<number>
}

export interface dayPickerFromTo {
  from: Date
  to: Date
}

export interface DatePickerProps {
  label?: string
  rules?: Array<Rule>
  selected: Date
  setSelected: React.Dispatch<SetStateAction<Date>>
  specifiedDate?: Date
  disabledDays?: [dayPickerConfig?, dayPickerFromTo?, ...Array<Date>]
  fromMonth?: Date
  toMonth?: Date
  duration?: Duration
  passedError?: string
}

const DatePicker = ({
  label,
  rules = [],
  selected,
  setSelected,
  specifiedDate,
  disabledDays = [],
  fromMonth,
  toMonth,
  duration,
  passedError,
}: DatePickerProps) => {
  const [input, setInput] = React.useState<string>()
  const [error, setError] = React.useState<string | null>(null)

  useEffect(() => {
    if (selected === null) {
      setInput(null)
    }
  }, [selected])

  useEffect(() => {
    if (passedError !== null) {
      setError(passedError)
    }
  }, [passedError])

  const changeDate = (
    day: Date,
    selectedDay: Date,
    activeModifiers: ActiveModifiers,
    e: React.MouseEvent<Element, MouseEvent>
  ) => {
    setSelected(selectedDay)
    setInput(format(selectedDay, 'dd-MM-yyyy'))
    setError(null)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsedDate = parse(e.currentTarget.value, 'dd-MM-yyyy', new Date())

    if (!isNaN(parsedDate.valueOf())) {
      if (rules.length > 0) {
        checkRules(parsedDate)
      } else {
        setError(null)
        setSelected(parsedDate)
      }
    } else {
      setError('Kies een geldige datum')
    }

    setInput(e.currentTarget.value)
  }

  const checkRules = (date: Date) => {
    let finalDate: Date

    if (rules.includes(Rule.shouldNotAllowDateBeforeCurrentDate)) {
      try {
        finalDate = isDateBefore(date)
      } catch (e) {
        setError(e.message)
        return
      }
    }

    if (rules.includes(Rule.shouldNotAllowDateAfterCurrentDate)) {
      try {
        finalDate = isDateAfter(date)
      } catch (e) {
        setError(e.message)
        return
      }
    }

    if (rules.includes(Rule.shouldBeAfterSpecifiedDate)) {
      try {
        if (!specifiedDate) {
          throw new Error('Zorg ervoor dat alle velden correct ingevuld zijn')
        }

        finalDate = isDateAfterSpecifiedDate(date, specifiedDate)
      } catch (e) {
        setError(e.message)
        return
      }
    }

    if (rules.includes(Rule.shouldDisallowAllDatesAfterSpecifiedDuration)) {
      try {
        if (!duration) {
          throw new Error('Zorg ervoor dat duration gespecifieerd is')
        }

        finalDate = isDateAfterSpecifiedDuration(date, duration)
      } catch (e) {
        setError(e.message)
        return
      }
    }

    if (rules.includes(Rule.shouldDisallowSpecificWeekDays)) {
      try {
        if (disabledDays.length < 1) {
          throw new Error('No disabled week days')
        }

        finalDate = isDateOnAllowedWeekday(date)
      } catch (e) {
        setError(e.message)
        return
      }
    }

    if (rules.includes(Rule.shouldDisallowDisabledApiDays)) {
      try {
        if (disabledDays.length < 1) {
          throw new Error('No disabled week days')
        }

        finalDate = isDateOnDisabledDay(date)
      } catch (e) {
        setError(e.message)
        return
      }
    }

    setSelected(finalDate)
  }

  const isDateBefore = (date: Date): Date => {
    if (!isBefore(date, sub(new Date(), { days: 1 }))) {
      setError(null)
      return date
    } else {
      throw new Error('Kies een datum in de toekomst')
    }
  }

  const isDateAfter = (date: Date): Date => {
    if (!isAfter(date, new Date())) {
      setError(null)
      return date
    } else {
      throw new Error('Deze datum is te ver in de toekomst')
    }
  }

  const isDateAfterSpecifiedDate = (date: Date, specifiedDate: Date): Date => {
    if (isAfter(specifiedDate, date)) {
      throw new Error('Kies een datum op of na de eerder gekozen datum')
    }

    setError(null)
    return date
  }

  const isDateAfterSpecifiedDuration = (date: Date, duration: Duration) => {
    if (isAfter(date, add(new Date(), duration))) {
      throw new Error(
        'Je kan nog geen aanhangwagen reserveren voor over meer dan 180 dagen'
      )
    }

    setError(null)
    return date
  }

  const isDateOnAllowedWeekday = (date: Date): Date => {
    if (disabledDays[0].dayOfWeek.includes(date.getDay())) {
      throw new Error('Kies een datum op een toegelaten dag')
    }

    setError(null)
    return date
  }

  const isDateOnDisabledDay = (date: Date): Date => {
    const allDisabledDays: Array<string> = []

    disabledDays.map((dt) => {
      if (dt instanceof Date) {
        allDisabledDays.push(dt.toDateString())
      }
    })

    if (allDisabledDays.includes(date.toDateString())) {
      throw new Error('Kies een niet gereserveerde dag')
    }

    setError(null)
    return date
  }

  return (
    <Input
      name="datepicker"
      label={label}
      value={input}
      error={error}
      placeholder={new Date().toLocaleDateString('nl-NL')}
      handleChange={handleChange}
      pattern="##-##-####"
      icon={
        <Popover.Root>
          <Popover.Trigger asChild>
            <button className="datepicker__button">
              <TbCalendar size={16} />
            </button>
          </Popover.Trigger>
          <Popover.Anchor />
          <Popover.Portal>
            <Popover.Content className="datepicker__body">
              <DayPicker
                mode="single"
                classNames={{}}
                selected={selected}
                onSelect={changeDate}
                showOutsideDays
                fixedWeeks
                fromMonth={fromMonth}
                toMonth={toMonth ? toMonth : null}
                disabled={disabledDays}
              />
              <Popover.Close />
              <Popover.Arrow />
            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>
      }
    />
  )
}

export { DatePicker }
