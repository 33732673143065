import {
  BagageTrailer,
  BicycleTrailer,
  BicycleTrailerDouble,
  BikeTrailer,
  BoatTrailerDouble,
  CampingTrailer,
  CarTrailer,
  ClosedTrailer,
  ClosedTrailerDouble,
  DrawBarTrailer,
  FlatTrailer,
  FlatTrailerClosed,
  FlatTrailerClosedDouble,
  FlatTrailerDouble,
  FoodTrailer,
  Hitch,
  HorseTrailer,
  LongLoad,
  Net,
  Pilon2,
  PlateauTrailer,
  PlateauTrailerDouble,
  PlugLeft,
  PlugRight,
  Ramps,
  TipperTrailer,
  TipperTrailerDouble,
  TransportTrailer,
  TransportTrailerDouble,
  UnbrakedTrailer,
  UnbrakedTrailerDouble,
  Wheelbarrow,
} from 'data/icons/TrailerIcons'
import { TbFrame, TbLock } from 'react-icons/tb'

export const TrailerCategories = [
  {
    id: 1,
    trailerType: 'Open aanhanger',
    averagePrice: 35,
    minLength: 150,
    maxLength: 450,
    minWidth: 80,
    maxWidth: 250,
    minHeight: 0,
    maxHeight: 1000,
    icon: <TransportTrailerDouble size={36} strokeWidth="1.4" />,
    description:
      'De open aanhanger is een eenvoudige, maar effectieve oplossing voor diverse transportbehoeften. Zonder zij- of bovenwanden kun je makkelijk grote of ongewoon gevormde objecten laden. Dit maakt het een ideale keuze voor tuinwerkzaamheden, het verhuizen van meubels, of het afvoeren van bouwafval. De maximale laadcapaciteit gaat meestal tot 750 kg, waardoor deze aanhanger heel toegankelijk is. Met betrekking tot het trekken van de aanhanger voldoet een gemiddelde personenauto meestal al aan de eisen. Als veiligheidstip is het belangrijk op te merken dat je lading niet beschermd is tegen de elementen. Bij slecht weer is het dus aan te raden om een zeil te gebruiken om je spullen te beschermen.',
    image: '/images/trailerTypes/open-trailer.png',
  },
  {
    id: 2,
    trailerType: 'Gesloten aanhanger',
    averagePrice: 60,
    minLength: 200,
    maxLength: 700,
    minWidth: 90,
    maxWidth: 320,
    minHeight: 100,
    maxHeight: 350,
    icon: <ClosedTrailerDouble size={36} strokeWidth="1.4" />,
    description:
      'Als je iets meer beveiliging nodig hebt, is een gesloten aanhanger de beste keuze. Deze is perfect voor het vervoeren van elektronica, kunstwerken, meubels en andere kostbare of kwetsbare items. De afgesloten structuur zorgt voor extra veiligheid en beschermt je lading tegen weersinvloeden. De laadcapaciteit varieert, maar ligt meestal tussen de 750 kg en 2.000 kg. Voor het trekken van een gesloten aanhanger is vaak een SUV of een kleine vrachtwagen meer geschikt. Vergeet niet om de deuren goed te vergrendelen voor vertrek om het risico op diefstal te minimaliseren.',
    image: '/images/trailerTypes/closed-trailer.png',
  },
  {
    id: 3,
    trailerType: 'Motorfiets aanhanger',
    averagePrice: 40,
    minLength: 120,
    maxLength: 450,
    minWidth: 50,
    maxWidth: 340,
    minHeight: 0,
    maxHeight: 1000,
    icon: <BikeTrailer size={36} strokeWidth="1.4" />,
    description:
      'Voor wie een motorfiets of scooter wil vervoeren, is de motorfietsaanhanger de ideale oplossing. Deze aanhangers zijn vaak voorzien van speciale haken en riemen voor veilig transport. De maximale laadcapaciteit is meestal rond de 500 kg, wat betekent dat een gemiddelde personenauto in veel gevallen voldoet. Zorg er wel voor dat je motorfiets stevig vastgezet is om beweging tijdens het rijden te minimaliseren.',
    image: '/images/trailerTypes/motorcycle-trailer.png',
  },
  {
    id: 4,
    trailerType: 'Bagage aanhanger',
    averagePrice: 70,
    minLength: 280,
    maxLength: 1000,
    minWidth: 90,
    maxWidth: 320,
    minHeight: 0,
    maxHeight: 1000,
    icon: <BagageTrailer size={30} strokeWidth="1.4" />,
    description:
      'Voor extra bagageruimte tijdens kampeertrips of grote gezinsuitjes is de bagage aanhanger ideaal. Deze compacte aanhangers kunnen verrassend veel bagage bevatten en hebben meestal een laadcapaciteit van rond de 500 kg. Een gewone personenauto is meestal voldoende om deze aanhanger te trekken. Als veiligheidstip is het aan te raden om te controleren of de aanhanger afsluitbaar is om diefstal te voorkomen',
    image: '/images/trailerTypes/bagage-trailer.png',
  },
  {
    id: 5,
    trailerType: 'Fietsen aanhanger',
    averagePrice: 20,
    minLength: 50,
    maxLength: 500,
    minWidth: 40,
    maxWidth: 350,
    minHeight: 0,
    maxHeight: 1000,
    icon: <BicycleTrailerDouble size={36} strokeWidth="1.4" />,
    description:
      'De fietsenaanhanger is perfect voor het vervoeren van meerdere fietsen. Met speciale rekken en bevestigingsmechanismen kun je fietsen veilig en schadevrij transporteren. De maximale laadcapaciteit is meestal ongeveer 500 kg, wat betekent dat een gemiddelde personenauto voldoende is. Zorg dat de fietsen stevig vastzitten om schade tijdens het rijden te vermijden.',
    image: '',
  },
  {
    id: 6,
    trailerType: 'Overig',
    averagePrice: 0,
    minLength: 0,
    maxLength: 10000,
    minWidth: 0,
    maxWidth: 10000,
    minHeight: 0,
    maxHeight: 10000,
    icon: <Hitch size={26} strokeWidth="1.4" />,
  },
]

export const AccessoireItems = [
  {
    id: 1,
    accessoire: 'Disselslot',
    icon: <TbLock size={22} />,
    description:
      'Dit is een slot dat op de dissel van de aanhanger wordt geplaatst om te voorkomen dat de aanhanger kan worden gekoppeld aan een trekhaak en gestolen kan worden.',
  },
  {
    id: 2,
    accessoire: 'Oprijplaten',
    icon: <Ramps size={22} />,
    description:
      'Dit zijn platen die op de grond kunnen worden gelegd om de aanhanger op te rijden. Ze worden gebruikt om het laden en lossen van de aanhanger gemakkelijker te maken, vooral wanneer de aanhanger laag bij de grond is.',
  },
  {
    id: 3,
    accessoire: '7 naar 13 polige adapter',
    icon: <PlugRight size={22} />,
    description:
      'Dit is een adapter die wordt gebruikt om een aanhanger met een 7-polige stekker aan te sluiten op een trekhaak met een 13-polige aansluiting.',
  },
  {
    id: 4,
    accessoire: '13 naar 7 polige adapter',
    icon: <PlugLeft size={22} />,
    description:
      'Dit is een adapter die wordt gebruikt om een aanhanger met een 13-polige stekker aan te sluiten op een trekhaak met een 7-polige aansluiting.',
  },
  {
    id: 5,
    accessoire: 'Afdek zeil',
    icon: <TbFrame size={22} />,
    description:
      'Dit is een zeil dat over de lading van de aanhanger kan worden gelegd om deze te beschermen tegen regen, wind en andere weersomstandigheden.',
  },
  {
    id: 6,
    accessoire: 'Afdek net',
    icon: <Net size={22} />,
    description:
      'Dit is een net dat over de lading van de aanhanger kan worden gespannen om te voorkomen dat deze tijdens het transport van de aanhanger valt.',
  },
  {
    id: 10,
    accessoire: 'Pionnen',
    icon: <Pilon2 size={22} />,
    description:
      'Dit is een kist of container die op de aanhanger kan worden bevestigd om spullen veilig op te slaan en te vervoeren. Ze worden vaak gebruikt door professionals die gereedschap en andere apparatuur moeten meenemen naar een werklocatie.',
  },
  {
    id: 11,
    accessoire: 'Kruiwagen',
    icon: <Wheelbarrow size={22} />,
    description:
      'Dit is een kist of container die op de aanhanger kan worden bevestigd om spullen veilig op te slaan en te vervoeren. Ze worden vaak gebruikt door professionals die gereedschap en andere apparatuur moeten meenemen naar een werklocatie.',
  },
  {
    id: 12,
    accessoire: 'Lange lading bord',
    icon: <LongLoad size={22} />,
    description:
      'Dit is een kist of container die op de aanhanger kan worden bevestigd om spullen veilig op te slaan en te vervoeren. Ze worden vaak gebruikt door professionals die gereedschap en andere apparatuur moeten meenemen naar een werklocatie.',
  },
]
